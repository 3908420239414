//   For calls
// Attach Strike (or whatever it's called) = Contract Price + adder
// Exhaust Strike = Attach Strike + spread adder

// For puts
// Attach Strike = max(Contract Price - adder,0)
// Exhaust Strike = max(Attach Strike - spread adder)

export class DerivativeUtil {
  static calculateFixedPutCap(fixedPutStrike: number, putCapAdder: number) {
    const fixedPutCap = fixedPutStrike - putCapAdder;
    return fixedPutCap;
  }

  static calculateFixedPut(putAdder: number, price: number) {
    const fixedPutStrike = price - putAdder;
    return fixedPutStrike;
  }

  static calculateFixedCall(callAdder: number, price: number) {
    const fixedCallStrike = price + callAdder;
    return fixedCallStrike;
  }

  static calculateCallCap(call: number, adder: number) {
    const cap = call + adder;
    return cap;
  }

  static calculateCallAdder(fixedCall: number, price: number) {
    const adder = fixedCall - price;
    return adder;
  }

  static calculatePutAdder(fixedPut: number, price: number) {
    const adder = price - fixedPut;
    return adder;
  }

  static calculateCallCapAdder(fixedCall: number, fixedCallCap: number) {
    const adder = fixedCallCap - fixedCall;
    return adder;
  }

  static calculatePutCapAdder(fixedPut: number, fixedPutCap: number) {
    const adder = fixedPut - fixedPutCap;
    return adder;
  }
  // price - put strike adder - put cap adder > 0.
  // put cap adder < price - put strike adder.
  // put strike adder < price - put cap adder
  // adder < price. cap adder < price - adder
  static validatePutSpread(
    spread: number,
    price: number,
    adder: number | null = null
  ) {
    let isValid = true;

    if (spread >= price) isValid = false;

    const hasSpread = adder !== null;
    if (hasSpread && spread >= price - adder) isValid = false;

    return isValid;
  }

  static validatePutAdder(
    putAdder: number,
    price: number,
    putCapAdder: number | null = null,
    callAdder: number | null = null
  ) {
    let isValid = true;

    if (putAdder >= price) isValid = false;

    const hasSpread = putCapAdder !== null;
    if (hasSpread && putAdder >= price - putCapAdder) isValid = false;

    // we need to check if fixed call > fixed put
    // price + call adder > price - put adder
    // put adder > - call adder
    const hasCallAdder = callAdder !== null;
    if (hasCallAdder) {
      if (putAdder <= -1 * callAdder) isValid = false;
    }

    return isValid;
  }

  // adder > -1 * price. cap adder > 0.
  static validateCallSpread(spread: number) {
    let isValid = true;
    if (spread <= 0) isValid = false;

    return isValid;
  }

  static validateCallAdder(
    adder: number,
    price: number,
    putAdder: number | null = null
  ) {
    let isValid = true;

    let negativePrice = -1 * price;
    if (adder <= negativePrice) isValid = false;

    // we need to check if fixed call > fixed put
    // price + call adder > price - put adder
    // call adder > -1 * put adder

    let hasPutAdder = putAdder !== null;
    if (hasPutAdder) {
      let negativePutAdder = -1 * (putAdder as number);
      if (adder < negativePutAdder) isValid = false;
    }

    return isValid;
  }

  // put: fixed > cap strike > 0.
  static validatePutCap(cap: number, put: number | null = null) {
    let isValid = true;

    if (cap <= 0) isValid = false;

    const hasPut = put !== null;
    if (hasPut && cap >= put) isValid = false;

    return isValid;
  }

  static validatePut(
    put: number,
    cap: number | null = null,
    call: number | null = null
  ) {
    let isValid = true;

    if (put <= 0) isValid = false;

    const hasCap = cap !== null;
    if (hasCap && put <= cap) isValid = false;

    const hasCall = call !== null;
    if (hasCall && put >= call) isValid = false;

    return isValid;
  }

  // 0 < fixed strike < fixed cap strike
  static validateCall(
    call: number,
    cap: number | null = null,
    put: number | null = null
  ) {
    let isValid = true;

    if (call <= 0) isValid = false;

    const hasCap = cap !== null;
    if (hasCap && call >= cap) isValid = false;

    const hasPut = put !== null;
    if (hasPut && call <= put) isValid = false;

    return isValid;
  }

  // if fixed strike is set, fixed cap strike > fixed strike. else fixed cap strike > 0
  static validateCallCap(cap: number, call: number | null = null) {
    let isValid = true;

    if (cap <= 0) isValid = false;

    const hasCall = call !== null;
    if (hasCall && cap <= call) isValid = false;

    return isValid;
  }

  // These can be covered by the call validators. Just plug in put cap to the left
  // call and put is treated as a call, positive is out of the money
  // but it also must be greater than the put cap
  validateFixedCallAndPut(
    callAndPut: number,
    callCap: number | null = null,
    putCap: number | null = null
  ) {
    let isValid = true;

    if (callAndPut <= 0) isValid = false;

    const hasCallCap = callCap !== null;
    if (hasCallCap && callAndPut >= callCap) isValid = false;

    const hasPutCap = putCap !== null;
    if (hasPutCap && callAndPut <= putCap) isValid = false;

    return isValid;
  }

  validateCallAndPutAdder(
    adder: number,
    price: number,
    putCapAdder: number | null = null
  ) {
    let isValid = true;

    let negativePrice = -1 * price;
    if (adder <= negativePrice) isValid = false;

    // We need to check if put cap adder > -callAdder

    let hasPutCapAdder = putCapAdder !== null;
    if (hasPutCapAdder) {
      let negativePutCapAdder = -1 * (putCapAdder as number);
      if (adder < negativePutCapAdder) isValid = false;
    }

    return isValid;
  }

  static extractMonthNumber(date: Date) {
    const monthIndex = date.getMonth(); // 0-based index
    return monthIndex + 1; // Adjust for 1-based month numbering
  }
}
