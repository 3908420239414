import { GridCellProps } from "@progress/kendo-react-grid";
import { NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";

const GridCellNumericEditor = ({ onChange, dataItem, dataIndex, field }: GridCellProps) => {

  const inEdit = dataItem.inEdit;


  const handleChange = (e: NumericTextBoxChangeEvent) => {
    const newValue = e.target.value;

    if (onChange) {
      onChange({
        dataItem,
        field,
        value: newValue,
        dataIndex,
        syntheticEvent: e.syntheticEvent,
      });
    }
  };
  
  const value = dataItem[field as string];

  if (inEdit) {
    return (
      <td>
        <NumericTextBox
          value={value}
          onChange={handleChange}
          min={0}
          format="n2"
          spinners={false}
        />
      </td>
    );
  } else {
    return (
      <td>{dataItem[field as string]}</td>
    );
  }
  
};

export default GridCellNumericEditor;
