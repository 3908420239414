import { useRef } from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { Switch } from "@progress/kendo-react-inputs";
import { ListItem } from "./ListItem";

export const FormSwitchList = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    data, // type ListItem[]
    value, // type (number | string)[]
    name,
    onChange,
    ...others
  } = fieldRenderProps;

  const editorRef = useRef(null);
  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = value ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const handleCheck = (event: any, item: number | string) => {
    const value = fieldRenderProps.value;

    let updatedList: (number | string)[] = [...value];
    if (event.value) {
      updatedList = [...value, item];
    } else {
      updatedList.splice(value.indexOf(item), 1);
    }

    event.value = updatedList;
    onChange(event);
  };

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>

      <div className="list-container">
        {data.map((item: ListItem, index: number) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Label key={`label_${index}`}>{item.label}</Label>
            <Switch
              key={`switch_${index}`}
              id={`${id}_${index}`}
              name={name}
              value={item.value}
              checked={value?.includes(item.value)}
              disabled={disabled || item.disabled}
              onChange={(e) => handleCheck(e, item.value)}
              {...others}
            />
          </div>
        ))}
      </div>

      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
