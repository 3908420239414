import { useState } from "react";
import { addDays, addMonths, addYears } from "@progress/kendo-date-math";
import { parseDate } from "@progress/kendo-intl";
import { useInternationalization } from "@progress/kendo-react-intl";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  TooltipContext,
} from "@progress/kendo-react-charts";
import { Label } from "@progress/kendo-react-labels";
import { Switch } from "@progress/kendo-react-inputs";
import { IApiResults } from "../../../hooks/useApi";
import {
  maxByProperty,
  minByProperty,
} from "../../../utilities/objectUtilities";
import MathUtility from "../../../utilities/mathUtilities";

interface IAccumulatorChartProps {
  knockOutLevel: number;
  strike: number;
  underlying: number;
  startDate: string;
  endDate: string;
  marketStatsData: IApiResults;
  title: string;
}

export const AccumulatorChart = ({
  knockOutLevel,
  strike,
  underlying,
  startDate,
  endDate,
  marketStatsData,
  title,
}: IAccumulatorChartProps) => {
  const [zoomChart, setZoomChart] = useState(true);
  const formatter = useInternationalization();
  const start = new Date(parseDate(startDate));
  const end = new Date(parseDate(endDate));
  const fakeEnd = addMonths(end, 1);  // For aesthetic plotting purposes

  const horizontalPlotBands: any[] = [];
  for (
    let date = new Date(parseDate(startDate));
    date < fakeEnd;
    date = addDays(date, 1)
  )
    horizontalPlotBands.push({ date, knockOutLevel, strike, underlying });

  const verticalPlotBands = [
    {
      from: start,
      to: fakeEnd,
      color: "lightgray",
      opacity: 0.2,
    },
    {
      from: start,
      to: start,
      color: "lightblue",
      label: {
        text: `Start\n${formatter.formatDate(start, "MM/dd/yyyy")}`,
        font: "small-caption",
        margin: { left: -15, top: 5 },
        rotation: -90,
      },
    },
    {
      from: fakeEnd,
      to: fakeEnd,
      color: "lightblue",
      label: {
        text: `End\n${formatter.formatDate(end, "MM/dd/yyyy")}`,
        font: "small-caption",
        margin: { left: -15, top: 5 },
        rotation: -90,
      },
    },
  ];

  const minDate = addYears(start, -1);
  const marketData = marketStatsData?.data?.statistics;

  const closeSeries = Object.keys(marketData)
    .map((k) => {
      return marketData[k].map((d: any) =>
        Object.assign(d, { date: new Date(Date.parse(d.contract)) })
      );
    })
    .flat()
    .filter((d: any) => !d.fullHistory && d.date >= minDate)
    .map((d: any) => {
      return { date: d.date, value: d.close };
    });

  closeSeries.push({ date: start, value: underlying });

  const chartMax = Math.round(Math.max(knockOutLevel, strike, maxByProperty(closeSeries, "value")) * 1.1);
  const chartMin = MathUtility.round(Math.min(knockOutLevel, strike, minByProperty(closeSeries, "value")) * 0.9, 1);

  const renderHistoryTooltip = ({ point }: TooltipContext) => {
    const { value, category } = point;
    return (
      <>
        <div>{formatter.formatDate(category as Date, "MM/dd/yyyy")}</div>
        <div>{formatter.formatNumber(value, "c2")}</div>
      </>
    );
  };

  const renderTooltip = (context: TooltipContext) => {
    const { point } = context;
    return (
      <div>
        {point.series.name}: {formatter.formatNumber(point.value, "c2")}
      </div>
    );
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div style={{ flexBasis: "80%", fontSize: "larger", textAlign: "center" }}>
          {title}
        </div>
        <div style={{ display: "flex" }}>
          <Label>Zoom</Label>
          <Switch
            value={zoomChart}
            checked={zoomChart}
            onChange={(e) => setZoomChart(!zoomChart)}
            size="small"
          />
        </div>
      </div>

      <div>
        <Chart transitions={false}>
          <ChartTooltip />
          <ChartSeries>
            <ChartSeriesItem
              name="Historical Close"
              type="line"
              field="value"
              categoryField="date"
              color="darkgray"
              style="normal"
              data={closeSeries}
              markers={{ visible: false }}
            >
              <ChartSeriesItemTooltip render={renderHistoryTooltip} />
            </ChartSeriesItem>

            <ChartSeriesItem
              name="Knock Out Level"
              type="line"
              field="knockOutLevel"
              categoryField="date"
              color={knockOutLevel < strike ? "green" : "red"}
              style="normal"
              dashType="longDashDot"
              data={horizontalPlotBands}
              markers={{ visible: false }}
            >
              <ChartSeriesItemTooltip render={renderTooltip} />
            </ChartSeriesItem>

            <ChartSeriesItem
              name="Strike"
              type="line"
              field="strike"
              categoryField="date"
              color={strike < knockOutLevel ? "green" : "red"}
              style="normal"
              dashType="longDashDot"
              data={horizontalPlotBands}
              markers={{ visible: false }}
            >
              <ChartSeriesItemTooltip render={renderTooltip} />
            </ChartSeriesItem>

            <ChartSeriesItem
              name="Underlying"
              type="line"
              field="underlying"
              categoryField="date"
              color="black"
              style="normal"
              dashType="dot"
              data={horizontalPlotBands}
              markers={{ visible: false }}
            >
              <ChartSeriesItemTooltip render={renderTooltip} />
            </ChartSeriesItem>

            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                labels={{
                  rotation: -45,
                  content: (e) =>
                    e.value < start ? formatter.formatDate(e.value, "MM/dd/yyyy") : "",
                }}
                plotBands={verticalPlotBands}
                maxDivisions={10}
                max={addDays(fakeEnd, 10)}
              />
            </ChartCategoryAxis>

            <ChartValueAxis>
              <ChartValueAxisItem
                labels={{ format: "c2" }}
                min={zoomChart ? chartMin : 0}
                max={chartMax}
              />
            </ChartValueAxis>
          </ChartSeries>
          <ChartLegend visible={false} />
        </Chart>
      </div>
    </div>
  );
};
