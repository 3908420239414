import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldRenderProps } from "@progress/kendo-react-form";
import { Checkbox, NumericTextBoxBlurEvent } from "@progress/kendo-react-inputs";
import { useInternationalization } from "@progress/kendo-react-intl";
import { Card, CardBody, CardHeader, CardTitle } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { InsuranceQuoteSummary } from ".";
import { FormNumericTextBox } from "../../form";
import InfoIconAndTooltip from "../../utilities/InfoIconAndTooltip";
import { IInsuranceQuoteSummary } from "./Types";

type Props = {
  name: string;
  fieldName: string;
  title: string;
  quoteSummary: IInsuranceQuoteSummary;
  isReadOnly?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  isExpandable?: boolean;
  isExpanded?: boolean;
  onBlur?(e: NumericTextBoxBlurEvent): void;
  onCheck?(value: string): void;
  onExpand?(value: string): void;
  tooltip?: string;
  tooltipLink?: string;
};

export const RetentionCard = ({
  name,
  fieldName,
  title,
  tooltip,
  tooltipLink,
  quoteSummary,
  isReadOnly,
  isSelectable,
  isSelected,
  isExpandable,
  isExpanded,
  onBlur,
  onCheck,
  onExpand,
}: Props) => {
  const formatter = useInternationalization();
  const headerStyle = {
    borderColor: isSelected ? "#4cbb17" : "inherit",
    backgroundColor: isSelected ? "#4cbb17" : "inherit",
    color: isSelected ? "white" : "#4cbb17",
    cursor: isSelectable && isReadOnly ? "pointer" : "inherit",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  };

  const loading = quoteSummary?.loading;

  const LevelDisplay = (fieldRenderProps: FieldRenderProps) => {
    const { value } = fieldRenderProps;
    const displayValue = formatter.formatNumber(value, "p0");

    return isSelectable ? (
      <CardTitle style={headerStyle}>{displayValue}</CardTitle>
    ) : (
      <div>Retention Level: {displayValue}</div>
    );
  };

  const handleCheckClick = () => {
    onCheck && onCheck(name);
  };

  const handleExpandClick = (e: any) => {
    e.preventDefault();
    onExpand && onExpand(name);
  };

  // Min and max reset out-of-range values to closest in-range value
  // So, validation is probably not needed. To make validation work while overriding onBlur,
  // Validation must be done manually in our onBlur function since the form won't do it automatically.
  return (
    <Card>
      <CardHeader style={headerStyle}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={isSelectable && isReadOnly ? handleCheckClick : undefined}
        >
          <CardTitle style={headerStyle}>
            <div style={{ display: "flex" }}>
              {isSelectable && (
                <Checkbox
                  checked={isSelected}
                  disabled={loading}
                  style={{ marginRight: 5 }}
                  onClick={handleCheckClick}
                />
              )}
              {title}
              {tooltip && <InfoIconAndTooltip link={tooltipLink}>{tooltip}</InfoIconAndTooltip>}
            </div>
          </CardTitle>

          {isReadOnly && (
            <Field
              id={name}
              key={name}
              name={fieldName}
              component={LevelDisplay}
              disabled={loading}
            />
          )}
          {!isReadOnly && (
            <Field
              id={name}
              key={name}
              name={fieldName}
              component={FormNumericTextBox}
              spinners={false}
              onBlurOverride={onBlur}
              min={0}
              max={1}
              format="p0"
              style={{ width: "75px", marginTop: 0, marginBottom: 5 }}
            />
          )}
        </div>
      </CardHeader>

      <CardBody style={{ fontSize: "smaller", padding: "1rem 0 0 0" }}>
        <div style={{ width: "95%" }}>
          <InsuranceQuoteSummary name={name} {...quoteSummary} />
        </div>

        {/* TODO: Move to children */}
        {!loading && isExpandable && isSelected && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 5,
            }}
          >
            <div>
              <Tooltip anchorElement="target" position="bottom" parentTitle={true}>
                <Button
                  icon={isExpanded ? "undo" : "fullscreen"}
                  size="small"
                  themeColor="primary"
                  title={isExpanded ? "Back to Compare" : "Customize"}
                  onClick={handleExpandClick}
                  style={{ visibility: isExpandable ? "inherit" : "hidden", padding: 3 }}
                />
              </Tooltip>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
