import { formatDate, parseDate } from "@progress/kendo-intl";

const FormatShortDate = "d"; // M/d/y
const FormatGeneral = "g"; // M/d/y h:mm a
const FormatUTC = "u";
const FormatDateTime = "MM/dd/yyyy h:mm:ss a";
const FormatYMD = "yyyy-MM-dd";
const FormatMD = "MMM dd";
const FormatMY = "MMM yyyy";
const FormatM = "MMM";

export default class DateUtility {
  static formatShortDate = (date: string | Date): string => this.format(date, FormatShortDate);

  static formatDateGeneral = (date: string | Date): string => this.format(date, FormatGeneral);

  static formatUTC = (date: string | Date): string => this.format(date, FormatUTC);

  static formatDateTime = (date: string | Date): string => this.format(date, FormatDateTime);

  static formatDateMD = (date: string | Date): string => this.format(date, FormatMD);

  static formatDateMY = (date: string | Date): string => this.format(date, FormatMY);

  static formatDateM = (date: string | Date): string => this.format(date, FormatM);

  static formatDateYMD = (date: string | Date): string => this.format(date, FormatYMD);

  static dateAsUtc = (date: Date): Date =>
    new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
      ),
    );

  static parseMonth(dateString: string) {
    const date = parseDate(dateString);

    date.setDate(1);

    return date;
  }

  static addOffsetToDateString(dateString: string, offset: number) {
    const date = parseDate(dateString);
    const newDate = this.addMonthOffset(date, offset);
    const newDateString = formatDate(newDate, FormatYMD);
    return newDateString;
  }

  static convertToDateWithOffset(dateString: string, offset: number) {
    const date = parseDate(dateString);
    const newDate = this.addMonthOffset(date, offset);
    return newDate;
  }

  static addMonthOffset(date: Date, offset: number) {
    const clone = new Date(date);
    clone.setMonth(date.getMonth() + offset);
    return clone;
  }

  private static format = (date: string | Date, format: string): string => {
    try {
      const newDate = typeof date === "string" ? parseDate(date) : date;

      return date
        ? formatDate(format === FormatUTC ? this.dateAsUtc(newDate) : newDate, format)
        : "";
    } catch {
      return "";
    }
  };
}
