import { CSSProperties } from "react";

type Props = {
  label: string;
  content: string;
  hint?: string;
  labelStyle?: CSSProperties;
};

const DEFAULT_LABEL_STYLE = { fontSize: "small", color: "gray" };

const DetailItem = ({ label, content, hint, labelStyle = DEFAULT_LABEL_STYLE }: Props) => {
  const hintStyle = hint ? { cursor: "pointer", textDecoration: "underline dotted" } : {};

  return (
    <div>
      <span style={{ ...labelStyle, ...hintStyle }} title={hint}>
        {label}
      </span>
      <div>{content}</div>
    </div>
  );
};
export default DetailItem;
