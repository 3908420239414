import { parseDate } from "@progress/kendo-intl";
import { FormRenderProps } from "@progress/kendo-react-form";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useCallback, useEffect, useMemo, useState } from "react";
import DateUtility from "../../../utilities/dateUtilities";
import { sumByProperty } from "../../../utilities/objectUtilities";
import { InsuranceSubmitSummary, RetentionCard } from ".";
import PdfDownloadButton from "../quoteDetails/PdfDownloadButton";
import { QuoteExpiry, SubmitDisclaimer } from "..";
import documentsApi from "../../../api/documents";
import insuranceApi from "../../../api/insurance";
import { useApi } from "../../../hooks/useApi";
import { useUserContext } from "../../../hooks/useUserContext";
import InsuranceUtility from "./insuranceUtilities";

interface IInsuranceSubmitProps {
  formRenderProps: FormRenderProps;
  product: any;
}

export const Submit = ({ formRenderProps, product }: IInsuranceSubmitProps) => {
  const { context: user } = useUserContext();
  const { commodity, company } = product;

  const formatter = useInternationalization();
  const getQuoteApi = useApi(insuranceApi.getQuote);

  const startDate = parseDate(formRenderProps.valueGetter("contract.start"));
  const endDate = parseDate(formRenderProps.valueGetter("contract.end"));
  const retentionLevels = formRenderProps.valueGetter("retentionLevels");
  const selectedLevel = formRenderProps.valueGetter("selectedLevel");
  const level = retentionLevels[selectedLevel];
  const quote = level.quote;
  const totalPrice = sumByProperty(quote, "brokerPrice");
  const coverages = InsuranceUtility.getCoverageData(formRenderProps);
  const canSubmit = user.roles.canSubmitQuotes(company.role);

  const quoteSummary = {
    loading: false,
    units: commodity.units.slice(0, -1),
    coverages: coverages,
    prices: level.pricing.details,
    quote: quote,
    companyRole: company.role,
    unitPriceDecimals: commodity.decimals,
  };

  const quoteDetails = useMemo(() => {
    return {
      units: commodity.units,
      coverage: formatter.formatNumber(formRenderProps.valueGetter("totalCoverage"), "c0"),
      volume: formRenderProps.valueGetter("volume"),
      startDate: DateUtility.formatDateMY(startDate),
      endDate: DateUtility.formatDateMY(endDate),
      retentionLevel: formatter.formatNumber(level.value, "p0"),
      quoteId: formRenderProps.valueGetter("quoteId"),
      pricingId: formRenderProps.valueGetter("pricingId"),
      name: formRenderProps.valueGetter("quoteName"),
      price: formatter.formatNumber(totalPrice, "c2"),
    };
  }, [commodity.units, endDate, formRenderProps, formatter, level.value, startDate, totalPrice]);

  const [loading, setLoading] = useState(false);
  const [expiration, setExpiration] = useState(
    new Date(Date.parse(formRenderProps.valueGetter("expiry")))
  );
  const checkboxFieldName = "isConfirmed";

  const getQuote = useCallback(async () => {
    getQuoteApi.request(quoteDetails.quoteId);
  }, [getQuoteApi, quoteDetails]);

  useEffect(() => {
    setLoading(getQuoteApi.loading);

    if (getQuoteApi.data) setExpiration(new Date(Date.parse(getQuoteApi.data.quoteExpiry)));
  }, [getQuoteApi.data, getQuoteApi.loading]);

  const onExpiryChanged = (expiry: string) => {
    formRenderProps.onChange("expiry", { value: expiry });
  };

  const handleAgree = () => {
    formRenderProps.onChange(checkboxFieldName, { value: true });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ flexBasis: "90%" }}>
          <RetentionCard
            name={selectedLevel}
            fieldName={`retentionLevels.${selectedLevel}.value`}
            isReadOnly={true}
            title={"Quote Summary"}
            quoteSummary={quoteSummary}
            isSelected={true}
          />

          <PdfDownloadButton
            quoteId={quoteDetails.quoteId}
            pricingId={quoteDetails.pricingId}
            api={documentsApi.getQuoteSummaryPdf}
          />

          <SubmitDisclaimer fieldName={checkboxFieldName} handleAgree={handleAgree}>
            <InsuranceSubmitSummary quoteDetails={quoteDetails} canSubmit={canSubmit} />
          </SubmitDisclaimer>

          <QuoteExpiry
            expiration={expiration}
            hideExpiry={true}
            loading={loading}
            onExpire={onExpiryChanged}
            onRefresh={getQuote}
          />
        </div>
      </div>
    </>
  );
};
