import { useEffect, useState } from "react";
import { CircularGauge } from "@progress/kendo-react-gauges";
import { Error } from "@progress/kendo-react-labels";
import usersApi from "../../api/users";
import { useFetch } from "../../hooks/useApi";
import { LoadingIndicator } from "../LoadingIndicator";

export const ActiveUsersChart = () => {
  const { data, loading, error } = useFetch(usersApi.getCount);
  const [percent, setPercent] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (data) {
      setPercent(data.percent);
      setCount(data.count);
    }
  }, [data]);

  const arcCenterRenderer = () => {
    return <h1>{count}</h1>;
  };

  return (
    <div>
      <div className="circular-gauge-container">
        <LoadingIndicator loading={loading} />
        {error && <Error>{error}</Error>}

        {!loading && !error && (
          <div>
            <CircularGauge
              value={percent}
              arcCenterRender={arcCenterRenderer}
              style={{ height: "100px" }}
              scale={{
                startAngle: 90,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
