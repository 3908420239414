import { ScrollView } from "@progress/kendo-react-scrollview";
import { Notification } from "@progress/kendo-react-notification";

export enum Severity {
  Info = "info",
  Warning = "warning",
  Error = "error",
  Success = "success",
  None = "none",
}

interface INotificationItem {
  severity: Severity;
  message: string;
}

type Props = { items: INotificationItem[] };

export const Notifications = ({ items }: Props) => {
  return items?.length > 0 ? (
    <ScrollView
      style={{
        height: 36,
        border: "none",
      }}
      pageable={false}
      arrows={false}
      endless={true}
    >
      {items.map((item, index) => {
        return (
          <div key={index}>
            <Notification
              style={{ padding: 5, justifyContent: "start" }}
              type={{
                style: item.severity,
                icon: true,
              }}
              closable={false}
            >
              <span>{item.message}</span>
            </Notification>
          </div>
        );
      })}
    </ScrollView>
  ) : (
    <></>
  );
};
