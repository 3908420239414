import { Card, CardTitle, CardBody } from "@progress/kendo-react-layout";
import { AccumulatorChart, AccumulatorQuoteSummary } from ".";
import { ICommodity } from "../Commodity";

interface IAccumulatorQuoteCardProps {
  title: string;
  quote: any;
  commodity: ICommodity;
  showInputs?: boolean;
  chartData?: any;
}

export const AccumulatorQuoteCard = ({
  title,
  quote,
  commodity,
  showInputs,
  chartData,
}: IAccumulatorQuoteCardProps) => {
  const { knockOutLevel, strike, underlying, marketStatsResults } = chartData || {};

  return (
    <Card>
      <CardTitle
        style={{
          borderColor: "#4cbb17",
          backgroundColor: "#4cbb17",
          color: "white",
          padding: "0.5rem",
        }}
      >
        {title}
      </CardTitle>
      <CardBody>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div>
            <AccumulatorQuoteSummary quote={quote} units={commodity.units} showInputs={showInputs} />
          </div>
          {chartData && (
            <div style={{ flexBasis: "60%" }}>
              <AccumulatorChart
                knockOutLevel={knockOutLevel}
                strike={strike}
                underlying={underlying}
                startDate={quote.startDate}
                endDate={quote.endDate}
                marketStatsData={marketStatsResults}
                title={`${commodity.name} (${commodity.abbreviation})`}
              />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
