import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Error, Hint } from "@progress/kendo-react-labels";
import { Card, CardSubtitle } from "@progress/kendo-react-layout";
import { Popover } from "@progress/kendo-react-tooltip";
import { useCallback, useRef, useState } from "react";
import { getIconFileName } from "../../utilities/textUtilities";

// TODO: clean up hard-coded styles: move to scss classes and/or pass styles as props

const OptionsCard = (props: any) => {
  const { item, value, name, onChange } = props;
  const anchor = useRef<any | null>(null);

  const selected = item?.items?.some((o: any) => value === o.value);

  const [cardHover, setCardHover] = useState(false);
  const [optionHover, setOptionHover] = useState("");

  const onSelect = useCallback(
    (e: any) => {
      setCardHover(false);
      const event = { target: { value: e.value } };
      if (onChange) onChange(event);
    },
    [setCardHover]
  );

  const onClick = useCallback(
    (value: any) => {
      setCardHover(false);
      if (onChange) {
        const event = { target: { value: value } };
        onChange(event);
      }
    },
    [setCardHover]
  );

  const iconFileName = getIconFileName(
    item.icon,
    item.disabled,
    cardHover,
    selected
  );

  return (
    <label
      onMouseEnter={() => setCardHover(true)}
      onMouseLeave={() => setCardHover(false)}
      onClick={(e: any) => e.preventDefault()}
      htmlFor={item.label}
    >
      <Card
        ref={anchor}
        key={value}
        style={{
          cursor: item.disabled ? "inherit" : "pointer",
          border: "none",
          width: 100,
          textAlign: "center",
        }}
      >
        <div>
          {item.icon && (
            <div
              style={{
                border: "2px solid lightgray",
                borderRadius: "60%",
                padding: ".55rem",
                backgroundColor: selected ? "#378811" : "white",
                display: "block", 
                margin: "auto",
                width: item.size === "small" ? 32 : 64,
                height: item.size === "small" ? 32 : 64,
              }}
            >
              <img
                alt={item.label}
                src={iconFileName}
              />
            </div>
          )}

          {item.hint && (
            <div
              style={{
                fontSize: "x-small",
                color: "gray",
              }}
            >
              {item.hint}
            </div>
          )}

          <CardSubtitle
            style={{
              color: item.disabled ? "#ccc" : selected ? "black" : "gray",
            }}
          >
            {item.label}
          </CardSubtitle>
        </div>
      </Card>

      <Popover
        show={cardHover && !item.disabled}
        anchor={anchor.current && anchor.current.element}
        callout={false}
        position={"bottom"}
      >
        {item.items.map((option: any, index: number) => (
          <div
            onMouseEnter={() => setOptionHover(option.value.toString())}
            onMouseLeave={() => setOptionHover("")}
            key={index}
          >
            <RadioButton
              onChange={onSelect}
              checked={value === option.value}
              id={option.label}
              value={option.value}
              name={name}
              disabled={option.disabled}
              key={`${name}_${index}`}
            />
            <span
              style={{
                color: option.disabled
                  ? "#ccc"
                  : value === option.value
                  ? "black"
                  : "gray",
                marginLeft: 2,
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => onClick(option.value)}
              >
                {option.label}
              </span>
            </span>
          </div>
        ))}
      </Popover>
    </label>
  );
};

export const CardOptionsSelector = (props: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    id,
    label,
    valid,
    disabled,
    hint,
    visited,
    modified,
    data,
    name,
    value,
    ...others
  } = props;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const onChange = useCallback(
    (event: any) => {
      props.onChange({ value: event.target.value });
    },
    [props]
  );

  return (
    <>
      {data?.length === 0 && (
        <FieldWrapper>
          <div>{label}</div>
          <label className="k-text-error">No selections available</label>
        </FieldWrapper>
      )}

      {data?.length > 0 && (
        <FieldWrapper>
          <label className={`k-label${valid ? "" : " k-text-error"}`}>
            {label}
          </label>

          <ul className="k-radio-list k-list-horizontal">
            {data.map((item: any, index: number) => {
              return (
                <li className="k-radio-item" key={index}>
                  <OptionsCard
                    key={value}
                    item={item}
                    value={value}
                    name={name}
                    onChange={onChange}
                  />
                </li>
              );
            })}
          </ul>

          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </FieldWrapper>
      )}
    </>
  );
};
