import { useEffect, useState } from "react";
import { Card } from "@progress/kendo-react-layout";
import { Error } from "@progress/kendo-react-labels";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { ChartFilter, ChartSwitch } from "./insurance";
import { LoadingIndicator } from "../LoadingIndicator";
import { RecentPricesStockChart } from "../charts";
import { IApiResults } from "../../hooks/useApi";

type Props = {
  apiResults: IApiResults;
};

export const PricingHistoryChartDisplay = ({ apiResults }: Props) => {
  const { data, loading, error } = apiResults;

  const radioButtonData: any[] = [
    { label: "All Days", value: 0 },
    { label: "Remaining Days", value: 1 },
  ];

  const [filter, setFilter] = useState<any | null>(null);
  const [apiData, setApiData] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any[]>([]);
  const [displayPercent, setDisplayPercent] = useState(false);
  const [path, setPath] = useState(radioButtonData[1].value);

  useEffect(() => {
    if (!loading && data?.statistics) {
      const marketData = data.statistics;

      const dataCopy = Object.keys(marketData).map((k) => {
        return marketData[k].map((d: any) =>
          Object.assign(d, { date: new Date(Date.parse(d.contract)) })
        );
      });

      setApiData(dataCopy);
    }
  }, [loading, data]);

  useEffect(() => {
    if (path < apiData.length) {
      const data = (apiData[path] || []).filter(
        (p: any) => !filter || p.date.getMonth() === filter?.value
      );

      setChartData(data);
    }
  }, [apiData, filter, path]);

  return (
    <>
      <LoadingIndicator loading={loading} />
      {error && <Error>{error}</Error>}
      {!loading && (
        <Card style={{ borderColor: "darkgray" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <ChartSwitch
              value={displayPercent}
              onChange={(e: any) => setDisplayPercent(e.value)}
            />

            <ChartFilter
              value={filter}
              onChange={(e: any) => setFilter(e.value)}
            />

            <RadioGroup
              data={radioButtonData}
              value={path}
              layout="horizontal"
              onChange={(e: any) => setPath(e.value)}
            />
          </div>
          <RecentPricesStockChart
            data={chartData}
            displayAsPercent={displayPercent}
          />
        </Card>
      )}
    </>
  );
};
