import { Button } from "@progress/kendo-react-buttons";
import { FormElement } from "@progress/kendo-react-form";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import contractsApi from "../../../api/contracts";
import { getStructures } from "../../../api/vanilla";
import { useFetch } from "../../../hooks/useApi";
import { ContractDates, useDatePair } from "../../../hooks/useDatePair";
import { SettlementType } from "../../../utilities/vanilla/vanillaEnums";
import { WorkflowSubmitButton } from "../WorkflowSubmitButton";
import StartVanillaQuote from "./StartVanillaQuote";
import { VanillaStepperHeader } from "./VanillaStepperHeader";
import VanillaStructureContainer from "./VanillaStructureContainer";

const VanillaFormRender = ({
  step,
  commodity,
  formRenderProps,
  instrument,
  steps,
  onPrevClick,
  isSubmitStep,
  isPreviousStepsValid,
  canSubmit,
}: any) => {
  const contractDates: ContractDates = formRenderProps.valueGetter("contract");

  const quoteId: number = formRenderProps.valueGetter("quoteId");

  const marketStatsResults = useFetch(
    contractsApi.getMarketStatistics,
    commodity.id,
    instrument.id,
  );

  // Reset allocations to flat curve if and only if dates change and monthly
  // Allocations were previously customized.

  const resetAllocation = () => formRenderProps.onChange("allocation", { value: 0 });
  const getAllocations = () => formRenderProps.valueGetter("allocation");

  useDatePair(contractDates, resetAllocation, getAllocations);

  const { state: { data } = {} } = useLocation();
  const [product] = useState<any>({ ...data, instrument, commodity });

  const { data: structuresData } = useFetch(getStructures, data.instrument.id);

  const structure = structuresData?.structures.find((structure: any) => {
    return structure.structureId === formRenderProps.valueGetter("structureId");
  });

  const isSubmitDisabled = !(isPreviousStepsValid && formRenderProps.valid);

  const quoteName = formRenderProps.valueGetter("quoteName");
  const startDate = formRenderProps.valueGetter("contract.start");
  const endDate = formRenderProps.valueGetter("contract.end");

  const monthsLabel = instrument.calendarMonthOffset === 0 ? "Contract" : "Calendar";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: 50,
        marginLeft: 50,
      }}
    >
      {step > 0 && (
        <VanillaStepperHeader
          quoteId={quoteId}
          quoteName={quoteName}
          product={product}
          step={step}
          startDate={startDate}
          endDate={endDate}
          structure={structure.longName}
          direction={formRenderProps.valueGetter("direction") === 1 ? "Long" : "Short"}
          settlement={SettlementType[formRenderProps.valueGetter("settlement")]}
          monthsLabel={monthsLabel}
        />
      )}

      <FormElement>
        {step > 0 && <span className={"k-form-separator"} style={{ marginTop: 10 }} />}

        {step === 0 && <StartVanillaQuote formRenderProps={formRenderProps} product={product} />}

        {step > 0 && (
          <VanillaStructureContainer
            formRenderProps={formRenderProps}
            step={step}
            product={product}
            marketStatsResults={marketStatsResults}
            structureName={structure.longName}
          />
        )}

        <span style={{ marginTop: 20 }} className={"k-form-separator"} />
        <div className="multi-step-form-footer" style={{ paddingTop: 10 }}>
          <span className="form-step-label k-button-text">
            Step {step + 1} of {steps.length}
          </span>
          <div>
            {step !== 0 ? (
              <Button style={{ marginRight: "16px" }} onClick={onPrevClick}>
                Previous
              </Button>
            ) : undefined}
            <WorkflowSubmitButton
              isDisabled={isSubmitDisabled}
              isSubmitStep={isSubmitStep}
              onSubmit={formRenderProps.onSubmit}
              canSubmit={canSubmit}
            />
          </div>
        </div>
      </FormElement>
    </div>
  );
};

export default VanillaFormRender;
