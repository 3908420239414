import { Tooltip } from "@progress/kendo-react-tooltip";
import { useEffect, useState } from "react";
import { ConfigurationsFilters, ConfigurationsGrid, IProductFilters } from ".";
import { Configuration } from "./Configuration";
import { Company } from "../Company";

interface IConfigurationsOverviewTabProps {
  companies: Company[];
  companyId: number;
  commodityTypes: Record<string, string>;
  configurations: Record<number, Configuration[]>;
  margins: any[];
  marginField: string;
  productTypes: Record<string, string>;
  onChange: () => void;
}

export const ConfigurationsOverviewTab = ({
  companies,
  companyId,
  commodityTypes,
  configurations,
  margins,
  marginField,
  productTypes,
  onChange,
}: IConfigurationsOverviewTabProps) => {
  const childCompanyIds =
    companies?.filter((c) => c.parentCompanyId === companyId).map((c) => c.id) ?? [];

  const [gridData, setGridData] = useState<any[]>([]);
  const [filter, setFilter] = useState<IProductFilters | null>(null);

  useEffect(() => {
    const selectedCommodities = filter?.commodities ?? [];
    const selectedProducts = filter?.products ?? [];

    const newConfigs = (configurations[companyId] ?? [])
      .filter((c) => c.isActive)
      .filter(
        (c) => selectedCommodities.length === 0 || selectedCommodities.includes(c.commodityType),
      )
      .filter((c) => selectedProducts.length === 0 || selectedProducts.includes(c.productType))
      .sort((a, b) =>
        !a || !b
          ? 0
          : a.commodity.localeCompare(b.commodity) ||
            a.type.localeCompare(b.type) ||
            a.instrument.localeCompare(b.instrument),
      );

    const allConfigs = Object.values(configurations).flat();

    const gridConfigList = newConfigs
      .map((config) => {
        return companies
          .filter((comp) => comp.businessTypes.includes(config.businessTypeId))
          .map((comp) => {
            const item: any =
              allConfigs.find(
                (conf) => conf.companyId === comp.id && conf.productId === config.productId,
              ) ??
              Configuration.create(
                Object.assign({}, config, {
                  configurationId: 0,
                  companyId: comp.id,
                  isActive: false,
                  isConfigured: false,
                }),
                Object.assign({}, config, {
                  company: comp.name,
                  parentConfigurationId: config.configurationId,
                }),
              );

            if (marginField) {
              const margin = margins.find((m) => m.marginId === item[marginField]);
              item.margin = item.configurationId === 0 ? "" : margin?.longName || "";
            }

            return item;
          });
      })
      .flat();

    // Filter out unactionable configs
    setGridData(
      gridConfigList.filter(
        (c) =>
          c.configurationId !== 0 ||
          (c.configurationId === 0 &&
            childCompanyIds.includes(c.companyId) &&
            newConfigs.find((c2) => c2.configurationId === c.parentConfigurationId)?.isEnabled()),
      ),
    );
  }, [configurations, filter]);

  return (
    <>
      <div style={{ margin: "1rem 0" }}>
        <ConfigurationsFilters
          commodityTypes={commodityTypes}
          productTypes={productTypes}
          onChangeFilters={setFilter}
        />
      </div>

      <Tooltip anchorElement="target" parentTitle={true} position={"top"}>
        <ConfigurationsGrid
          data={gridData}
          childCompanyIds={childCompanyIds}
          filterText={filter?.text ?? ""}
          margins={margins}
          marginField={marginField}
          onChange={onChange}
        />
      </Tooltip>
    </>
  );
};
