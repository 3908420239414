import { Button } from "@progress/kendo-react-buttons";
import { Popover, PopoverActionsBar, PopoverPosition } from "@progress/kendo-react-tooltip";
import { useEffect, useRef, useState } from "react";

interface DeletePopupProps {
  action: string,
  position?: PopoverPosition,
  data: any,
  onOpen: (action: string) => void,
  onClose: () => void,
  onConfirm: () => void
};

export const DeletePopup = (props: DeletePopupProps) => {
  const anchor = useRef<any>();
  const { action, position, data, onOpen, onClose, onConfirm } = props;
  const { quoteId } = data;

  const key = `delete${quoteId}`;
  const disabled = action.length > 0 && action !== key;
  
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(action === key);
  }, []);

  const onClick = () => {
    onOpen(key);  
  };

  const onCancel = () => {
    onClose();
  };

  const onDelete = (e: any) => {
    onConfirm();
  };

  return (
    <div>
      <Button
        ref={anchor}
        disabled={disabled}
        icon="delete"
        size="small"
        themeColor="primary"
        fillMode="outline"
        title="Delete Quote"
        onClick={onClick}
      />

      <Popover
        key={key}
        show={show}
        anchor={anchor.current && anchor.current.element}
        position={position || "top"}
        animate={{ closeDuration: 300, openDuration: 300 }}
        callout={false}
        collision={{
          vertical: "flip",
          horizontal: "flip",
        }}
        style={{ width: 300 }}
      >
        <div>{`Are you sure you want to delete quote ${quoteId}?`}</div>

        <PopoverActionsBar>
          <Button
            themeColor={"primary"}
            size={"small"}
            onClick={onDelete}
          >
            Delete
          </Button>
          <Button size={"small"} onClick={onCancel}>
            Cancel
          </Button>
        </PopoverActionsBar>
      </Popover>
    </div>
  );
};
