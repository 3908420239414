import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useState } from "react";
import { getIconFileName } from "../../../utilities/textUtilities";
import { ConfigStatus } from "./ConfigurationEnums";
import { Configuration } from "./Configuration";
import { StatusIcon } from ".";

interface IConfigurationItemProps {
  configuration: Configuration;
  commodityTypes: Record<string, string>;
  selected: number[];
  selectedByParent?: boolean;
  showStatus?: boolean;
  onClick(id: number, parentId?: number): void;
}

export const ConfigurationItem = ({
  configuration,
  commodityTypes,
  selected,
  selectedByParent,
  showStatus,
  onClick,
}: IConfigurationItemProps) => {
  const isSelected = selected?.includes(
    selectedByParent ? configuration.parentConfigurationId : configuration.configurationId,
  );
  const isEnabled = configuration.isEnabled();
  const status =
    configuration.status === ConfigStatus.Margins && configuration.margin
      ? ConfigStatus.ChildMargins
      : configuration.status as ConfigStatus;

  const [hovered, setHovered] = useState(false);

  return (
    <Tooltip anchorElement="target" parentTitle={true} position={"top"}>
      <span
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => onClick(configuration.configurationId, configuration.parentConfigurationId)}
      >
        <Card
          className="card-component"
          orientation="horizontal"
          type="primary"
          style={{
            fontSize: "small",
            backgroundColor: isSelected ? "#4cbb17" : "inherit",
            borderColor: hovered ? "#4cbb17" : isEnabled ? "gray" : "lightgray",
            color: isSelected ? "white" : !isEnabled ? "gray" : "inherit",
            padding: 0,
            minHeight: 100,
            maxWidth: 250,
            cursor: !isEnabled ? "inherit" : "pointer",
          }}
        >
          <CardHeader
            style={{
              width: "10%",
              border: "none",
              paddingLeft: 5,
              paddingTop: 10,
              backgroundColor: isSelected ? "#4cbb17" : "inherit",
            }}
          >
            <div style={{ width: 18 }}>
              <img
                src={getIconFileName(configuration.commodityIcon, !isEnabled, hovered, isSelected)}
                title={commodityTypes[configuration.commodityType]}
              />
            </div>
            <div style={{ width: 18, marginTop: 10 }}>
              <img
                src={getIconFileName(configuration.typeIcon, !isEnabled, hovered, isSelected)}
                title={configuration.productType}
              />
            </div>

            {showStatus && (
              <div>
                <StatusIcon status={status} hideUnassigned={true} />
              </div>
            )}
          </CardHeader>

          <CardBody style={{ margin: 5, padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <div
                  style={{
                    fontSize: "small",
                    fontWeight: isEnabled ? "bolder" : "inherit",
                  }}
                >
                  {configuration.commodity}
                </div>
                <div>{configuration.instrument}</div>
              </div>
            </div>
          </CardBody>
        </Card>
      </span>
    </Tooltip>
  );
};
