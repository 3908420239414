import { apiClient } from "./apiClient";

const endpoint = "users";

const getAll = () => apiClient(endpoint).fetch();

const get = () => apiClient(`${endpoint}/account`).fetch();

const getCount = () => apiClient(`${endpoint}/count`).fetch();

const create = (user: any) => apiClient(endpoint).post({ user: user });

const update = (user: any) => apiClient(endpoint).put({ user: user });

const getAgreementDocuments = () => apiClient(`${endpoint}/agreementDocuments`).fetch();

const approveAgreementDocuments = (agreementDocumentIds: number[]) =>
  apiClient(`${endpoint}/agreementDocuments`).put({ agreementDocumentIds });

export default {
  getAll,
  get,
  getCount,
  create,
  update,
  getAgreementDocuments,
  approveAgreementDocuments,
};
