import { Button } from "@progress/kendo-react-buttons";
import { Field, FormElement } from "@progress/kendo-react-form";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { WorkflowSubmitButton } from "../WorkflowSubmitButton";
import {
  AccumulatorStartStep,
  AccumulatorStepperHeader,
  AccumulatorQuoteStep,
  AccumulatorSubmitStep,
} from ".";
import FormCheckbox from "../../form/FormCheckBox";
import DateUtility from "../../../utilities/dateUtilities";
import { useFetch } from "../../../hooks/useApi";
import accumulatorApi from "../../../api/accumulator";
import contractsApi from "../../../api/contracts";
import { objectToLookup } from "../../../utilities/objectUtilities";
import { requiredCheckboxValidator } from "../../../validators";

export const AccumulatorFormRender = ({
  step,
  commodity,
  formRenderProps,
  instrument,
  steps,
  onPrevClick,
  isSubmitStep,
  isPreviousStepsValid,
  canSubmit,
}: any) => {
  const { state: { data } = {} } = useLocation();
  const [product] = useState<any>({ ...data, instrument, commodity });

  const quoteId: number = formRenderProps.valueGetter("quoteId");
  const quoteName = formRenderProps.valueGetter("quoteName");
  const contract = DateUtility.formatDateMY(
    formRenderProps.valueGetter("contract")
  );
  const values = {
    knockOutTypeId: formRenderProps.valueGetter("knockOutTypeId"),
    doubleUpTypeId: formRenderProps.valueGetter("doubleUpTypeId"),
    instrumentTypeId: formRenderProps.valueGetter("instrumentTypeId"),
    settlementTypeId: formRenderProps.valueGetter("settlementTypeId"),
    volume: formRenderProps.valueGetter("volume"),
  };
  const isSubmitDisabled = !(isPreviousStepsValid && formRenderProps.valid);

  const { data: lookupData } = useFetch(
    accumulatorApi.getLookups,
    product.instrument.id
  );

  const marketStatsResults = useFetch(
    contractsApi.getMarketStatistics,
    product.commodity.id,
    product.instrument.id
  );

  const formatLookupData = (data: any[]) => {
    return objectToLookup(data, false)
      .map((d: any) => {
        return { ...d, text: d.label };
      })
      .sort((a, b) => (a.value > b.value ? 1 : -1));
  };

  const lookups = {
    doubleUpTypes: formatLookupData(lookupData?.doubleUpTypes),
    knockOutTypes: formatLookupData(lookupData?.knockOutTypes),
    types: formatLookupData(lookupData?.types),
    settlementTypes: formatLookupData(lookupData?.settlementTypes).sort(
      (a, b) => (a.text > b.text ? -1 : 1)
    ),
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: 50,
        marginLeft: 50,
      }}
    >
      {step > 0 && (
        <AccumulatorStepperHeader
          quoteId={quoteId}
          quoteName={quoteName}
          product={product}
          contract={contract}
          lookups={lookups}
          values={values}
        />
      )}

      <FormElement>
        {step > 0 && (
          <span className={"k-form-separator"} style={{ marginTop: 10 }} />
        )}

        {step === 0 && (
          <AccumulatorStartStep
            formRenderProps={formRenderProps}
            product={product}
            lookups={lookups}
          />
        )}
        {step === 1 && (
          <AccumulatorQuoteStep
            formRenderProps={formRenderProps}
            product={product}
            lookups={lookups}
            values={values}
            marketStatsResults={marketStatsResults}
          />
        )}
        {step === 2 && (
          <AccumulatorSubmitStep
            formRenderProps={formRenderProps}
            product={product}
          />
        )}

        <span style={{ marginTop: 20 }} className={"k-form-separator"} />
        <div className="multi-step-form-footer" style={{ paddingTop: 10 }}>
          <span className="form-step-label k-button-text">
            Step {step + 1} of {steps.length}
          </span>
          <div>
            {step !== 0 ? (
              <Button style={{ marginRight: "16px" }} onClick={onPrevClick}>
                Previous
              </Button>
            ) : undefined}
            <WorkflowSubmitButton
              isDisabled={isSubmitDisabled}
              isSubmitStep={isSubmitStep}
              onSubmit={formRenderProps.onSubmit}
              canSubmit={canSubmit}
            />
          </div>
        </div>
      </FormElement>

      <FormElement style={{ visibility: "hidden", display: "none" }}>
        <Field
          name="loaded"
          component={FormCheckbox}
          validator={requiredCheckboxValidator}
          showValidationMessage={false}
        />
      </FormElement>
    </div>
  );
};
