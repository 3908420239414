import { useEffect, useState } from "react";
import { HeaderTextSelector } from "../utilities";
import { useUserContext } from "../../hooks/useUserContext";

interface QuotesChartFilterProps {
  selected: { category: string; value: string };
  onChange: (filters: { category: CategoryFilter; value: ValueFilter }) => void;
}

export enum CategoryFilter {
  Commodity = "Commodity",
  Company = "Client",
}

export enum ValueFilter {
  Quotes = "Quotes",
  Premium = "Premium",
  Volume = "Volume",
}

const stringToCategory = (value: string) => {
  return (Object.values(CategoryFilter) as string[]).includes(value) ? 
    value as CategoryFilter : CategoryFilter.Commodity;
};

const stringToValue = (value: string) => {
  return (Object.values(ValueFilter) as string[]).includes(value) ? 
    value as ValueFilter : ValueFilter.Quotes;
};

export const QuotesChartFilter = ({
  selected,
  onChange,
}: QuotesChartFilterProps) => {
  const { context: user } = useUserContext();
  const [selectedValue, setSelectedValue] = useState(stringToValue(selected.value));
  const [selectedCategory, setSelectedCategory] = useState(stringToCategory(selected.category));

  useEffect(() => {
    if (onChange) onChange({ category: selectedCategory, value: selectedValue });
  }, [selectedValue, selectedCategory]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <HeaderTextSelector
        values={Object.values(ValueFilter)}
        selected={selectedValue}
        onChange={(e) => setSelectedValue(stringToValue(e))}
      />

      {!user.roles.isClient && (
        <HeaderTextSelector
          values={Object.values(CategoryFilter)}
          selected={(selectedCategory)}
          onChange={(e) => setSelectedCategory(stringToCategory(e))}
        />
      )}
    </div>
  );
};
