import { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Switch } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { LoadingIndicator } from "../LoadingIndicator";
import productsApi from "../../api/products";
import { useApi } from "../../hooks/useApi";
import { ICommodity } from "../quotes";
import { IntradayCurvesChart } from "../charts";

export const MarketsAnalytics = () => {
  const getCommoditiesApi = useApi(productsApi.getCommodities);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [commodities, setCommodities] = useState<any[]>([]);
  const [selectedCommodity, setSelectedCommodity] = useState<any | null>(null);
  const [refresh, setRefresh] = useState(Date.now);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCommoditiesApi.request();
  }, []);

  useEffect(() => {
    setIsLoading(getCommoditiesApi.loading);
    const data = getCommoditiesApi.data;
    if (data?.commodities) {
      setCommodities(
        data.commodities.sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
      );
    }
  }, [getCommoditiesApi.data, getCommoditiesApi.loading]);

  useEffect(() => {
    if (!autoRefresh || !selectedCommodity) return;

    const interval = setInterval(() => {
      setRefresh(Date.now);
    }, 5000);

    return () => clearInterval(interval);
  }, [autoRefresh, selectedCommodity]);

  return (
    <div style={{ minWidth: 1000 }}> {/* TODO: fit content to full width */}
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
          paddingBottom: "1rem",
        }}
      >
        <div>
          <h5>Intraday Market Data</h5>
        </div>
        <Tooltip anchorElement="target" position="bottom" parentTitle={true}>
          <div>
            <Label style={{ paddingRight: 5 }}>Commodity</Label>
            <DropDownList
              data={commodities}
              textField="name"
              dataItemKey="id"
              style={{ width: 350 }}
              disabled={isLoading}
              onChange={(e) => setSelectedCommodity(e.target.value)}
            />
            <LoadingIndicator loading={isLoading} />
            {!isLoading && (
              <Button
                size="small"
                icon="refresh"
                themeColor="primary"
                title="Refresh"
                onClick={(e) => setRefresh(Date.now)}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        </Tooltip>
        <div>
          <Label>Auto Refresh</Label>
          <Switch
            size={"small"}
            checked={autoRefresh}
            onChange={(e) => setAutoRefresh(!autoRefresh)}
          />
        </div>
      </div>

      <Error>{getCommoditiesApi.error}</Error>

      <IntradayCurvesChart
        commodity={selectedCommodity}
        onLoading={setIsLoading}
        refresh={refresh}
      />
    </div>
  );
};
