import { useEffect, useState } from "react";
import { usePrevious } from "./usePrevious";

export type ContractDates = {
  [key: string]: Date;
}

export function useDatePair(
  contractDates: ContractDates,
  resetAllocation: () => void,
  getAllocation: () => any,
) {
  // As soon as the user clicks on the calendar the end date is set to null
  // We want useEffect to fire only when the dates have actually changed
  // So store the dates as comparable numeric values
  // Save the dates as a pair and convert them to values that can be compared
  const [datePair, setDatePair] = useState<number[]>([0, 0]);

  // If previous dates are null, this must be a copy or requote being initialized
  // therefore, in this case, don't reset allocations
  const previousDates = usePrevious(contractDates);

  useEffect(() => {
    let isDatePairSelected =
      contractDates && contractDates.start && contractDates.end;
    if (isDatePairSelected && previousDates) {
      const newDatePair = Object.values(contractDates).map((date) => {
        const comparableDate = date.getTime();
        return comparableDate;
      });

      const isRangeChanged = datePair.some((date: number, index: number) => {
        const newDate = newDatePair[index];
        const isDateChanged = date !== newDate;
        return isDateChanged;
      });

      if (isRangeChanged) {
        const allocation = getAllocation();
        const isMonthlyAllocationCustomized = allocation === -1;
        if (isMonthlyAllocationCustomized) {
          resetAllocation();
        }
        setDatePair(newDatePair);
      }
    }
  }, [contractDates]);
}
