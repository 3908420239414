import { Button } from "@progress/kendo-react-buttons";
import { GridCellProps } from "@progress/kendo-react-grid";
import DeleteAllocationDialog from "../admin/DeleteAllocationDialog";

interface Commands {
  editField?: string;
  idField?: string;
  onAdd(dataItem: any): void;
  onUpdate(dataItem: any): void;
  onDiscard(dataItem: any): void;
  onCancel(dataItem: any): void;
  onEdit(dataItem: any): void;
  onRemove(allocationId: number): void;
}

type GridCommandCellProps = Commands & GridCellProps;

const GridCommandCell = ({
  dataItem,
  editField = "inEdit",
  idField = "id",
  onAdd,
  onUpdate,
  onDiscard,
  onCancel,
  onEdit,
  onRemove,
}: GridCommandCellProps) => {
  const inEdit = dataItem[editField];
  const isNewItem = !dataItem[idField];

  const handleClickConfirm = () => {
    isNewItem ? onAdd(dataItem) : onUpdate(dataItem);
  };

  const handleClickCancel = () => {
    isNewItem ? onDiscard(dataItem) : onCancel(dataItem);
  };

  const handleClickEdit = () => {
    onEdit(dataItem);
  };

  const handleDelete = (allocationId: number) => {
    onRemove(allocationId);
  };

  return inEdit ? (
    <td>
      <Button
        onClick={handleClickConfirm}
        icon={isNewItem ? "plus" : "check"}
        size="small"
        themeColor="primary"
        fillMode="outline"
        title={isNewItem ? "Add" : "Update"}
      ></Button>
      <Button
        onClick={handleClickCancel}
        icon={isNewItem ? "delete" : "cancel"}
        size="small"
        themeColor="primary"
        fillMode="outline"
        title={isNewItem ? "Discard" : "Cancel"}
      ></Button>
    </td>
  ) : (
    <td>
      <Button
        onClick={handleClickEdit}
        icon="edit"
        size="small"
        themeColor="primary"
        fillMode="outline"
        title="Edit"
      />
      <DeleteAllocationDialog allocationId={dataItem.allocationId} name={dataItem.name} onDelete={handleDelete} />
    </td>
  );
};

export { GridCommandCell };
export type { Commands };

