export interface IUser {
  id: number;
  fullName: string;
  company: string;
  companyId: number;
  companyIds: number[];
  email: string;
  phone: string;
  roles: string[];
  permissions: any;
  isActive: boolean;
  externalId: string;
}

export class User implements IUser {
  id = 0;
  fullName = "";
  company = "";
  companyId = 0;
  companyIds: number[] = [];
  email = "";
  phone = "";
  roles: string[] = [];
  permissions: any = {};
  isActive = true;
  externalId = "";

  private constructor(user: IUser | null) {
    if (user) {
      this.id = user.id;
      this.fullName = user.fullName || "";
      this.company = user.company;
      this.companyId = user.companyId;
      this.companyIds = user.companyIds;
      this.email = user.email || "";
      this.phone = user.phone || "";
      this.roles = user.roles;
      this.permissions = user.permissions;
      this.isActive = user.isActive;
      this.externalId = user.externalId;
    }
  }

  static get EMPTY(): User {
    return User.create(null);
  }

  static create(obj: IUser | null): User {
    return new User(obj);
  }
}
