import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Error } from "@progress/kendo-react-labels";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { useEffect, useState } from "react";
import marginsApi from "../../../api/margins";
import { useApi, useFetch } from "../../../hooks/useApi";
import { requiredValidator } from "../../../validators";
import { FormDropDownList, FormInput } from "../../form";
import { LoadingIndicator } from "../../LoadingIndicator";
import { BetaValue } from "../margins/BetaValue";
import { BetaValuesEditor } from "../margins";
import { Configuration } from "./Configuration";

interface ICreateNewMarginDialogProps {
  configuration: Configuration;
  onClose: () => void;
  onSave: () => void;
}

export const CreateNewMarginDialog = ({ configuration, onClose, onSave }: ICreateNewMarginDialogProps) => {
  const { data, loading: loadingGet, error: errorGet } = useFetch(marginsApi.getMargins);
  const saveMarginApi = useApi(marginsApi.createMargin);
  const [marginId, setMarginId] = useState(0);
  const [betasData, setBetasData] = useState<BetaValue[]>([]);
  const [loading, setLoading] = useState(loadingGet);
  const [error, setError] = useState(errorGet);

  const marginsList = (data?.margins ?? [])
    .filter((m: any) => m.businessTypeId === configuration.businessTypeId)
    .map((m: any) => ({ value: m.marginId, label: m.longName }))
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

  useEffect(() => {
    setLoading(loadingGet || saveMarginApi.loading);
  }, [loadingGet, saveMarginApi.loading]);

  useEffect(() => {
    setError([errorGet, saveMarginApi.error].join(", "));
  }, [errorGet, saveMarginApi.error]);

  useEffect(() => {
    if (!saveMarginApi.loading && (saveMarginApi.data || saveMarginApi.error))
      if (!saveMarginApi.error) onSave();
  }, [saveMarginApi.loading, saveMarginApi.data, saveMarginApi.error]);

  const handleSelectMargin = (event: any) => setMarginId(event.value.value);

  const handleSubmitEdit = async (event: any) => {
    const formData = { ...event };

    await saveMarginApi.request(formData.name, betasData, configuration.commodityId, configuration.businessTypeId);
  };

  return (
    <Dialog title={"Create New Margin"} onClose={onClose} width={"80%"}>
      <Form
        onSubmit={handleSubmitEdit}
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ display: "flex", gap: 10 }}>
              <Field
                label="Copy Margin"
                name="margin"
                component={FormDropDownList}
                validator={requiredValidator}
                data={marginsList}
                disabled={loading}
                textField="label"
                dataItemKey="value"
                onChange={handleSelectMargin}
                style={{ minWidth: 250 }}
              />

              <Field
                label="New Margin Name"
                name="name"
                component={FormInput}
                validator={requiredValidator}
                disabled={loading}
                maxLength={255}
                style={{ minWidth: 250 }}
              />
            </div>

            {marginId > 0 && <BetaValuesEditor marginId={marginId} disabled={loading} onDataUpdate={setBetasData} />}
            
            {error && <Error style={{display: "flex", justifyContent: "center"}}>{error}</Error>}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2rem",
              }}
            >
              <LoadingIndicator loading={loading} />

              {!loading && (
                <>
                  <Button themeColor="base" disabled={loading} onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    themeColor="success"
                    type="submit"
                    disabled={!formRenderProps.allowSubmit}
                  >
                    Save
                  </Button>
                </>
              )}
            </div>
          </FormElement>
        )}
      ></Form>
    </Dialog>
  );
};
