import documentsApi from "../../../api/documents";
import { useUserContext } from "../../../hooks/useUserContext";
import { QuoteStatus } from "../../../types/quoteStatus";
import PdfDownloadButton from "./PdfDownloadButton";
import QuoteReviewForm from "./QuoteReviewForm";

const QuoteDetailsButtons = ({
  quote,
  prices,
  getQuoteDetails,
  onReject,
  totalVolume,
  unitPriceDecimals,
  allowOverride,
  isSwap,
}: any) => {
  const { context: user } = useUserContext();
  const { quoteId, pricingId } = quote;

  const isSubmitted = quote.status === QuoteStatus.Pending;
  const showApproveRejectButtons =
    isSubmitted && user.permissions.canApproveTrades && user.roles.isRiskTaker;

  return (
    <div style={{ display: "flex", alignItems: "end" }}>
      {showApproveRejectButtons ? (
        <QuoteReviewForm
          quoteId={quoteId}
          prices={prices}
          quote={quote}
          getQuoteDetails={getQuoteDetails}
          onReject={onReject}
          totalVolume={totalVolume}
          unitPriceDecimals={unitPriceDecimals}
          allowOverride={allowOverride}
          isSwap={isSwap}
        />
      ) : (
        <PdfDownloadButton
          quoteId={quoteId}
          pricingId={pricingId}
          api={documentsApi.getQuoteSummaryPdf}
        />
      )}
    </div>
  );
};

export default QuoteDetailsButtons;
