import { useInternationalization } from "@progress/kendo-react-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";

export const AccumulatorStepperHeader = ({
  quoteId,
  quoteName,
  product,
  contract,
  lookups,
  values,
}: any) => {
  const { commodity, instrument, company } = product;
  const { doubleUpTypes, knockOutTypes, settlementTypes, types } = lookups;
  const formatter = useInternationalization();

  const doubleUp = doubleUpTypes.find(
    (d: any) => d.value === values?.doubleUpTypeId
  );
  const knockOut = knockOutTypes.find(
    (d: any) => d.value === values?.knockOutTypeId
  );
  const settlementType = settlementTypes.find(
    (d: any) => d.value === values?.settlementTypeId
  );
  const instrumentType = types.find(
    (d: any) => d.value === values?.instrumentTypeId
  );

  const featureStyle = { flexBasis: "25%" };
  const labelStyle = { fontSize: "small", color: "gray" };

  return (
    <div>
      <span className={"k-form-separator"} />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 0",
        }}
      >
        <div>
          <Tooltip anchorElement="target" position="top">
            <img
              alt={commodity.name}
              src={commodity.icon()}
              title={`${commodity.name} (${commodity.abbreviation})`}
              style={{ width: 24, height: 24, paddingRight: ".25rem" }}
            />
            <img
              alt={instrument.productType}
              src={instrument.icon()}
              title={instrument.productType}
              style={{ width: 24, height: 24, paddingRight: ".25rem" }}
            />
            <span style={{ fontWeight: "bolder" }}>{quoteName}</span>
          </Tooltip>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div style={featureStyle}>
          <span style={labelStyle}>ID</span>
          <div>{quoteId}</div>
        </div>

        {company && (
          <div style={featureStyle}>
            <span style={labelStyle}>Client</span>
            <div>{company.name}</div>
          </div>
        )}

        <div style={featureStyle}>
          <span style={labelStyle}>Contract</span>
          <div>{contract}</div>
        </div>

        <div style={featureStyle}>
          <span style={labelStyle}>Volume</span>
          <div>{formatter.formatNumber(values?.volume, "n0")} {` ${commodity.units}`}</div>
        </div>
      </div>

      <div style={{ display: "flex", marginTop: ".25rem" }}>
        <div style={featureStyle}>
          <span style={labelStyle}>Type</span>
          <div>{instrumentType?.text}</div>
        </div>
        <div style={featureStyle}>
          <span style={labelStyle}>Knock Out</span>
          <div>{knockOut?.text}</div>
        </div>
        <div style={featureStyle}>
          <span style={labelStyle}>Double Up</span>
          <div>{doubleUp?.text}</div>
        </div>
        <div style={featureStyle}>
          <span style={labelStyle}>Fixing Frequency</span>
          <div>{settlementType?.text}</div>
        </div>
      </div>
    </div>
  );
};
