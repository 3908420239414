import {
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartNavigator,
  ChartNavigatorSelect,
  ChartNavigatorSeries,
  ChartNavigatorSeriesItem,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  StockChart,
  TooltipContext,
} from "@progress/kendo-react-charts";
import { useInternationalization } from '@progress/kendo-react-intl';
import DateUtility from "../../utilities/dateUtilities";
import "hammerjs";

export const RecentPricesStockChart = (props: any) => {
  const { data, displayAsPercent, title } = props;
  const formatter = useInternationalization();

  const calculatePercent = (value: number, denominator: number) =>
    denominator < 0.0000001 ? 0 : value / denominator - 1;

  const chartData = !displayAsPercent ? data :
    data.map((d: any) => {
        return {
          contract: d.contract,
          open: calculatePercent(d.open, d.open),
          close: calculatePercent(d.close, d.open),
          high: calculatePercent(d.high, d.open),
          low: calculatePercent(d.low, d.open),
        };
      });

  const format = (digits: number) => `${displayAsPercent ? "p" : "c"}${digits}`;

  const renderTooltip = (props: TooltipContext) => {
    const { value, category } = props.point;
    const header = (category instanceof Date) ? DateUtility.formatDateMY(category) : category;
    const valueFormat = format(displayAsPercent ? 0 : 2);

    return (
      <table>
      <tbody>
      <tr><th colSpan={2}>{header}</th></tr> 
      <tr><td>Open:</td><td>{formatter.formatNumber(value.open, valueFormat)}</td></tr>
      <tr><td>High:</td><td>{formatter.formatNumber(value.high, valueFormat)}</td></tr>
      <tr><td>Low:</td><td>{formatter.formatNumber(value.low, valueFormat)}</td></tr>
      <tr><td>Close:</td><td>{formatter.formatNumber(value.close, valueFormat)}</td></tr>
      </tbody>
      </table>
    );
  };

  return (
    <>
      <div style={{ alignSelf: "center", verticalAlign: "top" }}>{title}</div>

      {chartData && chartData.length > 0 && (
        <StockChart renderAs="canvas" transitions={false}>
          <ChartSeries>
            <ChartSeriesItem
              data={chartData}
              type="candlestick"
              openField="open"
              closeField="close"
              lowField="low"
              highField="high"
              categoryField="contract"
            >
              <ChartSeriesItemTooltip render={renderTooltip} />
            </ChartSeriesItem>
          </ChartSeries>

          <ChartValueAxis>
            <ChartValueAxisItem labels={{ format: format(displayAsPercent ? 0 : 2) }} />
          </ChartValueAxis>

          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              baseUnit="months"
              labels={{ format: "MMM yyyy", rotation: "auto" }}
              maxDivisions={6}
            />
          </ChartCategoryAxis>

          <ChartNavigator>
            <ChartNavigatorSelect />
            <ChartNavigatorSeries>
              <ChartNavigatorSeriesItem
                data={chartData}
                type="area"
                field="close"
                categoryField="contract"
              />
            </ChartNavigatorSeries>
          </ChartNavigator>
        </StockChart>
      )}
    </>
  );
};
