import { useState } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Analytics, MarketsAnalytics } from "../../components/dashboard";
import { useUserContext } from "../../hooks/useUserContext";

export const AnalyticsDashboard = () => {
  const { context: user } = useUserContext();
  const [selected, setSelected] = useState(0);

  return (
    <TabStrip selected={selected} onSelect={(e) => setSelected(e.selected)}>
      <TabStripTab title="System">
        <Analytics />
      </TabStripTab>

      {user.roles.isRiskTaker && (
        <TabStripTab title="Markets">
          <MarketsAnalytics />
        </TabStripTab>
      )}
    </TabStrip>
  );
};
