import { Icon } from "@progress/kendo-react-common";

const PdfFileItem = ({ dataItem, onOpenPdf }: { dataItem: any; onOpenPdf: Function }) => {
  const handleOpenPdf = () => {
    onOpenPdf(dataItem.getRawFile(), dataItem.name);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Icon name="pdf" size="xxlarge" />
      <div>
        <span
          onClick={handleOpenPdf}
          style={{ cursor: "pointer", textDecoration: "underline", marginLeft: "10px" }}
        >
          {dataItem.name}
          <span className="k-file-size" style={{ marginLeft: "10px" }}>
            {dataItem.size ? (dataItem.size / 1024).toFixed(2) + " KB" : "N/A"}
          </span>
        </span>
      </div>
    </div>
  );
};

export default PdfFileItem;
