import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useState } from "react";
import { AlertsGrid, CompaniesGrid, UsersGrid } from "../components/admin";
import { ProductConfigurations } from "../components/admin/configurations";
import AllocationsGrid from "../components/admin/AllocationsGrid";
import { MarginsGrid } from "../components/admin/margins";
import { useUserContext } from "../hooks/useUserContext";

export const Administration = () => {
  const { context: user } = useUserContext();
  const { permissions } = user;

  const [selected, setSelected] = useState(0);

  return (
    <TabStrip selected={selected} onSelect={(e) => setSelected(e.selected)}>
      {permissions.canAdminCompanies && (
        <TabStripTab title="Companies">
          <CompaniesGrid />
        </TabStripTab>
      )}

      {permissions.canAdminUsers && (
        <TabStripTab title="Users">
          <UsersGrid />
        </TabStripTab>
      )}

      {permissions.canConfigureProducts && (
        <TabStripTab title="Products">
          <ProductConfigurations />
        </TabStripTab>
      )}

      {permissions.canConfigureMargins && (
        <TabStripTab title="Margins">
          <MarginsGrid />
        </TabStripTab>
      )}
      
      <TabStripTab title="Allocations">
        <AllocationsGrid />
      </TabStripTab>

      {user.roles.isRiskTaker && (
        <TabStripTab title="Alerts">
          <AlertsGrid />
        </TabStripTab>
      )}
    </TabStrip>
  );
};
