import { Form, FormSubmitClickEvent, FormRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { StepperChangeEvent, Stepper } from "@progress/kendo-react-layout";
import { useState, useCallback } from "react";
import configApi from "../../../api/configuration";
import { StepsInterface } from "../../../pages/quotes/workflow";
import { Commodity } from "../../quotes";
import { Company } from "../Company";
import { ConfigurationFormRender } from ".";
import { ConfigWorkflow, ConfigAction } from "./ConfigurationEnums";
import { Configuration } from "./Configuration";

interface IWorkflowProps {
  businessTypes: Record<number, string>;
  companies: Company[];
  companyId: number;
  commodities: Commodity[];
  commodityTypes: Record<string, string>;
  configurations: Record<number, Configuration[]>;
  margins: any[];
  marginField: string;
  productTypes: Record<string, string>;
  onComplete: () => void;
}

export const ConfigurationsWorkflow = (props: IWorkflowProps) => {
  const { companyId, configurations, margins, marginField, onComplete } = props;

  const [steps, setSteps] = useState<Array<StepsInterface>>([
    { label: "Start", isValid: undefined, visible: true },
    { label: "Products", isValid: undefined, visible: true },
    { label: "Companies", isValid: undefined, visible: true },
    { label: "Margins", isValid: undefined, visible: false },
    { label: "Review", isValid: undefined, visible: true },
  ]);
  const [step, setStep] = useState(0);
  const [error, setError] = useState("");
  const [formKey, setFormKey] = useState(1);
  const [formState, setFormState] = useState<any | null>({
    companies: [],
    workflow: ConfigWorkflow.Products
  });

  const stepLabels: Record<string, string[]> = {
    products: ["Start", "Products", "Companies", "Margins", "Review"],
    companies: ["Start", "Companies", "Products", "Margins", "Review"],
    margins: ["Start", "Margins", "Products", "Companies", "Review"],
  };

  const lastStepIndex = steps.filter((s) => s.visible).length - 1;
  const isSubmitStep = step === lastStepIndex;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.visible && currentStep.isValid === false) === -1;

  const onStepSubmit = useCallback(
    async (event: FormSubmitClickEvent) => {
      let { isValid, values } = event;
      setError("");

      const isMargins = values.workflow === ConfigWorkflow.Margins;
      if (isMargins) values.action = ConfigAction.Assign;

      if (step > 0 && !isSubmitStep && !isMargins) {
        const configurationIds: number[] = values.configurations ?? [];
        const selectedConfigurations = (configurations[companyId] ?? []).filter((c) =>
          configurationIds.includes(c.configurationId),
        );
        values.products = [];
        values.productMarginIds = {};
        selectedConfigurations.forEach((c: any) => {
          const key = `marginSelection_${c.productId}`;
          const margin = margins.find(
            (m) => m.commodityId === c.commodityId && m.marginId === c[marginField],
          );
          if (!values[key]) {
            values[key] = margin ? { value: margin.marginId, label: margin.longName } : null;
          }

          values.products.push(c.productId);
          values.productMarginIds[c.productId] = values[key]?.value;
        });
      }

      if (isSubmitStep && isMargins) {
        const configurationIds: number[] = values.configurations ?? [];
        const selectedConfigurations = (configurations[companyId] ?? []).filter((c) =>
          configurationIds.includes(c.configurationId),
        );

        values.products = selectedConfigurations.map((c) => c.productId);
        values.productMarginIds = {};
        selectedConfigurations.forEach((c: any) => values.productMarginIds[c.productId] = values.margin);
      }

      if (isValid && isSubmitStep) {
        switch (values.action) {
          case ConfigAction.Assign:
            await configApi
              .assign(values.products, values.companies, true, values.productMarginIds)
              .catch((err: any) => {
                setError(err.response?.data?.message || err.message);
                isValid = false;
              });
            break;

          case ConfigAction.Activate:
          case ConfigAction.Suspend:
            await configApi
              .activateProducts(values.products, values.companies, values.action === ConfigAction.Activate)
              .catch((err: any) => {
                setError(err.response?.data?.message || err.message);
                isValid = false;
              });
            break;
        }
      }

      const currentSteps = steps.map((currentStep: StepsInterface, index: number) => {
        const label = stepLabels[values.workflow][index] ?? currentStep.label;

        return {
          ...currentStep,
          label: label,
          isValid: index === step ? isValid : currentStep.isValid,
          visible:
            label === "Margins"
              ? values.workflow === ConfigWorkflow.Margins || values.action === ConfigAction.Assign
              : true,
        };
      });

      setSteps(currentSteps);

      if (!isValid) return;

      setStep(Math.min(step + 1, lastStepIndex));
      setFormState(values);

      if (isSubmitStep) onComplete();
    },
    [steps, isSubmitStep, step, lastStepIndex],
  );

  const onPrevClick = useCallback(
    (event: any) => {
      event.preventDefault();
      setStep(Math.max(step - 1, 0));
      setError("");
    },
    [step, setStep],
  );

  const resetForm = (workflow: string) => {
    setFormState({ companies: [], workflow: workflow});
    setFormKey(formKey + 1);
  }

  const handleStepperChange = (e: StepperChangeEvent) => {
    if (e.value < step || steps[e.value].isValid || steps[e.value - 1].isValid) setStep(e.value);
  };

  const stepperStyle = {
    margin: "0 auto",
    padding: "1rem 0"
};

  return (
    <div>
      <Stepper
        value={step}
        items={steps
          .filter((s) => s.visible)
          .map((s) => {
            return { label: s.label, isValid: s.isValid };
          })}
        onChange={handleStepperChange}
        style={stepperStyle}
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ flexBasis: "80%" }}>
          {formState && (
            <Form
              key={formKey}
              initialValues={formState}
              onSubmitClick={onStepSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <ConfigurationFormRender
                  step={step}
                  formRenderProps={formRenderProps}
                  steps={steps}
                  isSubmitStep={isSubmitStep}
                  isPreviousStepsValid={isPreviousStepsValid}
                  onPrevClick={onPrevClick}
                  onResetForm={resetForm}
                  {...props}
                />
              )}
            />
          )}
          <div className="float-right">
            <Error>{error}</Error>
          </div>
        </div>
      </div>
    </div>
  );
};
