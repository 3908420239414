import { useContext, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { useUserCompanyContext } from "../../hooks/useUserCompanyContext";
import { CompaniesContext, IUserCompany } from "../functional";

interface SwitchAccountsProps {
  onCancel: () => void;
}

export const SwitchAccountsDialog = (props: SwitchAccountsProps) => {
  const { onCancel } = props;
  const { context: companies } = useContext(CompaniesContext);
  const { context: user, setContext: setUserContext } = useUserCompanyContext();
  const [company, setCompany] = useState(user?.id || 0);

  const values =
    companies?.map((c: IUserCompany) => {
      return { value: c.id, label: c.name, role: c.role };
    }) ?? [];
  values.sort((a, b) => a.label.localeCompare(b.label));

  const onSwitch = (e: any) => {
    const selected = companies.find((c: any) => c.id === company);
    setUserContext(selected);
    window.location.reload();
  };

  return (
    <Window
      title={"Switch Company"}
      left={window.innerWidth - 500}
      top={50}
      modal={true}
      minimizeButton={() => null}
      maximizeButton={() => null}
      onClose={onCancel}
    >
      <RadioGroup
        data={values}
        value={company}
        onChange={(e: any) => setCompany(e.value)}
      />

      <WindowActionsBar layout={"center"}>
        <Button 
          themeColor={"secondary"} 
          size={"small"} 
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          themeColor={"primary"}
          size={"small"}
          disabled={company === 0 || company === user?.id}
          onClick={onSwitch}
        >
          Switch
        </Button>
      </WindowActionsBar>
    </Window>
  );
};
