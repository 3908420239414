export const volumeHint =
  "The quantity of the selected commodity, in the units listed below, being protected from adverse price movements.";

export const attachHint =
  "The commodity price at which your policy coverage will begin. Prices below this level represent a price risk that you will retain.";

export const exhaustHint =
  "The commodity price at which your policy coverage will reach its maximum value. Prices between the attach and exhaust levels are prices included in coverage by your policy; prices below the exhaust level are covered at the policy limit.";

export const retentionHint =
  "Similar to a policy deductible, retention is a measure of risk for which you will assume responsibility. For example, if the underlying commodity price is $6.00 and you choose a retention level of 10%, your policy payouts will not begin until the underlying price has changed by $0.60. Retention, specified in dollars, is the amount that unfavorable commodity price changes will cost you before your insurance policy begins covering your losses.";

export const coverageHint = "The dollars that a policy may pay to you.";

export const deltaHint = "Total quote delta from the Risk Taker perspective";