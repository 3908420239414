import { FormRenderProps } from "@progress/kendo-react-form";
import { Company } from "../Company";
import {
  ConfigurationActionSummary,
  ISummaryDisplayItems,
} from ".";
import { Configuration } from "./Configuration";
import { ConfigAction, ConfigWorkflow } from "./ConfigurationEnums";

interface ISummaryStepProps {
  formRenderProps: FormRenderProps;
  companies: Company[];
  companyId: number;
  configurations: Record<number, Configuration[]>;
  margins: any[];
  marginField: string;
}

export const SummaryStep = ({
  formRenderProps,
  companies,
  companyId,
  configurations,
  margins,
  marginField,
}: ISummaryStepProps) => {
  const workflow = formRenderProps.valueGetter("workflow");
  const action = formRenderProps.valueGetter("action") || "";
  const isAssign = action === ConfigAction.Assign;
  const configurationIds: number[] = formRenderProps.valueGetter("configurations") || [];
  const companyIds: number[] = formRenderProps.valueGetter("companies") || [];
  const selectedCompanies = companies.filter((c) => companyIds.includes(c.id));
  const selectedMargin = margins.find((m) => m.marginId === formRenderProps.valueGetter("margin"));

  let selectedConfigurations: Configuration[] = (configurations[companyId] ?? []).filter((c) =>
    configurationIds.includes(c.configurationId),
  );

  if (workflow === ConfigWorkflow.Companies && !isAssign) {
    const selectedCompanyId = companyIds.length > 0 ? companyIds[0] : null;
    const companyConfigs = selectedCompanyId ? configurations[selectedCompanyId] ?? [] : [];

    selectedConfigurations = selectedConfigurations
      .map((c) => companyConfigs.find((cc) => cc.productId === c.productId))
      .filter((cc) => cc !== undefined) as Configuration[];
  }

  selectedConfigurations.forEach((c: any, index: number) => {
    const margin = workflow === ConfigWorkflow.Margins ? 
      selectedMargin : 
      formRenderProps.valueGetter(`marginSelection_${c.productId}`);
    if (margin) {
      c[marginField] = margin.value;
      c.margin = margins.find((m) => m.marginId === margin.value)?.longName ?? "";
    }
  });

  const items: ISummaryDisplayItems[] = [
    { items: selectedConfigurations, label: "Products", field1: "commodity", field2: "instrument" },
    { items: selectedCompanies, label: workflow === "companies" ? "Company" : "Companies", field1: "name" },
    { items: selectedConfigurations, label: "Margins", field1: "margin" },
    { items: [selectedMargin], label: "Margin", field1: "longName" }
  ];

  let summary: ISummaryDisplayItems[] = [];

  switch (workflow) {
    case ConfigWorkflow.Products:
      summary = isAssign ? [items[0], items[2], items[1]] : items.slice(0, 2);
      break;

    case ConfigWorkflow.Companies:
      summary = isAssign ? [items[1], items[0], items[2]] : [items[1], items[0]];   
      break;

    case ConfigWorkflow.Margins:
      summary = [items[3], items[0], items[1]];
      break;
  }


  return (
    <div className="stepper-form">
      <ConfigurationActionSummary action={action} summary={summary} />
    </div>
  );
};
