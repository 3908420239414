export const chartColors: string[] = [
  "#0c7825",
  "#69e40a",
  "#f8f94e",
  "#6af9e4",
  "#0dd5ff",
  "#0d91ff",
  "#0000ba",
  "#7744ff",
  "#c100ff",
  "#ff1dce",
  "#f69cff",
];
