import { useContext } from "react";
import { UserCompanyContext } from "../components/functional";

export const getFreshCompanyContext = () => {
  return { id: 0, name: "", role: "", canSell: false };
};

export const useUserCompanyContext = () => {
  const { context, setContext } = useContext(UserCompanyContext);
  return {
    context,
    setContext: (obj: any) => {
      setContext({ ...context, ...obj });
    },
    resetContext: () => {
      localStorage.removeItem("context");
      setContext(getFreshCompanyContext());
    },
  };
}
