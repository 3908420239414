export enum ConfigAction {
  Assign = "assign",
  Activate = "activate",
  Suspend = "suspend",
  Edit = "edit",
}

export enum ConfigStatus {
  Enabled = "Enabled",
  Unassigned = "Unassigned",
  Suspended = "Suspended",
  Margins = "Missing Margin",
  ChildMargins = "Missing Broker Margin",
}

export enum ConfigWorkflow {
  Products = "products",
  Companies = "companies",
  Margins = "margins",
}
