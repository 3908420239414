import { FormRenderProps, Field } from "@progress/kendo-react-form";
import { requiredValidator } from "../../../validators";
import { FormInput } from "../../form";
import { Company } from "../Company";
import { Configuration } from "./Configuration";
import { ConfigWorkflow, ConfigAction, ConfigStatus } from "./ConfigurationEnums";
import { ConfiguratonProductsSelector, IProductFilters } from ".";

interface IProductsSelectionStepProps {
  formRenderProps: FormRenderProps;
  companies: Company[];
  companyId: number;
  commodityTypes: Record<string, string>;
  configurations: Record<number, Configuration[]>;
  productTypes: Record<string, string>;
  margins: any[];
}

export const ProductsSelectionStep = ({
  formRenderProps,
  companies,
  companyId,
  commodityTypes,
  configurations,
  productTypes,
  margins,
}: IProductsSelectionStepProps) => {
  const workflow = formRenderProps.valueGetter("workflow");
  const action = formRenderProps.valueGetter("action");
  const filters = formRenderProps.valueGetter("productFilters");
  const selectedConfigurations = formRenderProps.valueGetter("configurations") ?? [];

  let companyConfigurations: Configuration[] = [];

  switch (workflow) {
    case ConfigWorkflow.Products:
      companyConfigurations = (configurations[companyId] ?? []).filter((c) => c.isEnabled());
      break;

    case ConfigWorkflow.Companies:
      const selectedCompany = companies.find((c) => c.id === formRenderProps.valueGetter("company"));
      const companyConfigs = selectedCompany?.id ? configurations[selectedCompany.id] ?? [] : [];
      const businessTypes = selectedCompany?.businessTypes ?? [];
    
      companyConfigurations = (configurations[companyId] ?? [])
        .filter((c) => c.isEnabled())
        .filter((c) => businessTypes.includes(c.businessTypeId))
        .map((config) => {
          const item: any = companyConfigs.find((conf) => conf.productId === config.productId);
          if (item) item.parentConfigurationId = config.configurationId;
    
          return (
            item ??
            Configuration.create(
              Object.assign({}, config, {
                configurationId: 0,
                companyId: selectedCompany?.id,
                isActive: false,
                isConfigured: false,
              }),
              Object.assign({}, config, {
                company: selectedCompany?.name,
                parentConfigurationId: config.configurationId,
              }),
            )
          );
        })
        .filter(
          (c) =>
            action === ConfigAction.Assign ||
            (action === ConfigAction.Activate && c.status === ConfigStatus.Suspended) ||
            (action === ConfigAction.Suspend &&
              [ConfigStatus.Enabled, ConfigStatus.Margins].includes(c.status)),
        );
    
      break;

    case ConfigWorkflow.Margins:
      const margin = margins.find((m) => m.marginId === formRenderProps.valueGetter("margin"));

      companyConfigurations = (configurations[companyId] ?? [])
        .filter((c) => c.isEnabled())
        .filter((c) => c.businessTypeId === margin.businessTypeId)
        .filter((c) => !margin.commodityId || c.commodityId === margin.commodityId);
      break;
  }

  const onFiltersChange = (filters: IProductFilters) => {
    if (JSON.stringify(filters) !== JSON.stringify(formRenderProps.valueGetter("productFilters")))
      formRenderProps.onChange("productFilters", { value: filters });
  };

  const onSelectionChange = (ids: number[]) => {
    const items = ids.sort((a, b) => a - b).join(",");
    if (items === formRenderProps.valueGetter("items")) return;

    const businessTypes = new Set(
      companyConfigurations
        .filter((c) => ids.includes(c.configurationId))
        .map((c) => c.businessTypeId),
    );

    formRenderProps.onChange("configurations", { value: ids });
    formRenderProps.onChange("items", { value: items });
    formRenderProps.onChange("businessTypes", { value: [...businessTypes] });
  };

  return (
    <div className="stepper-form">
      <ConfiguratonProductsSelector
        configurations={companyConfigurations}
        selectedIds={selectedConfigurations}
        filters={filters}
        commodityTypes={commodityTypes}
        productTypes={productTypes}
        selectByParent={workflow === ConfigWorkflow.Companies}
        showStatus={workflow === ConfigWorkflow.Companies}
        onFiltersChange={onFiltersChange}
        onSelectionChange={onSelectionChange}
      />

      <Field
        key={"items"}
        id={"items"}
        name={"items"}
        component={FormInput}
        validator={requiredValidator}
        showValidationMessage={false}
        style={{ visibility: "hidden", display: "none" }}
      />
    </div>
  );
};
