export type BetaValue = {
  betaCubeId: number;
  betaId: number;
  contractMonth: string;
  bidValue: number;
  askValue: number;
  twoWayValue: number;
  isModified: boolean;
};

export enum BetaValueType {
  Bid = "bidValue",
  Ask = "askValue",
  TwoWay = "twoWayValue",
}
