import { State, process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnMenuProps,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { Error } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import companyApi from "../../api/company";
import productsApi from "../../api/products";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useApi, useFetch } from "../../hooks/useApi";
import { objectToLookup } from "../../utilities/objectUtilities";
import { ListItem } from "../form";
import {
  BooleanGridCell,
  ColumnMenuCheckboxFilter,
  EditLinkCell,
} from "../grid";
import { AddEditCompanyDialog } from "./AddEditCompanyDialog";
import { Company, ICompany } from "./Company";

export const CompaniesGrid = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [roles, setRoles] = useState<ListItem[]>([]);
  const [businessTypes, setBusinessTypes] = useState<ListItem[]>([]);
  const [parentChildRoles, setparentChildRoles] = useState<Record<string, string>>({});
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState<Company>(Company.EMPTY);
  const [result, setResult] = useState<any>([]);
  const [dataState, setDataState] = useState<State>({
    sort: [{ field: "name", dir: "asc" }],
    skip: 0,
    take: 20,
  });

  const getCompaniesApi = useApi(companyApi.getLineage);
  const getBusinessTypesApi = useFetch(productsApi.getBusinessTypes);

  useEffect(() => {
    getCompaniesApi.request();
  }, []);

  useEffect(() => {
    setRoles(objectToLookup(getCompaniesApi.data?.roles || [], true));
    setparentChildRoles(getCompaniesApi.data?.parentChildRoles || {});
    setCompanies(getCompaniesApi.data?.companies.map((c: ICompany) => Company.create(c)) || []);
  }, [getCompaniesApi.data]);

  useEffect(() => {
    setBusinessTypes(objectToLookup(getBusinessTypesApi.data?.businessTypes || []));
  }, [getBusinessTypesApi.data]);

  useEffect(() => {
    const newState = createDataState(dataState);
    setResult(newState.result);
  }, [companies]);

  const createDataState = (dataState: State) => {
    return {
      result: process(companies.slice(0), dataState),
      dataState: dataState,
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const enterEdit = (item: any) => {
    setEditItem(item);
    setOpenForm(true);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleSubmitEdit = () => {
    setOpenForm(false);
    getCompaniesApi.request();
  };

  const CompanyNameCell = (props: GridCellProps) => (
    <EditLinkCell {...props} title="Edit Company" enterEdit={enterEdit} />
  );

  const FilterColumnMenu = (props: GridColumnMenuProps) => (
    <ColumnMenuCheckboxFilter {...props} data={companies} />
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "right",
        }}
      >
        <Button
          title="Add Company"
          themeColor="primary"
          size="small"
          onClick={() => enterEdit(Company.EMPTY)}
          style={{ marginBottom: 10 }}
        >
          Add Company
        </Button>
      </div>

      <LoadingIndicator loading={getCompaniesApi.loading} />
      {getCompaniesApi.error && <Error>{getCompaniesApi.error}</Error>}
      {!getCompaniesApi.loading && (
        <Grid
          data={result}
          {...dataState}
          pageable={true}
          sortable={true}
          filterable={false} // Using custom grid column menu filter
          onDataStateChange={dataStateChange}
        >
          <GridColumn
            field="name"
            title="Name"
            cell={CompanyNameCell}
            columnMenu={FilterColumnMenu}
          />
          <GridColumn field="contactName" title="Contact Name" />
          <GridColumn
            field="roleDisplay"
            title="Type"
            columnMenu={FilterColumnMenu}
            width={200}
          />
          <GridColumn
            field="isActive"
            title="Active"
            width={150}
            cell={BooleanGridCell}
            columnMenu={FilterColumnMenu}
          />
          <GridColumn
            field="canSell"
            title="Two Sided Credit"
            width={150}
            cell={BooleanGridCell}
            columnMenu={FilterColumnMenu}
          />
        </Grid>
      )}

      {openForm && (
        <AddEditCompanyDialog
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmitEdit}
          company={editItem}
          companies={companies.filter((c) => c.isActive)}
          roles={roles}
          businessTypes={businessTypes}
          parentChildRoles={parentChildRoles}
        />
      )}
    </>
  );
};
