import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { ConfigWorkflow } from "./ConfigurationEnums";
import { FormRadioGroup } from "../../form";
import { requiredValidator } from "../../../validators";

interface IConfigurationsStartStepProps {
  onWorkflowChange: (workflow: string) => void;
}

export const ConfigurationsStartStep = ({ onWorkflowChange }: IConfigurationsStartStepProps) => {
  const radioButtonData: any[] = [
    { label: "Products: Assign or (de)activate for Companies and Margins", value: ConfigWorkflow.Products },
    { label: "Company: Operate on all Products for a given Company", value: ConfigWorkflow.Companies },
    { label: "Margin: Operate on all Products for a given Margin", value: ConfigWorkflow.Margins },
  ];

  return (
    <div className="stepper-form" style={{ display: "flex", justifyContent: "center" }}>
      <Field
        label={"Select the type of bulk operation workflow:"}
        key={"workflow"}
        id={"workflow"}
        name={"workflow"}
        component={FormRadioGroup}
        validator={requiredValidator}
        data={radioButtonData}
        onChange={(e) => onWorkflowChange(e.value)}
      />
    </div>
  );
};
