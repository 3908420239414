import { addMonths, durationInMonths } from "@progress/kendo-date-math";
import { parseDate } from "@progress/kendo-intl";
import { FormRenderProps } from "@progress/kendo-react-form";
import { ICoverage } from "./Types";
import DateUtility from "../../../utilities/dateUtilities";

export default class InsuranceUtility {
  static getCoverageData = (formRenderProps: FormRenderProps): ICoverage[] => {
    const startDate = parseDate(formRenderProps.valueGetter("contract.start"));
    const endDate = parseDate(formRenderProps.valueGetter("contract.end"));
    startDate.setDate(1); //First of the month
    endDate.setDate(1);
    const duration = durationInMonths(startDate, endDate);
    const coverages: ICoverage[] = [];

    for (let i = 0; i <= duration; i++) {
      const month = addMonths(startDate, i);

      coverages.push({
        date: DateUtility.formatDateYMD(month),
        label: DateUtility.formatDateMY(month),
        value: month.getMonth() + 1,
        volume: formRenderProps.valueGetter(`volume_${i}`),
        coverage: formRenderProps.valueGetter(`coverage_${i}`),
      });
    }

    return coverages;
  };
}
