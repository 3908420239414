import { useMsal } from "@azure/msal-react";
import { Button } from "@progress/kendo-react-buttons";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../hooks/useUserContext";
import { SwitchAccountsDialog } from "./SwitchAccountsDialog";

const LOGOUT = "logout";
const SWITCH_ACCOUNTS = "switch";

export const NavMenu = () => {
  const { instance } = useMsal();
  const { context: user } = useUserContext();
  const navigate = useNavigate();
  const [showSwitchDialog, setShowSwitchDialog] = useState(false);

  const userRender = () => {
    return (
      <div style={{ display: "block" }} className="k-menu-link-text">
        <div>{user.name}</div>
        {user.companyIds.length > 1 && <div>{user.activeCompany.name}</div>}
      </div>
    );
  };

  const onSelect = (event: MenuSelectEvent) => {
    const route = event.item.data?.route;

    if (route === SWITCH_ACCOUNTS) setShowSwitchDialog(true);
    else if (route === LOGOUT) instance?.logoutRedirect({ postLogoutRedirectUri: "/login" });
    else if (route) navigate(route);
  };

  return (
    <>
      <Menu onSelect={onSelect}>
        <MenuItem text="Canopy" data={{ route: "/" }} />
        <MenuItem text="Analytics" data={{ route: "/analytics" }} />
        <MenuItem text="Administration" data={{ route: "/admin" }} />
        <MenuItem icon="user" render={userRender}>
          <MenuItem text="Profile" data={{ route: "/user/profile" }} />
          {user.companyIds?.length && (
            <MenuItem text="Switch Company" data={{ route: SWITCH_ACCOUNTS }} />
          )}
          <MenuItem text="Logout" data={{ route: LOGOUT }} />
        </MenuItem>
      </Menu>
      <Button title="New Quote" themeColor="primary" onClick={() => navigate("/quote/new")}>
        New Quote
      </Button>

      {showSwitchDialog && <SwitchAccountsDialog onCancel={() => setShowSwitchDialog(false)} />}
    </>
  );
};
