import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useState } from "react";
import { getIconFileName } from "../../../utilities/textUtilities";
import { Commodity } from "../../quotes";

interface IMarginItemProps {
  margin: any;
  commodities: Commodity[];
  selected: number | undefined;
  onClick(id: number): void;
}

export const MarginItem = ({ margin, commodities, selected, onClick }: IMarginItemProps) => {
  const isSelected = selected === margin?.marginId;
  const commodity = commodities.find((c) => c.id === margin?.commodityId);
  const iconPath = commodity?.icon();

  const [hovered, setHovered] = useState(false);

  return (
    <Tooltip anchorElement="target" parentTitle={true} position={"top"}>
      <span
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => onClick(margin.marginId)}
      >
        <Card
          className="card-component"
          orientation="horizontal"
          type="primary"
          style={{
            fontSize: "small",
            backgroundColor: isSelected ? "#4cbb17" : "inherit",
            borderColor: hovered ? "#4cbb17" : "gray",
            color: isSelected ? "white" : "inherit",
            padding: 0,
            minHeight: 100,
            maxWidth: 250,
            cursor: "pointer",
          }}
        >
          <CardHeader
            style={{
              width: "10%",
              border: "none",
              paddingLeft: 5,
              paddingTop: 10,
              backgroundColor: isSelected ? "#4cbb17" : "inherit",
            }}
          >
            {iconPath && (
              <div style={{ width: 18 }}>
                <img
                  src={getIconFileName(iconPath, false, hovered, isSelected)}
                  title={commodity?.type}
                />
              </div>
            )}
          </CardHeader>

          <CardBody style={{ margin: 5, padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <div
                  style={{
                    fontSize: "small",
                    fontWeight: "bolder",
                  }}
                >
                  {margin.longName}
                </div>
                <div>{margin.businessType}</div>
              </div>
            </div>
          </CardBody>
        </Card>
      </span>
    </Tooltip>
  );
};
