import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./App.scss";
import { msalConfig } from "./config/authConfig";
import reportWebVitals from "./reportWebVitals";

export const pca = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
  pca.setActiveAccount(pca.getAllAccounts()[0]);
}

pca.addEventCallback((event: any) => {
  const { eventType, payload } = event;

  if (payload?.account &&
    (eventType === EventType.LOGIN_SUCCESS ||
    eventType === EventType.ACQUIRE_TOKEN_SUCCESS)
  ) {
    pca.setActiveAccount(payload.account);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={pca}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
