import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Stepper, StepperChangeEvent } from "@progress/kendo-react-layout";
import { parseDate } from "@progress/kendo-intl";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Commodity, Instrument } from "../../components/quotes";
import { useUserContext } from "../../hooks/useUserContext";
import { StepsInterface } from "./workflow";
import { AccumulatorFormRender } from "../../components/quotes/accumulator";
import { useApi } from "../../hooks/useApi";
import accumulatorApi from "../../api/accumulator";
import quotesApi from "../../api/quotes";

export const AccumulatorWorkflow = () => {
  const { context: user } = useUserContext();
  const { roles, permissions } = user;
  const { state } = useLocation();
  const { data } = state || {};

  const canSubmit =
    permissions.canSubmitQuotes && roles.canSubmitQuotes(data.company.role);

  const getQuoteDetailsApi = useApi(accumulatorApi.getQuoteDetails);
    
  const commodity = data.commodity
    ? Commodity.create(data.commodity)
    : Commodity.EMPTY;
  const instrument = data.instrument
    ? Instrument.create(data.instrument)
    : Instrument.EMPTY;

  const { step: stepString } = useParams();
  const step = parseInt(stepString as string);
  const navigate = useNavigate();

  const setStep = (step: number) => {
    navigate(`/quote/accumulator/${step}`, { state });
  };
  const [steps, setSteps] = useState<Array<StepsInterface>>([
    { label: "Start", isValid: undefined },
    { label: "Quote", isValid: undefined },
    { label: "Submit", isValid: undefined },
  ]);

  const [error, setError] = useState("");
  const [formState, setFormState] = useState<any | null>(null);

  const lastStepIndex = steps.length - 1;
  const isQuoteStep = step === lastStepIndex - 1;
  const isSubmitStep = step === lastStepIndex;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1;

  useEffect(() => {
    setStep(0);

    if (data?.quoteId) 
      getQuoteDetailsApi.request(data.quoteId);
    else 
      setFormState({
        quoteName: `${commodity.name} (${commodity.abbreviation}) ${instrument.name}`,
      });
  }, []);

  useEffect(() => {
    if (!getQuoteDetailsApi.data) return;

    const details = getQuoteDetailsApi.data.quote;
    const form = {...details, 
      contract: new Date(parseDate(details.contractDate))
    };

    setFormState(form);
  }, [getQuoteDetailsApi.data]);

  const onStepSubmit = useCallback(
    async (event: FormSubmitClickEvent) => {
      let { isValid, values } = event;
      setError("");

      if (isValid && isSubmitStep && canSubmit) {
        const pricingId = values.quote.pricingId;
        await quotesApi
          .submitQuote(pricingId, values.quoteId)
          .catch((err: any) => {
            setError(err.response?.data?.message || err.message);
            isValid = false;
          });
      }
      
      const currentSteps = steps.map(
        (currentStep: StepsInterface, index: number) => ({
          ...currentStep,
          isValid: index === step ? isValid : currentStep.isValid,
        })
      );

      setSteps(currentSteps);

      if (!isValid) return;

      setStep(Math.min(step + 1, lastStepIndex));
      setFormState(values);

      if (isSubmitStep) navigate("/");
    },
    [steps, isQuoteStep, isSubmitStep, step, lastStepIndex]
  );

  const onPrevClick = useCallback(
    (event: any) => {
      event.preventDefault();
      setStep(Math.max(step - 1, 0));
      setError("");
    },
    [step, setStep]
  );

  const handleStepperChange = (e: StepperChangeEvent) => {
    if (e.value < step || steps[e.value].isValid || steps[e.value - 1].isValid)
      setStep(e.value);
  };

  return (
    <>
      <Stepper
        value={step}
        items={steps}
        onChange={handleStepperChange}
        style={{ padding: "10px 0" }}
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ flexBasis: "75%" }}>
          {formState && (
            <Form
              initialValues={formState}
              onSubmitClick={onStepSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <AccumulatorFormRender
                  step={step}
                  commodity={commodity}
                  formRenderProps={formRenderProps}
                  instrument={instrument}
                  steps={steps}
                  onPrevClick={onPrevClick}
                  isSubmitStep={isSubmitStep}
                  isPreviousStepsValid={isPreviousStepsValid}
                  canSubmit={canSubmit}
                />
              )}
            />
          )}
          <div className="float-right">
            <Error>{error}</Error>
          </div>
        </div>
      </div>
    </>
  );
};
