import { useInternationalization } from "@progress/kendo-react-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import DateUtility from "../../../utilities/dateUtilities";

export const VanillaStepperHeader = ({
  quoteId,
  quoteName,
  product,
  step = 2,
  startDate,
  endDate,
  totalVolume,
  structure,
  direction,
  settlement,
  monthsLabel,
}: any) => {
  const { commodity, instrument, company } = product;
  const formatter = useInternationalization();

  const labelStyle = { fontSize: "small", color: "gray" };

  return (
    <div>
      <span className={"k-form-separator"} />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 0",
        }}
      >
        <div>
          <Tooltip anchorElement="target" position="top">
            <img
              alt={commodity.name}
              src={commodity.icon()}
              title={`${commodity.name} (${commodity.abbreviation})`}
              style={{ width: 24, height: 24, paddingRight: ".25rem" }}
            />
            <img
              alt={instrument.productType}
              src={instrument.icon()}
              title={instrument.productType}
              style={{ width: 24, height: 24, paddingRight: ".25rem" }}
            />
            <span style={{ fontWeight: "bolder" }}>{quoteName}</span>
          </Tooltip>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {quoteId > 0 && (
          <div>
            <span style={labelStyle}>ID</span>
            <div>{quoteId}</div>
          </div>
        )}
        {company && (
          <div>
            <span style={labelStyle}>Client</span>
            <div>{company.name}</div>
          </div>
        )}
        <div>
          <span style={labelStyle}>{monthsLabel} Months</span>
          <div>
            {`${DateUtility.formatDateMY(startDate)} - 
                  ${DateUtility.formatDateMY(endDate)}`}
          </div>
        </div>
        {step > 1 && totalVolume && (
          <div>
            <span style={labelStyle}>Total Volume</span>
            <div>
              {formatter.formatNumber(totalVolume, "n0")}
              {` ${commodity.units}`}
            </div>
          </div>
        )}
        <div>
          <span style={labelStyle}>Structure</span>
          <div>{structure}</div>
        </div>
        <div>
          <span style={labelStyle}>Direction</span>
          <div>{direction}</div>
        </div>
        <div>
          <span style={labelStyle}>Settlement</span>
          <div>{settlement}</div>
        </div>
      </div>
    </div>
  );
};
