import { useEffect, useState } from "react";

export interface IApiResults {
  data: any,
  error: string,
  loading: boolean,
  request: Function
};

export const useApi = (apiFunc: Function): IApiResults => {
  const [data, setData] = useState<any | null>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const request = async (...args: any[]) => {
    setData(null);
    setError("");
    setLoading(true);

    try {
      const result = await apiFunc(...args);
      setData(result?.data?.payload || result?.data);
    } catch (err: any) {
      console.log("ERROR:", JSON.stringify(err));
      const error = err.response?.data?.message || 
        err.response?.data?.errors.join(", ") ||
        err.message || 
        "An unexpected error occurred";
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    request
  };
};

// Wrapper for useApi that does the common thing of fetching data when the component mounts
export const useFetch = (apiFunc: Function, ...args: any[]): IApiResults => {
  const client = useApi(apiFunc);
  useEffect(() => {
    client.request(...args);
  }, []); 

  return client;
};

export const useFetchWhen = (apiFunc: Function, condition: boolean, ...args: any[]): IApiResults => {
  const client = useApi(apiFunc);
  useEffect(() => {
    if (condition) {
      client.request(...args);
    }
  }, [condition]);

  return client;
};
