export const capitalizeFirstLetter = (word: string) => {
  const capitalizedWord = word.charAt(0).toUpperCase() + word.substring(1);
  return capitalizedWord;
};

export const getIconPath = (type: string, name: string) => {
  return type && name ? `/icons/${type.toLocaleLowerCase()}/${name.toLocaleLowerCase()}.svg` : "";
};

export function getIconFileName(
  defaultIconFileName: string,
  disabled: boolean,
  hover: boolean,
  selected: boolean,
) {
  let iconStateString = "";
  if (disabled) {
    iconStateString = "-disabled";
  } else if (selected) {
    iconStateString = "-active";
  } else if (hover) {
    iconStateString = "-hover";
  }

  const fileNameParts = defaultIconFileName.split(".");
  const theFileName = fileNameParts[0] + iconStateString + "." + fileNameParts[1];
  return theFileName;
}

export function getLastChar(string: string) {
  const chars = string.split("");
  const lastIndex = string.length - 1;

  const lastChar = chars[lastIndex];
  return lastChar;
}

export const isNumberString = (num: any) => {
  return !isNaN(num) && !isNaN(parseFloat(num));
};
