import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getToken } from "../api/zendesk";
import { ZendeskSettings } from "../types/zendesk";
import { useUserContext } from "./useUserContext";

type JwtCallback = (jwt: string) => void;

const DEFAULT_LABELS = ["terms", "toc"];

export function useZendeskClassicWebWidget() {
  usePrefill(); 
  const { pathname } = useLocation();

  useEffect(() => {
    close();
    reset();
    reauthenticate();
    setSuggestionsForUrl(pathname);
  }, [pathname]);

  useEffect(() => {
    authenticate();

    // JWT expires every 2 hours, so make sure to get a new token every two hours
    const intervalId = setInterval(authenticate, 2 * 60 * 60 * 1000);

    // Clean up the timer when the component unmounts.
    return () => {
      clearInterval(intervalId);
    };
  }, []);
}

export function useZendeskSuggestions(condition: any, label: string) {
  useEffect(() => {
    const labels = addDefaultLabels(label);
    const payload = { labels };
    setSuggestions(payload);
  }, [condition]);
}

function usePrefill() {
  const { context: user } = useUserContext();
  const { name, email } = user;

  let prefillOptions: any;
  if (name && email) {
    prefillOptions = {
      name: {
        value: name,
        readOnly: true,
      },
      email: {
        value: email,
        readOnly: true,
      }
    };
  }

  useEffect(() => {
    if (prefillOptions !== undefined) {
      prefillUserInfo(prefillOptions);
    }
  }, [prefillOptions?.name.value, prefillOptions?.email.value]);
}

// helpers

function setSuggestionsForUrl(pathname: string) {
  const label = processPathname(pathname);
  const labels = addDefaultLabels(label);
  const payload = { labels };
  setSuggestions(payload);
}

function addDefaultLabels(label: string) {
  const labels = DEFAULT_LABELS.concat([label]);
  return labels;
}

function processPathname(pathname: string) {
  let arrayOfWords = pathname.split("/");
  arrayOfWords = arrayOfWords.filter(word => {
    const isValidWord = word !== '';
    return isValidWord;
  });

  if(!arrayOfWords.length) {
    arrayOfWords.push("dashboard");
  }
  const label = arrayOfWords.join(" ");
  return label;
}

function setSuggestions(payload: object) {
  const SET_SUGGESTIONS = 'helpCenter:setSuggestions';
  applyToWebWidget(SET_SUGGESTIONS, payload);
}

function reauthenticate() {
  const REAUTHENTICATE = 'helpCenter:reauthenticate';
  applyToWebWidget(REAUTHENTICATE);
}

function authenticate() {
  const UPDATE_SETTINGS = "updateSettings";
  
  const settings: ZendeskSettings = {
    webWidget: {
      authenticate: {
        jwtFn: fetchJwt
      }
    }
  };
  
  applyToWebWidget(UPDATE_SETTINGS, settings);
}

function prefillUserInfo(prefillOptions: any) {
  const PREFILL = 'prefill';
  window.zE('webWidget:on', 'open', function() {
    applyToWebWidget(PREFILL, prefillOptions);
  });
}

function close() {
  const CLOSE = 'close';
  applyToWebWidget(CLOSE);
}

function reset() {
  const RESET = 'reset';
  applyToWebWidget(RESET);
}

function applyToWebWidget(functionName: string, payload?: any) {
  const WEB_WIDGET = 'webWidget';
  window.zE(WEB_WIDGET, functionName, payload);
}

function fetchJwt(callback: JwtCallback) {
  getToken().then(response => {
    const token = response.data.payload.token;
    callback(token);
  }).catch(err => console.log(err));
};
