import { PropsWithChildren } from "react";
import { Slide } from "@progress/kendo-react-animation";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";

interface IChartExpanderProps {
  isExpanded: boolean;
  text?: string;
  setIsExpanded: (isExpanded: boolean) => void;
}

export const ChartExpander = ({ isExpanded, text, setIsExpanded, children }: PropsWithChildren<IChartExpanderProps>) => {
  return (
    <div className="expander-wrapper">
      <ExpansionPanel
        key="chartPanel"
        subtitle={isExpanded ? "" : text ?? "View Charts"}
        expanded={isExpanded}
        expandIcon="k-i-arrow-60-down"
        collapseIcon="k-i-arrow-60-up"
        onAction={(event) => {
          setIsExpanded(!event.expanded);
        }}
        style={{
          border: isExpanded ? "1px solid lightgray" : "none",
          boxShadow: "none",
          padding: 0,
          margin: 0,
        }}
      >
        <Slide transitionEnterDuration={500} transitionExitDuration={500}>
          {isExpanded && <ExpansionPanelContent>{children}</ExpansionPanelContent>}
        </Slide>
      </ExpansionPanel>
    </div>
  );
};
