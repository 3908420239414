import { useEffect, useState } from "react";
import { useInternationalization } from "@progress/kendo-react-intl";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { chartColors } from "./palette";
import { CategoryFilter, ValueFilter } from "./QuotesChartFilter";

type Props = {
  data: any[];
  selectedValue: string;
  selectedCategory: string;
}

export const QuotesDonutChart = (props: Props) => {
  const { data, selectedValue, selectedCategory } = props || [];
  const formatter = useInternationalization();
  const height = 270;

  const categoryOptions = [
    {label: CategoryFilter.Commodity, field: "commodityName"},
    {label: CategoryFilter.Company, field: "companyName"}
  ];
  
  const [totals, setTotals] = useState<any>({});
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    setTotals(calculateTotals());
  }, [data, selectedValue, selectedCategory]);

  useEffect(() => {
    setChartData(
      Object.keys(totals).map((key: string) => {
        return { name: key, value: totals[key], shortName: shortNames[key] };
      })
    );
  }, [totals]);

  const calculateTotals = () => {
    const field = categoryOptions.find(o => o.label === selectedCategory)?.field || categoryOptions[0].field;

    return data.reduce((quote: any, item: any) => {
      const currCount = quote[item[field]] ?? 0;
      const value =
        selectedValue === ValueFilter.Quotes ? 1 : 
        selectedValue === ValueFilter.Premium ? item.price : 
        selectedValue === ValueFilter.Volume ? item.volume * item.volumeFactor : 0;

      return {
        ...quote,
        [item[field]]: currCount + value,
      };
    }, {});
  };

  const shortNames: any = data.reduce((quote: any, item: any) => {
    const field = categoryOptions.find(o => o.label === selectedCategory)?.field || categoryOptions[0].field;

    return {
      ...quote,
      [item[field]]: selectedCategory === CategoryFilter.Commodity ? item.tickerCode : item[field],
    };
  }, {});

  const labelContent = (props: any) => props.dataItem.shortName;

  const renderTooltip = (context: any) => {
    const { category, value } = context.point || context;
    const displayValue = formatter.formatNumber(
      value,
      selectedValue === ValueFilter.Premium ? "c0" : "n0"
    );

    return (
      <div>
        {category}: {displayValue}
      </div>
    );
  };

  return (
    <div>
      <div>
        <Chart 
          style={{ height: height }} 
          seriesColors={chartColors}
          transitions={false}
        >
          <ChartTooltip render={renderTooltip} />
          <ChartSeries>
            <ChartSeriesItem
              type="donut"
              holeSize={height / 5}
              startAngle={45}
              data={chartData}
              field="value"
              categoryField="name"
            >
            </ChartSeriesItem>
          </ChartSeries>
          <ChartLegend 
            visible={true} 
            labels={{ content: labelContent }} 
            position="right" 
            
          />
        </Chart>
      </div>
    </div>
  );
};
