import { useEffect, useState } from "react";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useInternationalization } from "@progress/kendo-react-intl";
import { sumNumbers } from "../../../utilities/objectUtilities";
import "hammerjs";

interface CurveDialogProps {
  title: string;
  chartData: any[];
  categories: any[];
  options: any[];
  units: string;
  selection: number;
  onApply: (index: number) => void;
  onCancel: () => void;
}

export const CurveSelectionDialog = (props: CurveDialogProps) => {
  const { chartData, categories, options, units } = props;
  const [selection, setSelection] = useState<any | null>(null);
  const [curveOptions, setCurveOptions] = useState<any[]>([]);

  const xAxisValues = categories.map((c: any) => c.label);
  const yAxisCrossings = [0, categories.length];

  useEffect(() => {
    if (props.selection >= 0 && props.selection <= options.length)
      setSelection(options[props.selection]);

    const filtered = options.filter((o: any, i: number) => {
      let sum = 0;
      chartData[i].forEach((d: any) => sum += sumNumbers(d.data));
      return sum > 0;
    });

    setCurveOptions(filtered);
  }, []);

  const formatter = useInternationalization();
  
  const renderTooltip = (context: any) => {
    const { point } = context;
    const format = getFormat(point.series.name);
    const suffix = point.series.name === "Coverage" ? "" : units;
    const value = formatter.formatNumber(point.value, format);

    return <span>{value} {suffix}</span>;
  };

  const getFormat = (type: string) => (type === "Coverage" ? "c0" : "n0");

  return (
    <Dialog closeIcon={false} title={props.title} width={700}>
      <div>
        <ComboBox
          data={curveOptions}
          allowCustom={false}
          onChange={(e: any) => setSelection(e.value)}
          textField={"label"}
          dataItemKey={"value"}
          value={selection}
          size={"small"}
        />
      </div>
      {selection && (
        <div>
          <Chart>
            <ChartSeries>
              {chartData[selection.value].map((d: any, index: number) => (
                <ChartSeriesItem
                  type="column"
                  style="smooth"
                  name={d.name}
                  data={d.data}
                  axis={d.name}
                  key={index}
                />
              ))}
            </ChartSeries>

            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                categories={xAxisValues}
                labels={{ rotation: "auto" }}
                axisCrossingValue={yAxisCrossings}
              />
            </ChartCategoryAxis>

            <ChartValueAxis>
              {chartData[selection.value]
                .map((d: any) => d.name)
                .filter(
                  (value: string, index: number, cur: string[]) =>
                    cur.indexOf(value) === index
                )
                .map((label: string, index: number) => (
                  <ChartValueAxisItem
                    key={index}
                    name={label}
                    title={{ text: label }}
                    labels={{ format: getFormat(label) }}
                  />
                ))}
            </ChartValueAxis>

            <ChartLegend position="top" />
            <ChartTooltip render={renderTooltip} />
          </Chart>
        </div>
      )}
      <div className="float-right">
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={(e: any) => props.onCancel()}
        >
          Cancel
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          disabled={!selection}
          onClick={(e: any) => props.onApply(selection.value)}
        >
          Apply
        </button>
      </div>
    </Dialog>
  );
};
