import { classNames } from "@progress/kendo-react-common";
import styles from "../charts/stock.module.scss";

interface IHeaderTextSelectorProps {
  values: string[];
  selected?: string;
  onChange: (value: string) => void;
}

export const HeaderTextSelector = ({ values, selected, onChange }: IHeaderTextSelectorProps) => {
  let selectedValue = selected || values[0];

  const onClick = (e: any, value: string) => {
    e.preventDefault();
    selectedValue = value;
    onChange(value);
  };

  return (
    <div className="d-inline-block" style={{ marginRight: "1rem" }}>
      <ul className="k-reset d-flex">
        {values.map((value: string) => (
          <li className="ml-3" key={value}>
            <a
              href="#"
              onClick={(e) => onClick(e, value)}
              data-label={value}
              className={classNames("list-item", styles["list-item"], {
                [styles["list-item-selected"]]: value === selectedValue,
              })}
            >
              {value}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
