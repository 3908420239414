import { Icon } from "@progress/kendo-react-common";
import { ConfigStatus } from "./ConfigurationEnums";

interface IStatusIconProps {
  status: ConfigStatus;
  hideUnassigned?: boolean;
}

export const StatusIcon = ({ status, hideUnassigned }: IStatusIconProps) => {
  return (
    <span title={status}>
      {status === ConfigStatus.ChildMargins && (
        <Icon name="check-circle" themeColor="warning" size="large" />
      )}
      {status === ConfigStatus.Enabled && (
        <Icon name="check-circle" themeColor="success" size="large" />
      )}
      {status === ConfigStatus.Margins && <Icon name="lock" themeColor="error" size="large" />}
      {status === ConfigStatus.Suspended && <Icon name="cancel" themeColor="error" size="large" />}
      {status === ConfigStatus.Unassigned && (
        <span><Icon name="radiobutton" size="large" className={hideUnassigned ? "hidden" : ""} /></span>
      )}
    </span>
  );
};
