import { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Error } from "@progress/kendo-react-labels";
import { Field, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { ConfigAction } from "./ConfigurationEnums";
import { FormDropDownList } from "../../form";
import { LoadingIndicator } from "../../LoadingIndicator";
import { useApi } from "../../../hooks/useApi";
import configApi from "../../../api/configuration";
import { getIconFileName, capitalizeFirstLetter } from "../../../utilities/textUtilities";
import { requiredValidator } from "../../../validators";

interface IActionDialogProps {
  action: string;
  data: any;
  onClose: () => void;
  onSave: () => void;
}

export const ActionDialog = (props: IActionDialogProps) => {
  const { action, data, onClose, onSave } = props;
  const { item, margins, marginField } = data;
  const actionDisplay = capitalizeFirstLetter(action);
  const marginsList = margins
    .filter((m: any) => m.businessTypeId === item.businessTypeId)
    .filter((m: any) =>!m.commodityId || m.commodityId === item.commodityId)
    .map((m: any) => ({ value: m.marginId, label: m.longName }))
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

  item.marginSelection = marginsList.find((m: any) => m.value === item[marginField]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const activateApi = useApi(configApi.activateProducts);
  const assignApi = useApi(configApi.assign);
  const updateApi = useApi(configApi.update);

  const handleSubmitEdit = async (event: any) => {
    const config = { ...event };
    config[marginField] = event.marginSelection?.value;
    delete config.marginSelection;

    switch (action) {
      case ConfigAction.Activate:
      case ConfigAction.Suspend:
        config.isActive = !config.isActive;
        await activateApi.request([config.productId], [config.companyId], config.isActive);
        break;

      case ConfigAction.Assign:
        const productMarginId: Record<number, number> = { [config.productId]: config[marginField] };
        await assignApi.request(
          [config.productId],
          [config.companyId],
          true,
          productMarginId,
        );
        break;

      case ConfigAction.Edit:
        await updateApi.request(
          config.configurationId,
          config.companyId,
          config.isActive,
          config[marginField],
        );
        break;
    }
  };

  useEffect(() => {
    setLoading(activateApi.loading);
    setError(activateApi.error);

    if (!activateApi.loading && (activateApi.data || activateApi.error))
      if (!activateApi.error) onSave();
  }, [activateApi.loading, activateApi.data, activateApi.error]);

  useEffect(() => {
    setLoading(assignApi.loading);
    setError(assignApi.error);

    if (!assignApi.loading && (assignApi.data || assignApi.error))
      if (!assignApi.error) {
        if (Object.keys(assignApi.data?.errors)?.length) setError("Product assignment failed");
        else onSave();
      }
  }, [assignApi.loading, assignApi.data, assignApi.error]);

  useEffect(() => {
    setLoading(updateApi.loading);
    setError(updateApi.error);

    if (!updateApi.loading && (updateApi.data || updateApi.error)) if (!updateApi.error) onSave();
  }, [updateApi.loading, updateApi.data, updateApi.error]);

  return (
    <Dialog title={`${actionDisplay} Product for ${item.company}`} onClose={onClose}>
      <>
        {action != ConfigAction.Edit && (
          <div style={{ marginBottom: 15 }}>
            Please confirm you would like to {action} the following:
          </div>
        )}

        <div>
          <p style={{ fontWeight: "bolder" }}>Product</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 24, height: 24, marginBottom: 10 }}>
              <img src={getIconFileName(item.commodityIcon, false, false, false)} />
            </div>
            <div>{item.commodity}</div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 24, height: 24, marginBottom: 15 }}>
              <img src={getIconFileName(item.typeIcon, false, false, false)} />
            </div>
            <div>{item.instrument}</div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <p style={{ fontWeight: "bolder" }}>Company</p>
          <div>{item.company}</div>
        </div>

        <Form
          onSubmit={handleSubmitEdit}
          initialValues={item}
          ignoreModified={action !== ConfigAction.Edit}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              {(action === ConfigAction.Edit || action === ConfigAction.Assign) && (
                <Field
                  label="Margin"
                  name="marginSelection"
                  component={FormDropDownList}
                  validator={requiredValidator}
                  data={marginsList}
                  disabled={loading}
                  textField="label"
                  dataItemKey="value"
                />
              )}

              <div>{error && <Error>{error}</Error>}</div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "1rem",
                }}
              >
                <LoadingIndicator loading={loading} />

                {!loading && (
                  <>
                    <Button themeColor="base" disabled={loading} onClick={onClose}>
                      Cancel
                    </Button>

                    <Button
                      themeColor="success"
                      type="submit"
                      disabled={loading || !formRenderProps.allowSubmit}
                    >
                      {`${action === ConfigAction.Edit ? "Update" : actionDisplay}`}
                    </Button>
                  </>
                )}
              </div>
            </FormElement>
          )}
        />
      </>
    </Dialog>
  );
};
