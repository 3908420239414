import Allocation from "../components/admin/Allocation";
import { StringListItem } from "../components/utilities/StringListGridCell";
import { apiClient } from "./apiClient";

const endpoint = "allocation";

const getAllocations = (companyId: number) =>
  apiClient(`${endpoint}`).fetchWithParamsObject({ companyId });

const upsertAllocation = (allocation: Allocation) => {
  allocation.values = {}

  for (let i = 1; i <= 12; i++) {
    allocation.values[i] = allocation[i];
  }

  return apiClient(`${endpoint}`).post({ 
    allocationId: allocation.allocationId || 0,
    companyId: allocation.companyId,
    name: allocation.name,
    businessTypeIds: allocation.businessTypes.map((type: StringListItem) => type.value),
    commodityTypes: allocation.commodityTypeObjects.map((type: StringListItem) => type.value),
    values: allocation.values,
  });
};

const deleteAllocation = (allocationId: number) => {
  return apiClient(`${endpoint}`).delete(allocationId);
}

export default {
  getAllocations,
  upsertAllocation,
  deleteAllocation,
};
