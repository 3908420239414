import { useRanger } from "react-ranger";
import { Handle, Segment, Tick, TickLabel, Track } from ".";

interface SliderOptions {
  name: string;
  values: number[];
  min: number;
  max: number;
  staticValues?: number[] | undefined;
  staticLabels?: string[] | undefined;
  stepSize?: number | undefined;
  steps?: number[] | undefined;
  tickSize?: number | undefined;
  ticks?: number[] | undefined;
}

type Props = {
  options: SliderOptions;
  onChange(values: number[]): void;
  onDrag?(values: number[]): void;
};

export const Slider = ({ options, onChange, onDrag }: Props) => {
  const { getTrackProps, ticks, segments, handles } = useRanger({
    min: options.min,
    max: options.max,
    stepSize: options.stepSize || 1,
    steps: options.steps,
    tickSize: options.tickSize,
    ticks: options.ticks,
    values: options.values,
    onChange: onChange,
    onDrag: onDrag,
  });

  const staticRanger = useRanger({
    min: options.min,
    max: options.max,
    stepSize: 1,
    values: options.staticValues || [0],
    onChange: () => {},
    onDrag: () => {},
  });

  const staticHandleProps = options.staticValues ? staticRanger.handles.map(({ getHandleProps }) => {
    const handleProps = getHandleProps({
      style: {
        appearance: "none",
        border: "none",
        background: "transparent",
        outline: "none",
      },
    });

    handleProps.onMouseDown = () => {};
    handleProps.onTouchStart = () => {};
    return handleProps;
  }) : [];

  return (
    <div key={options.name}>
      <Track {...getTrackProps()}>
        {ticks.map(({ value, getTickProps }) => (
          <Tick {...getTickProps()}>
            <TickLabel>{value}</TickLabel>
          </Tick>
        ))}

        {segments.map(({ getSegmentProps }, i) => (
          <Segment {...getSegmentProps()} />
        ))}

        {options.staticValues &&
          staticRanger.handles.map(
            ({ value, active }, index) => (
              <button
                {...staticHandleProps[index]}
                onClick={(e: any) => e.preventDefault()}
                key={index}
              >
                <Handle active={active} disabled={true}>
                  {options.staticLabels ? options.staticLabels[index] || value : value}
                </Handle>
              </button>
            )
          )}

        {handles.map(({ value, active, getHandleProps }, index) => (
          <button
            {...getHandleProps({
              style: {
                appearance: "none",
                border: "none",
                background: "transparent",
                outline: "none",
              },
            })}
            onClick={(e: any) => e.preventDefault()}
            key={index}
          >
            <Handle active={active} disabled={false}>
              {value}
            </Handle>
          </button>
        ))}
      </Track>
    </div>
  );
};
