import { PropsWithChildren, createContext, useEffect, useState } from "react";
import companyApi from "../../api/company";
import { useFetch } from "../../hooks/useApi";
import { IUserCompany } from "./UserCompanyContextProvider";

interface ICompaniesContext {
  context: IUserCompany[];
  setContext: (obj: any) => void;
}

export const CompaniesContext = createContext<ICompaniesContext>(
  {} as ICompaniesContext
);

export const CompaniesContextProvider = ({ children }: PropsWithChildren) => {
  const { data } = useFetch(companyApi.get);
  const [context, setContext] = useState<IUserCompany[]>([]);

  useEffect(() => {
    setContext(
      data?.companies?.map((c: any) => {
        return { id: c.id, name: c.name, role: c.role, canSell: c.canSell };
      }) || []
    );
  }, [data]);

  return (
    <CompaniesContext.Provider value={{ context, setContext }}>
      {children}
    </CompaniesContext.Provider>
  );
};
