import { apiClient } from "./apiClient";

const endpoint = "configuration";

const getConfiguration = (
  companyId: number,
  commodityId: number,
  instrumentType: number,
  productType: string,
) => {
  const params = `?companyId=${companyId}&commodityId=${commodityId}&instrumentType=${instrumentType}&productType=${productType}`;
  return apiClient(`${endpoint}`).fetchWithParams(params);
};

const getConfigurations = () => {
  return apiClient(`${endpoint}/all`).fetch();
};

const activate = (configurationIds: number[], isActive: boolean) => {
  return apiClient(`${endpoint}/activate`).post({ configurationIds, isActive });
};

const activateProducts = (productIds: number[], companyIds: number[], isActive: boolean) => {
  return apiClient(`${endpoint}/activateProducts`).post({ productIds, companyIds, isActive });
};

const create = (configuration: any) => {
  return apiClient(endpoint).post({ configuration: configuration });
};

const update = (configurationId: number, companyId: number, activate: boolean, marginId?: number) => {
  return apiClient(endpoint).put({ configurationId, companyId, activate, marginId });
};

const assign = (
  productIds: number[],
  companyIds: number[],
  activate: boolean = false,
  productMarginIds: Record<number, number> | null = null,
) => {
  return apiClient(`${endpoint}/assign`).post({
    productIds: productIds,
    companyIds: companyIds,
    activate: activate,
    productMarginIds: productMarginIds,
  });
};


export default {
  getConfiguration,
  getConfigurations,
  activate,
  activateProducts,
  update,
  create,
  assign,
};
