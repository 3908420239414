import { useNavigate, useParams } from "react-router-dom";
import { QuoteDetailsSummary } from "../../components/quotes/quoteDetails";

export const QuoteDetails = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();

  return (
    <div style={{ marginBottom: 20 }}>
      <QuoteDetailsSummary
        quoteId={parseInt(quoteId as string)}
        onReject={() => navigate("/")}
      />
    </div>
  );
};
