import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Route, Routes } from "react-router-dom";
import {
  AuthenticatedUserContextProvider,
  CompaniesContextProvider,
  UserCompanyContextProvider,
  UserContextProvider,
} from "./components/functional";
import { AboutProduct } from "./components/help/AboutProduct";
import { PageLayout } from "./components/layout/PageLayout";
import { useZendeskClassicWebWidget } from "./hooks/useZendeskClassicWebWidget";
import { Administration, Login, UnderConstruction } from "./pages";
import AgreementApprovalPage from "./pages/AgreementApprovalPage";
import { AnalyticsDashboard, QuotesDashboard } from "./pages/dashboard";
import {
  AccumulatorWorkflow,
  InsuranceWizard,
  NewQuote,
  QuoteDetails,
  VanillaWorkflow,
} from "./pages/quotes";

const Pages = () => {
  useZendeskClassicWebWidget();

  return (
    <Routes>
      <Route path="/" element={<QuotesDashboard />} />
      <Route path="/analytics" element={<AnalyticsDashboard />} />
      <Route path="/quote/new" element={<NewQuote />} />
      <Route path="/quote">
        <Route path="insurance/:step?" element={<InsuranceWizard />} />
        <Route path="details/:quoteId" element={<QuoteDetails />} />
        <Route path="vanilla/:step?" element={<VanillaWorkflow />} />
        <Route path="accumulator/:step?" element={<AccumulatorWorkflow />} />
      </Route>
      <Route path="/admin" element={<Administration />} />
      <Route path="/user/profile" element={<UnderConstruction />} />
      <Route path="help" element={<AboutProduct />} />
      <Route path="agreements" element={<AgreementApprovalPage />} />
    </Routes>
  );
};

const App = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <CompaniesContextProvider>
          <AuthenticatedUserContextProvider>
            <UserCompanyContextProvider>
              <UserContextProvider>
                <PageLayout>
                  <Pages />
                </PageLayout>
              </UserContextProvider>
            </UserCompanyContextProvider>
          </AuthenticatedUserContextProvider>
        </CompaniesContextProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </>
  );
};

export default App;
