import { Typography } from "@progress/kendo-react-common";
import { useInternationalization } from "@progress/kendo-react-intl";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { useLocation } from "react-router-dom";
import quotesApi from "../../../api/quotes";
import { useFetch } from "../../../hooks/useApi";
import { Commodity } from "../Commodity";
import { Instrument } from "../Instrument";

type Props = {
  quoteDetails: any,
  canSubmit: boolean
}

export const InsuranceSubmitSummary = ({ quoteDetails, canSubmit }: Props) => {
  let { units, coverage, volume, startDate, endDate, retentionLevel, quoteId, pricingId, name, price }
    = quoteDetails;
  
  const formatter = useInternationalization();
  volume = formatter.formatNumber(volume, "n0");
  const { state } = useLocation();
  const { data } = state || {};

  const commodity = data.commodity
    ? Commodity.create(data.commodity)
    : Commodity.EMPTY;
  const instrument = data.instrument
    ? Instrument.create(data.instrument)
    : Instrument.EMPTY;

  const commodityName = commodity.name;
  const instrumentName = instrument.name;

  const { data: quoteDetailsData } = useFetch(quotesApi.getDetails, quoteId);
  let companyName = "";
  if (quoteDetailsData) {
    const { quote: { brokerName, sellerName } } = quoteDetailsData;
    companyName = brokerName.toLowerCase() === "no broker name" ? sellerName : brokerName;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
      <Card>
        <CardBody>
          <Typography.p>
            <ul>
              <li>
                {`You have quoted an insurance policy that provides ${instrumentName}
                for commodity ${commodityName}. Your total
                volume covered will be ${volume} ${units} and your maximum
                coverage (policy limit) is ${coverage}. The consecutive months covered by this
                policy begin ${startDate} and end ${endDate}.`}
              </li>
              <li>
                {`You chose a retention level of ${retentionLevel}. Your monthly volume,
                attach, exhaust, retention and coverage, as presented above,
                will remain available to you on this website. For later
                reference, this quote has an ID of ${quoteId} and a label or name of
                ${name}.`}
              </li>

              { canSubmit && (  
              <>
                <li>
                  By pressing Apply, you are:
                  <ol style={{ listStyleType: "decimal", paddingLeft: 20 }}>
                    <li>
                      Applying for an insurance policy with no guarantee that
                      the policy will be approved.
                    </li>
                    <li>
                      Sending your application to the Risk Canopy underwriting
                      team.
                    </li>
                    <li>
                      {`Agreeing to purchase your policy with the terms described
                      above if the application is approved. The policy premium
                      due will be ${price} and is payable to ${companyName} within five (5) business days of
                      application approval.`}
                    </li>
                  </ol>
                </li>
                <li>
                  Notification of your application approval or denial will
                  arrive shortly in your email inbox.
                </li>
              </>              
              )}

              { !canSubmit && (
                <li>Click Finish to return to the dashboard.</li>
              )}
            </ul>
          </Typography.p>          
        </CardBody>
      </Card>
    </div>
  );
};
