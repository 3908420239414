import { Button } from "@progress/kendo-react-buttons";

import { useApi } from "../../../hooks/useApi";
import useDownloadFile from "../../../hooks/usePdfDownload";
import { LoadingIndicator } from "../../LoadingIndicator";

type Props = {
  quoteId: number,
  pricingId: number,
  api: Function
}

const PdfDownloadButton = ({ quoteId, pricingId, api }: Props) => {
  const pdfApi = useApi(api);

  const handleDownloadClick = () => {
    pdfApi.request(quoteId, pricingId);
  };

  let extension = "pdf";
  let tempFileName = `Quote_${quoteId}_Summary`;
  let fileName = `${tempFileName}.${extension}`;
  
  useDownloadFile(fileName, "application/pdf", pdfApi.data);

  return (
    <>
      <Button
        themeColor="primary"
        onClick={handleDownloadClick}
        disabled={pdfApi.loading}
      >
        Download PDF
      </Button>
      <LoadingIndicator loading={pdfApi.loading} />
    </>
  );
}

export default PdfDownloadButton;
