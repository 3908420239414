import { Button } from "@progress/kendo-react-buttons";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnMenuProps,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { State, process } from "@progress/kendo-data-query";
import { useEffect, useState } from "react";
import { Configuration } from "./Configuration";
import { ConfigAction, ConfigStatus } from "./ConfigurationEnums";
import { ActionDialog, StatusIcon } from ".";
import { ColumnMenuCheckboxFilter, CustomGridCell } from "../../grid";

interface IConfigurationsGridProps {
  data: Configuration[];
  childCompanyIds: number[];
  filterText: string;
  margins: any[];
  marginField: string;
  onChange: () => void;
}

export const ConfigurationsGrid = ({
  data,
  childCompanyIds,
  filterText,
  margins,
  marginField,
  onChange,
}: IConfigurationsGridProps) => {
  const [gridData, setGridData] = useState<Configuration[]>([]);
  const [filterAssigned, setFilterAssigned] = useState(false);
  const [action, setAction] = useState("");
  const [actionItem, setActionItem] = useState<Configuration | null>(null);

  const [result, setResult] = useState<any>([]);
  const [dataState, setDataState] = useState<State>({
    sort: [{ field: "commodity", dir: "asc" }],
    skip: 0,
    take: 20,
  });

  useEffect(() => {
    setGridData(data);
  }, [data]);

  useEffect(() => {
    applyDataStateChange(dataState);
  }, [gridData]);

  const createDataState = (dataState: State) => {
    return {
      result: process(data.slice(0), dataState),
      dataState: dataState,
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    applyDataStateChange(event.dataState);
  };

  const applyDataStateChange = (dataState: State) => {
    const updatedState = createDataState(dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  useEffect(() => {
    const newState = Object.assign({}, dataState, {
      filter: {
        logic: "and",
        filters: [
          { field: "status", operator: "contains", value: "" },
          {
            logic: "or",
            filters: [
              { field: "company", operator: "contains", value: filterText },
              { field: "commodity", operator: "contains", value: filterText },
              { field: "instrument", operator: "contains", value: filterText },
              { field: "margin", operator: "contains", value: filterText },
            ],
          },
        ],
      },
    });
    applyDataStateChange(newState);
  }, [filterText, filterAssigned]);

  const handleAction = (action: string, item: Configuration) => {
    setAction(action);
    setActionItem(item);
  };

  const StatusCell = (props: GridCellProps) => {
    const { field, dataItem } = props;
    const value: string = dataItem[field || ""];

    const status = 
      value === ConfigStatus.Margins && dataItem.margin ? ConfigStatus.ChildMargins : value as ConfigStatus;

    return (
      <CustomGridCell {...props}>
        <StatusIcon status={status} />
      </CustomGridCell>
    );
  };

  const ProductCell = (props: GridCellProps) => {
    const { dataItem } = props;

    return (
      <CustomGridCell {...props}>
        <div className="ellipsis-cell">{dataItem.commodity}</div>
        <div className="ellipsis-cell">{dataItem.instrument}</div>
      </CustomGridCell>
    );
  };

  interface ActionsProps {
    cellProps: GridCellProps;
    action: string;
    onActionClick: (action: string, item: Configuration) => void;
  }
  const ActionsCell = ({ cellProps, action, onActionClick }: ActionsProps) => {
    const { dataItem } = cellProps;
    const disabled = action.length > 0;

    return (
      <td>
        <div style={{ display: "flex" }}>
          {dataItem.status !== ConfigStatus.Unassigned && (
            <Button
              disabled={disabled}
              icon="edit"
              size="small"
              themeColor="primary"
              fillMode="outline"
              title="Edit Margin"
              onClick={() => onActionClick(ConfigAction.Edit, dataItem)}
            />
          )}

          {dataItem.configurationId > 0 && childCompanyIds.includes(dataItem.companyId) && (
            <Button
              disabled={disabled}
              icon={`${dataItem.isActive ? "cancel" : "check"}`}
              size="small"
              themeColor="primary"
              fillMode="outline"
              title={`${dataItem.isActive ? "Suspend" : "Activate"}`}
              onClick={() => onActionClick(dataItem.isActive ? ConfigAction.Suspend : ConfigAction.Activate, dataItem)}
            />
          )}

          {dataItem.status === ConfigStatus.Unassigned &&
            childCompanyIds.includes(dataItem.companyId) && (
              <Button
                disabled={disabled}
                icon="plus"
                size="small"
                themeColor="primary"
                fillMode="outline"
                title="Assign"
                onClick={() => onActionClick(ConfigAction.Assign, dataItem)}
              />
            )}
        </div>
      </td>
    );
  };

  const ActionsCellWrapper = (props: GridCellProps) => (
    <ActionsCell
      cellProps={props}
      action={action}
      onActionClick={(newAction: string) => handleAction(newAction, props?.dataItem)}
    />
  );

  const FilterColumnMenu = (props: GridColumnMenuProps) => (
    <ColumnMenuCheckboxFilter {...props} data={gridData} />
  );

  const onActionComplete = () => {
    setAction("");
    onChange();
  };

  return (
    <>
      <Grid
        data={result}
        {...dataState}
        pageable={true}
        resizable={true}
        sortable={true}
        onDataStateChange={dataStateChange}
      >
        <GridColumn field="commodity" title="Product" cell={ProductCell} />

        <GridColumn field="company" title="Company" columnMenu={FilterColumnMenu} />

        <GridColumn field="margin" title="Margin" columnMenu={FilterColumnMenu} />

        <GridColumn
          field="status"
          title="Status"
          cell={StatusCell}
          columnMenu={FilterColumnMenu}
          width={150}
        />

        <GridColumn field="configurationId" title="Actions" cell={ActionsCellWrapper} width={150} />
      </Grid>

      {action && actionItem && (
        <ActionDialog
          action={action}
          data={{ item: actionItem, margins, marginField }}
          onClose={() => setAction("")}
          onSave={onActionComplete}
        />
      )}
    </>
  );
};
