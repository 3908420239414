import { PropsWithChildren, createContext, useContext, useState } from "react";
import { AuthenticatedUserContext, IAuthenticatedUser } from "./AuthenticatedUserProvider";
import { UserPermissions, UserRoles } from "../../utilities/userUtilities";
import { IUserCompany, UserCompanyContext } from "./UserCompanyContextProvider";

export interface IAppUser {
  permissions: UserPermissions;
  roles: UserRoles;
  activeCompany: IUserCompany;
}

export class AppUser implements IAppUser, IAuthenticatedUser {
  name = "";
  email = "";
  companyIds: number[] = [];
  claimsPermissions: Record<number, number[]> = {};
  permissions = new UserPermissions([]);
  roles = new UserRoles([]);
  activeCompany = {} as IUserCompany;

  private constructor(user: IAuthenticatedUser | null, activeCompany?: IUserCompany | null) {
    if (user) {
      this.name = user.name;
      this.email = user.email;
      this.companyIds = user.companyIds;
      this.claimsPermissions = user.claimsPermissions;
      this.activeCompany = activeCompany ?? {} as IUserCompany;
      this.roles = new UserRoles(activeCompany?.role ? [activeCompany.role] : []);

      const permissions = user.claimsPermissions[this.activeCompany.id];
      this.permissions = new UserPermissions(permissions ?? []);
    }
  }

  static get EMPTY(): AppUser {
    return AppUser.create(null);
  }

  static create(user: IAuthenticatedUser | null, activeCompany?: IUserCompany | null): AppUser {
    return new AppUser(user, activeCompany);
  }

  public isAdmin = (): boolean =>
    this.permissions.canAdminCompanies ||
    this.permissions.canAdminUsers ||
    this.permissions.canConfigureMargins ||
    this.permissions.canConfigureProducts;
}

interface IUserContext {
  context: AppUser;
  setContext: (obj: any) => void;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const { context: user } = useContext(AuthenticatedUserContext);
  const { context: userCompany } = useContext(UserCompanyContext);
  const [context, setContext] = useState<AppUser>(AppUser.EMPTY);

  const newUser = AppUser.create(user, userCompany);

  if (JSON.stringify(context) !== JSON.stringify(newUser))
    setContext(newUser);  
    
  return (
    <UserContext.Provider value={{ context, setContext }}>
      {children}
    </UserContext.Provider>
  );
};
