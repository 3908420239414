import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useApi } from "../../hooks/useApi";
import { useSessionTracking } from "../../hooks/useSessionTracking";
import usersApi from "../../api/users";

export const SessionEndingDialog = () => {
  const { instance } = useMsal();
  const { seconds, showWarning } = useSessionTracking();
  const formatter = useInternationalization();
  const getUserAccountApi = useApi(usersApi.get);

  // useEffect(() => {
  //   // If the user has been deactivated, logout
  //   if (!getUserAccountApi.data?.user?.isActive) logout();
  // }, [getUserAccountApi.data]);

  const logout = async () => {
    await instance
      .logoutRedirect()
      .catch((error) => console.log(error));
  };

  return (
    <>
      {showWarning && (
        <Dialog title={"Session Expiring"} width={500} closeIcon={false}>
          <p>
            For security reasons, your online session is about to end. To stay
            signed in to your account, please click "Continue". Otherwise you
            will automatically be signed out in{" "}
            <span style={{ fontWeight: "bold" }}>
              {formatter.formatNumber(seconds, "n0")} seconds
            </span>
            .
          </p>
          <DialogActionsBar>
            <Button
              themeColor={"secondary"}
              disabled={getUserAccountApi.loading}
              onClick={async () => await logout()}
            >
              Log Out
            </Button>
            <Button
              themeColor={"primary"}
              disabled={getUserAccountApi.loading}
              onClick={async () => await getUserAccountApi.request()}
            >
              Continue
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};
