import { apiClient } from "./apiClient";

const endpoint = "companies";

const get = () => apiClient(endpoint).fetch();

const getAll = () => apiClient(`${endpoint}/all`).fetch();

const getLineage = () => apiClient(`${endpoint}/lineage`).fetch();

const getAgreementDocuments = (companyId: number) => {
  return apiClient(`${endpoint}/agreementdocuments`).fetchWithParams(`?companyId=${companyId}`);
};

const getFormData = (
  company: any,
  agreementDocumentFiles: File[],
  agreementDocumentsRemoved: number[],
) => {
  const formData = new FormData();
  formData.append("companyJson", JSON.stringify(company));
  if(agreementDocumentFiles?.length>0){
    agreementDocumentFiles.forEach((f) => formData.append("agreementDocuments", f));
  }
  if (agreementDocumentsRemoved?.length>0){
    agreementDocumentsRemoved.forEach(id => formData.append("agreementDocumentsRemoved", id.toString()));
  }
  formData.set("Content-Type", "multipart/form-data");
  return formData;
};

const create = (company: any, agreementDocumentFiles: File[]) => {
  return apiClient(endpoint).postFormData(getFormData(company, agreementDocumentFiles, []));
};

const update = (
  company: any,
  agreementDocumentFiles: File[],
  agreementDocumentsRemoved: number[],
) => {
  return apiClient(`${endpoint}/update`).postFormData(
    getFormData(company, agreementDocumentFiles, agreementDocumentsRemoved),
  );
};

export default {
  get,
  getAll,
  getLineage,
  create,
  update,
  getAgreementDocuments,
};
