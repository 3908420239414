import { useInternationalization } from "@progress/kendo-react-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import DateUtility from "../../../utilities/dateUtilities";
import DetailItem from "../../utilities/DetailItem";

export const InsuranceStepperHeader = ({
  quoteId,
  companyName = false,
  product,
  step = 2,
  quoteName,
  startDate,
  endDate,
  totalVolume,
  totalCoverage,
}: any) => {
  const formatter = useInternationalization();
  const { commodity, instrument } = product;

  return (
    <div>
      <span className={"k-form-separator"} />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 0",
        }}
      >
        <div>
          <Tooltip anchorElement="target" position="top">
            <img
              alt={commodity.name}
              src={commodity.icon()}
              title={`${commodity.name} (${commodity.abbreviation})`}
              style={{ width: 24, height: 24, paddingRight: ".25rem" }}
            />
            <img
              alt={instrument.productType}
              src={instrument.icon()}
              title={instrument.productType}
              style={{ width: 24, height: 24, paddingRight: ".25rem" }}
            />
            <span style={{ fontWeight: "bolder" }}>{quoteName}</span>
          </Tooltip>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {quoteId > 0 && <DetailItem label={"ID"} content={quoteId} />}
        {companyName && <DetailItem label={"Client"} content={companyName} />}

        <DetailItem
          label={"Contract Months"}
          content={`${DateUtility.formatDateMY(startDate)} -  ${DateUtility.formatDateMY(endDate)}`}
        />

        {step > 1 && totalVolume && (
          <DetailItem
            label={"Total Volume"}
            content={`${formatter.formatNumber(totalVolume, "n0")} ${commodity.units}`}
          />
        )}

        {step > 1 && (
          <DetailItem
            label={"Maximum Coverage"}
            content={formatter.formatNumber(totalCoverage, "c0")}
          />
        )}
      </div>
    </div>
  );
};
