import { useCallback, useEffect, useState } from "react";
import { useInternationalization } from "@progress/kendo-react-intl";
import { parseDate } from "@progress/kendo-intl";
import { Card, CardBody, CardSubtitle } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { Error } from "@progress/kendo-react-labels";
import contractsApi from "../../api/contracts";
import { useApi } from "../../hooks/useApi";
import DateUtility from "../../utilities/dateUtilities";
import { minByProperty } from "../../utilities/objectUtilities";
import "hammerjs";

interface IntradayCurvesSlippageChartProps {
  commodity: any | null;
  refresh?: number;
  maxDate?: string;
  showTitle?: boolean;
  quoteSlippage?: any[];
  onLoading: (value: boolean) => void;
}

export const IntradayCurvesSlippageChart = ({
  commodity,
  refresh,
  showTitle,
  quoteSlippage,
  onLoading,
}: IntradayCurvesSlippageChartProps) => {
  const decimals = commodity?.decimals ?? 2;
  const currencyFormat = `c${decimals}`;
  const numberFormat = `n${decimals}`;
  const unit = (commodity?.units ?? "s").slice(0, -1);

  const formatter = useInternationalization();
  const getSettlementDataApi = useApi(contractsApi.getSettlementData);

  const [commodityId, setCommodityId] = useState(0);
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [slippage, setSlippage] = useState<number | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [closingCurve, setClosingCurve] = useState<any[]>([]);
  const [intradayCurve, setIntradayCurve] = useState<any[]>([]);
  const [quoteCurve, setQuoteCurve] = useState<any[]>([]);
  const [chartMin, setChartMin] = useState(0);
  const [maxDate, setMaxDate] = useState<Date>();

  useEffect(() => {
    setCommodityId(commodity?.id ?? 0);
    setRefreshFlag(refresh ?? 0);
  }, [commodity, refresh]);

  useEffect(() => {
    setSlippage(quoteSlippage?.reduce((acc, cur) => (acc += cur.slippage), 0));

    setIntradayCurve(
      quoteSlippage?.map((s) => ({
        contract: s.contract,
        date: new Date(parseDate(s.contract)),
        value: s.underlyingPrice,
      })) ?? [],
    );

    const quoteUnderlying =
      quoteSlippage?.map((s) => ({
        contract: s.contract,
        date: new Date(parseDate(s.contract)),
        value: s.quoteUnderlyingPrice,
      })) ?? [];
    setQuoteCurve(quoteUnderlying);

    const maxDateTime = Math.max(...quoteUnderlying.map((q) => q.date.getTime()));
    const maxQuoteDate = new Date(maxDateTime);
    maxQuoteDate.setMonth(maxQuoteDate.getMonth() + 6);
    setMaxDate(maxQuoteDate);
  }, [quoteSlippage]);

  useEffect(() => {
    getCurves(commodityId);
  }, [commodityId, refreshFlag]);

  useEffect(() => {
    setIsLoading(getSettlementDataApi.loading);
    onLoading(getSettlementDataApi.loading);
    setClosingCurve(formatCurveData(getSettlementDataApi.data?.data));
  }, [getSettlementDataApi.data, getSettlementDataApi.loading, maxDate]);

  useEffect(() => {
    setChartMin(
      Math.floor(
        10 *
          Math.min(
            minByProperty(closingCurve, "value"),
            minByProperty(intradayCurve, "value"),
            minByProperty(quoteCurve, "value"),
          ),
      ) / 10,
    );
  }, [intradayCurve, closingCurve, quoteCurve]);

  const formatCurveData = (data: any) => {
    if (!data) return [];

    const keys = Object.keys(data);
    const x = keys.map((k) => {
      return { contract: k, date: new Date(parseDate(k)), value: data[k] };
    });
    return maxDate ? x.filter((d) => d.date <= maxDate) : x;
  };

  const getCurves = useCallback(async (commodityId: number) => {
    if (commodityId > 0) getSettlementDataApi.request(commodityId);
  }, []);

  const renderTooltip = (props: any) => {
    const { categoryText, points, colorMarker } = props;

    return (
      <div style={{ backgroundColor: colorMarker }}>
        <div>{DateUtility.formatDateMY(categoryText)}</div>
        <div>
          {points.map((point: any, i: number) => (
            <div key={i} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{point.series.name}: </div>
              <div>{formatter.formatNumber(point.value, currencyFormat)} / {unit}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {commodity && (
        <Card>
          <CardBody>
            {showTitle && (
              <CardSubtitle style={{ textAlign: "center" }}>
                Intraday Market Data: {commodity.name}
              </CardSubtitle>
            )}
            <Error>
              <div>{getSettlementDataApi.error}</div>
            </Error>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-evenly",
              }}
            >
              <div>Refresh Time: {DateUtility.formatDateTime(new Date())}</div>
              {slippage !== null && (
                <div>
                  Slippage: {formatter.formatNumber(slippage, currencyFormat)} / {unit}
                </div>
              )}
            </div>

            <Chart style={{ height: 200 }} transitions={false}>
              <ChartTooltip shared={true} render={renderTooltip} />
              {!isLoading && (
                <>
                  <ChartSeries>
                    <ChartSeriesItem
                      name="Settlements"
                      type="line"
                      field="value"
                      categoryField="date"
                      color="lightgray"
                      style="smooth"
                      data={closingCurve}
                      markers={{ visible: false }}
                    ></ChartSeriesItem>

                    <ChartSeriesItem
                      name="Most Recent"
                      type="line"
                      field="value"
                      categoryField="date"
                      color="lightblue"
                      style="smooth"
                      data={intradayCurve}
                    ></ChartSeriesItem>

                    <ChartSeriesItem
                      name="Time of Quote"
                      type="line"
                      field="value"
                      categoryField="date"
                      color="#4cbb17"
                      style="smooth"
                      data={quoteCurve}
                    ></ChartSeriesItem>
                  </ChartSeries>

                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      labels={{ format: "MMM yyyy", rotation: -45 }}
                      maxDivisions={30}
                    />
                  </ChartCategoryAxis>

                  <ChartValueAxis>
                    <ChartValueAxisItem
                      labels={{ format: numberFormat }}
                      min={chartMin}
                      title={{
                        text: `$ / ${unit}`,
                      }}
                    />
                  </ChartValueAxis>
                </>
              )}
              <ChartLegend position="right" />
            </Chart>
          </CardBody>
        </Card>
      )}
    </>
  );
};
