import { UploadFileInfo, UploadFileStatus } from "@progress/kendo-react-upload";
import { useEffect, useState } from "react";
import DateUtility from "../utilities/dateUtilities";
import { useApi } from "./useApi";

export interface AgreementDocument {
  agreementDocumentId: number;
  fileName: string;
  byteLength: number;
  document: any;
  createDate: Date;
  getRawFile: () => any;
}

export function useAgreementDocuments(
  isReady: boolean,
  fetchAgreementDocuments: Function,
  ...args: any[]
): [UploadFileInfo[], React.Dispatch<React.SetStateAction<UploadFileInfo[]>>, any[]] {
  const agreementDocumentsApi = useApi(fetchAgreementDocuments);

  const [files, setFiles] = useState<Array<UploadFileInfo>>([]);

  useEffect(() => {
    if (isReady) {
      agreementDocumentsApi.request(...args);
    }
  }, []);

  const documents = agreementDocumentsApi.data?.agreementDocuments;

  useEffect(() => {
    if (agreementDocumentsApi.data && documents) {
      const filesFromApi = agreementDocumentsApi.data.agreementDocuments.map(
        (document: AgreementDocument) => {
          const formattedDate = DateUtility.formatShortDate(document.createDate);
          return {
            size: document.byteLength,
            progress: 0,
            status: UploadFileStatus.Selected,
            name: document.fileName + " (uploaded:" + formattedDate + ")",
            extension: ".pdf",
            uid: document.agreementDocumentId.toString(),
            getRawFile: function () {
              return document.document;
            },
          };
        },
      );
      setFiles(filesFromApi);
    }
  }, [agreementDocumentsApi.data, agreementDocumentsApi.loading]);

  return [files, setFiles, documents];
}
