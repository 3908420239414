import { apiClient } from "./apiClient";

const ENDPOINT = "vanilla";

export const getStructures = (instrumentId: number) => {
  return apiClient(`${ENDPOINT}/structures`).fetchWithParams(`?instrumentId=${instrumentId}`);
};

export const getComponents = (structureId: number) => {
  return apiClient(`${ENDPOINT}/components`).fetchWithParams(`?structureId=${structureId}`);
};

export const getQuoteDetails = (quoteId: number) => {
  return apiClient(`${ENDPOINT}/quote`).fetchWithParams(`?quoteId=${quoteId}`);
}

export const saveQuoteDetails = (data: any) => {
  return apiClient(`${ENDPOINT}/quote`).post(data);
};

export const getQuote = (quoteId: number) => {
  return apiClient(`${ENDPOINT}/quote`).put({quoteId: quoteId});
};
