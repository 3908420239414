import { GridCellProps } from "@progress/kendo-react-grid";
import { useInternationalization } from "@progress/kendo-react-intl";
import { CustomGridCell } from ".";

export const NumberGridCell = (props: GridCellProps) => {
  const field = props.field || "";
  const format = props.format || "n0";
  const value = props.dataItem[field];
  const formatter = useInternationalization();

  return (
    <CustomGridCell {...props}>
      {formatter.formatNumber(value, format)}
    </CustomGridCell>
  );
};
