import { Fragment, useEffect, useState } from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartLegend,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
} from "@progress/kendo-react-charts";
import { Error } from "@progress/kendo-react-labels";
import { LoadingIndicator } from "../LoadingIndicator";
import { useApi } from "../../hooks/useApi";
import quotesApi from "../../api/quotes";
import DateUtility from "../../utilities/dateUtilities";
import "hammerjs";

export const QuotesHitRateChart = () => {
  const [data, setData] = useState<any[]>([]);

  const getHitRateApi = useApi(quotesApi.getHitRates);

  useEffect(() => {
    getHitRateApi.request();
  }, []);

  useEffect(() => {
    if (getHitRateApi.data?.data) setData(getHitRateApi.data.data);
  }, [getHitRateApi.data]);

  const renderTooltip = ({ point }: any) => (
    <Fragment>
      <div>Quote ID: {point.dataItem.quoteId}</div>
      <div>
        {point.dataItem.count} quotes since{" "}
        {DateUtility.formatShortDate(point.dataItem.firstQuoted)}
      </div>
    </Fragment>
  );

  return (
    <div style={{ padding: ".5rem" }}>
      <LoadingIndicator loading={getHitRateApi.loading} />
      {getHitRateApi.error && <Error>{getHitRateApi.error}</Error>}

      {!getHitRateApi.loading && data && (
        <>
          {data.length === 0 && <div>No data to display</div>}
          <Chart style={{ height: "250px" }}>
            <ChartSeries>
              <ChartSeriesItem
                type="bubble"
                data={data}
                xField="daysSinceFirstQuote"
                yField="daysSinceLastQuote"
                categoryField="quoteId"
                sizeField="count"
              >
              </ChartSeriesItem>
            </ChartSeries>
            <ChartXAxis>
              <ChartXAxisItem
                title={{
                  text: "Days Since First Quote",
                  font: "10pt Montserrat-VariableFont_wght",
                  padding: 5
                  
                }}
              />
            </ChartXAxis>
            <ChartYAxis>
              <ChartYAxisItem
                title={{
                  text: "Days Since Last Quote",
                  font: "10pt Montserrat-VariableFont_wght",
                  padding: 5
                }}
              />
            </ChartYAxis>
            <ChartTooltip render={renderTooltip} />
            <ChartLegend visible={false} />
          </Chart>
        </>
      )}
    </div>
  );
};
