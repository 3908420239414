import { Button } from "@progress/kendo-react-buttons";
import { GridCellProps } from "@progress/kendo-react-grid";
import { CustomGridCell } from ".";

export interface EditLinkCellProps extends GridCellProps {
  title?: string;
  enterEdit: (item: any) => void;
}

export const EditLinkCell = (props: EditLinkCellProps) => {
  const {
    field,
    dataItem,
    title,
    enterEdit,
  } = props;
  const defaultText = "Edit";
  const label = field ? dataItem[field] : defaultText;

  return (
    <CustomGridCell {...props}>
      <Button
        size="small"
        themeColor="primary"
        fillMode="link"
        title={title || defaultText}
        onClick={() => enterEdit(dataItem)}
      >
        {label}
      </Button>
    </CustomGridCell>
  );
};
