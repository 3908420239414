import { apiClient } from "./apiClient";

const endpoint = "margins";

const getMargins = () => apiClient(`${endpoint}`).fetch();

const getBetaCubes = (marginId: number) =>
  apiClient(`${endpoint}/betaCubes`).fetchWithParams(`?marginId=${marginId}`);

const updateBetas = (betaCubes: any[], marginId: number = 0) => {
  apiClient(`${endpoint}/betaCubes`).put({ marginId, betaCubes });
}

const createMargin = (
  name: string,
  betaCubes: any[],
  commodityId: number,
  businessTypeId: number,
) =>
  apiClient(`${endpoint}/create`).post({ name, betaCubes, commodityId, businessTypeId });


export default {
  getMargins,
  getBetaCubes,
  updateBetas,
  createMargin,
};
