import { DateRangePicker, DateRangePickerChangeEvent, DateRangePickerProps } from "@progress/kendo-react-dateinputs";
import { MutableRefObject, useRef } from "react";

const CanopyDateRangePicker = ({ onChange = () => { }, ...others }: DateRangePickerProps & { innerRef?: MutableRefObject<any> }) => {

  // Close calendar after both dates have been selected
  const pickerRef = useRef<any>(null);
  const handleChange = (event: DateRangePickerChangeEvent) => {
    const { value: { start, end } } = event;
    const { current } = pickerRef;
    const areBothDatesChosen = start && end;
    if (current) {
      if (areBothDatesChosen) {
        current.setShow(false);
      }
    }

    onChange(event);
  };

  return (
    <DateRangePicker ref={pickerRef} onChange={handleChange} {...others} />
  );
};

export default CanopyDateRangePicker;
