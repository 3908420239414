import { Switch } from "@progress/kendo-react-inputs";
import { useCallback, useState } from "react";

//Switch display between currency or percent
export const ChartSwitch = (props: any) => {
	const { value } = props;

	const [switchOn, setSwitchOn] = useState(value);

	const handleChange = useCallback(
		(event: any) => {
			setSwitchOn(event.target.value);

			if (props.onChange) {
				props.onChange.call(undefined, { value: event.target.value });
			}
		}, [props.onChange]);

	return (
		<div style={{ paddingLeft: "0.25rem" }}>
			<span style={{ paddingRight: "0.25rem" }}><small>Show As %</small></span>
			<Switch
				checked={switchOn}
				onChange={handleChange}
				size="small"
			/>
		</div>
	);
};
