export interface IAlert {
  notificationId: number;
  companyId: number | null;
  severity: string;
  startDate: string;
  endDate: string | null;
}

export class Alert implements IAlert {
  notificationId = 0;
  companyId: number | null = null;
  severity = "";
  startDate = "";
  endDate: string | null = null;

  private constructor(alert: IAlert | null) {
    if (alert) {
      this.notificationId = alert.notificationId;
      this.companyId = alert.companyId;
      this.severity = alert.severity;
      this.startDate = alert.startDate;
      this.endDate = alert.endDate;
    }
  }

  static get EMPTY(): Alert {
    return Alert.create(null);
  }

  static create(obj: IAlert | null): Alert {
    return new Alert(obj);
  }
}
