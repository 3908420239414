import { apiClient } from "./apiClient";

const endpoint = "notifications";
const getActive = () => apiClient(`${endpoint}/active`).fetch();

const getAll = () => apiClient(`${endpoint}/all`).fetch();

const save = (notification: any) => apiClient(endpoint).post({notification: notification});

export default {
  getActive,
  getAll,
  save
};
