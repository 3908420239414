import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Hint } from "@progress/kendo-react-labels";
import { Card, CardSubtitle } from "@progress/kendo-react-layout";
import { CSSProperties, useCallback, useRef, useState } from "react";
import { getIconFileName } from "../../utilities/textUtilities";

const OptionsCard = (props: any) => {
  const { item, value, name, onChange } = props;
  const anchor = useRef<any | null>(null);

  const [cardHover, setCardHover] = useState(false);

  const selected = value === item.value.toString();
  const iconFileName = getIconFileName(
    item.icon,
    item.disabled,
    cardHover,
    selected
  );

  const inputStyles: CSSProperties | undefined = {
    visibility: "hidden",
    position: "absolute",
  };

  const onSelect = useCallback(
    (e: any) => {
      setCardHover(false);

      if (onChange) onChange(e);
    },
    [setCardHover]
  );

  return (
    <>
      <input
        onChange={onSelect}
        checked={selected}
        id={item.label}
        type="radio"
        value={item.value}
        name={name}
        style={inputStyles}
        disabled={item.disabled}
      />
      <label
        onMouseEnter={() => setCardHover(true)}
        onMouseLeave={() => setCardHover(false)}
        htmlFor={item.label}
      >
        <Card
          ref={anchor}
          key={value}
          style={{
            cursor: item.disabled ? "inherit" : "pointer",
            border: "none",
            width: 105,
            textAlign: "center",
          }}
        >
          <div>
            {item.icon && (
              <div
                style={{
                  border: "2px solid lightgray",
                  borderRadius: "60%",
                  padding: ".55rem",
                  backgroundColor: selected ? "#378811" : "white",
                  display: "block",
                  margin: "auto",
                  width: item.size === "small" ? 32 : 64,
                  height: item.size === "small" ? 32 : 64,
                }}
              >
                <img alt={item.label} src={iconFileName} />
              </div>
            )}

            {item.hint && (
              <div
                style={{
                  fontSize: "x-small",
                  color: item.disabled ? "#ccc" : "gray",
                }}
              >
                {item.hint}
              </div>
            )}

            <CardSubtitle
              style={{
                color: item.disabled ? "#ccc" : selected ? "black" : "gray",
              }}
            >
              {item.label}
            </CardSubtitle>
          </div>
        </Card>
      </label>
    </>
  );
};

export const CardSelector = (props: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    id,
    label,
    valid,
    disabled,
    hint,
    visited,
    modified,
    data,
    name,
    value,
    ...others
  } = props;

  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const onChange = useCallback(
    (event: any) => props.onChange({ value: event.currentTarget.value }),
    [props]
  );

  // TODO: clean up hard-coded styles: move to scss classes and/or pass styles as props

  return (
    <>
      {data?.length === 0 && (
        <FieldWrapper>
          <div>{label}</div>
          <label className="k-text-error">No selections available</label>
        </FieldWrapper>
      )}

      {data?.length > 0 && (
        <FieldWrapper>
          <label className={`k-label${valid ? "" : " k-text-error"}`}>
            {label}
          </label>

          <ul className="k-radio-list k-list-horizontal">
            {data.map((item: any, index: number) => {
              return (
                <li className="k-radio-item" key={index}>
                  <OptionsCard
                    key={value}
                    item={item}
                    value={value}
                    name={name}
                    onChange={onChange}
                  />
                </li>
              );
            })}
          </ul>

          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </FieldWrapper>
      )}
    </>
  );
};
