import { Button } from "@progress/kendo-react-buttons";

type Props = {
  isDisabled: boolean;
  isSubmitStep: boolean;
  canSubmit: boolean;
  onSubmit(event: any): void;
  submitLabel?: string;
};

export const WorkflowSubmitButton = ({
  isDisabled,
  isSubmitStep,
  canSubmit,
  onSubmit,
  submitLabel = "Submit",
}: Props) => {
  return (
    <Button themeColor={"primary"} disabled={isDisabled} onClick={onSubmit}>
      {isSubmitStep ? (canSubmit ? submitLabel : "Finish") : "Next"}
    </Button>
  );
};
