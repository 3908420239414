
type Props = {
}

export const AboutProduct = ({ }: Props) => {
  return ( <></>
    // <GridLayout cols={[{ width: '25%' }, { width: '50%' }, { width: '25%' }]} align={{ horizontal: "center" }}>
    //   <GridLayoutItem col={2}><HelpExpansionPanels /></GridLayoutItem>
    // </GridLayout>
  );
};
