import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";

const WARNING_LEVEL = 60;

export const useSessionTracking = () => {
  const { instance } = useMsal();
  const [showWarning, setShowWarning] = useState(false);
  const [seconds, setSeconds] = useState(300);
  const [expiry, setExpiry] = useState<Date | undefined>();

  useEffect(() => {
    // Listen to msal events to determine token expiration
    const callbackId = instance.addEventCallback((message) => {
      if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        const result: any = message.payload;
        if (result?.idTokenClaims?.exp) {
          var date = new Date(0);
          date.setUTCSeconds(result.idTokenClaims.exp);
          setExpiry(date);
        }
      }
    });

    return () => {
      if (callbackId) instance.removeEventCallback(callbackId);
    };
  }, []);

  useEffect(() => {
    if (!expiry) return;

    const intv = window.setInterval(async () => {
      const now = new Date();
      const diff = (expiry.getTime() - now.getTime()) / 1000.0;

      if (diff < 0) await instance?.logoutRedirect();
      else {
        setShowWarning(diff < WARNING_LEVEL);
        setSeconds(diff);
      }
    }, 1000);

    return () => {
      window.clearInterval(intv);
    };
  }, [expiry, instance]);

  return {
    showWarning,
    seconds,
  };
};
