import { Typography } from "@progress/kendo-react-common";
import { useInternationalization } from "@progress/kendo-react-intl";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { LoadingIndicator } from "../../LoadingIndicator";
import { ICommodity } from "../Commodity";
import quotesApi from "../../../api/quotes";
import { useFetch } from "../../../hooks/useApi";

interface IAccumulatorTAndCProps {
  quoteDetails: any;
  commodity: ICommodity;
  canSubmit: boolean;
};

export const AccumulatorSubmitSummary = ({ quoteDetails, commodity, canSubmit }: IAccumulatorTAndCProps) => {
  const { quoteId, name, contract, volume, price } = quoteDetails;
  const formatter = useInternationalization();
  const totalVolume = formatter.formatNumber(volume, "n0");
  const contractMonth = formatter.formatDate(contract, "MMM yyyy");
  const totalPrice = formatter.formatNumber(price, "c2");

  const { data: quoteDetailsData, loading } = useFetch(quotesApi.getDetails, quoteId);
  let companyName = "";
  if (quoteDetailsData) {
    const { quote: { brokerName, sellerName } } = quoteDetailsData;
    companyName = brokerName.toLowerCase() === "no broker name" ? sellerName : brokerName;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
      <LoadingIndicator loading={loading} />

      {!loading && (
      <Card>
        <CardBody>
          <Typography.p>
            <ul>
              <li>
                {`You have quoted an Accumulator for commodity ${commodity.name}. Your total
                volume covered will be ${totalVolume} ${commodity.units}. The contract month covered by this
                instrument is ${contractMonth}.`}
              </li>
              <li>
                {`Your contract volume, strike, knockout price, and fixing frequency will remain 
                available to you on this website. For later reference, this quote has an ID of ${quoteId} 
                and a label or name of ${name}.`}
              </li>

              {canSubmit && (
                <>
                  <li>
                    By pressing Apply, you are:
                    <ol style={{ listStyleType: "decimal", paddingLeft: 20 }}>
                      <li>
                        Applying to purchase this financial instrument with no
                        guarantee that the purchase will be approved.
                      </li>
                      <li>Sending your application to the Risk Canopy team.</li>
                      <li>
                        {`Agreeing to purchase the product with the terms described
                          above if the application is approved. The total price
                          due will be ${totalPrice} and is payable to ${companyName} within five (5) 
                          business days of application approval.`}
                      </li>
                    </ol>
                  </li>
                  <li>
                    Notification of your application approval or denial will
                    arrive shortly in your email inbox.
                  </li>
                </>
              )}

              {!canSubmit && <li>Click Finish to return to the dashboard.</li>}
            </ul>
          </Typography.p>
        </CardBody>
      </Card>
      )}
    </div>
  );
};
