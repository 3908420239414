import { PropsWithChildren, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from "@progress/kendo-react-form";
import { Error, Label } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { FormInput } from "../form";
import { LoadingIndicator } from "../LoadingIndicator";
import useTimer from "../../hooks/useTimer";
import DateUtility from "../../utilities/dateUtilities";
import { requiredValidator } from "../../validators";

interface QuoteExpiryProps {
  expiration: Date;
  hideExpiry?: boolean;
  loading: boolean;
  onExpire: (expiry: string) => void;
  onRefresh: () => void;
}

export const QuoteExpiry = ({
  expiration,
  hideExpiry,
  loading,
  onExpire,
  onRefresh,
  children,
}: PropsWithChildren<QuoteExpiryProps>) => {
  const { count } = useTimer();

  const defaultExpiration = new Date();
  defaultExpiration.setMinutes(defaultExpiration.getMinutes() + 1);
  const [expiry, setExpiry] = useState(DateUtility.formatDateTime(defaultExpiration));
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    setExpiry(DateUtility.formatDateTime(expiration));
  }, [expiration, loading]);

  useEffect(() => {
    const now = new Date();
    setExpired(now.getTime() > expiration.getTime());
  }, [count]);

  useEffect(() => {
    if (expired) setExpiry("");
  }, [expired]);

  useEffect(() => {
    onExpire(expiry);
  }, [expiry]);

  const onClickRefresh = (e: any) => {
    e.preventDefault();
    onRefresh();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
      }}
    >
      {!loading && (
        <>
          {expiry && !expired && !hideExpiry && (
            <>
              <Label>
                Quote Expires
                {children}
                <span>&nbsp;{expiry}</span>
              </Label>
            </>
          )}

          {!expiry && expired && (
            <>
              <LoadingIndicator loading={loading} />
              {!loading && (
                <Tooltip anchorElement="target" position="top" parentTitle={true}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Error>The quote has expired.</Error>
                    <Button
                      size="small"
                      icon="refresh"
                      themeColor="primary"
                      title="Refresh"
                      onClick={onClickRefresh}
                    />
                  </div>
                </Tooltip>
              )}
            </>
          )}
        </>
      )}

      <Field
        key="expiry"
        id="expiry"
        name="expiry"
        component={FormInput}
        validator={requiredValidator}
        showValidationMessage={false}
        style={{ visibility: "hidden", display: "none" }}
      />
    </div>
  );
};
