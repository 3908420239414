import { useMsal } from "@azure/msal-react";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { ListView, ListViewHeader, ListViewItemProps } from "@progress/kendo-react-listview";
import { useState } from "react";
import usersApi from "../api/users";
import PdfFileItem from "../components/utilities/PdfFileItem";
import { ViewPdfDocumentDialog } from "../components/utilities/ViewPdfDocumentDialog";
import { useAgreementDocuments } from "../hooks/useAgreementDocuments";
import { useApi } from "../hooks/useApi";

const AgreementApprovalPage = () => {
  const [files] = useAgreementDocuments(true, usersApi.getAgreementDocuments);
  const approveAgreementDocumentsApi = useApi(usersApi.approveAgreementDocuments);

  const [viewPdf, setViewPdf] = useState(false);
  const [pdfData, setPdfData] = useState<{ bytes: any; fileName: string } | null>(null);
  const [checkedFiles, setCheckedFiles] = useState<{ [key: string]: boolean }>({});

  const handleOpenPdf = (document: any, fileName: string) => {
    setPdfData({ bytes: document, fileName: fileName });
    setViewPdf(true);
  };

  const handleCheckboxChange = (uid: string) => {
    setCheckedFiles((prev) => ({
      ...prev,
      [uid]: !prev[uid],
    }));
  };

  const allChecked =
    Object.values(checkedFiles).every((checked) => checked) &&
    Object.values(checkedFiles).length === files.length;

  const handleAgree = async () => {
    const agreementDocumentIds = Object.keys(checkedFiles);
    await approveAgreementDocumentsApi.request(agreementDocumentIds);
    window.location.reload();
  };

  const { instance } = useMsal();

  const logout = async () => {
    await instance.logoutRedirect().catch((error) => console.log(error));
  };

  const CustomListItem = (props: ListViewItemProps) => {
    const { dataItem } = props;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 0",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={checkedFiles[dataItem.uid] || false}
            onChange={() => handleCheckboxChange(dataItem.uid)}
            style={{ marginRight: "10px" }}
          />
          <PdfFileItem dataItem={dataItem} onOpenPdf={handleOpenPdf} />
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "1rem" }}>
          <ListView
            data={files}
            item={CustomListItem}
            header={() => (
              <ListViewHeader>
                <h1>To proceed, agree to all the documents below</h1>
              </ListViewHeader>
            )}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "1rem" }}>
          <Button themeColor="base" onClick={logout}>
            Log Out
          </Button>
          <Button
            themeColor="success"
            onClick={handleAgree}
            disabled={!allChecked}
            style={{ marginLeft: "10px" }}
          >
            Agree
          </Button>
        </div>
      </div>
      {viewPdf && pdfData && (
        <ViewPdfDocumentDialog
          document={pdfData.bytes}
          onClose={() => setViewPdf(false)}
          fileName={pdfData.fileName}
        />
      )}
    </>
  );
};

export default AgreementApprovalPage;
