import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";

export interface StringListItem {
  text: string;
  value: any;
}

interface StringListGridCellProps extends GridCellProps {
  allValues: StringListItem[];
}

const StringListGridCell = ({ dataItem, field, onChange, allValues }: StringListGridCellProps) => {
  const values = dataItem[field as string];
  const itemsString = values.map((item: any) => item.text).join(", ");

  const handleChange = (e: MultiSelectChangeEvent) => {
    const newValue = e.target.value;

    if (onChange) {
      onChange({
        dataItem,
        field,
        value: newValue,
        dataIndex: 0,
        syntheticEvent: e.syntheticEvent,
      });
    }
  };

  return (
    <td>
      {dataItem.inEdit ? (
        <MultiSelect
          onChange={handleChange}
          value={values}
          data={allValues}
          textField="text"
          dataItemKey="value"
          size="small"
        />
      ) : (<>{itemsString}</>)}
    </td>
  );
};

export default StringListGridCell;
