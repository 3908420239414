import productsApi from "../../../api/products";
import quotesApi from "../../../api/quotes";
import { IApiResults, useFetch } from "../../../hooks/useApi";
import { useUserContext } from "../../../hooks/useUserContext";
import { Commodity } from "../Commodity";
import { Instrument } from "../Instrument";
import { AccumulatorQuoteDetails } from "../accumulator";
import { InsuranceQuoteDetails } from "../insurance";
import { VanillaQuoteDetails } from "../vanilla/VanillaQuoteDetails";

type Props = {
  quoteId: number;
  onReject: () => void;
};

const INSURANCE = "Insurance";
const VANILLA = "Vanilla";
const ACCUMULATOR = "Accumulator";

export const QuoteDetailsSummary = (props: Props) => {
  const { quoteId, onReject } = props;

  const { context: user } = useUserContext();
  const isRiskTaker = user.roles.isRiskTaker;
  let isLoading = false;

  const commoditiesRequest = useFetch(productsApi.getCommodities);
  const instrumentsRequest = useFetch(productsApi.getInstruments);
  const quoteRequest = useFetch(quotesApi.getDetails, quoteId);

  const refetch = () => {
    quoteRequest.request(quoteId);
  };

  const allData: any = {};
  [
    [commoditiesRequest, "commodities"],
    [instrumentsRequest, "instruments"],
    [quoteRequest, "quote"],
  ].forEach((item) => {
    const [request, label, key] = item;
    const data = (request as IApiResults)?.data;
    if (data) {
      const theItem = data[label as string];
      const theKey = key || label;
      allData[theKey as string] = theItem;
    } else {
      isLoading = true;
    }
  });

  //useZendeskSuggestions(isLoading, "quote details");

  const { commodities, instruments, quote } = allData || {};
  const commodity = Commodity.create(
    commodities?.find((commodity: any) => commodity.id === quote?.commodityId),
  );
  const instrument = Instrument.create(
    instruments?.find(
      (instrument: any) =>
        instrument.id === quote?.instrumentId && instrument.productType === quote?.productType,
    ),
  );

  const quoteType =
    quote?.productType === INSURANCE
      ? INSURANCE
      : instrument.name === ACCUMULATOR
        ? ACCUMULATOR
        : instrument.name
          ? VANILLA
          : "";

  return (
    <>
      {quoteType === INSURANCE ? (
        <InsuranceQuoteDetails
          quote={quote}
          refetch={refetch}
          commodity={commodity}
          instrument={instrument}
          isRiskTaker={isRiskTaker}
          onReject={onReject}
        />
      ) : quoteType === ACCUMULATOR ? (
        <AccumulatorQuoteDetails
          quote={quote}
          refetch={refetch}
          commodity={commodity}
          instrument={instrument}
          isRiskTaker={isRiskTaker}
          onReject={onReject}
        />
      ) : quoteType === VANILLA ? (
        <VanillaQuoteDetails
          quote={quote}
          refetch={refetch}
          commodity={commodity}
          instrument={instrument}
          isRiskTaker={isRiskTaker}
          onReject={onReject}
        />
      ) : (
        <></>
      )}
    </>
  );
};
