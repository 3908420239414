import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Switch } from "@progress/kendo-react-inputs";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { useRef } from "react";

export const FormSwitch = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    optional,
    id,
    valid,
    disabled,
    hint,
    vertical,
    ...others
  } = fieldRenderProps;

  const editorRef = useRef(null);
  const showValidationMessage: boolean = touched && Boolean(validationMessage);
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const flexDirection = vertical ? "column" : "row";

  return (
    <FieldWrapper>
      <div style={{ display: "flex", justifyContent: "space-between", flexDirection }}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        {!vertical && <>&nbsp;</>}
        <Switch
          ref={editorRef}
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
      </div>
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
