import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { addDays } from "@progress/kendo-date-math";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useEffect, useState } from "react";
import { chartColors } from "./palette";
import { CategoryFilter, ValueFilter } from "./QuotesChartFilter";
import DateUtility from "../../utilities/dateUtilities";

type Props = {
  data: any[];
  selectedValue: string;
  selectedCategory: string;
  dateRange: SelectionRange;
};

export const QuotesByDateChart = (props: Props) => {
  const { data, selectedValue, selectedCategory, dateRange } = props || {};
  const formatter = useInternationalization();

  const categoryOptions = [
    { label: CategoryFilter.Commodity, field: "commodityName" },
    { label: CategoryFilter.Company, field: "companyName" },
  ];

  const [chartData, setChartData] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<string[]>([]);

  useEffect(() => {
    setChartData(createSeries());
  }, [data, selectedValue, selectedCategory]);

  const createSeries = () => {
    const field =
      categoryOptions.find((o) => o.label === selectedCategory)?.field ||
      categoryOptions[0].field;

    const dates: string[] = [];
    const labels: string[] = [];
    for (
      let d = dateRange.start;
      d && dateRange.end && d <= dateRange.end;
      d = addDays(d, 1)
    ) {
      dates.push(DateUtility.formatDateYMD(d));
      labels.push(DateUtility.formatDateMD(d));
    }

    setDateLabels(labels);

    const seriesData = data.reduce((acc: any, item: any) => {
      const dateIndex = dates.indexOf(item.quoteDate.substring(0, 10));
      const value =
        selectedValue === ValueFilter.Quotes ? 1 : 
        selectedValue === ValueFilter.Premium ? item.price : 
        selectedValue === ValueFilter.Volume ? item.volume * item.volumeFactor : 0;

      const index = acc.findIndex((i: any) => i.name === item[field]);

      if (index >= 0) 
        acc[index].data[dateIndex] += value;
      else {
        const series = {
          name: item[field],
          data: new Array(dates.length).fill(0),
        };
        series.data[dateIndex] += value;
        acc.push(series);
      }
      return acc;
    }, []);

    return seriesData;
  };

  const format = () => (selectedValue === ValueFilter.Premium ? "c0" : "n0");

  const shortNames: any = data?.reduce((quote: any, item: any) => {
    const field =
      categoryOptions.find((o) => o.label === selectedCategory)?.field ||
      categoryOptions[0].field;

    return {
      ...quote,
      [item[field]]: selectedCategory === CategoryFilter.Commodity ? item.tickerCode : item[field],
    };
  }, {});

  const labelContent = (props: any) => shortNames[props.series.name];

  const valueUnits = () => selectedValue === ValueFilter.Volume ? "(gallons)" : "";

  const renderTooltip = (context: any) => {
    const { series, value } = context.point;
    const displayValue = formatter.formatNumber(value, format());

    return (
      <>
        <div>{series.name}</div>
        <div>{displayValue} {valueUnits()}</div>
      </>
    );
  };

  return (
    <div>
      <div>
        <Chart style={{ height: 250 }} seriesColors={chartColors} transitions={false}>
          <ChartTooltip render={renderTooltip} />

          <ChartValueAxis>
            <ChartValueAxisItem
              labels={{ format: format() }}
              title={{ text: `${selectedValue} ${valueUnits()}` }}
            />
          </ChartValueAxis>

          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              categories={dateLabels}
              labels={{ rotation: "auto" }}
            />
          </ChartCategoryAxis>

          <ChartSeries>
            {chartData.map((item: any) => (
              <ChartSeriesItem
                key={item.name}
                type="column"
                stack={true}
                data={item.data}
                name={item.name}
              />
            ))}
          </ChartSeries>
          <ChartLegend visible={false} />
        </Chart>
      </div>
    </div>
  );
};
