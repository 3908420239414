import { Button } from "@progress/kendo-react-buttons";
import { FormElement } from "@progress/kendo-react-form";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  InsuranceStepperHeader,
  Quote,
  StartQuote,
  Submit,
  Volumes,
} from ".";
import allocationApi from "../../../api/allocation";
import contractsApi from "../../../api/contracts";
import insuranceApi from "../../../api/insurance";
import { useApi } from "../../../hooks/useApi";
import { ContractDates, useDatePair } from "../../../hooks/useDatePair";
import { BusinessType } from "../../../types/businessType";
import { WorkflowSubmitButton } from "../WorkflowSubmitButton";

export const InsuranceFormContent = ({
  step,
  commodity,
  formRenderProps,
  instrument,
  steps,
  onPrevClick,
  isSubmitStep,
  isPreviousStepsValid,
  canSubmit
}: any) => {
  const contractDates: ContractDates = formRenderProps.valueGetter("contract");

  const quoteId: number = formRenderProps.valueGetter("quoteId");

  // Reset allocations to flat curve if and only if dates change and monthly
  // Allocations were previously customized.

  const resetAllocation = () => formRenderProps.onChange("allocation", { value: 0 });
  const getAllocations = () => formRenderProps.valueGetter("allocation");

  useDatePair(contractDates, resetAllocation, getAllocations);

  const { state: { data } = {} } = useLocation();
  const [product] = useState<any>({ ...data, instrument, commodity });

  const getAllocationsApi = useApi(allocationApi.getAllocations);
  const getLevelsApi = useApi(insuranceApi.getRetentionLevels);
  const getMarketStatsApi = useApi(contractsApi.getMarketStatistics);
  useEffect(() => {
    getAllocationsApi.request(product.company.id);
    getLevelsApi.request();
    getMarketStatsApi.request(commodity.id, instrument.id);
  }, []);

  let allocations = getAllocationsApi.data?.allocations || [];

  const INSURANCE = BusinessType.Insurance;
  allocations = allocations.filter((allocation: any) => {
    const isInsurance = allocation.businessTypeIds.some((id: number) => id === INSURANCE);
    const matchesCommodityType = allocation.commodityTypes.some((commodityType: string) => commodityType === data.commodity.type);

    const isMatch = isInsurance && matchesCommodityType;
    return isMatch;
  });

  const retentionLevels = getLevelsApi.data?.retentionLevels || {};

  const isSubmitDisabled = !(isPreviousStepsValid && formRenderProps.valid);

  const quoteName = formRenderProps.valueGetter("quoteName");
  const startDate = formRenderProps.valueGetter("contract.start");
  const endDate = formRenderProps.valueGetter("contract.end");
  const totalVolume = formRenderProps.valueGetter("totalVolume");
  const totalCoverage = formRenderProps.valueGetter("totalCoverage");
  const companyName = product.company.name;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {step > 0 && (
       <InsuranceStepperHeader
          quoteId={quoteId}
          companyName={companyName}
          product={product}
          step={step}
          quoteName={quoteName}
          startDate={startDate}
          endDate={endDate}
          totalVolume={totalVolume}
          totalCoverage={totalCoverage}
        />
      )}

      <FormElement>
        {step > 0 && (
          <span
            className={"k-form-separator"}
            style={{ margin: "10px 0 20px 0" }}
          />
        )}

        {step === 0 && <StartQuote formRenderProps={formRenderProps} product={product} />}
        {step === 1 && <Volumes formRenderProps={formRenderProps} product={product} allocations={allocations} />}
        {step === 2 && <Quote formRenderProps={formRenderProps} product={product} retentionLevels={retentionLevels} marketStatsResults={getMarketStatsApi} />}
        {step === 3 && <Submit formRenderProps={formRenderProps} product={product} />}

        <span
          style={{ marginTop: 20 }}
          className={"k-form-separator"}
        />
        <div className="multi-step-form-footer" style={{ paddingTop: 10 }}>
          <span className="form-step-label k-button-text">Step {step + 1} of {steps.length}</span>
          <div>
            {step !== 0 ? (
              <Button style={{ marginRight: "16px" }} onClick={onPrevClick}>
                Previous
              </Button>
            ) : undefined}
            <WorkflowSubmitButton
              isDisabled={isSubmitDisabled}
              isSubmitStep={isSubmitStep}
              onSubmit={formRenderProps.onSubmit}
              canSubmit={canSubmit}
              submitLabel="Apply"
            />
          </div>
        </div>
      </FormElement>
    </div>
  );
};
