import { useCallback, useEffect, useState } from "react";
import companyApi from "../../../api/company";
import configApi from "../../../api/configuration";
import marginsApi from "../../../api/margins";
import productsApi from "../../../api/products";
import { useApi } from "../../../hooks/useApi";
import { useUserContext } from "../../../hooks/useUserContext";
import { Commodity, ICommodity, IInstrument, Instrument } from "../../quotes";
import { HeaderTextSelector } from "../../utilities";
import { Company, ICompany } from "../Company";
import { Configuration, IConfiguration } from "./Configuration";
import { ConfigurationsOverviewTab, ConfigurationsWorkflow } from ".";

const OVERVIEW_VIEW = "Overview";
const BULK_VIEW = "Bulk Operations";

export const ProductConfigurations = () => {
  const getBusinessTypesApi = useApi(productsApi.getBusinessTypesByCompany);
  const getCompaniesApi = useApi(companyApi.getLineage);
  const getConfigurationsApi = useApi(configApi.getConfigurations);
  const getCommoditiesApi = useApi(productsApi.getCommodities);
  const getCommodityTypesApi = useApi(productsApi.getCommodityTypes);
  const getInstrumentsApi = useApi(productsApi.getInstruments);
  const getMarginsApi = useApi(marginsApi.getMargins);
  const getProductTypesApi = useApi(productsApi.getProductTypes);
  const getProductsApi = useApi(productsApi.getProducts);

  const [selectedView, setSelectedView] = useState(OVERVIEW_VIEW);
  const [businessTypes, setBusinessTypes] = useState<Record<number, string>>({});
  const [companies, setCompanies] = useState<Company[]>([]);
  const [commodities, setCommodities] = useState<Commodity[]>([]);
  const [commodityTypes, setCommodityTypes] = useState<Record<string, string>>({});
  const [configurations, setConfigurations] = useState<Record<number, Configuration[]>>({});
  const [instruments, setInstruments] = useState<Instrument[]>([]);
  const [margins, setMargins] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [productTypes, setProductTypes] = useState<Record<string, string>>({});

  const { context: user } = useUserContext();
  const companyId = user.activeCompany.id;
  const marginField = user.roles.isRiskTaker
    ? "riskTakerMarginId"
    : user.roles.isBroker
      ? "brokerMarginId"
      : "";

  useEffect(() => {
    const getLists = async () =>
      await Promise.all([
        getBusinessTypesApi.request(companyId),
        getCompaniesApi.request(),
        getProductTypesApi.request(),
        getCommodityTypesApi.request(),
        getCommoditiesApi.request(),
        getInstrumentsApi.request(0),
        getMarginsApi.request(),
        getProductsApi.request(),
      ]);

    getLists();
    getConfigurations();
  }, [companyId]);

  const getConfigurations = useCallback(async () => {
    getConfigurationsApi.request();
  }, []);

  useEffect(() => {
    if (getBusinessTypesApi.data)
      setBusinessTypes(getBusinessTypesApi.data.businessTypes);
  }, [getBusinessTypesApi.data]);

  useEffect(() => {
    if (getCompaniesApi.data)
      setCompanies(getCompaniesApi.data.companies.map((c: ICompany) => Company.create(c)) || []);
  }, [getCompaniesApi.data]);

  useEffect(() => {
    if (getCommoditiesApi.data)
      setCommodities(
        (getCommoditiesApi.data.commodities || []).map((c: ICommodity) => Commodity.create(c)),
      );
  }, [getCommoditiesApi.data]);

  useEffect(() => {
    if (getCommodityTypesApi.data) 
      setCommodityTypes(getCommodityTypesApi.data.commodityTypes);
  }, [getCommodityTypesApi.data]);

  useEffect(() => {
    if (getInstrumentsApi.data)
      setInstruments(
        (getInstrumentsApi.data.instruments || []).map((c: IInstrument) => Instrument.create(c)),
      );
  }, [getInstrumentsApi.data]);

  useEffect(() => {
    if (getMarginsApi.data) setMargins(getMarginsApi.data.margins || []);
  }, [getMarginsApi.data]);

  useEffect(() => {
    if (getProductTypesApi.data) setProductTypes(getProductTypesApi.data.productTypes);
  }, [getProductTypesApi.data]);

  useEffect(() => {
    if (getProductsApi.data) setProducts(getProductsApi.data.products);
  }, [getProductsApi.data]);

  useEffect(() => {
    if (getConfigurationsApi.data) {
      const configs = (getConfigurationsApi.data.configurations || []).map((c: IConfiguration) => {
        const company = companies.find((com) => com.id === c.companyId);
        const commodity = commodities.find((com) => com.id === c.commodityId);
        const instrument = instruments.find(
          (i) => i.instrumentType === c.instrumentType && i.productType === c.productType,
        );

        const config = Configuration.create(c, {
          company: company?.name,
          commodityIcon: commodity?.icon(),
          commodity: commodity?.name,
          commodityType: commodity?.type,
          instrument: instrument?.name,
          typeIcon: instrument?.icon(),
          type: productTypes[c.productType],
        });

        return config;
      });

      const configItems = configs.reduce((acc: any, cur: Configuration) => {
        const key = cur.companyId;
        if (acc[key]) acc[key].push(cur);
        else acc[key] = [cur];
        return acc;
      }, {});

      setConfigurations(configItems);
    }
  }, [getConfigurationsApi.data, companies, commodities, instruments, productTypes]);

  const handleBulkOperationComplete = () => {
    setSelectedView(OVERVIEW_VIEW);
    getConfigurations();
  };

  return (
    <>
      <HeaderTextSelector
        values={[OVERVIEW_VIEW, BULK_VIEW]}
        selected={selectedView}
        onChange={setSelectedView}
      />

      {selectedView === OVERVIEW_VIEW && (
        <ConfigurationsOverviewTab
          commodityTypes={commodityTypes}
          companies={companies}
          companyId={companyId}
          configurations={configurations}
          margins={margins}
          marginField={marginField}
          productTypes={productTypes}
          onChange={getConfigurations}
        />
      )}

      {selectedView === BULK_VIEW && (
        <ConfigurationsWorkflow
          businessTypes={businessTypes}
          commodities={commodities}
          commodityTypes={commodityTypes}
          companies={companies}
          companyId={companyId}
          configurations={configurations}
          margins={margins}
          marginField={marginField}
          productTypes={productTypes}
          onComplete={handleBulkOperationComplete}
        />
      )}
    </>
  );
};
