import { GridCellProps } from "@progress/kendo-react-grid";
import { CustomGridCell } from ".";

export const BooleanGridCell = (props: GridCellProps) => {
  const field = props.field || "";
  const value = props.dataItem[field];

  return (
    <CustomGridCell {...props} style={{ textAlign: "center" }}>
      {value ? 
        (<span className="k-icon k-i-check" style={{ color: "green" }}></span>) : 
        ("")
      }
    </CustomGridCell>
  );
};
