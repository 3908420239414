import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useInternationalization } from "@progress/kendo-react-intl";
import DateUtility from "../../../utilities/dateUtilities";

type Props = {
  quote: any;
  units: string;
  showInputs?: boolean;
};

export const AccumulatorQuoteSummary = ({ quote, units, showInputs }: Props) => {
  const formatter = useInternationalization();
  const labelStyle = { fontWeight: "bold" };
  let row = 1;

  return (
    <GridLayout align={{ vertical: "bottom" }} gap={{ cols: 10, rows: 10 }}>
      {showInputs && (
        <>
          <GridLayoutItem col={1} row={row} key={`l_${row}`}>
            <span style={labelStyle}>Knock Out Level</span>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={row++} key={`v_${row}`}>
            {formatter.formatNumber(quote.knockOutLevel, "c2")}
          </GridLayoutItem>
          <GridLayoutItem col={1} row={row} key={`l_${row}`}>
            <span style={labelStyle}>Strike</span>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={row++} key={`v_${row}`}>
            {formatter.formatNumber(quote.strike, "c2")}
          </GridLayoutItem>
        </>
      )}
      <GridLayoutItem col={1} row={row} key={`l_${row}`}>
        <span style={labelStyle}>Start Date</span>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={row++} key={`v_${row}`}>
        {quote.startDate ? DateUtility.formatShortDate(quote.startDate) : ""}
      </GridLayoutItem>

      <GridLayoutItem col={1} row={row} key={`l_${row}`}>
        <span style={labelStyle}>End Date</span>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={row++} key={`v_${row}`}>
        {quote.endDate ? DateUtility.formatShortDate(quote.endDate) : ""}
      </GridLayoutItem>

      <GridLayoutItem col={1} row={row} key={`l_${row}`}>
        <span style={labelStyle}>Maximum Volume</span>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={row++} key={`v_${row}`}>
        {formatter.formatNumber(quote.maxVolume, "n0")} {units}
      </GridLayoutItem>

      <GridLayoutItem col={1} row={row} key={`l_${row}`}>
        <span style={labelStyle}>Number of Fixings</span>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={row++} key={`v_${row}`}>
        {formatter.formatNumber(quote.numFixings, "n0")}
      </GridLayoutItem>

      <GridLayoutItem col={1} row={row} key={`l_${row}`}>
        <span style={labelStyle}>Volume per Fixing</span>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={row++} key={`v_${row}`}>
        {formatter.formatNumber(quote.volumePerFixing, "n2")} {units}
      </GridLayoutItem>

      <GridLayoutItem col={1} row={row} key={`l_${row}`}>
        <span style={labelStyle}>Delta</span>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={row++} key={`v_${row}`}>
        {formatter.formatNumber(quote.delta, "n2")} {units}
      </GridLayoutItem>

      <GridLayoutItem col={1} row={row} key={`l_${row}`}>
        <span style={labelStyle}>Underlying Price</span>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={row++} key={`v_${row}`}>
        {formatter.formatNumber(quote.underlyingPrice, "c3")}
      </GridLayoutItem>

      <GridLayoutItem col={1} row={row} key={`l_${row}`}>
        <span style={labelStyle}>Unit Price</span>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={row++} key={`v_${row}`}>
        {formatter.formatNumber(quote.unitPrice, "c3")} / {units.slice(0, -1)}
      </GridLayoutItem>
    </GridLayout>
  );
};
