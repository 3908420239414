import { Button } from "@progress/kendo-react-buttons";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useEffect, useState } from "react";
import { IconToggleButton } from "../utilities";
import productsApi from "../../api/products";
import { useApi } from "../../hooks/useApi";
import CanopyDateRangePicker from "../functional/CanopyDateRangePicker";
import { QuoteStatus } from "../../types/quoteStatus";

export const FilterComponent = ({
  maxDate,
  selectedDates,
  selectedCommodityTypes,
  selectedProducts,
  selectedStatuses,
  searchText,
  onCommodityClick,
  onProductClick,
  onClearFilters,
  onDateChange,
  onStatusCheck,
  onTextChange,
}: any) => {
  const getCommodityTypesApi = useApi(productsApi.getCommodityTypes);
  const getProductTypesApi = useApi(productsApi.getProductTypes);

  const { data: cData, error: cError, loading: cLoading } = getCommodityTypesApi;
  const { data: pData, error: pError, loading: pLoading } = getProductTypesApi;

  const [commodityTypes, setCommodityTypes] = useState<any>({});
  const [productTypes, setProductTypes] = useState<any>({});
  const [hoveredCommodity, setHoveredCommodity] = useState("");
  const [hoveredProduct, setHoveredProduct] = useState("");

  useEffect(() => {
    getCommodityTypesApi.request();
    getProductTypesApi.request();
  }, []);

  useEffect(() => {
    setCommodityTypes(getCommodityTypesApi.data?.commodityTypes || {});
  }, [getCommodityTypesApi.data]);

  useEffect(() => {
    setProductTypes(getProductTypesApi.data?.productTypes || {});
  }, [getProductTypesApi.data]);

  return (
    <div>
      <div style={{ margin: ".25rem" }}>
        <CanopyDateRangePicker
          max={maxDate}
          value={selectedDates}
          startDateInputSettings={{ label: "Date Range" }}
          endDateInputSettings={{ label: "" }}
          onChange={onDateChange}
        />
      </div>

      {cError && <Error>{cError}</Error>}
      {!cLoading && (
        <div style={{ margin: ".25rem", paddingTop: ".75rem" }}>
          <Tooltip anchorElement="target" parentTitle={true}>
            {Object.keys(commodityTypes).map((type: string, index) => {
              const title = commodityTypes[type];

              return (
                <IconToggleButton
                  key={index}
                  item={type}
                  type={"commodity"}
                  title={title}
                  hoveredItem={hoveredCommodity}
                  selectedItemTypes={selectedCommodityTypes}
                  onClick={onCommodityClick}
                  setHoveredItem={setHoveredCommodity}
                />
              );
            })}
          </Tooltip>
        </div>
      )}

      {pError && <Error>{pError}</Error>}
      {!pLoading && (
        <div style={{ margin: ".25rem", paddingTop: ".75rem" }}>
          <Tooltip anchorElement="target" parentTitle={true}>
            {Object.keys(productTypes).map((key: any, index) => {
              const title = productTypes[key];

              return (
                <IconToggleButton
                  key={index}
                  item={key}
                  type={"instrument"}
                  title={title}
                  hoveredItem={hoveredProduct}
                  selectedItemTypes={selectedProducts}
                  onClick={onProductClick}
                  setHoveredItem={setHoveredProduct}
                />
              );
            })}
          </Tooltip>
        </div>
      )}

      <div style={{ display: "flex", marginLeft: ".25rem", paddingTop: "1rem" }}>
        {Object.values(QuoteStatus).map(
          (item: string, index: number) => (
            <span style={{ paddingRight: "1rem" }} key={index}>
              <Checkbox
                id={`status_${index}`}
                name={"status"}
                label={item}
                value={item}
                checked={selectedStatuses.includes(item)}
                onChange={(e) => onStatusCheck(e, item)}
              />
            </span>
          ))}
      </div>


      <div style={{ display: "flex", marginLeft: ".25rem", paddingTop: "1rem",  alignItems: "center", columnGap: 5,}}>
            <Label>Search</Label>
            <Input
              value={searchText}
              onChange={(e: any) => onTextChange(e.target.value)}
            />
          </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "1rem",
        }}
      >
        <div>
          <Button
            iconClass="k-icon k-i-filter-clear"
            themeColor="primary"
            fillMode="outline"
            title="Clear Filters"
            size="small"
            onClick={onClearFilters}
          >
            Clear
          </Button>
        </div>
      </div>
    </div>
  );
};
