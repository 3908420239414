import { useRef } from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { Checkbox } from "@progress/kendo-react-inputs";
import { ListItem } from "./ListItem";

export const FormCheckboxList = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    data,   // type ListItem[]
    value,  // type (number | string)[]
    name,
    ...others
  } = fieldRenderProps;

  const editorRef = useRef(null);
  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";
  
  const handleCheck = (event: any, item: number | string) => {
    const value = fieldRenderProps.value;

    let updatedList: (number | string)[] = [...value];
    if (event.value) {
      updatedList = [...value, item];
    } else {
      updatedList.splice(value.indexOf(item), 1);
    }

    event.value = updatedList;
    fieldRenderProps.onChange(event);
  };

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>

      <div className="list-container">
      {data.map((item: ListItem, index: number) => (
        <Checkbox
          key={index}
          id={`${id}_${index}`}
          name={name}
          label={item.label}
          value={item.value}
          checked={value?.includes(item.value)}
          disabled={disabled}
          onChange={(e) => handleCheck(e, item.value)}
        />
      ))}
      </div>

      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
