export class UserPermissions {
  canAdminCompanies: boolean = false;
  canAdminUsers: boolean = false;
  canApproveTrades: boolean = false;
  canConfigureMargins: boolean = false;
  canConfigureProducts: boolean = false;
  canSubmitQuotes: boolean = false;

  constructor(permissions: number[]) {
    this.canAdminCompanies = UserUtility.canAdminCompanies(permissions);
    this.canAdminUsers = UserUtility.canAdminUsers(permissions);
    this.canApproveTrades = UserUtility.canApproveTrades(permissions);
    this.canConfigureMargins = UserUtility.canConfigureMargins(permissions);
    this.canConfigureProducts = UserUtility.canConfigureProducts(permissions);
    this.canSubmitQuotes = UserUtility.canSubmitQuotes(permissions);
  }
}

export class UserRoles {
  isRiskTaker: boolean = false;
  isBroker: boolean = false;
  isClient: boolean = false;
  isFronter: boolean = false;

  constructor(roles: string[]) {
    this.isRiskTaker = UserUtility.hasRiskTakerRole(roles);
    this.isBroker = UserUtility.hasBrokerRole(roles);
    this.isClient = UserUtility.hasClientRole(roles);
    this.isFronter = UserUtility.hasFronterRole(roles);
  }

  public canSubmitQuotes = (companyRole: string): boolean =>
    this.isClient ||
    (this.isBroker && UserUtility.hasClientRole([companyRole])) ||
    (this.isRiskTaker && UserUtility.hasClientRole([companyRole]));
}

const permissions = {
  canSubmit: 2,
  canApprove: 6,
  corporateAdmin: 3,
  productAdmin: 7,
  marginAdmin: 5,
  userAdmin: 4,
};

const roles = {
  riskTaker: "risktaker",
  broker: "broker",
  client: "client",
  fronter: "fronting",
};

export class UserUtility {
  static hasClientRole = (userRoles: string[] | undefined): boolean =>
    this.hasRole(userRoles, roles.client);

  static hasBrokerRole = (userRoles: string[] | undefined): boolean =>
    this.hasRole(userRoles, roles.broker);

  static hasRiskTakerRole = (userRoles: string[] | undefined): boolean =>
    this.hasRole(userRoles, roles.riskTaker);

  static hasFronterRole = (userRoles: string[] | undefined): boolean =>
    this.hasRole(userRoles, roles.fronter);

  static canConfigureProducts = (
    userPermissions: number[] | undefined
  ): boolean => this.hasPermission(userPermissions, permissions.productAdmin);

  static canConfigureMargins = (
    userPermissions: number[] | undefined
  ): boolean => this.hasPermission(userPermissions, permissions.marginAdmin);

  static canSubmitQuotes = (userPermissions: number[] | undefined): boolean =>
    this.hasPermission(userPermissions, permissions.canSubmit);

  static canSubmitQuotesByRole = (
    userRoles: UserRoles,
    companyRole: string
  ): boolean =>
    userRoles.isClient ||
    (userRoles.isBroker && this.hasClientRole([companyRole])) ||
    (userRoles.isRiskTaker && this.hasClientRole([companyRole]));

  static canApproveTrades = (userPermissions: number[] | undefined): boolean =>
    this.hasPermission(userPermissions, permissions.canApprove);

  static canAdminCompanies = (userPermissions: number[] | undefined): boolean => 
    this.hasPermission(userPermissions, permissions.corporateAdmin);

  static canAdminUsers = (userPermissions: number[] | undefined): boolean => 
    this.hasPermission(userPermissions, permissions.userAdmin);

  private static hasPermission = (
    userPermissions: number[] | undefined,
    permission: number
  ): boolean =>
    userPermissions?.some(
      (p: number) => p === permission
    ) || false;

  private static hasRole = (
    userRoles: string[] | undefined,
    role: string
  ): boolean =>
    userRoles?.some((r: string) => r.toLowerCase() === role.toLowerCase()) ||
    false;
}
