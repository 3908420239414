import {
  Chart,
  ChartCategoryAxisItem,
  ChartCategoryAxis,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  TooltipContext,
} from "@progress/kendo-react-charts";
import { useInternationalization } from "@progress/kendo-react-intl";
import {
  ComponentType,
  Structure,
  TransactionDirection,
} from "../../utilities/vanilla/vanillaEnums";
import MathUtility from "../../utilities/mathUtilities";
import "hammerjs";

interface IStrike {
  type: ComponentType;
  direction: TransactionDirection;
  value: number;
}

interface IPayoutChartProps {
  decimals?: number;
  structureId?: number;
  strikes: IStrike[];
  underlying: number;
}

const range = (end: number, start: number, step: number) =>
  Array.from({ length: Math.ceil((end - start) / step) }, (_, i) => i * step + start);

const digits = (num: number) => {
  let count = 0;
  while (num > 0) {
    count++;
    num = Math.floor(num / 10);
  }
  return count;
};

export const PayoutChart = ({
  decimals = 2,
  structureId,
  strikes,
  underlying,
}: IPayoutChartProps) => {
  const formatter = useInternationalization();

  const strikeValues = strikes.sort((a, b) => a.value - b.value);
  if (strikeValues.length === 0) return <></>;

  const numberFormat = `n${decimals}`;
  const currencyFormat = `c${decimals}`;

  const padding = 0.2 * strikeValues[0].value;
  const xMin = MathUtility.round(strikeValues[0].value - padding, decimals);
  const xMax = MathUtility.round(strikeValues[strikeValues.length - 1].value + padding, decimals);
  const step = Math.pow(10, digits(xMin) - 3);

  const xValues = range(xMax, xMin, step)
    .concat(strikeValues.map((s) => MathUtility.round(s.value, decimals)))
    .sort((a, b) => a - b);

  const getYValue = (x: number, strike: IStrike, factor: number) => {
    if (structureId === Structure.Swap) return factor * (x - strike.value);

    return strike.type === ComponentType.Call
      ? factor * Math.max(x - strike.value, 0)
      : factor * Math.max(strike.value - x, 0);
  };

  let yValues: number[] = [];

  strikes.forEach((strike) => {
    const factor = strike.direction === TransactionDirection.Short ? -1 : 1;

    xValues.reduce((acc: number[], cur, index) => {
      const y = getYValue(cur, strike, factor);
      if (index < acc.length) acc[index] += y;
      else acc.push(getYValue(cur, strike, factor));
      return acc;
    }, yValues);
  });

  const points = xValues.map((x, index) => {
    return { x, y: yValues[index] };
  });

  const underlyingStep = Math.floor((underlying - xMin) / step);

  const verticalPlotBands = [
    {
      from: underlyingStep,
      to: underlyingStep,
      color: "lightblue",
      opacity: 0.7,
      label: {
        text: "ATM",
        font: "small-caps 12px/1 sans-serif",
        color: "darkgray",
        padding: { right: 10 },
        rotation: 270,
      },
    },
  ];

  const renderTooltip = (props: TooltipContext) => {
    const { value, category } = props.point;
    const format = `c${decimals}`;
    return (
      <>
        <div>Underlying {formatter.formatNumber(category as number, format)} </div>
        <div>Payout {formatter.formatNumber(value, format)}</div>
      </>
    );
  };

  return (
    <Chart transitions={false}>
      <ChartTooltip />
      <ChartSeries>
        <ChartSeriesItem
          type="line"
          categoryField="x"
          field="y"
          data={points}
          markers={{ visible: false }}
          color={"darkcyan"}
        >
          <ChartSeriesItemTooltip render={renderTooltip} />
        </ChartSeriesItem>
      </ChartSeries>

      <ChartValueAxis>
        <ChartValueAxisItem title={{ text: "Payout ($)" }} labels={{ format: numberFormat }} />
      </ChartValueAxis>

      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          title={{ text: "Underlying ($)" }}
          labels={{ format: numberFormat, step: Math.ceil(points.length / 10) }}
          plotBands={verticalPlotBands}
        />
      </ChartCategoryAxis>

      <ChartLegend visible={false} />
    </Chart>
  );
};
