import config from "./config.json";

interface IAzureConfig {
  authorityDomain: string;
  domain: string;
  userFlow: string;
  clientId: string;
  apiClientId: string;
  scope: string;
}

interface IConfigSettings {
  apiUrl: string;
  helpUrl: string;
  azure: IAzureConfig;
}

const settings: Record<string, IConfigSettings> = {
  dev: {
    apiUrl: "https://localhost:5001/api/",
    helpUrl: "https://riskcanopy.zendesk.com/hc/en-us/articles",
    azure: {
      authorityDomain: "TestRiskCanopyUsers.b2clogin.com",
      domain: "TestRiskCanopyUsers.onmicrosoft.com",
      userFlow: "B2C_1_SignInUserFlow_DEV",
      clientId: "5c8b1c7c-3e4e-4e24-9df7-d221b7e1b240",
      apiClientId: "81016fb3-1a69-4d17-9079-f29858da02c3",
      scope: "canopy-api-scope",
    },
  },
  test: {
    apiUrl: "https://test-riskcanopyapi-appservice.azurewebsites.net/api/",
    helpUrl: "https://riskcanopy.zendesk.com/hc/en-us/articles",
    azure: {
      authorityDomain: "TestRiskCanopyUsers.b2clogin.com",
      domain: "TestRiskCanopyUsers.onmicrosoft.com",
      userFlow: "B2C_1_SignInUserFlow_MFA",
      clientId: "d62aa422-d5d9-4a1e-adfe-ccf4770471fa",
      apiClientId: "63588fdc-5402-4325-a174-fe69bd86ae7a",
      scope: "canopy-api-scope",
    },
  },
  production: {
    apiUrl: "https://riskcanopyapi-appservice.azurewebsites.net/api/",
    helpUrl: "https://riskcanopy.zendesk.com/hc/en-us/articles",
    azure: {
      authorityDomain: "RiskCanopyUsers.b2clogin.com",
      domain: "RiskCanopyUsers.onmicrosoft.com",
      userFlow: "B2C_1_SignInUserFlow",
      clientId: "ee813fa7-514f-4dd9-b26f-5a7e1f09cdbf",
      apiClientId: "10c53d10-366a-48ca-8d59-212d9e2a2aa1",
      scope: "canopy-api-scope",
    },
  },
};

const getCurrentSettings = () => {
  return settings[config.env];
};

export default getCurrentSettings();
