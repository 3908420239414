import { Label } from "@progress/kendo-react-labels";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

export interface ISummaryDisplayItems {
  items: any[];
  label: string;
  field1: string;
  field2?: string;
}

interface IConfigurationActionSummaryProps {
  action: string;
  summary: ISummaryDisplayItems[];
}

export const ConfigurationActionSummary = ({
  action,
  summary,
}: IConfigurationActionSummaryProps) => {
  return (
    <>
      <div style={{ margin: "1rem 0", textAlign: "center" }}>
        Please confirm you would like to {action} the following:
      </div>

      <GridLayout
        align={{ vertical: "top" }}
        gap={{ rows: 5, cols: 10 }}
        cols={[{ width: "5%" }, { width: "30%" }, { width: "30%" }, { width: "30%" }]}
      >
        {summary.map((s, i) => (
          <>
            <GridLayoutItem col={i + 2} row={1} key={`${i + 1}_1`}>
              <Label style={{ fontWeight: "bolder" }}>{s.label}</Label>
            </GridLayoutItem>

            {s.items.map((c, j) => (
              <GridLayoutItem col={i + 2} row={j + 2} key={`${i + 1}_${j + 2}`}>
                <div>
                  <div>{c[s.field1]}</div>
                  <div>{s.field2 ? c[s.field2] : " "}</div>
                </div>
              </GridLayoutItem>
            ))}
          </>
        ))}
      </GridLayout>
    </>
  );
};
