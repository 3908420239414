import { Tooltip } from "@progress/kendo-react-tooltip";
import DetailItem from "../../utilities/DetailItem";

export interface IDetailItem {
  label: string;
  content: string;
  hint?: string;
}

type Props = {
  details?: IDetailItem[];
};

export const QuoteDetailsSubHeader = ({ details }: Props) => {
  return (
    <Tooltip anchorElement="target" position="auto" showCallout={false}>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {details?.map((d, index) => {
          return (
            <div key={index}>
              <DetailItem {...d} />
            </div>
          );
        })}
      </div>
    </Tooltip>
  );
};
