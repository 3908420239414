import { DataResult, process, State } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnMenuProps,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { useInternationalization } from "@progress/kendo-react-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import productsApi from "../../api/products";
import quotesApi from "../../api/quotes";
import insuranceApi from "../../api/insurance";
import { getQuote } from "../../api/vanilla";
import { useApi } from "../../hooks/useApi";
import { useUserContext } from "../../hooks/useUserContext";
import { getIconPath } from "../../utilities/textUtilities";
import { UserPermissions, UserRoles } from "../../utilities/userUtilities";
import {
  ColumnMenuCheckboxFilter,
  CustomGridCell,
  DateGridCell,
  EditLinkCell,
  NumberGridCell,
} from "../grid";
import { CopyPopup, DeletePopup, ICommodity, IInstrument, Instrument } from "../quotes";
import { QuoteDetailsSummary } from "../quotes/quoteDetails";
import { QuoteStatus } from "../../types/quoteStatus";

interface QuoteActionsProps {
  cellProps: GridCellProps;
  action: string;
  permissions: UserPermissions;
  roles: UserRoles;
  onActionClick: (action: string) => void;
  onCopy: (quote: any, company: any | null) => void;
  onEdit: (quote: any) => void;
  onDelete: (quoteId: number) => void;
  onQuote: (quoteId: number, productType: string) => void;
}

const QuoteActionsCell = (props: QuoteActionsProps) => {
  const {
    cellProps,
    action,
    permissions,
    roles,
    onActionClick,
    onCopy,
    onEdit,
    onDelete,
    onQuote,
  } = props;
  const { dataItem } = cellProps;
  const isClient = cellProps.field === "true";
  const disabled = action.length > 0;
  const navigate = useNavigate();

  const isActionable = dataItem.configurationEnabled;
  const showReviewButton =
    dataItem.status === "Pending" &&
    !dataItem.isExpired &&
    permissions.canApproveTrades &&
    roles.isRiskTaker;
  const showDeleteButton = dataItem.status === "Open";

  return (
    <td>
      <div style={{ display: "flex" }}>
        {isClient && isActionable && (
          <Button
            disabled={disabled}
            icon="copy"
            themeColor="primary"
            fillMode="outline"
            title="Copy Quote"
            size="small"
            onClick={() => onCopy(dataItem, null)}
          />
        )}

        {!isClient && isActionable && (
          <CopyPopup
            action={action}
            position={"left"}
            data={{
              companyId: dataItem.companyId,
              commodityId: dataItem.commodityId,
              instrumentId: dataItem.instrumentId,
              productType: dataItem.productType,
              quoteId: dataItem.quoteId,
            }}
            onOpen={onActionClick}
            onClose={() => onActionClick("")}
            onConfirm={(company: any | null) => onCopy(dataItem, company)}
          />
        )}

        {dataItem.status === "Open" && isActionable && (
          <>
            <Button
              disabled={disabled}
              icon="edit"
              size="small"
              themeColor="primary"
              fillMode="outline"
              title="Edit Quote"
              onClick={() => {
                onActionClick("edit");
                onEdit(dataItem);
              }}
            />

            <Button
              disabled={disabled}
              icon="refresh"
              size="small"
              themeColor="primary"
              fillMode="outline"
              title="Quote"
              onClick={async () => {
                onActionClick("requote");
                await onQuote(dataItem.quoteId, dataItem.productType);
                onActionClick("");
              }}
            />
          </>
        )}

        {showReviewButton && (
          <Button
            disabled={disabled}
            icon="check"
            size="small"
            themeColor="primary"
            fillMode="outline"
            title="Review Quote"
            onClick={() => navigate(`/quote/details/${dataItem.quoteId}`)}
          />
        )}

        {showDeleteButton && (
          <DeletePopup
            action={action}
            position={"left"}
            data={{ quoteId: dataItem.quoteId }}
            onOpen={onActionClick}
            onClose={() => onActionClick("")}
            onConfirm={() => onDelete(dataItem.quoteId)}
          />
        )}
      </div>
    </td>
  );
};

interface QuoteIdProps {
  cellProps: GridCellProps;
  onClick: (showDialog: number) => void;
}

const QuoteIdCell = (props: QuoteIdProps) => {
  const { cellProps, onClick } = props;
  const { field, dataItem } = cellProps;
  const value = parseInt(dataItem[field || ""]) || 0;

  return (
    <EditLinkCell {...cellProps} title="View Quote Details" enterEdit={() => onClick(value)} />
  );
};

const StatusCell = (props: GridCellProps) => {
  const { field, dataItem } = props;
  const status: string = dataItem[field || ""];
  const isActionable = dataItem.configurationEnabled;
  const title = status === "Open" && !isActionable ? "Deactivated" : status;

  return (
    <CustomGridCell {...props}>
      <span title={title}>
        {title === QuoteStatus.Approved && <Icon name="check" themeColor="success" />}
        {title === QuoteStatus.Rejected && <Icon name="close" themeColor="error" />}
        {title === QuoteStatus.Pending && <Icon name="clock" themeColor="warning" />}
        {title === "Deactivated" && <Icon name="cancel" themeColor="error" />}
      </span>
    </CustomGridCell>
  );
};

const CommodityCell = (props: GridCellProps) => {
  const { field, dataItem } = props;
  const value: string = dataItem[field || ""];
  const type: string = dataItem["commodityType"];
  const fullName = dataItem["commodityName"] || "";

  return (
    <CustomGridCell {...props}>
      <img
        alt={fullName}
        src={getIconPath("commodity", type)}
        title={fullName}
        style={{ width: 16, height: 16, marginRight: ".5rem" }}
      />
      <span title={fullName}>{value}</span>
    </CustomGridCell>
  );
};

const ProductCell = (props: GridCellProps) => {
  const { field, dataItem } = props;
  const value: string = dataItem[field || ""];
  const type = dataItem["productType"] || "";

  return (
    <CustomGridCell {...props}>
      <img
        alt={type}
        src={getIconPath("instrument", type)}
        title={type}
        style={{ width: 16, height: 16, marginRight: ".5rem" }}
      />
      <span>{value}</span>
    </CustomGridCell>
  );
};

interface PriceCellProps {
  cellProps: GridCellProps;
  commodities: ICommodity[];
}

const PriceCell = ({ cellProps, commodities }: PriceCellProps) => {
  const { field, dataItem } = cellProps;
  const price: number = dataItem[field || ""];
  const volume: number = dataItem.volume || 1;
  const commodity = commodities?.find((i: ICommodity) => i.id === dataItem.commodityId);
  const decimals = commodity?.decimals ?? 3;
  const formatter = useInternationalization();

  return (
    <CustomGridCell {...cellProps}>
      <span>{formatter.formatNumber(price /volume, `n${decimals}`)}</span>
    </CustomGridCell>
  );
};

interface QuotesGridProps {
  quotes: any[];
  commodities: ICommodity[];
  levels: any;
  onDataChange: (result: DataResult | undefined) => void;
  onRefresh: () => void;
}

export const QuotesGrid = (props: QuotesGridProps) => {
  const { quotes, commodities, levels, onDataChange, onRefresh } = props;
  const { context: user } = useUserContext();
  const { permissions, roles } = user;
  const navigate = useNavigate();
  const copyQuoteApi = useApi(quotesApi.copyQuote);
  const deleteQuoteApi = useApi(quotesApi.deleteQuote);
  const insuranceQuoteApi = useApi(insuranceApi.getQuote);
  const vanillaQuoteApi = useApi(getQuote);
  const getInstrumentsApi = useApi(productsApi.getInstruments);

  const [filters, setFilters] = useState<any[]>([]);
  const [result, setResult] = useState<DataResult>();
  const [dataState, setDataState] = useState<State>({
    sort: [],
    skip: 0,
    take: 10,
  });
  const [item, setItem] = useState<any | null>(null);
  const [copyCompany, setCopyCompany] = useState<any | null>(null);
  const [action, setAction] = useState("");
  const [showDetailsId, setShowDetailsId] = useState(0);

  useEffect(() => {
    applyDataStateChange(dataState);
  }, [quotes]);

  useEffect(() => {
    if (onDataChange) onDataChange(result);
  }, [result]);

  const createDataState = (dataState: State) => {
    return {
      result: process(quotes.slice(0), dataState),
      dataState: dataState,
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    applyDataStateChange(event.dataState);
  };

  const applyDataStateChange = (dataState: State) => {
    const updatedState = createDataState(dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);

    //if (onDataChange) onDataChange(dataState);
  };

  const clearFilter = (field: string, value: string) => {
    const { filter } = dataState;
    if (!filter) return;
  };

  const FilterColumnMenu = (props: GridColumnMenuProps) => {
    const colLookup: Record<string, string> = {
      companyName: "Client",
      instrumentName: "Product",
    };

    const onFiltersChanged = (gridFilters: any[] | undefined) => {
      const field: string = props.column.field || "";
      const filterList = filters.filter((f: any) => f.field !== field);

      (gridFilters || []).forEach((f: any) => {
        f.filters
          .filter((x: any) => x.field === field)
          .forEach((x: any) => {
            const display =
              typeof x.value === "boolean"
                ? x.value
                  ? colLookup[field]
                  : "Not " + colLookup[field]
                : x.value;
            filterList.push({
              field: x.field,
              value: x.value,
              display: display,
            });
          });
      });

      filterList.sort((a, b) => {
        if (a.field < b.field) return -1;
        if (a.field > b.field) return 1;
        if (a.value < b.value) return -1;
        if (a.value > b.value) return 1;
        return 0;
      });

      setFilters(filterList);
    };

    return <ColumnMenuCheckboxFilter {...props} data={quotes} onChange={onFiltersChanged} />;
  };

  const copyQuote = async (dataItem: any, company: any | null) => {
    const copyCompany = company || {
      id: dataItem.companyId,
      name: dataItem.companyName,
      role: dataItem.companyRole,
      canSell: dataItem.companyCanSell,
    };

    setItem(dataItem);
    setCopyCompany(copyCompany);
    await Promise.all([
      getInstrumentsApi.request(dataItem.commodityId),
      copyQuoteApi.request(dataItem.quoteId, copyCompany.id),
    ]);
  };

  useEffect(() => {
    if (!copyQuoteApi.loading && copyQuoteApi.data && item && getInstrumentsApi.data) {
      setAction("");
      if (!copyQuoteApi.data.quoteId) return; //TODO: Handle error

      const inst = getInstrumentsApi.data.instruments.find(
        (i: IInstrument) => i.id == item.instrumentId,
      );
      const quoteType = Instrument.create(inst).quoteType();

      navigate(`/quote/${quoteType}/0`, {
        state: {
          data: {
            company: copyCompany,
            commodity: commodities?.find((i: ICommodity) => i.id == item.commodityId),
            instrument: inst,
            quoteId: copyQuoteApi.data.quoteId,
          },
        },
      });
    }
  }, [copyQuoteApi.data, copyQuoteApi.error, getInstrumentsApi.data]);

  const editQuote = async (dataItem: any) => {
    setItem(dataItem);
    await getInstrumentsApi.request(dataItem.commodityId);
  };

  useEffect(() => {
    if (getInstrumentsApi.data && action === "edit") {
      const inst = getInstrumentsApi.data.instruments.find(
        (i: IInstrument) => i.id === item.instrumentId,
      );
      const quoteType = Instrument.create(inst).quoteType();

      navigate(`/quote/${quoteType}`, {
        state: {
          data: {
            company: {
              id: item.companyId,
              name: item.companyName,
              role: item.companyRole,
              canSell: item.companyCanSell,
            },
            commodity: commodities?.find((i: ICommodity) => i.id == item.commodityId),
            instrument: inst,
            quoteId: item.quoteId,
          },
        },
      });
    }
  }, [getInstrumentsApi.data]);

  const deleteQuote = async (quoteId: number) => {
    await deleteQuoteApi.request(quoteId);
    //TODO: error handling

    setAction("");
    onRefresh();
  };

  const requote = async (quoteId: number, productType: string) => {
    if (productType === "Insurance") await insuranceQuoteApi.request(quoteId);
    else await vanillaQuoteApi.request(quoteId);
    //TODO: error handling

    setAction("");
    onRefresh();
  };

  const PriceCellWrapper = (props: GridCellProps) => (  
    <PriceCell cellProps={props} commodities={commodities} />
  );

  const QuoteIdCellWrapper = (props: GridCellProps) => (
    <QuoteIdCell cellProps={props} onClick={setShowDetailsId} />
  );

  const QuoteActionsCellWrapper = (props: GridCellProps) => (
    <QuoteActionsCell
      cellProps={props}
      action={action}
      permissions={permissions}
      roles={roles}
      onActionClick={(newAction: string) => setAction(newAction)}
      onCopy={copyQuote}
      onEdit={editQuote}
      onDelete={deleteQuote}
      onQuote={requote}
    />
  );

  const closeDetailsDialog = () => {
    setShowDetailsId(0);
    onRefresh();
  };

  return (
    <>
      <div>
        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <Grid
            data={result}
            {...dataState}
            pageable={true}
            sortable={true}
            resizable={true}
            onDataStateChange={dataStateChange}
          >
            <GridColumn
              field="quoteId"
              title="Id"
              cell={QuoteIdCellWrapper}
              columnMenu={FilterColumnMenu}
              width={100}
            />
            
            <GridColumn field="companyName" title="Client" columnMenu={FilterColumnMenu} />

            <GridColumn field="tickerCode" title="Commodity" cell={CommodityCell} width={120} />

            <GridColumn
              field="structure"
              title="Product"
              cell={ProductCell}
              columnMenu={FilterColumnMenu}
            />
            <GridColumn field="quoteDate" title="Quoted" cell={DateGridCell} />

            <GridColumn
              field="volume"
              title="Volume"
              cell={NumberGridCell}
              format="n0"
              width={100}
            />

            <GridColumn field="price" title="Price ($/unit)" cell={PriceCellWrapper} />

            <GridColumn field="status" title="Status" cell={StatusCell} width={100} />

            <GridColumn
              field={roles.isClient.toString()}
              title=" "
              cell={QuoteActionsCellWrapper}
              width={150}
            />
          </Grid>
        </Tooltip>
      </div>

      {showDetailsId > 0 && (
        <Dialog width={"80%"} height="80%" closeIcon={true}>
          <div className="float-right">
            <span
              className="k-icon k-i-close"
              style={{ cursor: "pointer" }}
              title="Close"
              onClick={closeDetailsDialog}
            ></span>
          </div>
          <QuoteDetailsSummary quoteId={showDetailsId} onReject={closeDetailsDialog} />
        </Dialog>
      )}
    </>
  );
};
