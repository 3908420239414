import { useState, useEffect, useRef } from "react";

const useTimer = (initialValue: number = 0, interval: number = 1000) => {
  const [count, setCount] = useState(initialValue);
  const timerRef = useRef<number | undefined>();

  useEffect(() => {
    timerRef.current = window.setInterval(() => {
      setCount((prevTime) => prevTime + 1);
    }, interval);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [interval]);

  const restartTimer = () => {
    setCount(initialValue);
  };

  return { count, restartTimer };
};

export default useTimer;
