import { Field } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useState } from "react";
import { requiredValidator } from "../../../validators";
import { FormDropDownList } from "../../form";
import { Configuration } from "./Configuration";
import { CreateNewMarginDialog } from ".";

interface IProductMarginsSelector {
  configurations: Configuration[];
  margins: any[];
}

export const ProductMarginsSelector = ({ configurations, margins }: IProductMarginsSelector) => {
  const getMarginsList = (commodityId: number, businessTypeId: number) => {
    return (margins ?? [])
      .filter((m: any) => m.businessTypeId === businessTypeId)
      .filter((m: any) => !m.commodityId || m.commodityId === commodityId)
      .map((m: any) => ({ value: m.marginId, label: m.longName }))
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  };

  const [activeConfig, setActiveConfig] = useState<Configuration | null>(null);

  const refreshMargins = () => {
    //TODO: Need to add the new margin to the list
  };

  return (
    <>
      <GridLayout
        align={{ vertical: "bottom" }}
        gap={{ rows: 0, cols: 5 }}
        cols={[{ width: "10%" }, { width: "40%" }, { width: "30%" }, { width: "10%" }]}
      >
        {configurations.map((config, index) => (
          <>
            <GridLayoutItem col={2} row={index + 1} key={`config_${index}`}>
              <div>{config.commodity}</div>
              <div>{config.instrument}</div>
            </GridLayoutItem>

            <GridLayoutItem col={3} row={index + 1} key={`margin_${index}`}>
              <Field
                key={`marginSelection_${config.productId}`}
                name={`marginSelection_${config.productId}`}
                component={FormDropDownList}
                validator={requiredValidator}
                data={getMarginsList(config.commodityId, config.businessTypeId)}
                textField="label"
                dataItemKey="value"
              />
            </GridLayoutItem>

            <GridLayoutItem col={4} row={index + 1} key={`action_${index}`}>
              <a href="#" onClick={() => setActiveConfig(config)}>
                Create New
              </a>
            </GridLayoutItem>
          </>
        ))}
      </GridLayout>

      {activeConfig && (
        <CreateNewMarginDialog
          configuration={activeConfig}
          onClose={() => setActiveConfig(null)}
          onSave={refreshMargins}
        />
      )}
    </>
  );
};
