import styled, { createGlobalStyle } from "styled-components";


const GlobalStyles = createGlobalStyle`
  body {
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
   font-weight: 300;
  }
`;

export const Track = styled("div")`
  display: inline-block;
  height: 8px;
  width: 100%;
`;

export const Tick = styled("div")`
  :before {
    content: "";
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    height: 5px;
    width: 2px;
    transform: translate(0.25rem, -0.4rem);
  }
`;

export const TickLabel = styled("div")`
  position: absolute;
  font-size: 0.6rem;
  color: rgba(0, 0, 0, 0.5);
  top: 100%;
  transform: translate(-40%, 1rem);
  white-space: nowrap;
`;

export const Segment = styled("div")`
  background: black;
  height: 100%;
`;

interface ActiveProps {
  active: boolean;
  disabled: boolean;
}

export const Handle = styled.div<ActiveProps>`
  background: ${(props) => props.disabled ? "#999999" : "#4cbb17" };
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  font-size: 0.7rem;
  white-space: nowrap;
  color: white;
  font-weight: ${(props) => props.active && !props.disabled ? "bold" : "normal"};
  transform: ${(props) => props.disabled ? "translateY(0) scale(0.6)" : props.active ? "translateY(-100%) scale(1.3)" : "translateY(0) scale(0.9)"};
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;