import { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Field, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { requiredValidator } from "../../validators";
import { FormDateTimePicker, FormDropDownList, FormInput } from "../form";
import { LoadingIndicator } from "../LoadingIndicator";
import { useApi } from "../../hooks/useApi";
import notificationsApi from "../../api/notifications";
import { Severity } from "../layout/Notifications";
import { capitalizeFirstLetter } from "../../utilities/textUtilities";
import { Checkbox } from "@progress/kendo-react-inputs";

interface AddEditAlertFormProps {
  cancelEdit: () => void;
  onSubmit: () => void;
  item: any;
}

export const AddEditAlertDialog = (props: AddEditAlertFormProps) => {
  const { item } = props;
  const saveNotificationApi = useApi(notificationsApi.save);
  const [isLoading, setIsLoading] = useState(false);
  const [isIndefinite, setIsIndefinite] = useState(item.endDate ? false : true);

  const severityList = Object.values(Severity).map((s) => {
    return { value: s, label: capitalizeFirstLetter(s) };
  });

  item.severitySelection = severityList.find((s) => s.value === item.severity);
  item.start = item.startDate ? new Date(item.startDate) : new Date();
  item.end = item.endDate ? new Date(item.endDate) : new Date();

  useEffect(() => {
    setIsLoading(saveNotificationApi.loading);
    if (!saveNotificationApi.loading && (saveNotificationApi.data || saveNotificationApi.error))
      if (!saveNotificationApi.error) props.onSubmit();
  }, [saveNotificationApi.loading, saveNotificationApi.data, saveNotificationApi.error]);

  const handleClickIndefinite = (e: any, formRenderProps: FormRenderProps) => {
    const { value } = e;
    setIsIndefinite(value);
    formRenderProps.onChange("end", { value: new Date() });
  }

  const handleSubmitEdit = async (event: any) => {
    const editItem = { ...event };
    editItem.severity = event.severitySelection.value;
    editItem.startDate = event.start;
    editItem.endDate = isIndefinite ? null : event.end;
    delete editItem.severitySelection;
    delete editItem.start;
    delete editItem.end;

    await saveNotificationApi.request(editItem);
  };

  return (
    <Dialog
      title={item.notificationId > 0 ? "Edit Alert" : "Add New Alert"}
      onClose={props.cancelEdit}
    >
      <div style={{ minWidth: 400 }}>
        <Form
          onSubmit={handleSubmitEdit}
          initialValues={item}
          render={(formRenderProps) => (
            <FormElement>
              <Field
                label="Severity"
                name="severitySelection"
                component={FormDropDownList}
                validator={requiredValidator}
                data={severityList}
                disabled={isLoading}
                textField="label"
                dataItemKey="value"
              />
              <Field
                label="Message"
                name="message"
                component={FormInput}
                validator={requiredValidator}
                disabled={isLoading}
              />
              <Field
                label="Start"
                name="start"
                component={FormDateTimePicker}
                validator={requiredValidator}
                disabled={isLoading}
                min={new Date()}
              />

              <div style={{ display: "flex " }}>
                <Checkbox
                  checked={isIndefinite}
                  disabled={isLoading}
                  style={{ marginRight: 5 }}
                  onChange={(e) => handleClickIndefinite(e, formRenderProps)}
                />
                <Label>Run Indefinitely</Label>
              </div>

              {!isIndefinite && (
                <Field
                  label="End"
                  name="end"
                  component={FormDateTimePicker}
                  disabled={isLoading}
                  min={new Date()}
                />
              )}

              <div>{saveNotificationApi.error && <Error>{saveNotificationApi.error}</Error>}</div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "2rem",
                }}
              >
                <LoadingIndicator loading={isLoading} />

                {!isLoading && (
                  <>
                    <Button themeColor="base" disabled={isLoading} onClick={props.cancelEdit}>
                      Cancel
                    </Button>

                    <Button
                      themeColor="success"
                      type="submit"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>
            </FormElement>
          )}
        ></Form>
      </div>
    </Dialog>
  );
};
