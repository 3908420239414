import { getIconPath } from "../../utilities/textUtilities";

export interface IInstrument {
  id: number;
  name: string;
  instrumentType: string;
  productType: string;
  productName: string;
  calendarMonthOffset: number;
  companyIds: number[];
  selectable(id: number): boolean;
}

export class Instrument implements IInstrument {
  id = 0;
  name = "";
  instrumentType = "";
  productType = "";
  productName = "";
  companyIds: number[] = [];
  calendarMonthOffset: number = 0;

  private constructor(instrument: IInstrument | null | undefined) {
    if (instrument) {
      this.id = instrument.id;
      this.name = instrument.name;
      this.instrumentType = instrument.instrumentType;
      this.productType = instrument.productType;
      this.productName = instrument.productName;
      this.companyIds = instrument.companyIds;
      this.calendarMonthOffset = instrument.calendarMonthOffset;
    }
  }

  static get EMPTY(): Instrument {
    return Instrument.create(null);
  }

  static create(obj: IInstrument | null | undefined): Instrument {
    return new Instrument(obj);
  }

  public icon = () => getIconPath("instrument", this.productType);

  public selectable = (id: number) => this.companyIds.includes(id);

  public quoteType = () => {
    let quoteType = "vanilla";

    if (this.productType === "Insurance") {
      quoteType = this.productType.toLowerCase();
    } else if (this.instrumentType === "Accumulator") {
      quoteType = this.instrumentType.toLowerCase();
    }

    return quoteType;
  };
}
