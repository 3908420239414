import { PropsWithChildren, createContext, useState } from "react";
import { pca } from "../..";
import { ClaimsUtility } from "../../utilities/claimsUtilities";

export interface IAuthenticatedUser {
  name: string;
  email: string;
  companyIds: number[];
  claimsPermissions: Record<number, number[]>;
}

interface IAuthenticatedUserContext {
  context: IAuthenticatedUser;
  setContext: (obj: any) => void;
}

export const AuthenticatedUserContext = createContext<IAuthenticatedUserContext>(
  {} as IAuthenticatedUserContext,
);

export const AuthenticatedUserContextProvider = ({ children }: PropsWithChildren) => {
  const [context, setContext] = useState<IAuthenticatedUser>({
    name: "",
    email: "",
    companyIds: [],
    claimsPermissions: {},
  });

  pca.addEventCallback((event: any) => {
    const { payload } = event;
    if (!payload?.account) return;

    const { name, username, idTokenClaims } = payload.account;

    setContext({
      name: name,
      email: username,
      companyIds: ClaimsUtility.getUserCompanyIds(idTokenClaims),
      claimsPermissions: ClaimsUtility.getCompanyPermissions(idTokenClaims),
    });
  });

  return (
    <AuthenticatedUserContext.Provider value={{ context, setContext }}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};
