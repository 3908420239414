import { Slide } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { ExpansionPanel, ExpansionPanelContent } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import companyApi from "../../api/company";
import productsApi from "../../api/products";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { CardOptionsSelector, CardSelector, FormDropDownList } from "../../components/form";
import { ICompany } from "../../components/admin/Company";
import { Commodity, ICommodity, IInstrument, Instrument } from "../../components/quotes";
import InfoIconAndTooltip from "../../components/utilities/InfoIconAndTooltip";
import { useApi, useFetch } from "../../hooks/useApi";
import { useUserContext } from "../../hooks/useUserContext";
import { getHelpUrl } from "../../utilities/helpUtilities";
import { getIconFileName, getIconPath } from "../../utilities/textUtilities";
import { requiredValidator } from "../../validators";

export const NewQuote = () => {
  const navigate = useNavigate();
  const { context: user } = useUserContext();

  const [company, setCompany] = useState(0);
  const [userCompany, setUserCompany] = useState<any|null>(null);
  const [types, setTypes] = useState<Record<string, string>>({});
  const [commodities, setCommodities] = useState<Commodity[]>([]);
  const [commodity, setCommodity] = useState<Commodity>(Commodity.EMPTY);
  const [instruments, setInstruments] = useState<Instrument[]>([]);
  const [expanded, setExpanded] = useState(true);
  const [instrument, setInstrument] = useState(0);

  const companiesRequest = useFetch(companyApi.getLineage);
  const commodityTypesRequest = useFetch(productsApi.getCommodityTypes);
  const commoditiesRequest = useFetch(productsApi.getCommodities);
  const instrumentsRequest = useApi(productsApi.getInstruments);

  useEffect(() => {
    const defaultCompany = (companiesRequest.data?.companies || [])
      .find((c: ICompany) => c.id === companiesRequest.data?.default);

    if (defaultCompany) {
      setUserCompany({ value: defaultCompany.id, label: defaultCompany.name });
      setCompany(defaultCompany.id);
    }
  }, [companiesRequest.data]);

  useEffect(() => {
    setTypes(commodityTypesRequest.data?.commodityTypes);
  }, [commodityTypesRequest.data]);

  useEffect(() => {
    setCommodities((commoditiesRequest.data?.commodities || [])
      .map((c: ICommodity) => Commodity.create(c)));
  }, [commoditiesRequest.data]);

  useEffect(() => {
    setInstruments((instrumentsRequest.data?.instruments|| [])
      .map((i: IInstrument) => Instrument.create(i)));
  }, [instrumentsRequest.data]);

  useEffect(() => {
    if (commodity.valid) instrumentsRequest.request(commodity.id, company);
    setInstrument(0);
  }, [commodity, company]);

  const onCompanyChange = (event: any, props: FormRenderProps) => {
    const value = event.value.value;
    setCompany(value);

    if (!commodity.selectable(value)) {
      setCommodity(Commodity.EMPTY);
      setExpanded(true);
      resetFields(["commodity"], props);
    }
  };

  const onCommodityChange = (event: any, props: FormRenderProps) => {
    setCommodity(commodities.find((c: Commodity) => c.id == event.value) || Commodity.EMPTY);
    setExpanded(false);
    resetFields(["instrument"], props);
  };

  const getCommodityTypesData = (): any[] => {
    const com = getCommoditiesData();
    if (com.length === 0)
      return [];

    const data = types ? Object.keys(types).map((key) => {
      const items: any[] = com.filter((c: any) => c.type === key);
      return { value: key, 
        label: types[key], 
        icon: getIconPath("commodity", key),
        items: items,
        disabled: items.every((o: any) => o.disabled)
      };
    }) : [];

    return data;
  };

  const getCommoditiesData = (): any[] => {
    return commodities 
        .map((c: Commodity) => {
          return { value: c.id, label: c.name, type: c.type, disabled: !c.selectable(company), icon: c.icon() };
        });
  };

  const getInstrumentData = () => {
    return instruments
        .map((i: Instrument) => {
          return { value: i.id, label: i.name, disabled: !i.selectable(company), icon: i.icon(), hint: i.productName };
        });
  };

  const resetFields = (fields: string[], props: FormRenderProps) => {
    fields.forEach((field: string) => props.onChange(field, { value: 0 }));
  };

  const handleSubmit = () => {
    const item = companiesRequest.data.companies.find((c: ICompany) => c.id === company);
    const selectedCommodity = commoditiesRequest.data.commodities.find((c: ICommodity) => c.id == commodity.id);
    const selectedInstrument = instrumentsRequest.data.instruments.find((i: IInstrument) => i.id == instrument);
    const quoteType = Instrument.create(selectedInstrument).quoteType();

    navigate(`/quote/${quoteType}/0`, {
      state: {
        data: {
          company: {
            id: company,
            name: item?.name,
            role: item?.role,
            canSell: item?.canSell,
          },
          commodity: selectedCommodity,
          instrument: selectedInstrument,
          quoteId: 0,
        },
      },
    });
  };

  const selectedCommodityIcon = getIconFileName(commodity.icon(), false, false, false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{ alignSelf: "center", margin: "1rem 0" }}
        className="k-card-title"
      >
        New Quote
        <InfoIconAndTooltip link={getHelpUrl(20303587758605)}>
          Choose the commodity you wish to protect, and then the type of price
          protection you desire.
        </InfoIconAndTooltip>
        
        <div>
          <LoadingIndicator loading={companiesRequest.loading} />
        </div>
      </div>

      {userCompany && (
        <Form
          initialValues={{ company: userCompany }}
          onSubmit={handleSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <div style={{ alignSelf: "center", width: "50%" }}>
              <FormElement>
                {companiesRequest.error && (
                  <Error>{companiesRequest.error}</Error>
                )}
                {!companiesRequest.loading && !user.roles.isClient && (
                  <Field
                    key="company"
                    id="company"
                    name="company"
                    label="Client"
                    textField="label"
                    dataItemKey="value"
                    onChange={(e) => onCompanyChange(e, formRenderProps)}
                    component={FormDropDownList}
                    data={(companiesRequest.data?.companies || []).map(
                      (c: ICompany) => {
                        return { value: c.id, label: c.name };
                      }
                    )}
                    validator={requiredValidator}
                    style={{ maxWidth: 500 }}
                  />
                )}

                <LoadingIndicator loading={commoditiesRequest.loading} />
                {commoditiesRequest.error && (
                  <Error>{commoditiesRequest.error}</Error>
                )}
                {!commoditiesRequest.loading && (
                  <div className="expander-wrapper">
                    <ExpansionPanel
                      key="commodityPanel"
                      title={<div style={{ display: "flex", alignItems: "baseline" }}>
                      Commodity
                        {commodity.valid &&
                        !expanded && (
                          <div style={{ textAlign: "center", paddingLeft: "1rem" }}>
                            <div>
                              <img
                                alt={commodity.abbreviation}
                                src={selectedCommodityIcon}
                                style={{ width: 32, height: 32 }}
                              />
                            </div>
                            <label>{commodity.name}</label>
                          </div>
                        )}
                        </div>
                      }
                      expanded={expanded}
                      expandIcon="k-i-arrow-60-down"
                      collapseIcon="k-i-arrow-60-up"
                      onAction={(event) => {
                        setExpanded(!event.expanded);
                      }}
                      style={{ border: "none", boxShadow: "none" }}
                    >
                      <Slide
                        transitionEnterDuration={500}
                        transitionExitDuration={500}
                      >
                        {expanded && (
                          <ExpansionPanelContent>
                            <Field
                              key="com"
                              id="com"
                              name="commodity"
                              onChange={(e) =>
                                onCommodityChange(e, formRenderProps)
                              }
                              component={CardOptionsSelector}
                              data={getCommodityTypesData()}
                              validator={requiredValidator}
                            />
                          </ExpansionPanelContent>
                        )}
                      </Slide>
                    </ExpansionPanel>
                  </div>
                )}

                <LoadingIndicator loading={instrumentsRequest.loading} />
                {instrumentsRequest.error && (
                  <Error>{instrumentsRequest.error}</Error>
                )}
                {!instrumentsRequest.loading && commodity.valid && (
                  <Field
                    key="ins"
                    id="ins"
                    name="instrument"
                    label="Product"
                    onChange={(e) => {
                      setInstrument(e.value);
                    }}
                    component={CardSelector}
                    data={getInstrumentData()}
                    validator={requiredValidator}
                  />
                )}

                {instrument > 0 && (
                  <div style={{ float: "right", paddingTop: "1rem" }}>
                    <Button
                      themeColor="primary"
                      type="submit"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      Continue
                    </Button>
                  </div>
                )}
              </FormElement>
            </div>
          )}
        />
      )}
    </div>
  );
};
