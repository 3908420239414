import { Fragment, useEffect, useState } from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartXAxis,
  ChartXAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { Error } from "@progress/kendo-react-labels";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { LoadingIndicator } from "../LoadingIndicator";
import { HeaderTextSelector } from "../utilities";
import { useApi } from "../../hooks/useApi";
import quotesApi from "../../api/quotes";
import "hammerjs";

interface IQuotesStatusHeatChartProps {
  dates: SelectionRange;
}

export const QuotesStatusHeatMapChart = (
  props: IQuotesStatusHeatChartProps
) => {
  const { dates } = props;

  const getQuotesApi = useApi(quotesApi.getStatusDetails);
  const [data, setData] = useState<any[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [commodities, setCommodities] = useState<any>({});
  const [products, setProducts] = useState<any>({});
  const [chartData, setChartData] = useState<any[]>([]);
  const [selected, setSelected] = useState("tickerCode");

  const yOptions: any = {
    Commodity: "tickerCode",
    Product: "instrumentCode",
    Company: "companyName",
    User: "user",
  };

  useEffect(() => {
    getQuotesApi.request(dates);
  }, [dates]);

  useEffect(() => {
    const data = getQuotesApi.data;
    if (!data) return;

    setData(data.data);
    setStatuses(Object.values(data.statuses));
    setCommodities(data.commodities);
    setProducts(data.products);
  }, [getQuotesApi.data]);

  useEffect(() => {
    if (data.length && statuses.length) {
      const totals = createChartData(selected);
      const keys = Object.keys(totals).sort();
      setChartData(keys.map((k: string) => totals[k]));
    }
  }, [data, statuses, selected]);

  const createChartData = (field: string) => {
    return data.reduce((acc: any, cur: any, index: number) => {
      const key = `${cur.status}_${cur[field]}`;
      if (acc[key]) acc[key].value++;
      else acc[key] = { x: cur[field], y: cur.status, value: 1 };
      return acc;
    }, {});
  };

  const renderTooltip = ({ point }: any) => (
    <Fragment>
      <span>
        {selected === yOptions.Commodity
          ? commodities[point.value.x]
          : selected === yOptions.Product
          ? products[point.value.x]
          : point.value.x}
        , {point.value.y}: {point.value.value}
      </span>
    </Fragment>
  );

  return (
    <div style={{ padding: "1rem" }}>
      <LoadingIndicator loading={getQuotesApi.loading} />
      {getQuotesApi.error && <Error>{getQuotesApi.error}</Error>}

      {!getQuotesApi.loading && data && (
        <>
          {data.length === 0 && <div>No data to display</div>}
          {data.length > 0 && (
            <Chart style={{ height: "180px" }}>
              {" "}
              //TODO: Don't use fixed height
              <ChartSeries>
                0
                <ChartSeriesItem
                  type="heatmap"
                  data={chartData}
                  xField="x"
                  yField="y"
                  field="value"
                  labels={{
                    visible: true,
                  }}
                  markers={{
                    type: "roundedRect",
                    border: {
                      width: 2,
                    },
                  }}
                />
              </ChartSeries>
              <ChartXAxis>
                <ChartXAxisItem labels={{ rotation: "auto" }} />
              </ChartXAxis>
              <ChartTooltip render={renderTooltip} />
            </Chart>
          )}
        </>
      )}

      {data && data.length > 0 && (
        <HeaderTextSelector
          values={Object.keys(yOptions)}
          selected={Object.keys(yOptions).find(
            (key) => yOptions[key] === selected
          )}
          onChange={(e) => setSelected(yOptions[e])}
        />
      )}
    </div>
  );
};
