import { Form, FormRenderProps, FormSubmitClickEvent } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Stepper, StepperChangeEvent } from "@progress/kendo-react-layout";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import quotesApi from "../../api/quotes";
import { getQuoteDetails } from "../../api/vanilla";
import { Commodity, Instrument } from "../../components/quotes";
import VanillaFormRender from "../../components/quotes/vanilla/VanillaFormRender";
import { useApi } from "../../hooks/useApi";
import { useUserContext } from "../../hooks/useUserContext";
import DateUtility from "../../utilities/dateUtilities";
import { StepsInterface } from "./workflow";

type Props = {};

export const VanillaWorkflow = (props: Props) => {
  const { context: user } = useUserContext();
  const { roles, permissions } = user;
  const { state } = useLocation();
  const { data } = state || {};

  const canSubmit = permissions.canSubmitQuotes && roles.canSubmitQuotes(data.company.role);
  const getQuoteDetailsApi = useApi(getQuoteDetails);

  const commodity = data?.commodity ? Commodity.create(data.commodity) : Commodity.EMPTY;
  const instrument = data?.instrument ? Instrument.create(data.instrument) : Instrument.EMPTY;

  const { step: stepString } = useParams();
  const step = parseInt(stepString as string);
  const navigate = useNavigate();

  const setStep = (step: number) => {
    navigate(`/quote/vanilla/${step}`, { state });
  };
  const [steps, setSteps] = useState<Array<StepsInterface>>([
    { label: "Start", isValid: undefined },
    { label: "Structure", isValid: undefined },
    { label: "Quote", isValid: undefined },
    { label: "Submit", isValid: undefined },
  ]);

  const [error, setError] = useState("");
  const [formState, setFormState] = useState<any | null>(null);

  const lastStepIndex = steps.length - 1;
  const isQuoteStep = step === lastStepIndex - 2;
  const isSubmitStep = step === lastStepIndex;
  const isPreviousStepsValid =
    steps.slice(0, step).findIndex((currentStep) => currentStep.isValid === false) === -1;

  useEffect(() => {
    setStep(0);

    if (data?.quoteId) {
      getQuoteDetailsApi.request(data.quoteId);
    } else {
      setFormState({
        quoteName: `${commodity.name} (${commodity.abbreviation}) ${instrument.name}`,
        strikeType: "Fixed",
        direction: 1,
        settlement: 2,
        quoteId: 0,
        commonStrike: false,
      });
    }
  }, []);

  useEffect(() => {
    if (getQuoteDetailsApi.data) {
      const {
        quote: {
          clientLong,
          companyId,
          configurationId,
          endDate,
          prices,
          quoteName,
          settlementTypeId,
          startDate,
          structureId,
          totalVolume,
        },
      } = getQuoteDetailsApi.data;

      const direction = clientLong ? 1 : 2;

      const contractStart = DateUtility.convertToDateWithOffset(
        startDate,
        -1 * instrument.calendarMonthOffset,
      );
      const contractEnd = DateUtility.convertToDateWithOffset(
        endDate,
        -1 * instrument.calendarMonthOffset,
      );

      setFormState({
        quoteId: data.quoteId,
        quoteName,
        strikeType: "Fixed",
        direction,
        settlement: settlementTypeId,
        configurationId,
        contract: {
          start: contractStart,
          end: contractEnd,
        },
        totalVolume,
        structureId,
        prices,
        commonStrike: true,
      });
    }
  }, [getQuoteDetailsApi.data]);

  const onStepSubmit = useCallback(
    async (event: FormSubmitClickEvent) => {
      let { isValid, values } = event;
      setError("");

      if (isValid && isSubmitStep && canSubmit) {
        await quotesApi.submitQuote(values.pricingId, values.quoteId).catch((err: any) => {
          setError(err.response?.data?.message || err.message);
          isValid = false;
        });
      }

      const currentSteps = steps.map((currentStep: StepsInterface, index: number) => ({
        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid,
      }));

      setSteps(currentSteps);

      if (!isValid) return;

      setStep(Math.min(step + 1, lastStepIndex));
      setFormState(values);

      if (isSubmitStep) {
        if (canSubmit && roles.isRiskTaker) navigate(`/quote/details/${values.quoteId}`);
        else navigate("/");
      }
    },
    [steps, isQuoteStep, isSubmitStep, step, lastStepIndex],
  );

  const onPrevClick = useCallback(
    (event: any) => {
      event.preventDefault();
      setStep(Math.max(step - 1, 0));
      setError("");
    },
    [step, setStep],
  );

  const handleStepperChange = (e: StepperChangeEvent) => {
    if (e.value < step || steps[e.value].isValid || steps[e.value - 1].isValid) setStep(e.value);
  };

  return (
    <>
      <Stepper
        value={step}
        items={steps}
        onChange={handleStepperChange}
        style={{ padding: "10px 0" }}
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ flexBasis: "75%" }}>
          {formState && (
            <Form
              initialValues={formState}
              onSubmitClick={onStepSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <VanillaFormRender
                  step={step}
                  commodity={commodity}
                  formRenderProps={formRenderProps}
                  instrument={instrument}
                  steps={steps}
                  onPrevClick={onPrevClick}
                  isSubmitStep={isSubmitStep}
                  isPreviousStepsValid={isPreviousStepsValid}
                  canSubmit={canSubmit}
                  userRoles={roles}
                />
              )}
            />
          )}
          <div className="float-right">
            <Error>{error}</Error>
          </div>
        </div>
      </div>
    </>
  );
};
