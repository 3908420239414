import { parseDate } from "@progress/kendo-intl";
import { Form, FormRenderProps, FormSubmitClickEvent } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Stepper, StepperChangeEvent } from "@progress/kendo-react-layout";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import insuranceApi from "../../api/insurance";
import quotesApi from "../../api/quotes";
import { Commodity, Instrument } from "../../components/quotes";
import { InsuranceFormContent } from "../../components/quotes/insurance/InsuranceFormContent";
import { useApi } from "../../hooks/useApi";
import { useUserContext } from "../../hooks/useUserContext";
import { StepsInterface } from "./workflow";

export const InsuranceWizard = () => {
  const { context: user } = useUserContext();
  const { roles, permissions } = user;
  const { state } = useLocation();
  const { data } = state || {};

  const canSubmit = permissions.canSubmitQuotes && roles.canSubmitQuotes(data.company.role);
  const getQuoteDetailsApi = useApi(insuranceApi.getQuoteDetails);

  const commodity = data.commodity ? Commodity.create(data.commodity) : Commodity.EMPTY;
  const instrument = data.instrument ? Instrument.create(data.instrument) : Instrument.EMPTY;

  const { step: stepString } = useParams();
  const step = parseInt(stepString as string);
  const navigate = useNavigate();

  const setStep = (step: number) => {
    navigate(`/quote/insurance/${step}`, { state });
  };
  const [steps, setSteps] = useState<Array<StepsInterface>>([
    { label: "Start", isValid: undefined },
    { label: "Coverage", isValid: undefined },
    { label: "Quote", isValid: undefined },
    { label: "Review", isValid: undefined },
  ]);

  const [error, setError] = useState("");
  const [formState, setFormState] = useState<any | null>(null);

  const lastStepIndex = steps.length - 1;
  const isSubmitStep = step === lastStepIndex;
  const isPreviousStepsValid =
    steps.slice(0, step).findIndex((currentStep) => currentStep.isValid === false) === -1;

  useEffect(() => {
    setStep(0);

    if (data.quoteId) getQuoteDetailsApi.request(data.quoteId);
    else {
      setFormState({
        quoteName: `${commodity.name} (${commodity.abbreviation}) ${instrument.name}`,
      });
    }
  }, []);

  useEffect(() => {
    if (!getQuoteDetailsApi.data) return;

    const details = getQuoteDetailsApi.data.quote;
    const levels: any = {};
    details.retentionLevels.forEach((rl: any) => (levels[rl.field] = rl));

    const form: any = {};
    const temp: any[] = Object.values(levels);
    temp[0].pricing.details.forEach((l: any, i: number) => {
      form[`volume_${i}`] = l.volume;
      form[`coverage_${i}`] = l.coverage;
    });

    Object.assign(form, {
      configurationId: details.configurationId,
      quoteId: data.quoteId,
      quoteName: details.quoteName,
      contract: {
        start: parseDate(details.startDate),
        end: parseDate(details.endDate),
      },
      totalVolume: details.totalVolume,
      totalCoverage: details.totalCoverage,
      retentionLevels: levels,
      allocation: -1,
    });

    setFormState(form);
  }, [getQuoteDetailsApi.data]);

  const onStepSubmit = useCallback(
    async (event: FormSubmitClickEvent) => {
      let { isValid, values } = event;
      setError("");

      if (isValid && isSubmitStep && canSubmit) {
        await quotesApi.submitQuote(values.pricingId, values.quoteId).catch((err: any) => {
          setError(err.response?.data?.message || err.message);
          isValid = false;
        });
      }

      const currentSteps = steps.map((currentStep: StepsInterface, index: number) => ({
        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid,
      }));

      setSteps(currentSteps);

      if (!isValid) return;

      setStep(Math.min(step + 1, lastStepIndex));
      setFormState(values);

      if (isSubmitStep) navigate("/");
    },
    [steps, isSubmitStep, step, lastStepIndex],
  );

  const onPrevClick = useCallback(
    (event: any) => {
      event.preventDefault();
      setStep(Math.max(step - 1, 0));
      setError("");
    },
    [step, setStep],
  );

  const handleStepperChange = (e: StepperChangeEvent) => {
    if (e.value < step || steps[e.value].isValid || steps[e.value - 1].isValid) setStep(e.value);
  };

  return (
    <>
      <Stepper
        value={step}
        items={steps}
        onChange={handleStepperChange}
        style={{ padding: "10px 0" }}
      />

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ flexBasis: "90%" }}>
          {formState && (
            <Form
              initialValues={formState}
              onSubmitClick={onStepSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <InsuranceFormContent
                  step={step}
                  commodity={commodity}
                  formRenderProps={formRenderProps}
                  instrument={instrument}
                  steps={steps}
                  onPrevClick={onPrevClick}
                  isSubmitStep={isSubmitStep}
                  isPreviousStepsValid={isPreviousStepsValid}
                  canSubmit={canSubmit}
                />
              )}
            />
          )}
          <div className="float-right">
            <Error>{error}</Error>
          </div>
        </div>
      </div>
    </>
  );
};
