import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip,
  ChartLegend,
} from "@progress/kendo-react-charts";
import "hammerjs";

interface IQuotesStatusFunnelChartProps {
  data: any[]
}

export const QuotesStatusFunnelChart = (props: IQuotesStatusFunnelChartProps) => {
  const { data } = props;

  const renderTooltip = (props: any) => {
    if (props.point) {
      return props.point.dataItem.status;
    }
  };

  return (
    <div style={{ padding: ".5rem" }}>
        {data.length === 0 && <div>No data to display</div>}
        <Chart style={{ height: "240px" }}> //TODO: Don't use fixed height 
          <ChartSeries>
            <ChartSeriesItem
              type="funnel"
              data={data}
              categoryField="status"
              field="count"
            >
              <ChartSeriesLabels background="none" format="n0" />
            </ChartSeriesItem>
          </ChartSeries>
          <ChartTooltip render={renderTooltip} />
          <ChartLegend visible={false} />
        </Chart>
    </div> 
  );
};
