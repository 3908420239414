import { Hint } from "@progress/kendo-react-labels";

export const Footer = () => {
  return (
    <footer id="footer">
      <div id="footer-content-wrapper">
        <div>
          <Hint>© 2024 Risk Canopy. All rights reserved.</Hint>
        </div>
        <div>
          <a
            href="https://riskcanopy.com/terms-of-use/"
            target="_blank"
            rel="noreferrer"
          >
            <Hint>Terms of Use</Hint>
          </a>
        </div>
      </div>
    </footer>
  );
};
