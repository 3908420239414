import { FormRenderProps } from "@progress/kendo-react-form";
import { useState, useEffect } from "react";
import { Company } from "../Company";
import { ConfigurationActions, ConfigurationCompaniesSelector } from ".";
import { ConfigAction } from "./ConfigurationEnums";

interface ICompanySelectionStepProps {
  formRenderProps: FormRenderProps;
  companies: Company[];
  companyId: number;
  showAction?: boolean;
  selectMultiple?: boolean;
}

export const CompanySelectionStep = ({
  formRenderProps,
  companies,
  companyId,
  showAction,
  selectMultiple,
}: ICompanySelectionStepProps) => {
  const businessTypes: number[] = selectMultiple
    ? formRenderProps.valueGetter("businessTypes")
    : undefined;

  const childCompanies = (companies?.filter((c) => c.parentCompanyId === companyId) ?? [])
    .filter((c) => !businessTypes || businessTypes.every((t) => c.businessTypes.includes(t)))
    .map((c) => ({ value: c.id, label: c.name }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const selectedCompanies: number[] = formRenderProps.valueGetter("companies") || [];
  const selectedCompany = formRenderProps.valueGetter("company");

  if (!selectMultiple && selectedCompany && selectedCompanies.join(",") !== selectedCompany.toString()) {
    formRenderProps.onChange("company", {value: null});
    formRenderProps.onChange("companies", { value: [] });
  }
  else {
    const ids = childCompanies.map((cc) => cc.value);
    const filtered = selectedCompanies.filter((id) => ids.includes(id));
    if (filtered.length !== selectedCompanies.length)
      formRenderProps.onChange("companies", { value: filtered });
  }

  const [isAssign, setIsAssign] = useState(
    formRenderProps.valueGetter("action") === ConfigAction.Assign,
  );

  useEffect(() => {
    if (!isAssign) formRenderProps.onChange("isActive", { value: false });
  }, [isAssign]);

  const onChangeCompany = (value: number) =>
    formRenderProps.onChange("companies", { value: [value] });

  return (
    <div className="stepper-form">
      {showAction && (
        <ConfigurationActions
          onChange={(value: string) => setIsAssign(value === ConfigAction.Assign)}
        />
      )}

      <ConfigurationCompaniesSelector
        companyList={childCompanies}
        multiSelect={selectMultiple}
        onChange={!selectMultiple ? onChangeCompany : undefined}
      />
    </div>
  );
};
