import { Dialog } from "@progress/kendo-react-dialogs";
import { IApiResults } from "../../hooks/useApi";
import { PricingHistoryChartDisplay } from "./PricingHistoryChartDisplay";

interface PricingHistoryDialogProps {
  commodityName: string;
  apiResults: IApiResults;
  onCancel: () => void;
}

export const PricingHistoryDialog = (props: PricingHistoryDialogProps) => {
  const { commodityName, apiResults, onCancel } = props;

  return (
    <Dialog title={`${commodityName} Pricing History`} onClose={onCancel}>
      <PricingHistoryChartDisplay apiResults={apiResults} />
    </Dialog>
  );
};
