import { useEffect, useState } from "react";
import { Error } from "@progress/kendo-react-labels";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnMenuProps,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { State, process } from "@progress/kendo-data-query";
import { BooleanGridCell, ColumnMenuCheckboxFilter, CustomGridCell, EditLinkCell } from "../grid";
import { Button } from "@progress/kendo-react-buttons";
import { AddEditUserDialog } from "./AddEditUserDialog";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ListItem } from "../form";
import { objectToLookup } from "../../utilities/objectUtilities";
import { ICompany } from "./Company";
import { IUser, User } from "./User";
import usersApi from "../../api/users";
import { useApi } from "../../hooks/useApi";

export const UsersGrid = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [permissions, setPermissions] = useState<ListItem[]>([]);
  const [rolePermissions, setRolePermissions] = useState<Record<string, number[]>>({});
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState<User>(User.EMPTY);
  const [result, setResult] = useState<any>([]);
  const [dataState, setDataState] = useState<State>({
    sort: [{ field: "fullName", dir: "asc" }],
    skip: 0,
    take: 20,
  });

  const getUsersApi = useApi(usersApi.getAll);
  useEffect(() => {
    getUsersApi.request();
  }, []);

  useEffect(() => {
    const data = getUsersApi.data;

    setUsers(data?.users.map((u: IUser) => User.create(u)) || []);
    setPermissions(objectToLookup(data?.permissions || []));
    setRolePermissions(data?.rolePermissions ?? {});

    const comps: ICompany[] = data?.companies || [];
    setCompanies(
      comps.map((c) => ({
        value: c.id,
        label: c.name,
        role: c.role,
        children: comps.filter((ch) => ch.parentCompanyId === c.id).map((ch) => ch.id),
      })),
    );
  }, [getUsersApi.data]);

  useEffect(() => {
    const newState = createDataState(dataState);
    setResult(newState.result);
  }, [users]);

  const createDataState = (dataState: State) => {
    return {
      result: process(users.slice(0), dataState),
      dataState: dataState,
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const enterEdit = (item: any) => {
    setEditItem(item);
    setOpenForm(true);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleSubmitEdit = () => {
    setOpenForm(false);
    getUsersApi.request();
  };

  const UserNameCell = (props: GridCellProps) => (
    <EditLinkCell {...props} title="Edit User" enterEdit={enterEdit} />
  );

  const UserCompaniesCell = (props: GridCellProps) => {
    const { field, dataItem } = props;
    const value = dataItem[field || ""] || [];

    const list = companies
      .filter((c) => value.includes(c.value))
      .map((c) => c.label)
      .sort()
      .join(", ");

    return (
      <CustomGridCell {...props}>
        <div className={"ellipsis-cell"}>{list}</div>
      </CustomGridCell>
    );
  };

  const FilterColumnMenu = (props: GridColumnMenuProps) => (
    <ColumnMenuCheckboxFilter {...props} data={users} />
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "right",
        }}
      >
        <Button
          title="Add User"
          themeColor="primary"
          size="small"
          onClick={() => enterEdit(User.EMPTY)}
          style={{ marginBottom: 10 }}
        >
          Add User
        </Button>
      </div>

      <LoadingIndicator loading={getUsersApi.loading} />
      {getUsersApi.error && <Error>{getUsersApi.error}</Error>}
      {!getUsersApi.loading && (
        <Grid
          data={result}
          {...dataState}
          pageable={true}
          sortable={true}
          filterable={false} // Using custom grid column menu filter
          onDataStateChange={dataStateChange}
        >
          <GridColumn
            field="fullName"
            title="Name"
            cell={UserNameCell}
            columnMenu={FilterColumnMenu}
          />
          <GridColumn
            field="companyIds"
            title="Companies"
            cell={UserCompaniesCell}
            filterable={true}
          />
          <GridColumn field="email" title="Email" columnMenu={FilterColumnMenu} />
          <GridColumn field="phone" title="Phone" />
          <GridColumn
            field="isActive"
            title="Active"
            cell={BooleanGridCell}
            columnMenu={FilterColumnMenu}
            width={150}
          />
        </Grid>
      )}

      {openForm && (
        <AddEditUserDialog
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmitEdit}
          user={editItem}
          companies={companies}
          permissions={permissions}
          rolePermissions={rolePermissions}
        />
      )}
    </>
  );
};
