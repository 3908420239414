import { Typography } from "@progress/kendo-react-common";
import { useInternationalization } from "@progress/kendo-react-intl";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { Error } from "@progress/kendo-react-labels";
import { useLocation } from "react-router-dom";
import { IApiResults } from "../../../hooks/useApi";
import { Commodity } from "../Commodity";

type Props = {
  quoteData: any;
  quoteDetailsResults: IApiResults;
  canSubmit: boolean;
};

export const VanillaSubmitSummary = ({ quoteData, quoteDetailsResults, canSubmit }: Props) => {
  let { units, totalVolume, startDate, endDate, id, name, price, structure } = quoteData;

  const formatter = useInternationalization();
  totalVolume = formatter.formatNumber(totalVolume, "n0");
  const { state } = useLocation();
  const { data } = state || {};

  const commodity = data.commodity ? Commodity.create(data.commodity) : Commodity.EMPTY;
  const commodityName = commodity.name;

  const { data: quoteDetails, loading, error } = quoteDetailsResults;

  let companyName = "";
  if (quoteDetails) {
    const {
      quote: { brokerName, sellerName },
    } = quoteDetails;
    companyName = brokerName.toLowerCase() === "no broker name" ? sellerName : brokerName;
  }

  const payableString =
    price === "$0.00"
      ? ""
      : ` and is payable to ${companyName} within five (5) business days of application approval`;

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
      <Card>
        <CardBody>
          <Typography.p>
            { error && <Error>{error}</Error>}
            {!loading && (
              <ul>
                <li>
                  {`You have quoted a ${structure}
                for commodity ${commodityName}. Your total
                volume covered will be ${totalVolume} ${units}. The consecutive months covered by this
                instrument begin ${startDate} and end ${endDate}.`}
                </li>
                <li>
                  {`Your monthly volume and strikes, as presented above,
                will remain available to you on this website. For later
                reference, this quote has an ID of ${id} and a label or name of
                ${name}.`}
                </li>

                {canSubmit && (
                  <>
                    <li>
                      By pressing Apply, you are:
                      <ol style={{ listStyleType: "decimal", paddingLeft: 20 }}>
                        <li>
                          Applying to purchase this financial instrument with no guarantee that the
                          purchase will be approved.
                        </li>
                        <li>Sending your application to the Risk Canopy team.</li>
                        <li>
                          {`Agreeing to purchase the product with the terms described
                          above if the application is approved. The total price
                          due will be ${price}${payableString}.`}
                        </li>
                      </ol>
                    </li>
                    <li>
                      Notification of your application approval or denial will arrive shortly in
                      your email inbox.
                    </li>
                  </>
                )}

                {!canSubmit && <li>Click Finish to return to the dashboard.</li>}
              </ul>
            )}
          </Typography.p>
        </CardBody>
      </Card>
    </div>
  );
};
