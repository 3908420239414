import { useCallback, useEffect, useState } from "react";
import { useInternationalization } from "@progress/kendo-react-intl";
import { geometry } from "@progress/kendo-drawing";
import { parseDate } from "@progress/kendo-intl";
import { Card, CardBody, CardSubtitle } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  MarkersVisualArgs,
  TooltipContext,
} from "@progress/kendo-react-charts";
import { Error } from "@progress/kendo-react-labels";
import contractsApi from "../../api/contracts";
import { useApi } from "../../hooks/useApi";
import DateUtility from "../../utilities/dateUtilities";
import { maxByProperty, minByProperty } from "../../utilities/objectUtilities";
import "hammerjs";

const SECS_IN_MINUTE = 60;
const SECS_IN_HOUR = 60 * 60;
const SECS_IN_DAY = 60 * 60 * 24;

interface IntradayCurvesChartProps {
  commodity: any | null;
  refresh?: number;
  maxDate?: string;
  showTitle?: boolean;
  onLoading: (value: boolean) => void;
}

export const IntradayCurvesChart = ({
  commodity,
  refresh,
  maxDate,
  showTitle,
  onLoading,
}: IntradayCurvesChartProps) => {
  const decimals = commodity?.decimals ?? 2;
  const currencyFormat = `c${decimals}`;
  const numberFormat = `n${decimals}`;
  const unit = (commodity?.units ?? "s").slice(0, -1);

  const formatter = useInternationalization();
  const getSettlementDataApi = useApi(contractsApi.getSettlementData);
  const getBidAskDataApi = useApi(contractsApi.getBidAskData);

  const [commodityId, setCommodityId] = useState(0);
  const [refreshFlag, setRefreshFlag] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [closingCurve, setClosingCurve] = useState<any[]>([]);
  const [intradayCurve, setIntradayCurve] = useState<any[]>([]);
  const [bidAskAge, setBidAskAge] = useState<any[]>([]);
  const [bidAskSpread, setBidAskSpread] = useState<any[]>([]);
  const [chartMin, setChartMin] = useState(0);
  const [maxLoadDate, setMaxLoadDate] = useState("");

  useEffect(() => {
    setCommodityId(commodity?.id ?? 0);
    setRefreshFlag(refresh ?? 0);
  }, [commodity, refresh]);

  useEffect(() => {
    getCurves(commodityId);
  }, [commodityId, refreshFlag]);

  useEffect(() => {
    setClosingCurve(formatCurveData(getSettlementDataApi.data?.data));
  }, [getSettlementDataApi.data]);

  useEffect(() => {
    const data = getBidAskDataApi.data?.data;
    if (!data) return;

    if (!data.maxLoadDate) {
      setMaxLoadDate("");
      setBidAskSpread([]);
      setBidAskAge([]);
      setIntradayCurve([]);
      return;
    }

    const spread = data.dates.map((d: string) => {
      return {
        contract: d,
        date: new Date(parseDate(d)),
        value: (data.ask[d] || 0) - (data.bid[d] || 0),
        tolerance: data.tolerance,
      };
    });

    const loadAge = formatCurveData(data.bidAskAge, data.dates);
    const factor = minByProperty(loadAge, "value");

    setMaxLoadDate(DateUtility.formatDateGeneral(data.maxLoadDate));
    setBidAskSpread(spread);
    setBidAskAge(
      loadAge.map((l) => {
        return { ...l, factor: (l.value /= factor) };
      }),
    );
    setIntradayCurve(formatCurveData(data.intraday, data.dates));
  }, [getBidAskDataApi.data]);

  useEffect(() => {
    const loading = getSettlementDataApi.loading || getBidAskDataApi.loading;
    setIsLoading(loading);
    onLoading(loading);
  }, [getSettlementDataApi.loading, getBidAskDataApi.loading]);

  useEffect(() => {
    setChartMin(
      Math.floor(
        10 * Math.min(minByProperty(closingCurve, "value"), minByProperty(intradayCurve, "value")),
      ) / 10,
    );

    if (intradayCurve.length > 0) {
      const chartDates = intradayCurve.map((d) => d.contract);

      if (closingCurve.some((d) => !chartDates.includes(d.contract)))
        setClosingCurve(closingCurve.filter((d) => chartDates.includes(d.contract)));

      if (bidAskSpread.some((d) => !chartDates.includes(d.contract)))
        setBidAskSpread(bidAskSpread.filter((d) => chartDates.includes(d.contract)));
    }
  }, [intradayCurve, closingCurve, bidAskSpread]);

  const formatCurveData = (data: any, dates?: string[]) => {
    if (!data) return [];

    const keys = dates ? [...dates] : Object.keys(data);
    return keys.map((k) => {
      return { contract: k, date: new Date(parseDate(k)), value: data[k] };
    });
  };

  const getCurves = useCallback(async (commodityId: number) => {
    if (commodityId > 0) {
      getSettlementDataApi.request(commodityId);
      getBidAskDataApi.request(commodityId, maxDate);
    }
  }, []);

  const renderTooltip = (props: TooltipContext) => {
    const { value, category } = props.point;
    return (
      <>
        <div>{formatter.formatDate(category as Date, "MM/dd/yyyy")}</div>
        <div>
          {formatter.formatNumber(value, currencyFormat)} / {unit}
        </div>
      </>
    );
  };

  const renderIntradayTooltip = (props: TooltipContext) => {
    const { value, category } = props.point;
    const categoryDate = category as Date;
    const contract = DateUtility.formatDateYMD(categoryDate);
    const loadAge = bidAskAge.find((a) => a.contract === contract)?.value || 0;

    const formatLoadAge = (age: number, units: string): string => {
      return `${formatter.formatNumber(age, "n1")} ${units}`;
    };

    const display =
      loadAge / SECS_IN_DAY > 1
        ? formatLoadAge(loadAge / SECS_IN_DAY, "days")
        : loadAge / SECS_IN_HOUR > 1
          ? formatLoadAge(loadAge / SECS_IN_HOUR, "hours")
          : loadAge / SECS_IN_MINUTE > 1
            ? formatLoadAge(loadAge / SECS_IN_MINUTE, "minutes")
            : formatLoadAge(loadAge, "seconds");

    return (
      <>
        <div>{formatter.formatDate(categoryDate, "MM/dd/yyyy")}</div>
        <div>{formatter.formatNumber(value, currencyFormat)} / {unit}</div>
        <div>Bid-Ask Age: {display}</div>
      </>
    );
  };

  const transformMarkers = (e: MarkersVisualArgs) => {
    let visual = e.createVisual();
    const contract = DateUtility.formatDateYMD(e.category);

    // Scale the marker anywhere from x0.5 to x5
    const minMarker = 0.5;
    const maxMarker = 5;
    const maxPoint = maxByProperty(bidAskAge, "factor");
    const minPoint = minByProperty(bidAskAge, "factor");
    const factor = bidAskAge.find((a) => a.contract === contract)?.factor ?? 1;
  
    const scale = maxPoint > maxMarker ?
      minMarker + ((maxMarker - minMarker) / (maxPoint - minPoint)) * (factor - minPoint) :
      factor;

    visual.transform(geometry.transform().scale(scale, scale, e.rect.center()));
    return visual;
  };

  return (
    <>
      {commodity &&
      (intradayCurve.length > 0 || closingCurve.length > 0 || bidAskSpread.length > 0) ? (
        <Card>
          <CardBody>
            {showTitle && (
              <CardSubtitle style={{ textAlign: "center" }}>
                Intraday Market Data: {commodity.name}
              </CardSubtitle>
            )}
            <Error>
              <div>{getSettlementDataApi.error}</div>
              <div>{getBidAskDataApi.error}</div>
            </Error>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-evenly",
              }}
            >
              <div>Load Date: {isLoading ? "" : maxLoadDate}</div>
              <div>Refresh Time: {DateUtility.formatDateTime(new Date())}</div>
            </div>

            <Chart style={{ height: 200 }} transitions={false}>
              <ChartTooltip />
              {!isLoading && (
                <>
                  <ChartSeries>
                    <ChartSeriesItem
                      name="Settlements"
                      type="line"
                      field="value"
                      categoryField="date"
                      color="lightblue"
                      style="smooth"
                      data={closingCurve}
                      markers={{
                        visible: false,
                      }}
                    >
                      <ChartSeriesItemTooltip render={renderTooltip} />
                    </ChartSeriesItem>

                    <ChartSeriesItem
                      name="Estimated"
                      type="line"
                      field="value"
                      categoryField="date"
                      color="lightgreen"
                      style="smooth"
                      data={intradayCurve}
                      markers={{
                        background: "lightgreen",
                        visual: transformMarkers,
                      }}
                    >
                      <ChartSeriesItemTooltip render={renderIntradayTooltip} />
                    </ChartSeriesItem>
                  </ChartSeries>

                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      labels={{ format: "MMM yyyy", rotation: "auto" }}
                      maxDivisions={30}
                    />
                  </ChartCategoryAxis>

                  <ChartValueAxis>
                    <ChartValueAxisItem
                      labels={{ format: numberFormat }}
                      min={chartMin}
                      title={{
                        text: `$ / ${unit}`,
                      }}
                    />
                  </ChartValueAxis>
                </>
              )}
              <ChartLegend position="right" />
            </Chart>

            <Chart style={{ height: 200 }} transitions={false}>
              <ChartTooltip />
              <ChartSeries>
                <ChartSeriesItem
                  name="Tolerance"
                  type="line"
                  field="tolerance"
                  categoryField="date"
                  color="red"
                  dashType="dot"
                  data={bidAskSpread}
                  markers={{ visible: false }}
                >
                  <ChartSeriesItemTooltip format={`{0:c} / ${unit}`} />
                </ChartSeriesItem>

                <ChartSeriesItem
                  name="Bid-Ask Spread"
                  type="line"
                  field="value"
                  categoryField="date"
                  color="lightgreen"
                  style="smooth"
                  data={bidAskSpread}
                  markers={{ visible: false }}
                >
                  <ChartSeriesItemTooltip render={renderTooltip} />
                </ChartSeriesItem>
              </ChartSeries>

              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  labels={{ format: "MMM yyyy", rotation: "auto" }}
                  maxDivisions={30}
                />
              </ChartCategoryAxis>

              <ChartValueAxis>
                <ChartValueAxisItem
                  labels={{ format: numberFormat }}
                  title={{
                    text: `$ / ${unit}`,
                  }}
                />
              </ChartValueAxis>

              <ChartLegend position="right" />
            </Chart>
          </CardBody>
        </Card>
      ) : (
        <div>
          {!commodity || isLoading ? "" : "Intraday data is unavailable for this commodity"}
        </div>
      )}
    </>
  );
};
