import { PropsWithChildren, createContext, useContext } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getFreshCompanyContext } from "../../hooks/useUserCompanyContext";
import { CompaniesContext } from "./CompaniesContextProvider";

export interface IUserCompany {
  id: number;
  name: string;
  role: string;
  canSell: boolean;
}

interface IUserCompanyContext {
  context: IUserCompany;
  setContext: (obj: any) => void;
}

export const UserCompanyContext = createContext<IUserCompanyContext>(
  {} as IUserCompanyContext
);

export const UserCompanyContextProvider = ({ children }: PropsWithChildren) => {
  const [context, setContext] = useLocalStorage(
    "context",
    getFreshCompanyContext()
  );

  const { context: companies } = useContext(CompaniesContext);
  companies.sort((a, b) => a.id - b.id);

  if (companies.length > 0 && !companies.find((c) => c.id === context.id))
    setContext(companies[0]);

  return (
    <UserCompanyContext.Provider value={{ context, setContext }}>
      {children}
    </UserCompanyContext.Provider>
  );
};
