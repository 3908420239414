export interface ICompany {
  id: number;
  parentCompanyId: number | null;
  role: string;
  roleDisplay: string;
  name: string;
  contactName: string;
  isActive: boolean;
  canSell: boolean;
  businessTypes: number[];
}

export class Company implements ICompany {
  id = 0;
  parentCompanyId: number | null = null;
  role = "";
  roleDisplay = "";
  name = "";
  contactName = "";
  isActive = false;
  canSell = false;
  businessTypes: number[] = [];  

  private constructor(company: ICompany | null) {
    if (company) {
      this.id = company.id;
      this.parentCompanyId = company.parentCompanyId;
      this.role = company.role;
      this.roleDisplay = company.roleDisplay;
      this.name = company.name;
      this.contactName = company.contactName;
      this.isActive = company.isActive;
      this.canSell = company.canSell
      this.businessTypes = company.businessTypes;
    }
  }

  static get EMPTY(): Company {
    return Company.create(null);
  }

  static create(obj: ICompany | null): Company {
    return new Company(obj);
  }
}
