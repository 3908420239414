const claimType = {
  companyIds: "extension_CompanyIds",
  permissions: "extension_CanopyPermissions",
};

export class ClaimsUtility {
  static getUserCompanyIds = (idTokenClaims: any): number[] => {
    if (!idTokenClaims) return [];

    const companyIdsClaim = idTokenClaims[claimType.companyIds];
    return companyIdsClaim?.split(",").map((c: string) => parseInt(c)) || [];
  };

  static getCompanyPermissions = (idTokenClaims: any): Record<number, number[]> => {
    if (!idTokenClaims) return [];

    const permissionsClaim = idTokenClaims[claimType.permissions];
    return permissionsClaim ? JSON.parse(permissionsClaim) : {};
  };

  static compareIssuingPolicy = (
    idTokenClaims: any,
    policyToCompare: string
  ) => {
    let tfpMatches = idTokenClaims["tfp"] === policyToCompare.toLowerCase();
    let acrMatches = idTokenClaims["acr"] === policyToCompare.toLowerCase();
    return tfpMatches || acrMatches;
  };
}
