import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import {
  ConfigurationsFilters,
  EMPTY_PRODUCT_FILTERS,
  IProductFilters,
  ConfigurationItem,
} from ".";
import { Configuration } from "./Configuration";

interface IConfigurationProductsSelectorProps {
  configurations: Configuration[];
  selectedIds: number[];
  filters: IProductFilters;
  commodityTypes: Record<string, string>;
  productTypes: Record<string, string>;
  selectByParent?: boolean;
  showStatus?: boolean;
  onFiltersChange: (filters: IProductFilters) => void;
  onSelectionChange: (ids: number[]) => void;
}

export const ConfiguratonProductsSelector = ({
  configurations,
  selectedIds,
  filters,
  commodityTypes,
  productTypes,
  selectByParent,
  showStatus,
  onFiltersChange,
  onSelectionChange,
}: IConfigurationProductsSelectorProps) => {
  const [displayConfigs, setDisplayConfigs] = useState<Configuration[]>([]);
  const [filter, setFilter] = useState(filters ?? EMPTY_PRODUCT_FILTERS);
  const [selectedConfigurations, setSelectedConfigurations] = useState<number[]>([]);

  useEffect(() => {
    setSelectedConfigurations(selectedIds ?? []);
  }, []);

  useEffect(() => {
    const { text, commodities, products } = filter;

    const filteredConfigs = (configurations ?? [])
      .filter((c) => commodities.length === 0 || commodities.includes(c.commodityType))
      .filter((c) => products.length === 0 || products.includes(c.productType))
      .filter(
        (c) =>
          text.length === 0 ||
          c.commodity.toLowerCase().includes(text.toLowerCase()) ||
          c.instrument.toLowerCase().includes(text.toLowerCase()),
      )
      .sort((a, b) =>
        !a || !b
          ? 0
          : a.commodity.localeCompare(b.commodity) ||
            a.type.localeCompare(b.type) ||
            a.instrument.localeCompare(b.instrument),
      );

    if (JSON.stringify(displayConfigs) !== JSON.stringify(filteredConfigs))
      setDisplayConfigs(filteredConfigs);
    onFiltersChange(filter);
  }, [configurations, filter]);

  useEffect(() => {
    const configIds = displayConfigs.map((c) =>
      selectByParent ? c.parentConfigurationId : c.configurationId,
    );
    trySetSelectedConfigurations(
      selectedConfigurations.filter((id) => configIds.includes(id)) ?? [],
    );
  }, [displayConfigs, selectedConfigurations]);

  useEffect(() => {
    onSelectionChange(selectedConfigurations);
  }, [selectedConfigurations]);

  const trySetSelectedConfigurations = (ids: number[]) => {
    ids.sort((a, b) => a - b);
    if (JSON.stringify(ids) !== JSON.stringify(selectedConfigurations))
      setSelectedConfigurations(ids);
  };

  const handleConfigItemClick = (id: number, parentId: number) => {
    const idToUse = selectByParent ? parentId : id;

    trySetSelectedConfigurations(
      selectedConfigurations.includes(idToUse)
        ? selectedConfigurations.filter((c) => c !== idToUse)
        : [...selectedConfigurations, idToUse],
    );
  };

  const handleChangeFilters = (selections: IProductFilters) => {
    setFilter(selections);
    onFiltersChange(selections);
  };

  const selectAll = (e: any) => {
    e.preventDefault();
    trySetSelectedConfigurations(
      displayConfigs.map((c) => (selectByParent ? c.parentConfigurationId : c.configurationId)),
    );
  };

  const clearAll = (e: any) => {
    e.preventDefault();
    trySetSelectedConfigurations([]);
  };

  return (
    <>
      <div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>Select Products:</div>
          <div>
            <Button size="small" fillMode="outline" themeColor="primary" onClick={selectAll}>
              Select All
            </Button>
            <Button size="small" fillMode="outline" themeColor="primary" onClick={clearAll}>
              Clear
            </Button>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "1rem" }}>
        <ConfigurationsFilters
          commodityTypes={commodityTypes}
          productTypes={productTypes}
          initialSelections={filters}
          onChangeFilters={handleChangeFilters}
        />
      </div>

      <div className="card-container grid">
        {displayConfigs.map((c, index) => (
          <ConfigurationItem
            key={index}
            configuration={c}
            commodityTypes={commodityTypes}
            selected={selectedConfigurations}
            selectedByParent={selectByParent}
            showStatus={showStatus}
            onClick={handleConfigItemClick}
          />
        ))}

        {displayConfigs.length === 0 && <div>No products to display</div>}
      </div>
    </>
  );
};
