import { GridCellProps, GridColumnProps } from "@progress/kendo-react-grid";
import GridCellNumericEditor from "../utilities/GridCellNumericEditor";

type RenderStringCell = (props: GridCellProps) => React.JSX.Element;

export default function buildAllocationColumnProps(
  showBusinessTypes: boolean,
  commodityTypesGridCellRender: RenderStringCell,
  businessTypesGridCellRender: RenderStringCell,
) {
  const columnConfigs: GridColumnProps[] = [
    { field: "allocationId", title: "Id", width: 70, editable: false },
    { field: "name", title: "Name", width: 150, editor: "text" },
    { field: "1", title: "Jan", width: 70, cell: GridCellNumericEditor, },
    { field: "2", title: "Feb", width: 70, cell: GridCellNumericEditor, },
    { field: "3", title: "Mar", width: 70, cell: GridCellNumericEditor, },
    { field: "4", title: "Apr", width: 70, cell: GridCellNumericEditor, },
    { field: "5", title: "May", width: 70, cell: GridCellNumericEditor, },
    { field: "6", title: "Jun", width: 70, cell: GridCellNumericEditor, },
    { field: "7", title: "Jul", width: 70, cell: GridCellNumericEditor, },
    { field: "8", title: "Aug", width: 70, cell: GridCellNumericEditor, },
    { field: "9", title: "Sep", width: 70, cell: GridCellNumericEditor, },
    { field: "10", title: "Oct", width: 70, cell: GridCellNumericEditor, },
    { field: "11", title: "Nov", width: 70, cell: GridCellNumericEditor, },
    { field: "12", title: "Dec", width: 70, cell: GridCellNumericEditor, },
    {
      field: "commodityTypeObjects",
      title: "Commodities",
      width: 368,
      cell: commodityTypesGridCellRender,
    },
  ];

  if (showBusinessTypes) {
    columnConfigs.push({
      field: "businessTypes",
      title: "Areas",
      width: 161,
      cell: businessTypesGridCellRender,
    });
  }

  return columnConfigs;
};
