import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { Error, Label } from "@progress/kendo-react-labels";
import {
  Upload,
  UploadListItemProps,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { useEffect, useState } from "react";
import companyApi from "../../api/company";
import { useAgreementDocuments } from "../../hooks/useAgreementDocuments";
import { useApi } from "../../hooks/useApi";
import { useUserContext } from "../../hooks/useUserContext";
import { requiredValidator } from "../../validators";
import { LoadingIndicator } from "../LoadingIndicator";
import { FormDropDownList, FormInput, FormSwitch, FormSwitchList, ListItem } from "../form";
import PdfFileItem from "../utilities/PdfFileItem";
import { ViewPdfDocumentDialog } from "../utilities/ViewPdfDocumentDialog";
import { Company } from "./Company";

interface AddEditCompanyFormProps {
  cancelEdit: () => void;
  onSubmit: () => void;
  company: any;
  companies: Company[];
  roles: ListItem[];
  businessTypes: ListItem[];
  parentChildRoles: Record<string, string>;
}

export const AddEditCompanyDialog = (props: AddEditCompanyFormProps) => {
  const { company, companies, roles, businessTypes, parentChildRoles } = props;
  const { context: user } = useUserContext();

  company.roleSelection = roles.find((r) => r.value === company.role);
  const parent = companies.find((c) => c.id === company.parentCompanyId);
  if (parent) company.parentSelection = { id: parent.id, name: parent.name };

  const addCompanyApi = useApi(companyApi.create);
  const updateCompanyApi = useApi(companyApi.update);
  const [isLoading, setIsLoading] = useState(false);
  const [viewPdf, setViewPdf] = useState(false);

  const companyId = company.id;
  const isReady = companyId > 0;
  const [files, setFiles, documents] = useAgreementDocuments(
    isReady,
    companyApi.getAgreementDocuments,
    companyId,
  );

  const filterRoles = (parentRole: string | null) =>
    parentRole
      ? roles.filter((r) => parentChildRoles[parentRole].includes(r.value.toString()))
      : roles;

  const filterCompanies = (role: string): Company[] => {
    const parentRoles = role
      ? Object.keys(parentChildRoles).filter((r) => parentChildRoles[r].includes(role))
      : Object.keys(parentChildRoles);

    return companies.filter((c: Company) => parentRoles.includes(c.role));
  };

  const [parentCompanies, setParentCompanies] = useState<Company[]>(filterCompanies(company.role));

  const onRoleChange = (event: any, form: FormRenderProps) => {
    const role = event.value.value;
    form.onChange("parentSelection", { value: {} });
    setParentCompanies(filterCompanies(role));
  };

  const onAdd = (event: UploadOnAddEvent, form: FormRenderProps) => {
    form.onChange("uploadFile", { value: JSON.stringify(event.newState) });

    setFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent, form: FormRenderProps) => {
    form.onChange("uploadFile", { value: null });
    setFiles(event.newState);
  };

  useEffect(() => {
    if (!addCompanyApi.loading && (addCompanyApi.data || addCompanyApi.error))
      if (!addCompanyApi.error) props.onSubmit();
  }, [addCompanyApi.loading, addCompanyApi.data, addCompanyApi.error]);

  useEffect(() => {
    if (!updateCompanyApi.loading && (updateCompanyApi.data || updateCompanyApi.error))
      if (!updateCompanyApi.error) props.onSubmit();
  }, [updateCompanyApi.loading, updateCompanyApi.data, updateCompanyApi.error]);

  const [pdfData, setPdfData] = useState<{ bytes: any; fileName: string } | null>(null);
  const onOpenPdf = (document: any, fileName: string) => {
    // If document is not of type File, assume it is already the byte array
    setPdfData({ bytes: document, fileName: fileName });
    setViewPdf(true);
  };

  const CustomListItemUI = (props: UploadListItemProps) => {
    return (
      <>
        {props.files.map((file) => (
          <div style={{ display: "flex", justifyContent: "space-between", flexGrow: 1 }}>
            <div style={{ display: "flex" }}>
              <PdfFileItem dataItem={file} onOpenPdf={onOpenPdf} />
            </div>
            <Button
              onClick={() => props.onRemove(file.uid)}
              type="button"
              aria-label="Remove"
              title="Remove"
              icon={"x"}
            />
          </div>
        ))}
      </>
    );
  };

  const handleSubmitEdit = async (event: any) => {
    setIsLoading(true);

    const editCompany = { ...event };
    editCompany.parentCompanyId = event.parentSelection?.id;
    editCompany.role = event.roleSelection.value;
    delete editCompany.parentSelection;
    delete editCompany.roleSelection;

    const uploadedFiles =
      files?.length > 0
        ? files.filter((f) => isNaN(Number(f.uid))).map((f) => f.getRawFile!())
        : null;

    if (editCompany.id === 0) {
      await addCompanyApi.request(editCompany, uploadedFiles);
    } else {
      // Initialize an empty array to store uids of documents to be removed
      const removedDocumentIds: number[] = [];

      // Loop through agreement documents
      for (const doc of documents) {
        // Check if the document uid exists in files
        const existsInFiles = files.some((file) => file.uid === doc.agreementDocumentId.toString());

        // If the document uid doesn't exist in files, add it to removedDocumentIds
        if (!existsInFiles) {
          removedDocumentIds.push(doc.agreementDocumentId);
        }
      }

      await updateCompanyApi.request(editCompany, uploadedFiles, removedDocumentIds);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Dialog
        title={company.id > 0 ? `Edit ${company.name}` : "Add New Company"}
        height="95%"
        onClose={props.cancelEdit}
      >
        <div style={{ minWidth: "400px" }}>
          <Form
            onSubmit={handleSubmitEdit}
            initialValues={company}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <Field
                  label="Role"
                  name="roleSelection"
                  component={FormDropDownList}
                  validator={requiredValidator}
                  data={filterRoles(user.activeCompany.role)}
                  textField="label"
                  dataItemKey="value"
                  onChange={(e) => onRoleChange(e, formRenderProps)}
                />
                <Field
                  label="Parent Company"
                  name="parentSelection"
                  component={FormDropDownList}
                  validator={parentCompanies.length > 0 ? requiredValidator : undefined}
                  data={parentCompanies}
                  disabled={parentCompanies.length === 0}
                  textField="name"
                  dataItemKey="id"
                />
                <Field
                  label="Company Name"
                  name="name"
                  component={FormInput}
                  validator={requiredValidator}
                />
                <Field
                  label="Contact Name"
                  name="contactName"
                  component={FormInput}
                  validator={requiredValidator}
                />

                <Label style={{ paddingTop: "20px" }}>Agreement Documents</Label>
                <Label style={{ fontStyle: "italic" }}>
                  Any documents listed will need to be agreed upon by users of this company.
                </Label>
                <Field
                  name="uploadFile"
                  component={FormInput}
                  style={{ visibility: "hidden", display: "none" }}
                />
                {!isLoading && (
                  <>
                    <Upload
                      id="uploadPdf"
                      batch={false}
                      files={files}
                      multiple={true}
                      onAdd={(e) => onAdd(e, formRenderProps)}
                      onRemove={(e) => onRemove(e, formRenderProps)}
                      withCredentials={false}
                      autoUpload={false}
                      listItemUI={CustomListItemUI}
                      restrictions={{
                        allowedExtensions: [".pdf"],
                      }}
                    />
                  </>
                )}
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "block" }}>
                    <Field
                      label="Active"
                      name="isActive"
                      component={FormSwitch}
                      defaultChecked={company.isActive}
                      size="small"
                    />

                    <Field
                      label="Two Sided Credit"
                      name="canSell"
                      component={FormSwitch}
                      defaultChecked={company.canSell}
                      size="small"
                    />
                  </div>
                  <div>
                    <Field
                      label="Line of Business"
                      name="businessTypes"
                      id="businessTypes"
                      component={FormSwitchList}
                      data={businessTypes}
                      disabled={isLoading}
                      size="small"
                    />
                  </div>
                </div>

                <div>
                  {updateCompanyApi.error && <Error>{updateCompanyApi.error}</Error>}
                  {addCompanyApi.error && <Error>{addCompanyApi.error}</Error>}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "1rem",
                  }}
                >
                  <LoadingIndicator loading={isLoading} />

                  {!isLoading && (
                    <>
                      <Button themeColor="base" disabled={isLoading} onClick={props.cancelEdit}>
                        Cancel
                      </Button>

                      <Button
                        themeColor="success"
                        type="submit"
                        disabled={!formRenderProps.allowSubmit}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </FormElement>
            )}
          ></Form>
        </div>
      </Dialog>
      {viewPdf && pdfData && (
        <ViewPdfDocumentDialog
          document={pdfData.bytes}
          onClose={() => setViewPdf(false)}
          fileName={pdfData.fileName}
        />
      )}
    </>
  );
};
