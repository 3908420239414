import { NumericTextBox } from "@progress/kendo-react-inputs";
import { useInternationalization } from "@progress/kendo-react-intl";
import { useRef } from "react";
import { Popup } from "@progress/kendo-react-popup";
import { Error } from "@progress/kendo-react-labels";
import { CustomGridCell } from "../../grid";

export const NumberEditGridCell = (props: any) => {
  const { dataItem, field, onChange } = props;
  const value: number = dataItem[field];
  const validMessage = dataItem[props.field + "Valid"];
  const defaultFormat = "n6";
  const format = dataItem.format || defaultFormat;
  const formatter = useInternationalization();

  const anchor = useRef(null);
  const className = validMessage ? " k-input-solid k-invalid" : "";
  const style = { paddingLeft: 0 };

  const handleChange = (e: any) => {
    if (onChange) {
      onChange({
        dataIndex: 0,
        dataItem: dataItem,
        field: field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  const handleKeyDown = (e: any) => {
    // This is a hack because the grid's onKeyDown event isn't useful
    if (onChange) {
        const value = (e.altKey ? "alt+" : e.ctrlKey ? "ctrl+" : e.shiftKey ? "shift+" : "") + e.key;

        onChange({
            dataIndex: 0,
            dataItem: dataItem,
            field: field,
            syntheticEvent: e,
            value: value,
        });
    }
  };

  const tbd = "Constant"; //TODO: pass as prop

  const canEdit = dataItem.isEditing &&
    !dataItem.isAggregate && 
    ((dataItem.hasTermStructure && field !== tbd) || (!dataItem.hasTermStructure && field === tbd));

  return (
    <CustomGridCell {...props} style={style} id={dataItem.id}>
      <div ref={anchor}>
        {canEdit ? (
          <NumericTextBox
            className={className}
            format={defaultFormat}
            spinners={false}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <span>{formatter.formatNumber(value, format)}</span>
        )}
      </div>

      {validMessage && (
        <Popup anchor={anchor.current} show={true} popupClass={"popup-content"}>
          <Error style={{ fontSize: "smaller" }}>{validMessage}</Error>
        </Popup>
      )}
    </CustomGridCell>
  );
};
