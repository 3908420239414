import { apiClient } from "./apiClient";

const controller = "documents";

const getQuoteSummaryPdf = (quoteId: number, pricingId: number) => {
  const params = `?quoteId=${quoteId}&pricingId=${pricingId}&documentType=quoteSummary&returnWordDoc=false`;

  return apiClient(controller).fetchPdf(params);
}

export default { getQuoteSummaryPdf }
