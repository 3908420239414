import { GridCellProps } from "@progress/kendo-react-grid";
import DateUtility from "../../utilities/dateUtilities";
import { CustomGridCell } from "./CustomGridCell";

export const DateGridCell = (props: GridCellProps) => {
  const field = props.field || "";
  const value = props.dataItem[field];

  return <CustomGridCell {...props}>{DateUtility.formatDateGeneral(value)}</CustomGridCell>;
};
