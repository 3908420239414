import { useCallback, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { Error, Label } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { FormNumericTextBox } from "../../form";
import { LoadingIndicator } from "../../LoadingIndicator";
import { requiredValidator } from "../../../validators";
import accumulatorApi from "../../../api/accumulator";
import contractsApi from "../../../api/contracts";
import { IApiResults, useApi, useFetch } from "../../../hooks/useApi";
import DateUtility from "../../../utilities/dateUtilities";
import { PricingHistoryDialog, QuoteExpiry } from "..";
import { AccumulatorQuoteCard } from ".";
import { Slider } from "../../slider/Slider";
import MathUtility from "../../../utilities/mathUtilities";

interface IAccumulatorQuoteProps {
  formRenderProps: FormRenderProps;
  product: any;
  lookups: any;
  values: any;
  marketStatsResults: IApiResults;
}

export const AccumulatorQuoteStep = ({
  formRenderProps,
  product,
  lookups,
  values,
  marketStatsResults,
}: IAccumulatorQuoteProps) => {
  const { commodity, instrument } = product;

  const saveDetailsApi = useApi(accumulatorApi.saveQuoteDetails);
  const getQuoteApi = useApi(accumulatorApi.getQuote);

  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const [quoteId, setQuoteId] = useState(formRenderProps.valueGetter("quoteId"));
  const [expiration, setExpiration] = useState(new Date());
  const [quote, setQuote] = useState<any | null>(null);

  const [fields, setFields] = useState<any[]>([]);
  const [underlying, setUnderlying] = useState(0);
  const [knockOutLevel, setKnockOutLevel] = useState(
    formRenderProps.valueGetter("knockOutLevel") || 0
  );
  const [strike, setStrike] = useState(
    formRenderProps.valueGetter("strike") || 0
  );
  const [sliderValues, setSliderValues] = useState<number[]>([]);
  const [ticks, setTicks] = useState<number[]>([]);

  const instrumentType = lookups?.types.find(
    (d: any) => d.value === values.instrumentTypeId
  );
  const isProducer = instrumentType?.text === "Producer";

  const contract = DateUtility.formatDateYMD(
    formRenderProps.valueGetter("contract")
  );
  const { data: contractsData, error: contractsError } = useFetch(
    contractsApi.getIntraDayPrices,
    {
      tickerCode: commodity.abbreviation,
      instrumentType: instrument.id,
      startDate: contract,
      endDate: contract,
    }
  );

  useEffect(() => {
    if (!contractsData?.prices) return;

    const underlying = MathUtility.round(contractsData.prices[contract]);
    setUnderlying(underlying);

    let sliderTicks: number[] = [];
    for (let i = 0; i < underlying * 0.2; i += 0.1) {
      sliderTicks.push(MathUtility.round(underlying + i, 2));
      if (i > 0) sliderTicks.push(MathUtility.round(underlying - i, 2));
    }
    setTicks(sliderTicks);

    let margin = 0.01;
    const factor = isProducer ? 1 : -1;
    const koLimit = underlying - factor * margin;
    const stLimit = underlying + factor * margin;

    margin = underlying * 0.2;
    const knockOutField = {
      field: "knockOutLevel",
      label: "Knock Out Level",
      min: isProducer ? underlying - margin : koLimit,
      max: isProducer ? koLimit : underlying + margin,
      onChange: (value: number) => {
        if (value !== knockOutLevel) {
          setKnockOutLevel(value);
          saveQuoteDetails(value, strike);
        }
      }
    };
    const strikeField = {
      field: "strike",
      label: "Strike",
      min: isProducer ? stLimit : underlying - margin,
      max: isProducer ? underlying + margin : stLimit,
      onChange: (value: number) => {
        if (value !== strike) {
          setStrike(value);
          saveQuoteDetails(knockOutLevel, value);
        }
      }
    };
    setFields(
      isProducer ? [knockOutField, strikeField] : [strikeField, knockOutField]
    );

    const ko =
      knockOutLevel > knockOutField.max ||
      knockOutLevel < knockOutField.min
        ? MathUtility.round(koLimit - factor * 0.1, 2)
        : knockOutLevel;
    setKnockOutLevel(ko);

    const st = strike > strikeField.max || strike < strikeField.min
        ? MathUtility.round(stLimit + factor * 0.1, 2)
        : strike;
    setStrike(st);

    saveQuoteDetails(ko, st);
  }, [contractsData]);

  useEffect(() => {
    formRenderProps.onChange("knockOutLevel", { value: knockOutLevel });
    formRenderProps.onChange("strike", { value: strike });
    setSliderValues([knockOutLevel, strike]);
  }, [knockOutLevel, strike]);

  useEffect(() => {
    if (saveDetailsApi.data?.quoteId) {
      const value = saveDetailsApi.data?.quoteId;
      formRenderProps.onChange("quoteId", { value: value });
      setQuoteId(value);
      getQuote(value);
    }
  }, [saveDetailsApi.data]);

  useEffect(() => {
    if (!getQuoteApi.data) return;

    const { quoteExpiry, quote } = getQuoteApi.data;
    if (quoteExpiry) setExpiration(new Date(Date.parse(quoteExpiry)));
    if (quote) {
      setQuote(quote);
      formRenderProps.onChange("quote", { value: quote });
    }
  }, [getQuoteApi.data]);

  useEffect(() => {
    const isLoading = saveDetailsApi.loading || getQuoteApi.loading;
    setLoading(isLoading);
    formRenderProps.onChange("loaded", { value: !isLoading });
  }, [saveDetailsApi.loading, getQuoteApi.loading]);

  useEffect(() => {
    // If there's an error, clear the expiry to force an invalid form => disable Next
    if (saveDetailsApi.error || getQuoteApi.error)
      onExpiryChange("");
  }, [saveDetailsApi.error, getQuoteApi.error]);  

  const saveQuoteDetails = useCallback(
    async (ko?: number, st?: number) => {
      const saveDetails = {
        companyId: product.company.id,
        configurationId: formRenderProps.valueGetter("configurationId"),
        quoteId: quoteId,
        quoteName: formRenderProps.valueGetter("quoteName"),
        contractDate: contract,
        structureId: 21, //TODO
        settlementTypeId: values.settlementTypeId,
        doubleUpTypeId: values.doubleUpTypeId,
        instrumentTypeId: values.instrumentTypeId,
        knockOutTypeId: values.knockOutTypeId,
        volume: formRenderProps.valueGetter("volume"),
        knockOutLevel: ko || knockOutLevel,
        strike: st || strike,
      };

      saveDetailsApi.request(saveDetails);
    },
    [quoteId, contract, knockOutLevel, strike, saveDetailsApi]
  );

  const getQuote = useCallback(async (quoteId: number) => {
    if (quoteId) getQuoteApi.request(quoteId);
  }, []);

  const onExpiryChange = (expiry: string) => {
    formRenderProps.onChange("expiry", { value: expiry });
  };

  const onSliderChange = (newValues: number[]) => {
    const ko = MathUtility.round(newValues[0], 2);
    const st = MathUtility.round(newValues[1], 2);
    saveQuoteDetails(ko, st);
  };

  const onSliderDrag = (newValues: number[]) => {
    const ko = MathUtility.round(newValues[0], 2);
    const koField = isProducer ? fields[0] : fields[1];
    if (ko <= koField.max && ko >= koField.min) setKnockOutLevel(ko);

    const st = MathUtility.round(newValues[1], 2);
    const stField = isProducer ? fields[1] : fields[0];
    if (st <= stField.max && st >= stField.min) setStrike(st);
  };

  return (
    <div className="stepper-form">
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flexBasis: "35%" }}>
          <div
            style={{
              display: "flex",
              alignContent: "start",
              justifyContent: "space-around",
            }}
          >
            {fields.map((t) => (
              <Field
                key={t.field}
                id={t.field}
                name={t.field}
                label={t.label}
                component={FormNumericTextBox}
                spinners={false}
                validator={requiredValidator}
                format={"n2"}
                min={t.min}
                max={t.max}
                onBlurOverride={(e: any) => t.onChange(e.target.value)}
                style={{ maxWidth: 150 }}
              />
            ))}
          </div>
        </div>
        <div style={{ flexBasis: "50%" }}>
          <div>
            <Label>Adjust Values</Label>
            <Slider
              options={{
                name: "levelSlider",
                values: sliderValues,
                min: MathUtility.round(underlying * 0.8, 2),
                max: MathUtility.round(underlying * 1.2, 2),
                staticValues: [underlying],
                staticLabels: ["U"],
                stepSize: 0.01,
                ticks: ticks,
              }}
              onChange={onSliderChange}
              onDrag={onSliderDrag}
            />
          </div>
        </div>

        <div>
          <div className="utility-button-container">
            <Tooltip
              anchorElement="target"
              position="bottom"
              parentTitle={true}
            >
              <Button
                size="small"
                icon="refresh"
                themeColor="primary"
                title="Refresh"
                onClick={(e: any) => {
                  e.preventDefault();
                  getQuote(quoteId);
                }}
                disabled={loading}
                style={{ marginLeft: "5px" }}
              />
              <Button
                togglable={true}
                size="small"
                icon="align-bottom-element"
                themeColor="primary"
                title="View Chart"
                onClick={(e: any) => {
                  e.preventDefault();
                  setShowChart(true);
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <Error>{contractsError}</Error>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "1rem",
        }}
      >
        <div style={{ flexBasis: "100%" }}>
          <LoadingIndicator loading={loading} />
          {saveDetailsApi.error && <Error>{saveDetailsApi.error}</Error>}
          {getQuoteApi.error && <Error>{getQuoteApi.error}</Error>}

          {!loading && quote && (
            <AccumulatorQuoteCard
              title="Quote Details"
              quote={quote}
              commodity={commodity}
              chartData={{
                knockOutLevel,
                strike,
                underlying,
                marketStatsResults,
              }}
            />
          )}
        </div>
      </div>

      <div style={{ paddingTop: 15 }}>
        <QuoteExpiry
          expiration={expiration}
          loading={loading}
          onExpire={onExpiryChange}
          onRefresh={saveQuoteDetails}
        />
      </div>

      {showChart && (
        <PricingHistoryDialog
          commodityName={commodity.name}
          apiResults={marketStatsResults}
          onCancel={() => setShowChart(false)}
        />
      )}
    </div>
  );
};
