import { SelectionRange } from "@progress/kendo-react-dateinputs";

const requiredMessage = "This field is required.";
export const requiredValidator = (value: string) => {
  return !value ? requiredMessage : "";
};

export const requiredListValidator = (value: string[] | number[]) => {
  return (value?.length ?? 0) === 0 ? requiredMessage : "";
};

export const requiredDateRangePickerValidator = (value: SelectionRange) => {
  const isStartDateSelected = !!value?.start;
  const isEndDateSelected = !!value?.end;

  const isDateRangeSelected = isStartDateSelected && isEndDateSelected;

  let validationMessage = "";
  if (!isDateRangeSelected) {
    validationMessage = "Please select start and end dates";
  }
  return validationMessage;
};

export const requiredCheckboxValidator = (value: boolean) => {
  const isValid = value === true;
  const returnValue = isValid ? "" : requiredMessage;
  return returnValue;
};

const emailRegex = new RegExp(/^\w+([.-]?\w+)+(\+\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
export const emailValidator = (value: string) =>
  emailRegex.test(value) ? "" : "Please enter a valid email.";

const phoneRegex: RegExp = new RegExp(/^[0-9 ()+-]+$/);
export const phoneValidator = (value: string) =>
  value ? (phoneRegex.test(value) ? "" : "Please enter a valid phone number.") : "";
