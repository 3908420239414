import { Card, CardActions, CardBody, CardHeader, CardTitle } from "@progress/kendo-react-layout";
import { PropsWithChildren } from "react";
import DateUtility from "../../../utilities/dateUtilities";
import QuoteDetailsButtons from "./QuoteDetailsButtons";

type Props = {
  quote: any;
  onReject: () => void;
  prices?: any[];
  getQuoteDetails: any;
  totalVolume: number;
  unitPriceDecimals?: number;
  allowOverride?: boolean;
  hideActions?: boolean;
  isSwap?: boolean;
};

export const QuoteSummaryCard = ({
  quote,
  prices = [],
  onReject,
  children,
  getQuoteDetails,
  totalVolume,
  unitPriceDecimals,
  allowOverride = false,
  hideActions,
  isSwap = false,
}: PropsWithChildren<Props>) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle style={{ fontSize: "18px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Status: {quote.status}</span>
            <span>Quote Time: {DateUtility.formatDateTime(quote.quoteDate)}</span>
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody style={{ fontSize: "smaller", padding: "1rem 0.1rem" }}>{children}</CardBody>
      {!hideActions && (
        <CardActions style={{ justifyContent: "center" }}>
          <QuoteDetailsButtons
            quote={quote}
            prices={prices}
            getQuoteDetails={getQuoteDetails}
            onReject={onReject}
            totalVolume={totalVolume}
            unitPriceDecimals={unitPriceDecimals}
            allowOverride={allowOverride}
            isSwap={isSwap}
          />
        </CardActions>
      )}
    </Card>
  );
};
