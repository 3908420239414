import { GridCellProps, GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { PropsWithChildren } from "react";

export const CustomGridCell = ({
    ariaColumnIndex,
    columnIndex,
    colSpan,
    isSelected,
    style,
    children,
  }: PropsWithChildren<GridCellProps>) => {
    return (
      <td
        role="gridcell"
        colSpan={colSpan}
        aria-colindex={ariaColumnIndex}
        aria-selected={isSelected}
        className="k-table-td"
        style={style}
        {...{ [GRID_COL_INDEX_ATTRIBUTE]: columnIndex }}
      >
        {children}
      </td>
    );
  };