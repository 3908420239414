import { Field, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { useEffect, useState } from "react";
import { requiredValidator } from "../../../validators";
import { FormNumericInput } from "../../form";
import { Commodity } from "../../quotes";
import { ConfigurationsFilters, IProductFilters } from ".";
import { MarginItem } from "./MarginItem";

interface IMarginSelectionStepProps {
  formRenderProps: FormRenderProps;
  businessTypes: Record<number, string>;
  commodities: Commodity[];
  commodityTypes: Record<string, string>;
  margins: any[];
}

export const MarginSelectionStep = ({
  formRenderProps,
  businessTypes,
  commodities,
  commodityTypes,
  margins,
}: IMarginSelectionStepProps) => {
  const filters = formRenderProps.valueGetter("marginFilters");
  const [selectedMargin, setSelectedMargin] = useState<number | undefined>(
    formRenderProps.valueGetter("margin"),
  );
  const [marginList, setMarginList] = useState<any[]>([]);

  useEffect(() => {
    if (!filters || !margins) return;

    const {
      commodities: selectedCommodities,
      businessType: selectedBusinessType,
      text: filterText,
    } = filters;

    const ids = selectedCommodities
      ? commodities.filter((c) => selectedCommodities.includes(c.type)).map((c) => c.id)
      : [];

    const filteredList = margins
      .filter((m: any) => !filterText || m.longName.toLowerCase().includes(filterText.toLowerCase()))
      .filter((m: any) => !selectedBusinessType || m.businessTypeId === selectedBusinessType)
      .filter((m: any) => !m.commodityId || ids.length === 0 || ids.includes(m.commodityId))
      .sort(
        (a: any, b: any) => a.commodityId - b.commodityId || a.longName.localeCompare(b.longName),
      );

    setMarginList(filteredList);

    if (!filteredList.find((m) => m.marginId === selectedMargin)) 
      setSelectedMargin(undefined);
  }, [margins, filters]);

  useEffect(() => {
    formRenderProps.onChange("margin", { value: selectedMargin });
  }, [selectedMargin]);

  const handleChangeFilters = (filters: IProductFilters) => {
    if (JSON.stringify(filters) !== JSON.stringify(formRenderProps.valueGetter("marginFilters")))
      formRenderProps.onChange("marginFilters", { value: filters });
  };

  return (
    <div className="stepper-form">
      <div>Select a Margin:</div>
      <div style={{ margin: "1rem 0" }}>
        <ConfigurationsFilters
          businessTypes={businessTypes}
          commodityTypes={commodityTypes}
          initialSelections={filters}
          onChangeFilters={handleChangeFilters}
        />
      </div>

      <div className="card-container grid">
        {marginList.map((margin, index) => (
          <MarginItem
            key={index}
            margin={margin}
            commodities={commodities}
            selected={selectedMargin}
            onClick={setSelectedMargin}
          />
        ))}

        {margins.length === 0 && <div>There are no margins that can be acted upon.</div>}
      </div>

      <FormElement className="hidden">
        <Field
          name="margin"
          id="margin"
          component={FormNumericInput}
          validator={requiredValidator}
          data={selectedMargin}
        />
      </FormElement>
    </div>
  );
};
