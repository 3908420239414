import { apiClient } from "./apiClient";

const endpoint = "insurance";

const getRetentionLevels = () => {
  return apiClient(`${endpoint}/levels`).fetch();
}

const getPricing = (data: any) => {
  return apiClient(`${endpoint}/pricing`).post(data);
}

const getQuoteDetails = (quoteId: number) => {
  return apiClient(`${endpoint}/quote`).fetchWithParams(`?quoteId=${quoteId}`);
}

const saveQuoteDetails = (data: any) => {
  return apiClient(`${endpoint}/quote`).post(data);
}

const getQuote = (quoteId: number) => {
  return apiClient(`${endpoint}/quote`).put({quoteId: quoteId});
}


export default {
  getRetentionLevels,
  getPricing,
  getQuoteDetails,
  saveQuoteDetails,
  getQuote
};
