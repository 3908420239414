import { Error } from "@progress/kendo-react-labels";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";

export const FormInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, label, id, valid, disabled, showValidationMessage, ...others } =
    fieldRenderProps;

  return (
    <FieldWrapper>
      <Input
        id={id}
        label={label}
        valid={valid}
        disabled={disabled}
        {...others}
      />

      {visited && validationMessage && showValidationMessage && (
         <Error>{validationMessage}</Error>
      )}
    </FieldWrapper>
  );
};
