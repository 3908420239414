import {
  TileLayout,
  TileLayoutItem,
  TileLayoutRepositionEvent,
} from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { useApi } from "../../hooks/useApi";
import quotesApi from "../../api/quotes";
import {
  ActiveUsersChart,
  QuotesHitRateChart,
  QuotesStatusFunnelChart,
  QuotesStatusHeatMapChart,
} from ".";
import CanopyDateRangePicker from "../functional/CanopyDateRangePicker";
import { addDays } from "@progress/kendo-date-math";
import {
  DateRangePickerChangeEvent,
  SelectionRange,
} from "@progress/kendo-react-dateinputs";
import DateUtility from "../../utilities/dateUtilities";
import { useUserContext } from "../../hooks/useUserContext";
import { QuoteStatus } from "../../types/quoteStatus";

export const Analytics = () => {
  const getQuotesApi = useApi(quotesApi.getStatusSummary);
  const { context: user } = useUserContext();

  const today = new Date();
  const defaultDates = { start: addDays(today, -14), end: today };
  const [dates, setDates] = useState<SelectionRange>(defaultDates);
  const [dateDisplay, setDateDisplay] = useState("");
  const [quotesStatus, setQuotesStatus] = useState<any[]>([]);

  useEffect(() => {
    getQuotesApi.request(dates);
    setDateDisplay(
      `${DateUtility.formatShortDate(
        dates.start || ""
      )} to ${DateUtility.formatShortDate(dates.end || "")}`
    );
  }, [dates]);

  useEffect(() => {
    if (getQuotesApi.data) {
      setQuotesStatus(
        Object.keys(getQuotesApi.data.quotesStatus).map((key) => {
          return { status: key, count: getQuotesApi.data.quotesStatus[key] };
        })
      );
    }
  }, [getQuotesApi.data]);

  const onDateChange = ({ value }: DateRangePickerChangeEvent) => {
    setDates(value);
  };


  let tileData: any[] = [
    { row: 1, col: 1, colSpan: 3 },
    { row: 1, col: 4, colSpan: 2 },
    { row: 1, col: 6, colSpan: 2 },
    { row: 1, col: 8, colSpan: 2 }
  ];

  const handleReposition = (e: TileLayoutRepositionEvent) => {
    tileData = e.value;
  };

  let tiles: TileLayoutItem[] = [
    {
      style: { background: "transparent", border: "none" },
      item: (
        <CanopyDateRangePicker
          max={today}
          value={dates}
          startDateInputSettings={{ label: "Date Range" }}
          endDateInputSettings={{ label: "" }}
          onChange={onDateChange}
        />
      ),
    },
    {
      item: (
        <div>
          <div className="dashboard-card-content">
            <h5>Overdue Tasks</h5>
            <p className="dashboard-card-content-number red">
              {quotesStatus.find((q) => q.status === QuoteStatus.Pending)?.count || 0}
            </p>
            <span className="footer">Quotes Awaiting Review</span>
          </div>
        </div>
      ),
      reorderable: false,
    },
    {
      item: (
        <div>
          <div className="dashboard-card-content">
            <h5>Rejected</h5>
            <p className="dashboard-card-content-number orange">
              {quotesStatus.find((q) => q.status === QuoteStatus.Rejected)?.count || 0}
            </p>
            <span className="footer">{dateDisplay}</span>
          </div>
        </div>
      ),
      reorderable: false,
    },
    {
      item: (
        <div>
          <div className="dashboard-card-content">
            <h5>Approved</h5>
            <p className="dashboard-card-content-number green">
              {quotesStatus.find((q) => q.status === QuoteStatus.Approved)?.count || 0}
            </p>
            <span className="footer">{dateDisplay}</span>
          </div>
        </div>
      ),
      reorderable: false,
    },
  ];

  if (user.permissions.canAdminUsers) {
    tileData.push({ row: 1, col: 10, colSpan: 2 });

    tiles.push({
      item: (
        <div className="dashboard-card-content">
          <h5>Active Users</h5>
          <ActiveUsersChart />
        </div>
      ),
    });
  }

  if (user.roles.isRiskTaker || user.roles.isBroker) {
    tileData = tileData.concat([
      { row: 2, col: 1, colSpan: 3, rowSpan: 2 },
      { row: 2, col: 4, colSpan: 5, rowSpan: 2 },
      { row: 2, col: 9, colSpan: 4, rowSpan: 2 }
    ]);

    tiles = tiles.concat([
      {
        item: (
          <div className="dashboard-card-content">
            <h5>Quotes Status</h5>
            <span className="footer">{dateDisplay}</span>
            <QuotesStatusFunnelChart data={quotesStatus} />
          </div>
        ),
        reorderable: false,
      },
      {
        item: (
          <div className="dashboard-card-content">
            <h5>Quotes Status</h5>
            <span className="footer">{dateDisplay}</span>
            <QuotesStatusHeatMapChart dates={dates} />
          </div>
        ),
        reorderable: false,
      },
      {
        item: (
          <div className="dashboard-card-content">
            <h5>Open Quotes: Hit Rate</h5>
            <QuotesHitRateChart />
          </div>
        ),
        reorderable: false,
      },
    ]);
  }

  return (
    <TileLayout
      columns={12}
      positions={tileData}
      items={tiles}
      onReposition={handleReposition}
      dataItemKey={"id"}
      rowHeight={150}
    />
  );
};
