import { useMsal } from "@azure/msal-react";
import { Navigate, useLocation } from "react-router-dom";
import { loginApiRequest } from "../config/authConfig";

export const Login = () => {
  const { instance } = useMsal();
  const { pathname } = useLocation();

  const accounts = instance?.getAllAccounts();
  const activeAccount = accounts?.length > 0 ? 
    instance.getActiveAccount() || accounts[0] : undefined;

  loginApiRequest.redirectStartPage = pathname;

  if (!activeAccount) {
    instance
      .loginRedirect(loginApiRequest)
      .catch((error) => console.log(error));
  }

  return activeAccount ? <Navigate to={pathname === "/login" ? "/" : pathname} /> : <></>;
};
