import { useEffect, useRef, useState } from "react";
import { Button, ChipList } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridCellProps, GridColumn, GridItemChangeEvent } from "@progress/kendo-react-grid";
import { Label } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import marginsApi from "../../../api/margins";
import { useApi } from "../../../hooks/useApi";
import DateUtility from "../../../utilities/dateUtilities";
import {
  averageByProperty,
  maxByProperty,
  minByProperty,
} from "../../../utilities/objectUtilities";
import { CustomGridCell } from "../../grid";
import { LoadingIndicator } from "../../LoadingIndicator";
import { BetaValue, BetaValueType } from "./BetaValue";
import { NumberEditGridCell } from ".";

interface IBetaValuesEditorProps {
  marginId: number;
  disabled: boolean;
  onDataUpdate?: (data: BetaValue[]) => void;
}

export const BetaValuesEditor = ({ marginId, disabled, onDataUpdate }: IBetaValuesEditorProps) => {
  const getBetaCubesApi = useApi(marginsApi.getBetaCubes);
  const [loading, setLoading] = useState(false);
  const [displayField, setDisplayField] = useState(BetaValueType.TwoWay);
  const [betas, setBetas] = useState<Record<number, any>>({});
  const [updatedData, setUpdatedData] = useState<BetaValue[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [gridData, setGridData] = useState<any[]>([]);
  const [editId, setEditId] = useState<number>();

  const [betasToAdd, setBetasToAdd] = useState<any[]>([]);
  const [selectedBeta, setSelectedBeta] = useState<any>();

  const _export: any = useRef(null);
  const _grid: any = useRef();
  const singleFeeColumnHeader = "Constant";

  useEffect(() => {
    setEditId(0);
    getBetaCubesApi.request(marginId);
  }, [marginId]);

  useEffect(() => {
    setLoading(getBetaCubesApi.loading);
    setUpdatedData(getBetaCubesApi.data?.betaCubes ?? []);

    setBetas(
      (getBetaCubesApi.data?.betas ?? []).reduce(
        (acc: any, { betaId, ...others }: any) => {
          acc[betaId] = { ...others };
          return acc;
        },
        {} as Record<number, { name: string; hasTermStructure: boolean; units: string }>,
      ),
    );
  }, [getBetaCubesApi.loading, getBetaCubesApi.data]);

  useEffect(() => {
    if (onDataUpdate) onDataUpdate(updatedData);
  }, [updatedData]);

  useEffect(() => {
    const grouped = updatedData.reduce<{
      [key: string]: { [key: string]: number | string | boolean };
    }>((acc, item: BetaValue) => {
      const id = item.betaId;
      const beta = betas[id];

      if (!acc[id])
        acc[id] = {
          id,
          ...beta,
          isEditing: id === editId,
        };

      const dateField = item.contractMonth || singleFeeColumnHeader;
      acc[id][dateField] = item[displayField];
      return acc;
    }, {});

    const items = Object.values(grouped)
      .sort((a, b) => (a.name > b.name ? -1 : 1))
      .sort((a, b) => (a.hasTermStructure ? (b.hasTermStructure ? 0 : 1) : -1));

    const cols: string[] = Array.from(
      items.reduce<Set<string>>((acc, obj) => {
        Object.keys(obj).forEach((key) => acc.add(key));
        return acc;
      }, new Set<string>()),
    )
      .sort((a, b) => a.localeCompare(b))
      .slice(0, -7); // Trim off properties that we're not displaying

    cols.unshift(singleFeeColumnHeader); // Put the Constant column first

    // HIDING FOR NOW. Will show again once the data grid is transposed properly.
    // const averages: any = {},
    //   minima: any = {},
    //   maxima: any = {};
    // cols.forEach((c) => {
    //   const validItems = items.filter((i) => i[c] != null);
    //   averages[c] = averageByProperty(validItems, c);
    //   minima[c] = minByProperty(validItems, c);
    //   maxima[c] = maxByProperty(validItems, c);
    // });

    // items.push(Object.assign({ name: "Minimum", isAggregate: true, ...minima }, { format: null }));
    // items.push(
    //   Object.assign({ name: "Average", isAggregate: true, ...averages }, { format: null }),
    // );
    // items.push(Object.assign({ name: "Maximum", isAggregate: true, ...maxima }, { format: null }));

    setColumns(cols);
    setGridData(items);
  }, [updatedData, displayField, editId]);

  const betaTypeList = [
    { text: "Bid", value: BetaValueType.Bid },
    { text: "Ask", value: BetaValueType.Ask },
    { text: "Two-Way", value: BetaValueType.TwoWay },
  ];

  const handleItemChange = ({ dataItem, field, value, syntheticEvent }: GridItemChangeEvent) => {
    if (syntheticEvent?.type === "keydown" && typeof value === "string") {
      // This is a hack because the grid's onKeyDown event isn't useful
      processKeyDown(dataItem, field, value?.toLowerCase() || "");
      return;
    }
    if (!field) return;

    const numValue = parseFloat(value) || null;
    const validMessage = valueValidator(numValue);

    const itemCopy = { ...dataItem };
    if (validMessage) itemCopy[field + "Valid"] = validMessage;
    else delete itemCopy[field + "Valid"];

    let dataCopy = [...gridData];
    const index = dataCopy.findIndex((d) => d.id === dataItem.id);
    if (index >= 0) {
      dataCopy[index] = itemCopy;
      setGridData(dataCopy);
    }

    if (!validMessage) {
      const newData: BetaValue[] = updatedData.map((item: BetaValue) => {
        return item.betaId === dataItem.id &&
          (item.contractMonth === field ||
            (!item.contractMonth && field === singleFeeColumnHeader))
          ? { ...item, [displayField]: numValue, isModified: true }
          : item;
      });

      setUpdatedData(newData);
    }
  };

  const processKeyDown = (dataItem: any, field: string | undefined, value: string) => {
    if (value === "alt+enter" && field) {
      const index = columns.findIndex((c) => c === field);
      const validMessage = valueValidator(dataItem[field]);

      if (index >= 0 && !validMessage) {
        columns.forEach((c, i) => {
          if (i > index) dataItem[c] = dataItem[field];
        });

        const newData: BetaValue[] = updatedData.map((item: BetaValue) =>
          item.betaId === dataItem.id
            ? { ...item, [displayField]: dataItem[item.contractMonth], isModified: true }
            : item,
        );
        setUpdatedData(newData);
      }
    } else if (value === "enter") {
      const index = gridData.findIndex((d) => d.id === dataItem.id);
      const newId = index < gridData.length - 4 ? gridData[index + 1].id : 0;
      setEditId(newId);
    } else if (value === "shift+enter") {
      const index = gridData.findIndex((d) => d.id === dataItem.id);
      const newId = index > 0 ? gridData[index - 1].id : 0;
      setEditId(newId);
    }
  };

  const RowHeaderCell = (props: GridCellProps) => {
    const { field, dataItem } = props;
    const value: string = dataItem[field || ""];
    const units: string = dataItem.units;

    const cellStyle = dataItem.isAggregate
      ? {
          border: "none",
          display: "flex",
          fontSize: "smaller",
          fontStyle: "italic",
          justifyContent: "end",
        }
      : {};

    return (
      <CustomGridCell {...props} style={cellStyle}>
        <span>
          {value} {units ? `(${units})` : ""}
        </span>
      </CustomGridCell>
    );
  };

  const valueValidator = (value: number | null) => (value != null && value < 0 ? "Must be non-negative" : "");

  const onClickAddRow = () => {
    const ids = gridData.map((g) => g.id);

    const betaList = Object.keys(betas)
      .map((str) => parseInt(str))
      .filter((k) => !ids.includes(k))
      .map((k) => ({ id: k, value: betas[k] }))
      .sort((a, b) => a.value.name.localeCompare(b.value.name));

    setBetasToAdd(betaList);
  };

  const addNewBeta = () => {
    const dataCopy = [...updatedData];

    if (selectedBeta.hasTermStructure) {
    } else {
      dataCopy.push({
        betaCubeId: 0,
        betaId: selectedBeta.id,
        contractMonth: "",
        bidValue: 0,
        askValue: 0,
        twoWayValue: 0,
        isModified: true
      });
    }

    setUpdatedData(dataCopy);
    setBetasToAdd([]);
    setSelectedBeta("");
  };

  const excelExport = () => {
    if (_export.current && _grid.current)
      _export.current.save(gridData.slice(0, -3), _grid.current.columns);
  };

  return (
    <div>
      <LoadingIndicator loading={loading} />

      {!loading && (
        <>
          <ExcelExport ref={_export} />

          <Tooltip anchorElement="target" parentTitle={true}>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "0.5rem" }}>
              <div>
                <Button
                  fillMode="outline"
                  iconClass="k-icon k-i-plus"
                  size="small"
                  themeColor="primary"
                  title="Add Row"
                  onClick={onClickAddRow}
                />

                <Button
                  fillMode="outline"
                  iconClass="k-icon k-i-download"
                  size="small"
                  themeColor="primary"
                  title="Export"
                  onClick={excelExport}
                />

                {/* <Button
                  fillMode="outline"
                  iconClass="k-icon k-i-upload"
                  size="small"
                  themeColor="primary"
                  title="Import"
                  onClick={}
                /> */}
              </div>

              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <Label>Type</Label>
                <ChipList
                  data={betaTypeList}
                  selection={"single"}
                  value={displayField}
                  disabled={disabled}
                  onChange={(e) => setDisplayField(e.value)}
                />
              </div>
            </div>
          </Tooltip>

          {gridData.length && columns.length ? (
            <Grid
              ref={_grid}
              data={gridData}
              editField="isEditing"
              onItemChange={handleItemChange}
              onRowClick={(e) => setEditId(e.dataItem.id)}
            >
              <GridColumn
                field="name"
                title="Fee Type"
                cell={RowHeaderCell}
                width={200}
                editable={false}
              />

              {columns.map((c, i) => (
                <GridColumn
                  key={i + 1}
                  field={c}
                  width={150}
                  title={DateUtility.formatDateMY(c)}
                  editable={!disabled}
                  cell={NumberEditGridCell}
                />
              ))}
            </Grid>
          ) : (
            <div>No margin values to display</div>
          )}
        </>
      )}

      {betasToAdd.length > 0 && (
        <Dialog title={"Add Row"} onClose={() => setBetasToAdd([])}>
          <Label style={{ paddingRight: 5 }}>Fee Type</Label>
          <DropDownList
            data={betasToAdd}
            textField="value.name"
            dataItemKey="id"
            style={{ width: 350 }}
            onChange={(e) => setSelectedBeta(e.target.value)}
          />

          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              gap: 10,
              marginTop: 5,
            }}
          >
            <Button size="small" onClick={() => setBetasToAdd([])}>
              Cancel
            </Button>
            <Button size="small" themeColor="primary" disabled={!selectedBeta} onClick={addNewBeta}>
              Add
            </Button>
          </div>
        </Dialog>
      )}
    </div>
  );
};
