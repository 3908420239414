import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import usersApi from "../api/users";
import { useFetch } from "./useApi";

export const useUserAgreements = () => {
  const [agreementRequired, setAgreementRequired] = useState(false);
  const agreementsApi = useFetch(usersApi.getAgreementDocuments);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (agreementsApi.data?.agreementDocuments.length && !agreementRequired)
      setAgreementRequired(true);
  }, [agreementsApi.data]);

  useEffect(() => {
    if (agreementRequired) navigate("/agreements");
    else if (location.pathname === "/agreements" || location.pathname === "/login") navigate("/");
  }, [location.pathname, agreementRequired]);
};
