import { classNames } from "@progress/kendo-react-common";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useCallback, useState } from "react";
import DateUtility from "../../../utilities/dateUtilities";

export const ChartFilter = (props: any) => {
	const { value } = props;
	const [selected, setSelected] = useState(value);
	const options = [];
	const today = new Date();

	for (let i = 0; i < 12; i++) {
		today.setMonth(i);
		options.push({ value: i, label: DateUtility.formatDateM(today) });
	}

	const handleChange = useCallback(
		(event: any) => {
			setSelected(event.target.value);

			if (props.onChange) {
				props.onChange.call(undefined, { value: event.target.value });
			}
		}, [props.onChange]);

	return (
		<div className={classNames("d-inline-block")}>
			<span style={{ paddingRight: "0.25rem" }}><small>Month</small></span>
			<ComboBox
				data={options}
				allowCustom={false}
				onChange={handleChange}
				textField={"label"}
				dataItemKey={"value"}
				value={selected}
				size={"small"}
				style={{
					width: "120px",
					height: "30px"
				}}
			/>
		</div>
	);
}
