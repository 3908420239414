import { PropsWithChildren, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Field } from "@progress/kendo-react-form";
import FormCheckbox from "../form/FormCheckBox";
import { requiredCheckboxValidator } from "../../validators";

interface ISubmitDisclaimerProps {
  title?: string;
  tandcDocument?: string;
  fieldName: string;
  handleAgree: () => void;
}

export const SubmitDisclaimer = ({
  title,
  tandcDocument,
  fieldName,
  handleAgree,
  children,
}: PropsWithChildren<ISubmitDisclaimerProps>) => {
  const [showDialog, setShowDialog] = useState(false);

  const onClickLink = (e: any) => {
    e.preventDefault();
    setShowDialog(!showDialog);
  };

  const onCancel = () => {
    setShowDialog(false);
  };

  const onAgree = () => {
    onCancel();
    handleAgree();
  };

  return (
    <>
      <Field name={fieldName} component={FormCheckbox} validator={requiredCheckboxValidator}>
        <label className="k-checkbox-label" htmlFor={fieldName}>
          I have read and accept the{" "}
          <a href="#" style={{ fontWeight: "bold" }} onClick={onClickLink}>
            disclaimers
          </a>{" "}
          and the{" "}
          <a
            href={tandcDocument ?? "/examples/SampleTermsAndConditions.pdf"}
            style={{ fontWeight: "bold" }}
            target="_blank"
          >
            Policy Terms and Conditions
          </a>
        </label>
      </Field>

      {showDialog && (
        <Dialog title={title ?? "Disclaimers"} onClose={onCancel} width={"60%"}>
          {children}

          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              gap: 10,
              marginTop: 5,
            }}
          >
            <Button size="small" onClick={onCancel}>
              Cancel
            </Button>
            <Button size="small" themeColor="primary" onClick={onAgree}>
              Agree
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
};
