import { Field } from "@progress/kendo-react-form";
import { FormCheckboxList, FormRadioGroup, ListItem } from "../../form";
import { requiredListValidator, requiredValidator } from "../../../validators";

interface IConfigurationCompaniesSelectorProps {
  companyList: ListItem[];
  multiSelect?: boolean;
  onChange?: (value: number) => void;
}

export const ConfigurationCompaniesSelector = ({
  companyList,
  multiSelect,
  onChange,
}: IConfigurationCompaniesSelectorProps) => {
  const onChangeCompany = (e: any) => {
    if (onChange) onChange(e.value);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {companyList.length > 0 && (
        <>
          {multiSelect ? (
            <Field
              label="Companies"
              name="companies"
              id="companies"
              component={FormCheckboxList}
              validator={requiredListValidator}
              data={companyList}
            />
          ) : (
            <Field
              label="Company"
              name="company"
              id="company"
              component={FormRadioGroup}
              validator={requiredValidator}
              data={companyList}
              onChange={onChangeCompany}
            />
          )}
        </>
      )}
      {companyList.length === 0 && (
        <div>There are no companies for which the selected products can be acted upon.</div>
      )}
    </div>
  );
};
