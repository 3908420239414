export enum StrikeType {
  Fixed,
  Adder,
}

export enum ComponentType {
  Call = 1,
  Put = 2,
}

export enum TransactionDirection {
  Long = 1,
  Short = 2,
}

export enum SettlementType {
  Daily = 1,
  Monthly = 2,
}

export enum Structure {
  Swap = 17,
}
