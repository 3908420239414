import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useState } from "react";

type Props = {
  allocationId: number;
  name: string;
  onDelete(allocationId: number): void;
}

const DeleteAllocationDialog = ({ allocationId, name, onDelete }: Props) => {
  const [visible, setVisible] = useState(false);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleDelete = () => {
    onDelete(allocationId); // Call the delete function passed as prop
    toggleDialog(); // Close the dialog
  };

  return (
    <>
      <Button
        onClick={toggleDialog}
        icon="delete"
        size="small"
        themeColor="primary"
        fillMode="outline"
        title="Delete"
      />
      {visible && (
        <Dialog title={"Confirm Deletion"} onClose={toggleDialog}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            Are you sure you want to delete allocation {name} with Id {allocationId}?
          </p>
          <DialogActionsBar>
            <Button size="small" onClick={toggleDialog}>Cancel</Button>
            <Button size="small" themeColor="primary" onClick={handleDelete}>
              Confirm
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default DeleteAllocationDialog;
