import { GetIntraDayPricesRequest } from "../components/quotes/vanilla/VanillaStructureContainer";
import { apiClient } from "./apiClient";

const endpoint = "contract";

const getRecentPrices = (tickerCode: string, months: number) => {
  return apiClient(`${endpoint}/prices`).fetchWithParams(
    `?tickerCode=${tickerCode}&months=${months}`
  );
};

const getDateBoundaries = (tickerCode: string, isFuture: boolean = false) => {
  return apiClient(`${endpoint}/dates`).fetchWithParams(
    `?tickerCode=${tickerCode}&isFuture=${isFuture}`
  );
};

const getMarketStatistics = (commodityId: number, instrumentType: number) => {
  return apiClient(`${endpoint}/stats`).fetchWithParams(`?commodityId=${commodityId}`);
};

const getIntraDayPrices = (data: GetIntraDayPricesRequest) => {
  return apiClient(`${endpoint}/intraPrices`).post(data);
};

const getSettlementData = (commodityId: number) => {
  return apiClient(`${endpoint}/settlement`).fetchWithParams(
    `?commodityId=${commodityId}`
  );
};

const getBidAskData = (commodityId: number, maxLoadDate?: string) => {
  const dateParam = maxLoadDate ? `&maxLoadTime=${maxLoadDate}` : "";
  return apiClient(`${endpoint}/bidAsk`).fetchWithParams(
    `?commodityId=${commodityId}${dateParam}`
  );
};

export default {
  getRecentPrices,
  getDateBoundaries,
  getMarketStatistics,
  getIntraDayPrices,
  getSettlementData,
  getBidAskData,
};
