import { Chip, ChipProps } from "@progress/kendo-react-buttons";

function DisableableChiplistChip(props: ChipProps) {
  const { dataItem: { disabled } } = props;

  return (
    <Chip {...props} disabled={disabled} />
  );
}

export default DisableableChiplistChip;
