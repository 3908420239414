import { useInternationalization } from "@progress/kendo-react-intl";
import { PropsWithChildren } from "react";
import { deltaHint } from "../../../text/coverageSummary";
import { ICommodity } from "../Commodity";
import { IDetailItem, QuoteDetailsSubHeader } from "./QuoteDetailsSubHeader";

interface IQuoteDetailsHeaderProps {
  quote: any;
  commodity: ICommodity;
  instrumentName: string;
  showDelta: boolean;
  unitPriceDecimals?: number;
  volume?: number;
}

const Separator = () => {
  return <span className={"k-form-separator"} style={{ margin: "10px 0" }} />;
};

export const QuoteDetailsHeader = ({
  quote,
  commodity,
  instrumentName,
  showDelta,
  children,
}: PropsWithChildren<IQuoteDetailsHeaderProps>) => {
  const formatter = useInternationalization();
  const { buyerName, brokerName, sellerName, createdBy, comments, totalDelta } = quote;

  const subHeaderDetails1: IDetailItem[] = [
    { label: "Buyer (Pays)", content: buyerName },
    { label: "Broker", content: brokerName },
    { label: "Seller (Receives)", content: sellerName },
  ];
  const subHeaderDetails2: IDetailItem[] = [
    { label: "Quoted By", content: createdBy },
    { label: "Commodity", content: commodity.name },
    { label: "Product", content: instrumentName },
  ];

  if (comments) subHeaderDetails1.push({ label: "Comments", content: comments });

  if (showDelta)
    subHeaderDetails2.push({
      label: "Risk Taker Delta",
      content: `${formatter.formatNumber(Math.round(-1 * totalDelta), "n0")} ${commodity.units}`,
      hint: deltaHint,
    });

  return (
    <>
      {children}

      <Separator />
      <QuoteDetailsSubHeader details={subHeaderDetails1} />

      <Separator />
      <QuoteDetailsSubHeader details={subHeaderDetails2} />

      <Separator />
    </>
  );
};
