import MathUtility from "./mathUtilities";

// Take the original price divided by total volume. Round to given number of decimals.
// Determine if this is exactly equal to the unit price override (rounded to the same number of decimals).
// If it is, return the original total price. Otherwise, return the new total price.
export function calculateTotalPriceOverride(
  override: number,
  totalVolume: number,
  price: number,
  unitPriceDecimals: number,
) {
  const originalUnitPrice = calculateUnitPrice(price, unitPriceDecimals, totalVolume);
  let totalPriceOverride = null;
  if (originalUnitPrice !== override) {
    totalPriceOverride = override * totalVolume;
    totalPriceOverride = MathUtility.round(totalPriceOverride, 2);
  }

  return totalPriceOverride;
}

export function calculateSwapOverride(
  override: number,
  totalVolume: number,
  volumeWeightedTotalStrike: number,
) {
  const totalPrice = (override - volumeWeightedTotalStrike) * totalVolume;

  return totalPrice;
}

export function calculateUnitPrice(
  totalPrice: number,
  unitPriceDecimals: number,
  totalVolume: number,
) {
  let unitPrice = totalPrice / totalVolume;
  unitPrice = MathUtility.round(unitPrice, unitPriceDecimals);

  return unitPrice;
}
