import { useEffect } from "react";

const useDownloadFile = (
  fileName: string,
  type: string,
  data: any
) => {
  useEffect(() => {
    if (data) {
      const blob = new Blob([data], { type });
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = fileName;
      alink.click();
    }
  }, [data]);
};

export default useDownloadFile;
