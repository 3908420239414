import { Button } from "@progress/kendo-react-buttons";
import { getIconFileName, getIconPath } from "../../utilities/textUtilities";

type Props = {
  item: string;
  type: string;
  title: string;
  hoveredItem: string;
  selectedItemTypes: string[];
  onClick(item: string): void;
  setHoveredItem(hoveredItem: string): void;
};

export const IconToggleButton = ({
  item,
  type,
  title,
  hoveredItem,
  selectedItemTypes,
  onClick,
  setHoveredItem,
}: Props) => {
  const handleMouseEnter = () => setHoveredItem(item);
  const handleMouseLeave = () => setHoveredItem("");

  const isHovered = hoveredItem === item;
  const isSelected = selectedItemTypes.includes(item);
  const iconFileName = getIconFileName(getIconPath(type, item), false, isHovered, isSelected);

  return (
    <Button
      key={item}
      togglable={true}
      fillMode="outline"
      themeColor="primary"
      imageUrl={iconFileName}
      onClick={(e) => {
        e.preventDefault();
        onClick(item);
      }}
      title={title}
      selected={isSelected}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ padding: "7px" }}
    />
  );
};
