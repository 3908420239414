import { State, process } from "@progress/kendo-data-query";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useEffect, useState } from "react";
import marginsApi from "../../../api/margins";
import productsApi from "../../../api/products";
import { useApi, useFetch } from "../../../hooks/useApi";
import { objectToLookup } from "../../../utilities/objectUtilities";
import { requiredValidator } from "../../../validators";
import { LoadingIndicator } from "../../LoadingIndicator";
import { FormDropDownList, FormInput } from "../../form";
import { ICommodity } from "../../quotes";
import { BetaValue } from "./BetaValue";
import { BetaValuesEditor } from ".";

const COPY_MODE = "copy";

export const MarginsGrid = () => {
  const getBusinessTypesApi = useFetch(productsApi.getBusinessTypes);
  const {
    data: commoditiesData,
    loading: commoditiesLoading,
    error: commoditiesError,
  } = useFetch(productsApi.getCommodities);

  const getMarginsApi = useApi(marginsApi.getMargins);
  const saveBetasApi = useApi(marginsApi.updateBetas);
  const saveMarginApi = useApi(marginsApi.createMargin);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [gridData, setGridData] = useState<any[]>([]);
  const [result, setResult] = useState<any>([]);
  const [dataState, setDataState] = useState<State>({
    skip: 0,
    take: 10,
  });

  const [searchText, setSearchText] = useState("");
  const [selectedId, setSelectedId] = useState(0);
  const [mode, setMode] = useState("");
  const [betasData, setBetasData] = useState<BetaValue[]>([]);
  const [businessTypesList, setBusinessTypesList] = useState<any[]>([]);
  const [commoditiesList, setCommoditiesList] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any | null>(null);

  useEffect(() => {
    getMargins();
  }, []);

  const getMargins = async () => {
    await getMarginsApi.request();
  };

  useEffect(() => {
    setBusinessTypesList(objectToLookup(getBusinessTypesApi.data?.businessTypes || []));
  }, [getBusinessTypesApi.data]);

  useEffect(() => {
    setLoading(commoditiesLoading || getMarginsApi.loading);
  }, [commoditiesLoading, getMarginsApi.loading]);

  useEffect(() => {
    setCommoditiesList(
      [{ id: null, name: " " }]
        .concat(commoditiesData?.commodities ?? [])
        .map((c: any) => ({ value: c.id, label: c.name }))
        .sort((a: any, b: any) => a.label.localeCompare(b.label)),
    );
  }, [commoditiesData]);

  useEffect(() => {
    const margin = gridData.find((m: any) => m.marginId === selectedId);

    if (!margin) setInitialValues(null);
    else {
      setInitialValues({
        name: "",
        commodity: commoditiesList.find((c) => c.value === margin.commodityId),
        businessType: businessTypesList.find((b) => b.value === margin.businessTypeId),
      });
    }
  }, [selectedId]);

  useEffect(() => {
    const text = searchText.toLowerCase();
    const marginsData = getMarginsApi.data?.margins;

    if (marginsData && commoditiesData?.commodities) {
      setGridData(
        marginsData
          .filter((m: any) => selectedId === 0 || m.marginId === selectedId)
          .map((margin: any) => {
            const selected = margin.marginId === selectedId;
            const commodity = margin.commodityId
              ? commoditiesData.commodities.find((c: ICommodity) => c.id === margin.commodityId)
              : null;
            return { selected, commodity, ...margin };
          })
          .filter(
            (m: any) =>
              text.length === 0 ||
              m.longName.toLowerCase().includes(text) ||
              m.businessType.toLowerCase().includes(text) ||
              m.commodity?.name.toLowerCase().includes(text),
          ),
      );
    }
  }, [getMarginsApi.data, commoditiesData, selectedId, searchText]);

  useEffect(() => {
    applyDataStateChange(dataState);
  }, [gridData]);

  useEffect(() => {
    setLoading(saveBetasApi.loading);
    setError(saveBetasApi.error);

    //TODO: Figure out why data is coming back as undefined here
    // if (!saveBetasApi.loading && (saveBetasApi.data || saveBetasApi.error))
    //   if (!saveBetasApi.error)

    if (!saveBetasApi.loading && !saveBetasApi.error) handleCancel();
  }, [saveBetasApi.loading, saveBetasApi.data, saveBetasApi.error]);

  useEffect(() => {
    setLoading(saveMarginApi.loading);
    setError(saveMarginApi.error);

    if (!saveMarginApi.loading && (saveMarginApi.data || saveMarginApi.error))
      if (!saveMarginApi.error) handleCancel();
  }, [saveMarginApi.loading, saveMarginApi.data, saveMarginApi.error]);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    applyDataStateChange(event.dataState);
  };

  const applyDataStateChange = (newDataState: State) => {
    setResult(process(gridData.slice(0), newDataState));
    setDataState(newDataState);
  };

  const handleEditMargin = (dataItem: any) => setSelectedId(dataItem?.marginId);

  const handleCopyMargin = (dataItem: any) => {
    setSelectedId(dataItem?.marginId);
    setMode(COPY_MODE);
  };

  const handleSaveMargin = async (event: any) => {
    const formData = { ...event };

    await saveMarginApi.request(
      formData.name,
      betasData,
      formData.commodity.value,
      formData.businessType.value
    );
  };

  const handleSaveBetas = async () => {
    const data = betasData
      .filter((d: any) => d.isModified)
      .map((d: any) => (d.contractMonth?.length > 0 ? d : { ...d, contractMonth: null }));

    await saveBetasApi.request(data, selectedId);
  };

  const handleCancel = () => {
    setSelectedId(0);
    setBetasData([]);
    setMode("");
    getMargins();
  };

  const ActionsCell = (props: GridCellProps) => {
    const field = props.field || "";
    const value = props.dataItem[field];

    return (
      <td>
        {!value && selectedId === 0 && (
          <div style={{ display: "flex" }}>
            <Button
              icon="edit"
              themeColor="primary"
              fillMode="outline"
              title="Edit"
              size="medium"
              onClick={() => handleEditMargin(props.dataItem)}
            />

            <Button
              icon="copy"
              themeColor="primary"
              fillMode="outline"
              title="Copy"
              size="small"
              onClick={() => handleCopyMargin(props.dataItem)}
            />
          </div>
        )}
      </td>
    );
  };

  return (
    <>
      <LoadingIndicator loading={loading} />
      {commoditiesError && <Error>{commoditiesError}</Error>}
      {getMarginsApi.error && <Error>{getMarginsApi.error}</Error>}

      {!loading && (
        <>
          {selectedId === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                columnGap: 5,
                margin: "1rem",
              }}
            >
              <Label>Search</Label>
              <Input
                value={searchText}
                onChange={(e: any) => setSearchText(e.target.value)}
                style={{ width: 200 }}
              />
            </div>
          )}

          <Tooltip anchorElement="target" parentTitle={true}>
            <Grid
              data={result}
              {...dataState}
              pageable={selectedId === 0}
              sortable={true}
              onDataStateChange={dataStateChange}
              selectedField="selected"
            >
              <GridColumn field="longName" title="Margin Name" />
              <GridColumn field="businessType" title="Business Type" />
              <GridColumn field="commodity.name" title="Commodity" />
              <GridColumn field="isAggregate" title=" " cell={ActionsCell} width={100} />
            </Grid>
          </Tooltip>
        </>
      )}

      {selectedId > 0 && (
        <>
          {mode === COPY_MODE && initialValues ? (
            <Form
              initialValues={initialValues}
              onSubmit={handleSaveMargin}
              render={(formRenderProps) => (
                <FormElement>
                  <div style={{ display: "flex", gap: 10, marginTop: "1rem" }}>
                    <Field
                      label="New Margin Name"
                      name="name"
                      component={FormInput}
                      validator={requiredValidator}
                      disabled={loading}
                      maxLength={255}
                      style={{ minWidth: 300 }}
                    />

                    <Field
                      label="Business Type"
                      name="businessType"
                      component={FormDropDownList}
                      validator={requiredValidator}
                      data={businessTypesList}
                      disabled={loading}
                      textField="label"
                      dataItemKey="value"
                      style={{ minWidth: 200 }}
                    />

                    <Field
                      label="Commodity"
                      name="commodity"
                      component={FormDropDownList}
                      data={commoditiesList}
                      disabled={loading}
                      textField="label"
                      dataItemKey="value"
                      style={{ minWidth: 300 }}
                    />
                  </div>

                  <div style={{ margin: "1rem 0" }}>
                    <BetaValuesEditor
                      marginId={selectedId}
                      disabled={loading}
                      onDataUpdate={setBetasData}
                    />
                  </div>

                  <div>
                    <Button
                      themeColor="success"
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                    >
                      Save
                    </Button>

                    <Button themeColor="secondary" onClick={handleCancel} disabled={loading}>
                      Cancel
                    </Button>
                  </div>
                </FormElement>
              )}
            ></Form>
          ) : (
            <>
              <div style={{ margin: "1rem 0" }}>
                <BetaValuesEditor
                  marginId={selectedId}
                  disabled={loading}
                  onDataUpdate={setBetasData}
                />
              </div>
              <div>
                <Button
                  themeColor="success"
                  onClick={handleSaveBetas}
                  disabled={!betasData.some((d) => d.isModified) || loading}
                >
                  Save
                </Button>

                <Button themeColor="secondary" onClick={handleCancel} disabled={loading}>
                  Cancel
                </Button>
              </div>
            </>
          )}

          {error && <Error>{error}</Error>}
        </>
      )}
    </>
  );
};
