import { PDFViewer, PDFViewerTool } from "@progress/kendo-react-pdf-viewer";
import { Dialog } from "@progress/kendo-react-dialogs";

interface PdfDocumentDialog {
  document: any;
  fileName: string;
  onClose: () => void;
}

const tools: PDFViewerTool[] = [
  "pager",
  "spacer",
  "zoomInOut",
  "zoom",
  "selection",
  "spacer",
  "search",
  "download",
  "print",
];

export const ViewPdfDocumentDialog = (props: PdfDocumentDialog) => {
  const { document, fileName, onClose } = props;

  return (
    <Dialog title={fileName} onClose={onClose} height="90%">
      <PDFViewer data={document} tools={tools} />
    </Dialog>
  );
};
