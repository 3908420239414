import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { useInternationalization } from "@progress/kendo-react-intl";
import { Error, Label } from "@progress/kendo-react-labels";
import "hammerjs";
import { useEffect, useState } from "react";
import { IApiResults } from "../../hooks/useApi";
import DateUtility from "../../utilities/dateUtilities";
import { getHelpUrl } from "../../utilities/helpUtilities";
import { maxByProperty } from "../../utilities/objectUtilities";
import { LoadingIndicator } from "../LoadingIndicator";
import InfoIconAndTooltip from "../utilities/InfoIconAndTooltip";

interface IPlotLine {
  label: string;
  value: number;
  color: string;
  labelAbove?: boolean;
}

interface IMarketStatsChartProps {
  commodity: string;
  structure: string;
  plotLines?: IPlotLine[];
  apiResults: IApiResults;
}

const PERCENT_HIGH = "percentHigh";
const PERCENT_LOW = "percentLow";
const PERCENT_FULL = "percentFull";

export const MarketStatisticsChart = (props: IMarketStatsChartProps) => {
  const { commodity, structure, plotLines, apiResults } = props;
  const { data, loading, error } = apiResults;
  const formatter = useInternationalization();

  const radioButtonData: any[] = [
    { label: "All Days", value: 0 },
    { label: "Remaining Days", value: 1 },
  ];

  const field = structure === "HighPriceProtection" ? PERCENT_HIGH : 
    structure === "LowPriceProtection" ? PERCENT_LOW : PERCENT_FULL;

  const direction = field === PERCENT_HIGH ? "Upward" : field === PERCENT_LOW ? "Downward" : "";

  const [path, setPath] = useState(radioButtonData[1].value);
  const [chartData, setChartData] = useState<any[]>([[], []]);
  const [max, setMax] = useState([0, 0]);

  useEffect(() => {
    if (!loading && data?.statistics) {
      const marketData = data.statistics;

      const dataCopy = Object.keys(marketData).map((k) => {
        return marketData[k].map((d: any) =>
          Object.assign(d, { date: new Date(Date.parse(d.contract)) }),
        );
      });

      const maxPlotLine = plotLines ? maxByProperty(plotLines, "value") : 0;
      const max = dataCopy.map((d: any) => Math.max(maxByProperty(d, field), maxPlotLine) + 0.01);

      setMax(max);
      setChartData(dataCopy);
    }
  }, [loading, data, plotLines]);

  const padding = 0.004;
  const plotBands = plotLines?.map((p: IPlotLine) => {
    return (
      {
        from: p.value - padding,
        to: p.value + padding,
        color: p.color,
        opacity: 0.7,
        label: {
          text: p.label,
          color: p.color,
          font: "small-caption",
          margin: p.labelAbove ? { left: 5, top: -20 } : 5,
        },
      } ?? []
    );
  });

  const renderTooltip = (context: any) => {
    const { category, value } = context.point;

    return (
      <div>
        <div>{DateUtility.formatDateMY(category)}</div>
        <div>{formatter.formatNumber(value, "p0")}</div>
      </div>
    );
  };

  return (
    <>
      <LoadingIndicator loading={loading} />
      {error && <Error>{error}</Error>}

      {!loading && !error && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ flexBasis: "50%" }}>
              <Label>
                {` ${direction} Percent Price Change for ${commodity}`}
                <InfoIconAndTooltip link={getHelpUrl(17197172739469)}>
                  This chart displays historical what-if scenarios of the retention levels chosen
                  above. The horizontal green line represents the chosen retention level, and the
                  red line represents the exhaust. The vertical grey bars show worst-case scenarios
                  of the chosen commodity's price movements for past years' contracts, in percent
                  change. As an example, if you have chosen a December contract for your coverage,
                  the chart displays worst-case price movements of the past ten December contracts.
                  The time frame of the past contracts matches that of the covered December
                  contract. E.g., if your chosen December contract is 180 business days from
                  expiration, all the past December contracts are all treated as if they were also
                  180 business days from expiration.
                </InfoIconAndTooltip>
              </Label>
            </div>
            <div>
              <RadioGroup
                data={radioButtonData}
                value={path}
                layout="horizontal"
                onChange={(e: any) => setPath(e.value)}
              />
            </div>
          </div>
          <div>
            <Chart transitions={false}>
              <ChartValueAxis>
                <ChartValueAxisItem
                  labels={{ format: "p0" }}
                  plotBands={plotBands}
                  max={max[path]}
                />
              </ChartValueAxis>

              <ChartSeries>
                <ChartSeriesItem
                  type="column"
                  field={field}
                  categoryField="date"
                  data={chartData[path]}
                  color={"black"}
                  opacity={0.3}
                />
              </ChartSeries>

              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  baseUnit="months"
                  labels={{ format: "MMM yyyy", rotation: "auto" }}
                  maxDivisions={10}
                />
              </ChartCategoryAxis>

              <ChartTooltip render={renderTooltip} />
            </Chart>
          </div>
        </>
      )}
    </>
  );
};
