import { Field } from "@progress/kendo-react-form";
import { requiredValidator } from "../../../validators";
import { FormRadioGroup } from "../../form";
import { ConfigAction } from "./ConfigurationEnums";

interface IConfigurationActionsProps {
  onChange: (action: ConfigAction) => void;
}

export const ConfigurationActions = ({ onChange }: IConfigurationActionsProps) => {
  const radioButtonData: any[] = [
    { label: "Assign", value: ConfigAction.Assign },
    { label: "Activate", value: ConfigAction.Activate },
    { label: "Suspend", value: ConfigAction.Suspend },
  ];

  return (
    <div
      style={{
        display: "flex",
        alignItems: "end",
        justifyContent: "space-evenly",
        marginBottom: "1rem",
      }}
    >
      <Field
        key={"action"}
        id={"action"}
        name={"action"}
        component={FormRadioGroup}
        validator={requiredValidator}
        layout={"horizontal"}
        data={radioButtonData}
        onChange={(e) => onChange(e.value)}
      />
    </div>
  );
};
