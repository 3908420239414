import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import {
  Popover,
  PopoverActionsBar,
  PopoverPosition,
} from "@progress/kendo-react-tooltip";
import { Form, FormElement, Field } from "@progress/kendo-react-form";
import { useEffect, useRef, useState } from "react";
import { useApi } from "../../hooks/useApi";
import productsApi from "../../api/products";
import { FormDropDownList, ListItem } from "../form";
import { LoadingIndicator } from "../LoadingIndicator";
import { requiredValidator } from "../../validators";
import { ICompany } from "../admin/Company";

interface CopyPopupProps {
  action: string;
  position?: PopoverPosition;
  data: any;
  onOpen: (action: string) => void;
  onClose: () => void;
  onConfirm: (company: any | null) => void;
}

export const CopyPopup = (props: CopyPopupProps) => {
  const anchor = useRef<any>();
  const getCompaniesApi = useApi(productsApi.getCompanies);
  const { action, position, data, onOpen, onClose, onConfirm } = props;

  const key = `copy${data.quoteId}`;
  const disabled = action.length > 0 && action !== key;

  const [show, setShow] = useState(action === key);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [companyList, setCompanyList] = useState<ListItem[]>([]);
  const [selected, setSelected] = useState<any>();

  useEffect(() => {
    if (show && companyList.length === 0)
      getCompaniesApi.request(
        data.commodityId,
        data.instrumentId,
        data.productType
      );
  }, [show]);

  useEffect(() => {
    const list = getCompaniesApi.data?.companies || [];
    if (list.length === 0 && companyList.length === 0) return;

    setCompanies(list);
    setCompanyList(list.map((c: ICompany) => { return { value: c.id, label: c.name }; }));
  }, [getCompaniesApi.data]);

  useEffect(() => {
    if (companyList.length === 1) onConfirm(null);
    else if (companyList.length > 0 && !selected) {
      const initial = companyList?.find((c) => c.value === data.companyId);
      if (initial) setSelected({ company: initial });
    }
  }, [companyList]);

  const onCompanyChange = (e: any) => {
    setSelected({ company: e.value });
  };

  const onClick = () => {
    onOpen(key);
  };

  const onCopy = () => {
    const company = companies.find(c => c.id === selected.company.value);
    onConfirm(company);
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <div>
      <Button
        ref={anchor}
        disabled={disabled}
        icon="copy"
        size="small"
        themeColor="primary"
        fillMode="outline"
        title="Copy Quote"
        onClick={onClick}
      />

      <Popover
        key={key}
        show={show}
        anchor={anchor.current && anchor.current.element}
        position={position || "top"}
        animate={{ closeDuration: 300, openDuration: 300 }}
        callout={false}
        collision={{
          vertical: "flip",
          horizontal: "flip",
        }}
        style={{ width: 300 }}
      >
        <LoadingIndicator loading={getCompaniesApi.loading} />
        {getCompaniesApi.error && <Error>{getCompaniesApi.error}</Error>}
        {!getCompaniesApi.loading && selected && (
          <Form
            initialValues={selected}
            render={() => (
              <FormElement>
                {!getCompaniesApi.loading && companyList.length > 0 && (
                  <Field
                    key="company"
                    id="company"
                    name="company"
                    label={`Copy Quote ${data.quoteId} As Client`}
                    textField="label"
                    dataItemKey="value"
                    onChange={onCompanyChange}
                    component={FormDropDownList}
                    validator={requiredValidator}
                    data={companyList}
                  />
                )}
              </FormElement>
            )}
          />
        )}

        {!getCompaniesApi.loading && companyList.length === 0 && (
          <Error>This quote cannot be copied.</Error>
        )}

        <PopoverActionsBar>
          {companyList.length > 0 && (
            <Button themeColor={"primary"} size={"small"} onClick={onCopy}>
              Copy
            </Button>
          )}
          <Button size={"small"} onClick={onCancel}>
            Cancel
          </Button>
        </PopoverActionsBar>
      </Popover>
    </div>
  );
};
