import { FormRenderProps } from "@progress/kendo-react-form";
import { useCallback, useEffect, useState } from "react";
import { LoadingIndicator } from "../../LoadingIndicator";
import { AccumulatorQuoteCard, AccumulatorSubmitSummary } from ".";
import { QuoteExpiry, SubmitDisclaimer } from "..";
import accumulatorApi from "../../../api/accumulator";
import { useApi } from "../../../hooks/useApi";
import { useUserContext } from "../../../hooks/useUserContext";

interface IAccumulatorSubmitProps {
  formRenderProps: FormRenderProps;
  product: any;
}

export const AccumulatorSubmitStep = ({ formRenderProps, product }: IAccumulatorSubmitProps) => {
  const { commodity, company } = product;
  const { context: user } = useUserContext();
  const canSubmit = user.roles.canSubmitQuotes(company.role);

  const getQuoteApi = useApi(accumulatorApi.getQuote);
  const quoteId = formRenderProps.valueGetter("quoteId");
  const quoteName = formRenderProps.valueGetter("quoteName");
  const contract = formRenderProps.valueGetter("contract");
  const volume = formRenderProps.valueGetter("volume");
  const knockOutLevel = formRenderProps.valueGetter("knockOutLevel");
  const strike = formRenderProps.valueGetter("strike");

  const [loading, setLoading] = useState(false);
  const [quote, setQuote] = useState(formRenderProps.valueGetter("quote"));
  const [expiration, setExpiration] = useState(
    new Date(Date.parse(formRenderProps.valueGetter("expiry")))
  );
  const checkboxFieldName = "isConfirmed";

  const getQuote = useCallback(async () => {
    getQuoteApi.request(quoteId);
  }, [getQuoteApi, quoteId]);

  useEffect(() => {
    setLoading(getQuoteApi.loading);

    if (getQuoteApi.data) {
      setExpiration(new Date(Date.parse(getQuoteApi.data.quoteExpiry)));
      setQuote(getQuoteApi.data.quote);
      formRenderProps.onChange("quote", { value: getQuoteApi.data.quote });
    }
  }, [getQuoteApi.data, getQuoteApi.loading]);

  const onExpiryChanged = (expiry: string) => {
    formRenderProps.onChange("expiry", { value: expiry });
  };

  const handleAgree = () => {
    formRenderProps.onChange(checkboxFieldName, { value: true });
  };

  return (
    <div className="stepper-form">
      <div style={{ width: "60%" }}>
        <LoadingIndicator loading={loading} />

        {!loading && (
          <AccumulatorQuoteCard
            title="Quote Summary"
            quote={{ ...quote, knockOutLevel, strike }}
            commodity={commodity}
            showInputs={true}
          />
        )}
      </div>

      <SubmitDisclaimer fieldName={checkboxFieldName} handleAgree={handleAgree}>
        <AccumulatorSubmitSummary
          quoteDetails={{
            quoteId,
            name: quoteName,
            contract,
            volume,
            price: quote.fairValuePrice,
          }}
          commodity={commodity}
          canSubmit={canSubmit}
        />
      </SubmitDisclaimer>

      <QuoteExpiry
        expiration={expiration}
        hideExpiry={true}
        loading={loading}
        onExpire={onExpiryChanged}
        onRefresh={getQuote}
      />
    </div>
  );
};
