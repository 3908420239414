import { addDays } from "@progress/kendo-date-math";
import { apiClient } from "./apiClient";

const endpoint = "quotes";

const getSummary = (dates: any) => {
  const params = getDateParams(dates);
  if (!params) return [];

  return apiClient(`${endpoint}/summary`).fetchWithParams(params);
};

const getDetails = (quoteId: number) => {
  const params = `?quoteId=${quoteId}`;
  return apiClient(`${endpoint}/details`).fetchWithParams(params);
};

const getStatusSummary = (dates: any) => {
  const params = getDateParams(dates);
  if (!params) return [];

  return apiClient(`${endpoint}/status`).fetchWithParams(params);
};

const getStatusDetails = (dates: any) => {
  const params = getDateParams(dates);
  if (!params) return [];

  return apiClient(`${endpoint}/statusDetails`).fetchWithParams(params);
};

const getHitRates = () => apiClient(`${endpoint}/hitRates`).fetch();

const getDateParams = (dates: { start: Date; end: Date }) => {
  if (!dates || !dates.start || !dates.end) return "";

  // Set start / end dates to midnight and convert to UTC
  dates.start.setHours(0, 0, 0, 0);
  const newEnd = addDays(dates.end, 1);
  newEnd.setHours(0, 0, 0, 0);

  const params = `?startDate=${dates.start.toISOString()}&endDate=${newEnd.toISOString()}`;
  return params;
};

const submitQuote = (
  pricingId: number,
  quoteId: number,
  overridePrice?: number,
  comments?: string,
) => {
  const data = { pricingId, quoteId, comments, overridePrice };
  const result = apiClient(`${endpoint}/submit`).post(data);
  return result;
};

const approveQuote = (
  quoteId: number,
  isApproval: boolean,
  priceOverride: number | null,
  brokerPriceOverride: number | null,
  comments?: string,
) => {
  const data = { quoteId, isApproval, priceOverride, brokerPriceOverride, comments };
  const result = apiClient(`${endpoint}/review`).post(data);
  return result;
};

const deleteQuote = (quoteId: number) => {
  return apiClient(endpoint).delete(quoteId);
};

const copyQuote = (quoteId: number, companyId: number | null) => {
  const params = companyId ? `?quoteId=${quoteId}&companyId=${companyId}` : `?quoteId=${quoteId}`;
  return apiClient(`${endpoint}/copy`).fetchWithParams(params);
};

const getMarginsDetails = (quoteId: number, pricingId: number) =>
  apiClient(`${endpoint}/margins`).fetchWithParams(`?quoteId=${quoteId}&pricingId=${pricingId}`);

const getSlippage = (quoteId: number, pricingId: number) =>
  apiClient(`${endpoint}/slippage`).fetchWithParams(`?quoteId=${quoteId}&pricingId=${pricingId}`);

export default {
  getSummary,
  getDetails,
  submitQuote,
  approveQuote,
  deleteQuote,
  copyQuote,
  getStatusSummary,
  getStatusDetails,
  getHitRates,
  getMarginsDetails,
  getSlippage,
};
