import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import { Error, Label } from "@progress/kendo-react-labels";
import { Card, CardBody, CardHeader, CardTitle } from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useCallback, useEffect, useState } from "react";
import { ChartExpander } from "..";
import { getQuote, getQuoteDetails, saveQuoteDetails } from "../../../api/vanilla";
import { IApiResults, useApi, useFetch } from "../../../hooks/useApi";
import DateUtility from "../../../utilities/dateUtilities";
import MathUtility from "../../../utilities/mathUtilities";
import { maxByProperty, minByProperty } from "../../../utilities/objectUtilities";
import { VanillaComponent } from "../../../utilities/vanilla/vanillaComponent";
import {
  StrikeType,
  Structure,
  TransactionDirection,
} from "../../../utilities/vanilla/vanillaEnums";
import { LoadingIndicator } from "../../LoadingIndicator";
import { MarketStatisticsChart, PayoutChart } from "../../charts";
import { Slider } from "../../slider/Slider";
import { HeaderTextSelector } from "../../utilities";
import { PricingHistoryDialog } from "../PricingHistoryDialog";
import { QuoteExpiry } from "../QuoteExpiry";
import VanillaQuoteSummary from "./VanillaQuoteSummary";

const LOOKBACK_CHART = "Lookback Chart";
const PAYOUT_DIAGRAM = "Payout Diagram";

type Props = {
  formRenderProps: FormRenderProps;
  calculator: any;
  product: any;
  marketStatsResults: IApiResults;
  structureName: string;
};

const VanillaQuote = ({
  formRenderProps,
  calculator,
  product,
  marketStatsResults,
  structureName,
}: Props) => {
  const { totalVolume, columns, months, volumes } = calculator;
  const {
    commodity,
    company,
    instrument: { calendarMonthOffset },
  } = product;

  const contractStart = DateUtility.formatDateYMD(formRenderProps.valueGetter("contract.start"));
  const contractEnd = DateUtility.formatDateYMD(formRenderProps.valueGetter("contract.end"));
  const direction = formRenderProps.valueGetter("direction");
  const clientLong = direction === TransactionDirection.Long;
  const quoteId = formRenderProps.valueGetter("quoteId");
  const quoteName = formRenderProps.valueGetter("quoteName");
  const configurationId = formRenderProps.valueGetter("configurationId");
  const settlementTypeId = formRenderProps.valueGetter("settlement");
  const structureId = formRenderProps.valueGetter("structureId");
  const isSwap = structureId === Structure.Swap;
  const chartTabs = [LOOKBACK_CHART, PAYOUT_DIAGRAM];

  const [expiration, setExpiration] = useState(new Date());
  const [showChart, setShowChart] = useState(false);
  const [chartExpanded, setChartExpanded] = useState(false);
  const [selectedView, setSelectedView] = useState(LOOKBACK_CHART);
  const [dragging, setDragging] = useState(false);

  const decimals = commodity?.decimals || 2;
  const underlying: number = MathUtility.round(
    columns?.length > 0 ? columns[0].avgPrice : 0,
    decimals,
  );

  const getPrices = () => {
    return months.map((month: Date, i: number) => {
      month = DateUtility.addMonthOffset(month, calendarMonthOffset);
      const date = DateUtility.formatDateYMD(month);

      const volume = volumes[i];
      const prices = columns.map((column: VanillaComponent) => {
        const strike = column.fixedStrikes[i];
        const componentId = column.componentId;
        return { strike, componentId };
      });
      return { date, volume, prices };
    });
  };

  const startDate = DateUtility.addOffsetToDateString(contractStart, calendarMonthOffset);
  const endDate = DateUtility.addOffsetToDateString(contractEnd, calendarMonthOffset);

  const saveRequestData = () => {
    return {
      companyId: company.id,
      configurationId,
      quoteId,
      quoteName,
      startDate,
      endDate,
      totalVolume,
      clientLong,
      settlementTypeId,
      structureId,
      prices: getPrices(),
    };
  };

  const { data: saveQuoteData, loading, error } = useFetch(saveQuoteDetails, saveRequestData());
  const saveDetailsApi = useApi(saveQuoteDetails);
  const getQuoteApi = useApi(getQuote);

  const saveDetails = useCallback(async () => {
    const requestData = saveRequestData();
    saveDetailsApi.request(requestData);
  }, [saveDetailsApi]);

  useEffect(() => {
    if (saveDetailsApi.data) refreshQuote();
  }, [saveDetailsApi.data]);

  const refreshQuote = useCallback(async () => {
    getQuoteApi.request(quoteId);
  }, [getQuoteApi, quoteId]);

  const pricingId = getQuoteApi.data?.quotes[0].pricingId || 0;

  useEffect(() => {
    setDragging(false);

    if (getQuoteApi.data) {
      setExpiration(new Date(Date.parse(getQuoteApi.data.quoteExpiry)));
      formRenderProps.onChange("pricingId", { value: pricingId });
    }
  }, [getQuoteApi.data]);

  useEffect(() => {
    const loaded = !(getQuoteApi.loading || saveDetailsApi.loading || loading);
    formRenderProps.onChange("loaded", { value: loaded });
  }, [getQuoteApi.loading, saveDetailsApi.loading, loading]);

  useEffect(() => {
    // If there's an error, clear the expiry to force an invalid form => disable Next
    if (getQuoteApi.error || saveDetailsApi.error || error) onExpiryChanged("");
  }, [getQuoteApi.error, saveDetailsApi.error, error]);

  const onExpiryChanged = (expiry: string) => {
    formRenderProps.onChange("expiry", { value: expiry });
  };

  const strikes = columns
    .map((column: any, index: number) => {
      return {
        colIndex: index,
        value: MathUtility.round(column.avgFixedStrike, decimals),
      };
    })
    .filter(
      (s: any) =>
        (isSwap && columns[s.colIndex].direction === direction) || !columns[s.colIndex].isLinked,
    )
    .sort((a: any, b: any) => a.value - b.value);

  const [sliderValues, setSliderValues] = useState<number[]>(strikes.map((d: any) => d.value));
  const [sliderMin, setSliderMin] = useState(0);
  const [sliderMax, setSliderMax] = useState(0);
  const [plotLines, setPlotLines] = useState<any[]>([]);
  const [payoutValues, setPayoutValues] = useState<any[]>([]);

  useEffect(() => {
    const min = minByProperty(strikes, "value");
    const max = maxByProperty(strikes, "value");
    const padding = min * 0.2;

    setSliderMin(MathUtility.round(min - padding, 0));
    setSliderMax(MathUtility.round(max + padding, 0));
  }, []);

  useEffect(() => {
    sliderValues.forEach((value: number, index: number) => {
      const colIndex = strikes[index].colIndex;
      const column = columns[colIndex];
      const diff = (value - column.avgFixedStrike) / months.length;
      if (diff !== 0)
        months.forEach((m: Date, monIndex: number) =>
          calculator.setStrike(
            column.fixedStrikes[monIndex] + diff,
            colIndex,
            StrikeType.Fixed,
            monIndex,
          ),
        );
    });

    setPlotLines(
      columns.map((column: any) => {
        return {
          label: column.label,
          value: column.avgFixedStrikeAsPercent,
          color: column.direction === TransactionDirection.Short ? "red" : "green",
          labelAbove: column.direction === TransactionDirection.Long,
        };
      }),
    );

    setPayoutValues(
      columns
        .filter((c: any) => (isSwap && c.direction === direction) || !isSwap)
        .map((column: any) => {
          return {
            type: column.columnType,
            direction: column.direction,
            value: column.avgFixedStrike,
          };
        }),
    );
  }, [sliderValues]);

  const onSliderChange = (newValues: number[]) => {
    saveDetails();
  };

  const onSliderDrag = (newValues: number[]) => {
    const values = newValues.map((v) => MathUtility.round(v, decimals));

    const isValid = values.every((num, idx, arr) => {
      return (idx === 0 || num > arr[idx - 1]) && (idx === arr.length - 1 || num < arr[idx + 1]);
    });

    if (isValid) setSliderValues(values);

    setDragging(true);
  };

  useEffect(() => {
    if (!loading) {
      if (saveQuoteData) {
        formRenderProps.onChange("quoteId", { value: saveQuoteData.quoteId });
      }
    }
  }, [loading]);

  useEffect(() => {
    if (saveQuoteData?.quoteId) getQuoteApi.request(saveQuoteData.quoteId);
  }, [saveQuoteData]);

  const getVanillaDetailsApi = useApi(getQuoteDetails);
  useEffect(() => {
    if (isSwap && getVanillaDetailsApi.data) {
      const newPrices = getVanillaDetailsApi.data.quote.prices.map((price: any) => {
        const newStrike = price.prices[0].strike;
        return newStrike;
      });
      calculator.setPrices(newPrices);
    }
  }, [getVanillaDetailsApi.data]);

  useEffect(() => {
    if (getQuoteApi.data) {
      const { quotes } = getQuoteApi.data;
      const pricingId = quotes[0].pricingId;
      formRenderProps.onChange("pricingId", { value: pricingId });
      formRenderProps.onChange("quote", { value: quotes });

      getVanillaDetailsApi.request(quoteId);
    }
  }, [getQuoteApi.data, getQuoteApi.loading]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div className="utility-button-container">
          <Tooltip anchorElement="target" position="bottom" parentTitle={true}>
            <Button
              size="small"
              icon="refresh"
              themeColor="primary"
              title="Refresh"
              onClick={(e: any) => {
                e.preventDefault();
                refreshQuote();
              }}
              disabled={loading}
              style={{ marginLeft: "5px" }}
            />
            <Button
              togglable={true}
              size="small"
              icon="align-bottom-element"
              themeColor="primary"
              title="View Chart"
              onClick={(e: any) => {
                e.preventDefault();
                setShowChart(true);
              }}
            />
          </Tooltip>
        </div>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Quote Details</CardTitle>
        </CardHeader>
        <CardBody>
          <LoadingIndicator loading={getQuoteApi.loading} />
          {error && <Error>{error}</Error>}
          {saveDetailsApi.error && <Error>{saveDetailsApi.error}</Error>}
          {getQuoteApi.error && <Error>{getQuoteApi.error}</Error>}
          {!!getQuoteApi.data && (
            <VanillaQuoteSummary
              quote={getQuoteApi.data.quotes}
              prices={getPrices()}
              columns={columns}
              units={commodity.units.slice(0, -1)}
              calendarMonthOffset={calendarMonthOffset}
              companyRole={company.role}
              isSwap={isSwap}
              dragging={dragging}
              unitPriceDecimals={commodity.decimals}
            />
          )}
        </CardBody>
      </Card>

      <QuoteExpiry
        expiration={expiration}
        loading={getQuoteApi.loading || loading}
        onExpire={onExpiryChanged}
        onRefresh={refreshQuote}
      />

      {!isSwap && (
        <div style={{ marginBottom: "1.25rem" }}>
          <Label>Adjust Strikes</Label>
          <Slider
            options={{
              name: "strikeSlider",
              values: sliderValues,
              min: sliderMin,
              max: sliderMax,
              stepSize: 0.01,
              staticValues: [underlying],
              staticLabels: ["U"],
              ticks: [sliderMin, underlying, sliderMax],
            }}
            onChange={onSliderChange}
            onDrag={onSliderDrag}
          />
        </div>
      )}

      <ChartExpander isExpanded={chartExpanded} setIsExpanded={setChartExpanded}>
        <>
          <HeaderTextSelector values={chartTabs} onChange={setSelectedView} />

          {selectedView === LOOKBACK_CHART && (
            <Card>
              <CardBody>
                <MarketStatisticsChart
                  commodity={commodity.name}
                  structure={structureName}
                  plotLines={plotLines}
                  apiResults={marketStatsResults}
                />
              </CardBody>
            </Card>
          )}

          {selectedView === PAYOUT_DIAGRAM && (
            <PayoutChart
              decimals={decimals}
              structureId={structureId}
              strikes={payoutValues}
              underlying={underlying}
            />
          )}
        </>
      </ChartExpander>

      {showChart && (
        <PricingHistoryDialog
          commodityName={commodity.name}
          apiResults={marketStatsResults}
          onCancel={() => setShowChart(false)}
        />
      )}
    </>
  );
};

export default VanillaQuote;
