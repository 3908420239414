import { useContext } from "react";
import { UserContext } from "../components/functional";

export const useUserContext = () => {
  const { context, setContext } = useContext(UserContext);

  return {
    context,
    setContext: (obj: any) => {
      setContext({ ...context, ...obj });
    },
    resetContext: () => {
      setContext({});
    },
  };
};
