import {
  GridColumnMenuCheckboxFilter,
  GridColumnMenuProps,
} from "@progress/kendo-react-grid";

export interface ColumnMenuProps extends GridColumnMenuProps {
  data: any;
  onChange?: ((filters: any[] | undefined) => void) | undefined;
}

export const ColumnMenuCheckboxFilter = (props: ColumnMenuProps) => {
  return (
    <div>
      <GridColumnMenuCheckboxFilter
        {...props}
        data={props.data.sort((a: any, b: any) => props.column.field ? (a[props.column.field] > b[props.column.field] ? 1 : -1) : 0)}
        expanded={true}
        onFilterChange={(filter, event) => {
          if (props.onChange)
            props.onChange(filter?.filters);

          if (props.onFilterChange)
            props.onFilterChange(filter, event);
        }}
      />
    </div>
  );
};
