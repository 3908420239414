import { useEffect } from "react";
import { TRANSACTION_DIRECTIONS } from "../utilities/vanilla/vanillaData";
import { TransactionDirection } from "../utilities/vanilla/vanillaEnums";

function useTransactionDirections(
  canCompanySell: boolean,
  requiresCanSellToTransact: boolean,
  onReset: () => void
) {
  const canSell = canCompanySell;
  const canBuy = canCompanySell || !requiresCanSellToTransact;

  useEffect(() => {
    if (!canBuy && !canSell) {
      onReset();
    }
  }, []);

  const transactionDirections = TRANSACTION_DIRECTIONS.map(({ text, value }) => {
    const isEnabled = value === TransactionDirection.Long ? canBuy : canSell;
    const chipItem = { text, value, disabled: !isEnabled };
    return chipItem;
  });

  return transactionDirections;
}

export default useTransactionDirections;
