import { apiClient } from "./apiClient";

const endpoint = "accumulator";

const getLookups = (id: number) =>
  apiClient(`${endpoint}/lookups`).fetchWithParams(`?instrumentId=${id}`);

const getQuoteDetails = (id: number) =>
  apiClient(`${endpoint}/quote`).fetchWithParams(`?quoteId=${id}`);

const saveQuoteDetails = (data: any) =>
  apiClient(`${endpoint}/quote`).post(data);

const getQuote = (quoteId: number) => 
  apiClient(`${endpoint}/quote`).put({quoteId: quoteId});

const getPricingDetails = (quoteId: number, pricingId: number) => 
  apiClient(`${endpoint}/pricing`).fetchWithParams(`?quoteId=${quoteId}&pricingId=${pricingId}`);


export default {
  getLookups,
  getQuoteDetails,
  saveQuoteDetails,
  getQuote,
  getPricingDetails,
};
