import { parseDate } from "@progress/kendo-intl";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import configApi from "../../../api/configuration";
import contractsApi from "../../../api/contracts";
import { useFetch } from "../../../hooks/useApi";
import { requiredValidator } from "../../../validators";
import { LoadingIndicator } from "../../LoadingIndicator";
import { FormDatePicker, FormInput, FormNumericTextBox } from "../../form";
import { FormChipList } from "../../form/FormChipList";

interface IAccumulatorStartProps {
  formRenderProps: FormRenderProps;
  product: any;
  lookups: {
    doubleUpTypes: any[];
    knockOutTypes: any[];
    settlementTypes: any[];
    types: any[];
  };
}

export const AccumulatorStartStep = ({
  formRenderProps,
  product,
  lookups,
}: IAccumulatorStartProps) => {
  const { commodity, instrument, company } = product;
  const [loading, setLoading] = useState(false);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);

  const {
    data: contractData,
    loading: contractLoading,
    error: contractError,
  } = useFetch(contractsApi.getDateBoundaries, commodity?.abbreviation);

  const {
    data: configData,
    loading: configLoading,
    error: configError,
  } = useFetch(
    configApi.getConfiguration,
    company.id,
    commodity.id,
    instrument.id,
    instrument.productType
  );

  useEffect(() => {
    const isLoading = contractLoading || configLoading;
    setLoading(isLoading);
    formRenderProps.onChange("loaded", { value: !isLoading });
  }, [contractLoading, configLoading]);

  useEffect(() => {
    setMinDate(parseDate(contractData?.minDate || ""));
    setMaxDate(parseDate(contractData?.maxDate || ""));
  }, [contractData]);

  useEffect(() => {
    const id = configData?.configurationId || 0;
    formRenderProps.onChange("configurationId", { value: id });
  }, [configData]);

  return (
    <div
      className="stepper-form"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div style={{ width: "60%" }}>
        <Field
          key={"quoteName"}
          id={"quoteName"}
          name={"quoteName"}
          label={"Quote Name"}
          component={FormInput}
          validator={requiredValidator}
        />
        <LoadingIndicator loading={loading} />

        <Error>{configError}</Error>
        <Error>{contractError}</Error>

        {!loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              {minDate && maxDate && (
                <Field
                  key={"contract"}
                  id={"contract"}
                  name={"contract"}
                  label={"Contract"}
                  component={FormDatePicker}
                  calendar={(props: any) => (
                    <Calendar bottomView="year" topView="year" {...props} />
                  )}
                  min={minDate}
                  max={maxDate}
                  format={"MMM yyyy"}
                  validator={requiredValidator}
                  wrapperStyle={{ maxWidth: 200 }}
                />
              )}
              <Field
                key={"instrumentTypeId"}
                id={"instrumentTypeId"}
                name={"instrumentTypeId"}
                label={"Type"}
                component={FormChipList}
                data={lookups.types}
                selection={"single"}
                validator={requiredValidator}
              />
              <Field
                key={"knockOutTypeId"}
                id={"knockOutTypeId"}
                name={"knockOutTypeId"}
                label={"Knock Out"}
                component={FormChipList}
                data={lookups.knockOutTypes}
                selection={"single"}
                validator={requiredValidator}
              />

              <Field
                key={"doubleUpTypeId"}
                id={"doubleUpTypeId"}
                name={"doubleUpTypeId"}
                label={"Double Up"}
                component={FormChipList}
                data={lookups.doubleUpTypes}
                selection={"single"}
                validator={requiredValidator}
              />

              <Field
                key={"settlementTypeId"}
                id={"settlementTypeId"}
                name={"settlementTypeId"}
                label={"Fixing Frequency"}
                component={FormChipList}
                data={lookups.settlementTypes}
                selection={"single"}
                validator={requiredValidator}
              />
            </div>
            <div>
              <Field
                key={"volume"}
                id={"volume"}
                name={"volume"}
                label={`Volume (${commodity?.units})`}
                component={FormNumericTextBox}
                spinners={false}
                validator={requiredValidator}
                format={"n0"}
                min={0}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
