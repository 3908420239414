import { parseDate } from "@progress/kendo-intl";
import { Field, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { useEffect, useState } from "react";
import allocationApi from "../../../api/allocation";
import contracts from "../../../api/contracts";
import { getComponents } from "../../../api/vanilla";
import { IApiResults, useFetch } from "../../../hooks/useApi";
import { useVanillaUtil } from "../../../hooks/useVanilla";
import { BusinessType } from "../../../types/businessType";
import DateUtility from "../../../utilities/dateUtilities";
import { StrikeType } from "../../../utilities/vanilla/vanillaEnums";
import { requiredCheckboxValidator } from "../../../validators";
import { LoadingIndicator } from "../../LoadingIndicator";
import FormCheckbox from "../../form/FormCheckBox";
import VanillaQuote from "./VanillaQuote";
import VanillaStructure from "./VanillaStructure";
import VanillaSubmit from "./VanillaSubmit";

export interface GetIntraDayPricesRequest {
  instrumentType: number;
  tickerCode: string;
  startDate: string;
  endDate: string;
}

type Props = {
  formRenderProps: FormRenderProps;
  step: number;
  product: any;
  marketStatsResults: IApiResults;
  structureName: string;
};

type UserDataItem = {
  date: string;
  volume: number;
  prices: UserStrike[];
};

type UserStrike = {
  componentId: number;
  strike: number;
};

// This component fetches intraday prices before rendering the structuring tool
const VanillaStructureContainer = ({
  formRenderProps,
  step,
  product,
  marketStatsResults,
  structureName,
}: Props) => {
  const { commodity, company, instrument } = product;
  const { calendarMonthOffset } = instrument;

  const contractStart = formRenderProps.valueGetter("contract.start");
  const contractEnd = formRenderProps.valueGetter("contract.end");
  const userData = formRenderProps.valueGetter("prices");
  const [startMonth, endMonth] = [contractStart, contractEnd].map((string) => {
    let date = DateUtility.parseMonth(string);
    return date;
  });

  const params: GetIntraDayPricesRequest = {
    tickerCode: commodity.abbreviation,
    instrumentType: instrument.id,
    startDate: DateUtility.formatDateYMD(startMonth),
    endDate: DateUtility.formatDateYMD(endMonth),
  };

  const { data: pricesData, loading, error } = useFetch(contracts.getIntraDayPrices, params);

  const structureId = formRenderProps.valueGetter("structureId");
  const { data: componentsData } = useFetch(getComponents, structureId);

  const components = componentsData?.components || [];

  const prices = pricesData?.prices ? Object.values(pricesData.prices) : [];

  const getAllocationsApi = useFetch(allocationApi.getAllocations, company.id);

  let allocations = getAllocationsApi.data?.allocations || [];

  const DERIVATIVES = BusinessType.Derivatives;
  allocations = allocations.filter((allocation: any) => {
    const isInsurance = allocation.businessTypeIds.some((id: number) => id === DERIVATIVES);
    const matchesCommodityType = allocation.commodityTypes.some(
      (commodityType: string) => commodityType === commodity.type,
    );

    const isMatch = isInsurance && matchesCommodityType;
    return isMatch;
  });

  const transactionDirection = formRenderProps.valueGetter("direction");
  const vanillaUtil = useVanillaUtil(
    components,
    startMonth,
    endMonth,
    prices as number[],
    transactionDirection,
  );

  const [isFormPrefilled, setIsFormPrefilled] = useState(false);

  // When editing a quote, check if the user modified the start or end date or the structure
  // If not, prefill the structuring step with the values from when previously quoted
  // If yes, reset everything to defaults.
  useEffect(() => {
    if (vanillaUtil && userData && userData.length && !isFormPrefilled) {
      let prevStartDate = parseDate(userData[0].date);
      let prevEndDate = parseDate(userData[userData.length - 1].date);
      prevStartDate = DateUtility.addMonthOffset(prevStartDate, -1 * calendarMonthOffset);
      prevEndDate = DateUtility.addMonthOffset(prevEndDate, -1 * calendarMonthOffset);

      const isDateMatch =
        prevStartDate.getTime() === startMonth.getTime() &&
        prevEndDate.getTime() === endMonth.getTime();

      const isStructureMatch =
        userData[0].prices.every((item: UserStrike, i: number) => {
          return item.componentId === components[i]?.componentId;
        }) && userData[0].prices.length === components.length;

      const isMatch = isDateMatch && isStructureMatch;

      if (isMatch) {
        vanillaUtil.setIsCommonStrike(false);
        const FIXED_STRIKE = StrikeType.Fixed;
        // assume everything is in order
        userData.forEach(({ volume, date: _date, prices }: UserDataItem, i: number) => {
          vanillaUtil.setVolume(volume, i);
          prices.forEach(({ strike, componentId: _componentId }: UserStrike, j: number) => {
            vanillaUtil.setStrike(strike, j, FIXED_STRIKE, i);
          });
        });
      }
      setIsFormPrefilled(true);
    }
  }, [vanillaUtil]);

  const isLoading = vanillaUtil === null;
  useEffect(() => {
    formRenderProps.onChange("loaded", { value: !isLoading });
  }, [isLoading]);

  const direction = formRenderProps.valueGetter("direction");
  useEffect(() => {
    if (direction && !isLoading) {
      vanillaUtil.setDirection(direction);
    }
  }, [direction, isLoading]);

  return (
    <>
      <LoadingIndicator loading={isLoading} />
      {!isLoading && step === 1 && (
        <VanillaStructure
          formRenderProps={formRenderProps}
          calculator={vanillaUtil}
          allocations={allocations}
          commodity={commodity}
          product={product}
        />
      )}
      {!isLoading && step === 2 && (
        <VanillaQuote
          formRenderProps={formRenderProps}
          calculator={vanillaUtil}
          product={product}
          marketStatsResults={marketStatsResults}
          structureName={structureName}
        />
      )}
      {!isLoading && step === 3 && (
        <VanillaSubmit
          formRenderProps={formRenderProps}
          calculator={vanillaUtil}
          product={product}
        />
      )}
      <FormElement style={{ visibility: "hidden", display: "none" }}>
        <Field
          name="loaded"
          component={FormCheckbox}
          validator={requiredCheckboxValidator}
          showValidationMessage={false}
        />
      </FormElement>
    </>
  );
};

export default VanillaStructureContainer;
