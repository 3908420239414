import axios, { AxiosRequestConfig } from "axios";
import { pca } from "..";
import { loginApiRequest } from "../config/authConfig";
import settings from "../config/settings";

const PDF_CONFIG: AxiosRequestConfig = {
  responseType: "arraybuffer",
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/pdf"
  }
};

axios.interceptors.request.use(
  async (config: any) => {
    const accounts = pca.getAllAccounts();
    const account = accounts.length ? accounts[0] : undefined;
    const item = localStorage.getItem("context");
    const userCompany = item ? JSON.parse(item) : null;

    const msalResponse = await pca.acquireTokenSilent({
      ...loginApiRequest,
      account: account,
    });

    config.headers = { 
      ...config.headers,
      authorization: `Bearer ${msalResponse.accessToken}`,
      "X-Active-Company": userCompany?.id || 0
    };


    // let currentDate = new Date().valueOf();
    // console.log(currentDate);
    
    // console.log(msalResponse);
    // console.log(msalResponse.accessToken);
    return config;
  },
  (error: any) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error.response?.status === 401) {
      (window as Window).location =
        window.location.protocol + "//" + window.location.host + "/login";
    } 
    else {
      return Promise.reject(error);
    }
  }
);

export const apiClient = (endpoint: string) => {
  let baseUrl = settings.apiUrl;
  let url = baseUrl + endpoint + "/";


  return {
    fetch: () => axios.get(url),
    fetchById: (id: number) => axios.get(url + id),
    fetchWithParams: (params: string) => axios.get(url + params),
    fetchWithParamsObject: (params: any) => axios.get(url, { params }),
    fetchPdf: (params: string) => axios.get(url + params, PDF_CONFIG),
    post: (newRecord: any) => axios.post(url, newRecord),
    put: (updatedRecord: any) => axios.put(url, updatedRecord),
    delete: (id: number) => axios.delete(url, {data: {id : id}}),
    postFormData: (formData: FormData)=> axios.post(url,formData),
    putFormData: (formData: FormData)=> axios.put(url,formData)
  };
};
